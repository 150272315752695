
import OrganizationApiService from "@/services/OrganizationApiService";
import Guid from "@/types/Guid";
import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";

@Component({
  components: {
    VMSTextField,
    VMSTextArea,
    VMSSelect,
    VMSAutoComplete
  }
})
export default class OrganizationCreate extends Vue {
  orgApiService: OrganizationApiService = new OrganizationApiService();
  org: UpdateOrganizationRequest = new UpdateOrganizationRequest();

  canadaCountryId: string = "";
  regions: GetOrgEnumResponse[] = [];
  genderDistributions: GetOrgEnumResponse[] = [];
  languages: GetOrgEnumResponse[] = [];
  types: GetOrgEnumResponse[] = [];
  subTypes: GetOrgEnumResponse[] = [];
  schoolBoards: GetOrgEnumResponse[] = [];
  gradeLevels: GetOrgEnumResponse[] = [];
  status: object[] = [
    {
      text: this.$root.$t("New"),
      value: 0
    },
    {
      text: this.$root.$t("Verified"),
      value: 1
    },
    {
      text: this.$root.$t("Merged"),
      value: 2
    }
  ];

  mounted() {
    this.orgApiService
      .All([
        this.orgApiService.GetEnumerationList("Country", this.$i18n.locale),
        this.orgApiService.GetEnumerationList("Region", this.$i18n.locale),
        this.orgApiService.GetEnumerationList(
          "SchoolClassType",
          this.$i18n.locale
        ),
        this.orgApiService.GetEnumerationList(
          "SchoolLanguage",
          this.$i18n.locale
        ),
        this.orgApiService.GetEnumerationList("SchoolType", this.$i18n.locale),
        this.orgApiService.GetEnumerationList(
          "SchoolSubType",
          this.$i18n.locale
        ),
        this.orgApiService.GetEnumerationList(
          "LTS_SchoolBoard",
          this.$i18n.locale
        ),
        this.orgApiService.GetEnumerationList("GradeLevels", this.$i18n.locale)
      ])
      .then(resps => {
        let countries: GetOrgEnumResponse[] = resps[0].Value;
        let canada: GetOrgEnumResponse | undefined = countries.find(
          c => c.Text === "Canada"
        );
        if (canada) this.canadaCountryId = canada.Id;

        this.regions = resps[1].Value;
        this.genderDistributions = resps[2].Value;
        this.languages = resps[3].Value;
        this.types = resps[4].Value;
        this.subTypes = resps[5].Value;
        this.schoolBoards = resps[6].Value;
        this.gradeLevels = resps[7].Value;
      });

    if (this.$route.query.id) {
      let orgId: string = this.$route.query.id as string;
      console.log(`getting data for org ${orgId}`);
      this.orgApiService.GetOrganizationById(orgId).then(resp => {
        if (resp && resp.Success) {
          this.org = (resp.Value as object) as UpdateOrganizationRequest;
        }
      });
    }
  }

  onSaveClicked() {
    if ((this.$refs.OrgForm as any).validate()) {
      if (!this.org.RegionId) {
        this.org.RegionId = Guid.Empty;
      }

      if (this.org.Id.length > 0) {
        this.orgApiService
          .UpdateOrganization(this.org)
          .then(resp => {
            if (resp && resp.Success) {
              this.$router.push("/organizations");
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      } else {
        this.org.CountryId = this.canadaCountryId;
        this.orgApiService
          .CreateOrganization(this.org)
          .then(resp => {
            if (resp && resp.Success) {
              this.$router.push("/organizations");
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      }
    } else {
      this.$ScrollToFirstError();
    }
  }
}
