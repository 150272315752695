import Guid from "../Guid";

export default class GetOrganizationResponse {
  Id: string = Guid.Empty;
  Name: string = "";
  CountryId: string = Guid.Empty;
  RegionId: string = Guid.Empty;
  City: string = "";
  Address: string = "";
  PhysicalAddress: string = "";
  PostalCode: string = "";
  PhysicalPostalCode: string = "";
  TelephoneNumber: string = "";
  FaxNumber: string = "";
  IsLowIncome: boolean = false;
  IsPrimarilyFnmi: boolean = false;
  RepresentedFnmi: string = "";
  GenderDistributionId: string = Guid.Empty;
  LanguageId: string = Guid.Empty;
  TypeId: string = "";
  SubTypeId: string = "";
  SchoolBoardId: string = "";
  GradeLevelsId: string = "";
  Validated: boolean = false;
  SchoolBoard: string = "";
  Editable: boolean = false;
  Status: number = 0;
  StatusDate: string = "";
  DateClosed: string = "";
  StatusComments: string = "";
  MergedId: string = "";
  CreationSource: string = "";
}
