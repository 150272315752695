
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import VolunteerLookup from "@/components/hourlogging/VolunteerLookup.vue";
import VolunteerLookupItem from "@/components/hourlogging/VolunteerLookupItem";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import DataObject from "@/types/DataObject";
import {
  WorkshopFilter,
  WorkshopFilterTypes
} from "@/types/Workshop/WorkshopList/WorkshopFilter";
import EducatorLookup from "@/components/educator/EducatorLookup.vue";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";

@Component({
  components: {
    VolunteerLookup,
    SchoolLookup,
    EducatorLookup
  }
})
export default class WorkshopListFilter extends Vue {
  @Prop(Array) value!: WorkshopFilter[];

  menu_open: boolean = false;
  filters: WorkshopFilter[] = this.value || [];

  filterVolunteer: VolunteerLookupItem | null = null;
  filterSchoolId: string | null = null;
  schoolDictionary: DataObject = new DataObject();
  volunteerDictionary: DataObject = new DataObject();
  educatorDictionary: DataObject = new DataObject();

  filterEducator: EducatorAuxData | null = null;

  onVolunteerFilterApplied() {
    if (this.filterVolunteer) {
      let fn: string = "";
      if (this.filterVolunteer.Subject) {
        fn = this.filterVolunteer.Subject.FullName;
      }
      let val: object = {
        SubjectId: this.filterVolunteer.SubjectId,
        SiteId: this.filterVolunteer.SiteId
      };
      this.filters.push(
        new WorkshopFilter(WorkshopFilterTypes.Volunteer, val, fn)
      );

      this.onInput();
    }
  }

  onSchoolSelected(org: GetOrganizationResponse) {
    if (!this.schoolDictionary[org.Id]) {
      this.schoolDictionary[org.Id] = org;
    }
  }

  onEducatorFilterApplied() {
    if (this.filterEducator) {
      let en: string = "";
      if (this.filterEducator.FirstName && this.filterEducator.LastName)
        en = this.filterEducator.FirstName.concat(
          " ",
          this.filterEducator.LastName
        );

      this.filters.push(
        new WorkshopFilter(
          WorkshopFilterTypes.Educator,
          this.filterEducator.Id,
          en
        )
      );

      this.onInput();
    }
  }

  onSchoolFilterApplied() {
    if (this.filterSchoolId) {
      this.filters.push(
        new WorkshopFilter(
          WorkshopFilterTypes.School,
          this.filterSchoolId,
          this.schoolDictionary[this.filterSchoolId].Name
        )
      );

      (this.$refs.schoolLookup as SchoolLookup).Clear();
      this.onInput();
    }
  }

  onInput() {
    this.menu_open = false;
    this.$emit("input", this.filters);
  }
}
