import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import EvaluationReponse from "@/types/Evaluation/EvaluationResponse";

export default class EvluationApiService extends BaseApiService {
  async GetEvaluationByWorkshopId(
    id: string
  ): Promise<UseCaseResponse<EvaluationReponse>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Evaluation?workshopId=${id}`)
      .then(resp => resp.data as UseCaseResponse<EvaluationReponse>);
  }
}
