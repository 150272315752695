
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ConfirmButton extends Vue {
  @Prop(String) value: String | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) icon: String | undefined;
  @Prop(String) arialabel: String | undefined;
  @Prop(String) tooltip: String | undefined;
  @Prop(Boolean) disabled: boolean | undefined;

  confirm: boolean = false;

  onClick() {
    this.$emit("click");
    this.confirm = true;
  }

  onConfirmed() {
    this.confirm = false;
    this.$emit("confirmed");
  }
}
