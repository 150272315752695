
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DataObject from "../../types/DataObject";
import Subject from "@/types/Subject/Subject";
import SubjectApiService from "@/services/SubjectApiService";
import VolunteerProfile from "@/types/Profile/VolunteerProfile";
import SeasonApiService from "@/services/SeasonApiService";
import Season from "@/types/Season";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import SiteRegistrationApiService from "@/services/SiteRegistrationApiService";
import SiteApiService from "@/services/SiteApiService";
import Site from "@/types/Site/Site";
import SiteRegistrationConfig from "@/types/SiteRegistration/SiteRegistrationConfig";
import SiteSpecificResponse from "@/types/SiteRegistration/SiteSpecificResponse";
import EnumerationResponseItem from "@/types/Enumeration/EnumerationResponseItem";
import EmailDialog from "@/components/EmailDialog.vue";
import EnumerationResponseHeader from "@/types/Enumeration/EnumerationResponseHeader";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationListItem from "@/types/Enumeration/EnumerationListItem";
import Enumeration from "@/types/Enumeration/Enumeration";
import EnumerationApiService from "@/services/EnumerationApiService";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

class DisplayItem {
  Subject: Subject = new Subject();
  Over18Str: string = "";
  MediaConsentStr: string = "";
  PronounPreferenceStr: string = "";
  DeliveryLanguageStrs: string[] = [];
  AreasOfStudyStrs: string[] = [];
  SortedRegistrations: SiteRegistration[] = [];

  HighSchoolSubjectsArray: string[] = [];
  CampusArray: string[] = [];
  AgeGroupsArray: string[] = [];
  VehicleAccess: string = "";
  CanDriveRental: string = "";
  Location: string = "";
  IndigenousProgramsInterest: string = "";
  PreferredActivitiesArray: string[] = [];

  get HighSchoolSubjects(): string {
    return this.HighSchoolSubjectsArray.join(", ");
  }

  get Campus(): string {
    return this.CampusArray.join(", ");
  }

  get AgeGroups(): string {
    return this.AgeGroupsArray.join(", ");
  }

  get DietaryRestrictions(): string {
    return this.SiteSpecificRegistration.DietaryRestrictions || "";
  }

  get PreferredSchoolEducator(): string {
    return this.SiteSpecificRegistration.PreferredSchoolEducator || "";
  }

  get LocalSchools(): string {
    return this.SiteSpecificRegistration.LocalSchools || "";
  }

  get PreferredVolunteers(): string {
    return this.SiteSpecificRegistration.PreferredVolunteers || "";
  }

  get WhyVolunteer(): string {
    return this.SiteSpecificRegistration.WhyVolunteer || "";
  }

  get AvailableTimes(): string {
    return this.SiteSpecificRegistration.AvailableTimes || "";
  }

  get PreferredActivities(): string {
    return this.PreferredActivitiesArray.join(", ");
  }

  get SiteSpecificRegistration(): SiteSpecificResponse {
    return (
      this.LatestEnrolment.SiteSpecificResponse || new SiteSpecificResponse()
    );
  }

  get Profile(): VolunteerProfile {
    return this.Subject.VolunteerProfile || new VolunteerProfile();
  }

  get LatestEnrolment(): SiteRegistration {
    const length: number = this.SortedRegistrations.length;
    if (length > 0) return this.SortedRegistrations[length - 1];
    else return new SiteRegistration();
  }

  get LatestEnrolmentDate(): Date {
    return new Date(this.LatestEnrolment.CreatedOn);
  }

  get LatestEnrolmentSeason(): string {
    let lrs = this.LatestEnrolment.Season;
    if (lrs)
      return `${lrs.StartDate.substr(0, 10)} : ${lrs.EndDate.substr(0, 10)}`;
    else return "";
  }

  get ApprovalStatus(): number {
    const enrolment: SiteRegistration = this.LatestEnrolment;
    if (!enrolment.IsApproved) {
      if (enrolment.ApprovalDate) return 3;
      //withdrawn
      else return 0; //pending
    } else {
      if (enrolment.Season && enrolment.Season.Active) return 1;
      //Approved
      else return 2; //"Alumni";
    }
  }

  get ApprovalStatusStr(): string {
    switch (this.ApprovalStatus) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Alumni";
      default:
        return "Withdrawn";
    }
  }

  get Faculty(): string {
    let ret: string = "";
    if (
      this.Subject.VolunteerProfile &&
      this.Subject.VolunteerProfile.StudentProfile
    ) {
      ret = this.Subject.VolunteerProfile.StudentProfile.Faculty;
    }

    return ret;
  }

  get Department(): string {
    let ret: string = "";
    if (
      this.Subject.VolunteerProfile &&
      this.Subject.VolunteerProfile.StudentProfile
    ) {
      ret = this.Subject.VolunteerProfile.StudentProfile.Department;
    }

    return ret;
  }

  get CovidInPersonApproved(): boolean {
    if (this.Subject.VolunteerProfile) {
      return (
        this.Subject.VolunteerProfile.CovidVaccinesReceived === true &&
        this.Subject.VolunteerProfile.AcceptCovidBestPracticesAgreement === true
      );
    } else {
      return false;
    }
  }

  constructor(s: Subject) {
    this.Subject = s;

    this.SortedRegistrations = this.Subject.SiteRegistrations;
    this.SortedRegistrations.sort((a, b) => {
      let aco: Date = new Date(a.CreatedOn);
      let bco: Date = new Date(b.CreatedOn);
      if (aco < bco) return -1;
      else if (aco > bco) return 1;
      else return 0;
    });
  }
}

@Component({
  components: {
    EmailDialog,
    VMSTextField,
    VMSSelect
  }
})
export default class SiteVolunteerManagement extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  seasons: Season[] = [];
  seasonApiService: SeasonApiService = new SeasonApiService();

  subjectApiService: SubjectApiService = new SubjectApiService();

  siteApiService: SiteApiService = new SiteApiService();
  site: Site = new Site();

  siteRegistrationApiService: SiteRegistrationApiService = new SiteRegistrationApiService();

  wrappers: DisplayItem[] = [];
  selectedItems: DisplayItem[] = [];
  enrolmentDialog: boolean = false;
  statusFilterValue: number | null = 1;
  searchText: string = "";
  over18FilterValue: string | null = null;
  mediaConsentFilterValue: string | null = null;
  languageFilterValue: string[] = [];
  covidInPersonApprovedFilterValue: boolean | null = null;
  emails: string = "";
  emailsDialog: boolean = false;

  enumApiService: EnumerationApiService = new EnumerationApiService();
  yesNoEnumList: EnumerationList = new EnumerationList();
  languagesEnumList: EnumerationList = new EnumerationList();
  pronounsEnumList: EnumerationList = new EnumerationList();

  visibleHeaders: object[] = [];

  selectAll: boolean = false;

  sortOn: string | undefined = "";
  sortDesc: boolean = false;

  approveClicked: boolean = false;
  approve: boolean = false;

  get headers(): object[] {
    return [
      {
        text: this.$root.$t("Name"),
        localeKey: "Name",
        align: "left",
        value: "Subject.FullName",
        defaultVisible: true,
        visibilityToggle: true,
        index: 0
      },
      {
        text: this.$root.$t("LatestRegistration"),
        localeKey: "LatestRegistration",
        align: "left",
        value: "LatestEnrolmentDate",
        defaultVisible: true,
        visibilityToggle: true,
        sort: this.$DateSort,
        index: 1
      },
      {
        text: this.$root.$t("LatestRegistrationSeason"),
        localeKey: "LatestRegistrationSeason",
        align: "left",
        value: "LatestEnrolmentSeason",
        defaultVisible: true,
        visibilityToggle: true,
        index: 2
      },
      {
        text: this.$root.$t("RegistrationStatus"),
        localeKey: "RegistrationStatus",
        align: "left",
        value: "ApprovalStatusStr",
        filter: this.approvalStatusFilter,
        defaultVisible: true,
        visibilityToggle: true,
        index: 3
      },
      {
        text: this.$root.$t("Over18Col"),
        localeKey: "Over18Col",
        align: "left",
        value: "Over18Str",
        filter: this.over18Filter,
        defaultVisible: false,
        visibilityToggle: true,
        index: 4
      },
      {
        text: this.$root.$t("MediaConsentCol"),
        localeKey: "MediaConsentCol",
        align: "left",
        value: "MediaConsentStr",
        filter: this.mediaConsentFilter,
        defaultVisible: false,
        visibilityToggle: true,
        index: 5
      },
      {
        text: this.$root.$t("Faculty"),
        localeKey: "Faculty",
        align: "left",
        value: "Faculty",
        defaultVisible: false,
        visibilityToggle: true,
        index: 6
      },
      {
        text: this.$root.$t("Department"),
        localeKey: "Department",
        align: "left",
        value: "Department",
        defaultVisible: false,
        visibilityToggle: true,
        index: 7
      },
      {
        text: this.$root.$t("PronounPreference"),
        localeKey: "PronounPreference",
        align: "left",
        value: "PronounPreferenceStr",
        defaultVisible: false,
        visibilityToggle: true,
        index: 8
      },
      {
        text: this.$root.$t("DeliveryLanguagesCol"),
        localeKey: "DeliveryLanguagesCol",
        align: "left",
        value: "DeliveryLanguageStrs",
        filter: this.languagePreferenceFilter,
        defaultVisible: false,
        visibilityToggle: true,
        index: 9
      },
      {
        text: this.$root.$t("AreaOfStudyOrExpertise"),
        localeKey: "AreaOfStudyOrExpertise",
        align: "left",
        value: "AreasOfStudyStrs",
        defaultVisible: false,
        visibilityToggle: true,
        index: 10
      },
      {
        text: this.$root.$t("CovidInPersonApproved"),
        localeKey: "CovidInPersonApproved",
        align: "left",
        value: "CovidInPersonApproved",
        defaultVisible: false,
        visibilityToggle: true,
        filter: this.covidInPersonApprovedFilter,
        index: 11
      }
    ];
  }

  get ApprovalStatusItems(): object[] {
    return [
      {
        text: this.$t("Pending"),
        value: 0
      },
      {
        text: this.$t("Approved"),
        value: 1
      },
      {
        text: this.$t("Alumni"),
        value: 2
      },
      {
        text: this.$t("Withdrawn"),
        value: 3
      }
    ];
  }

  get SortedVisibleHeaders(): object[] {
    return this.visibleHeaders.sort(
      (a, b) => (a as any).index - (b as any).index
    );
  }

  get ToggleableHeaders(): object[] {
    return this.headers.filter((h: DataObject) => {
      return h["visibilityToggle"] === true;
    });
  }

  enableColumn(colName: string) {
    const header: object | undefined = this.headers.find(
      h => (h as any).value === colName
    );
    if (header) {
      if (!this.visibleHeaders.includes(header)) {
        this.visibleHeaders.push(header);
      }
    }
  }

  updateSort(v: string | undefined) {
    this.sortOn = v;
  }

  updateSortDesc(v: boolean) {
    this.sortDesc = v;
  }

  sortActive(v: any): boolean {
    return this.sortOn === v;
  }

  enumListToItemsList(list: EnumerationList): object[] {
    let ret: object[] = [];
    let loc: string = this.$i18n.locale;
    list.EnumerationItems.forEach((eli: EnumerationListItem) => {
      let ls: string = this.$root
        .$t(eli.Enumeration.LocalizationKey)
        .toString();
      ret.push({
        text: ls,
        value: eli.EnumerationId
      });
    });

    return ret;
  }

  approvalStatusFilter(
    val: any,
    search: string | undefined,
    item: DisplayItem
  ): boolean {
    if (this.statusFilterValue === null) {
      return true;
    } else {
      return item.ApprovalStatus === this.statusFilterValue;
    }
  }

  over18Filter(
    val: any,
    search: string | undefined,
    item: DisplayItem
  ): boolean {
    if (this.over18FilterValue === null) {
      return true;
    } else {
      return item.Profile.EighteenOrOverId === this.over18FilterValue;
    }
  }

  mediaConsentFilter(
    val: any,
    search: string | undefined,
    item: DisplayItem
  ): boolean {
    if (this.mediaConsentFilterValue === null) {
      return true;
    } else {
      return item.Profile.MediaConsentId === this.mediaConsentFilterValue;
    }
  }

  languagePreferenceFilter(
    val: any,
    search: string | undefined,
    item: DisplayItem
  ): boolean {
    if (this.languageFilterValue.length === 0) {
      return true;
    } else {
      return this.languageFilterValue.some(l =>
        //item.Profile.DeliveryLanguages.includes(l)
        item.Profile.DeliveryLanguages.Items.map(
          eri => eri.EnumerationId
        ).includes(l)
      );
    }
  }

  covidInPersonApprovedFilter(
    val: any,
    search: string | undefined,
    item: DisplayItem
  ): boolean {
    if (this.covidInPersonApprovedFilterValue === null) {
      return true;
    } else {
      return (
        item.CovidInPersonApproved === this.covidInPersonApprovedFilterValue
      );
    }
  }

  getToggleApprovalIcon(item: any): string {
    return item.LatestEnrolment.IsApproved
      ? "mdi-account-cancel-outline"
      : "mdi-account-check-outline";
  }

  getToggleApprovalTooltip(item: any): string {
    return item.LatestEnrolment.IsApproved
      ? (this.$root.$t("Unapprove") as string)
      : (this.$root.$t("Approve") as string);
  }

  GetSiteId(): string {
    return this.siteId || "";
  }

  mounted() {
    this.$SetLoading(true);

    this.siteApiService.GetSiteById(this.GetSiteId()).then(resp => {
      this.site = resp.Value;
      this.setSiteSpecificHeaders();
    });

    this.enumApiService
      .GetEnumerationListsBySystemName(["YesNo", "Pronouns", "Languages"])
      .then(resp => {
        let lists: EnumerationList[] = resp.Value;
        this.yesNoEnumList =
          lists.find(x => x.SystemName === "YesNo") || new EnumerationList();
        this.pronounsEnumList =
          lists.find(x => x.SystemName === "Pronouns") || new EnumerationList();
        this.languagesEnumList =
          lists.find(x => x.SystemName === "Languages") ||
          new EnumerationList();
      });

    const q_status = (this.$router.currentRoute.query.status as string) || "";
    const i_status = Number.parseInt(q_status);
    if (!Number.isNaN(i_status)) {
      this.statusFilterValue = i_status;
    }

    this.getData();
  }

  getData() {
    if (this.siteId) {
      this.$SetLoading(true);

      this.subjectApiService
        .GetSubjectsBySite(this.siteId)
        .then(resp => {
          let subjects: Subject[] = resp.Value;
          this.wrapItems(subjects);
        })
        .finally(() => {
          this.$SetLoading(false);
        });
    }
  }

  setSiteSpecificHeaders() {
    let idx: number = this.headers.length;
    if (this.site.RegistrationConfig) {
      let registrationConfig: SiteRegistrationConfig = JSON.parse(
        this.site.RegistrationConfig
      ) as SiteRegistrationConfig;
      for (let prop in registrationConfig) {
        if ((registrationConfig as any)[prop] === true) {
          this.headers.push({
            text: this.$t(prop),
            localeKey: prop,
            align: "left",
            value: prop,
            index: idx++,
            defaultVisible: false,
            visibilityToggle: true
          });
        }
      }
    }

    this.headers.push({
      text: "",
      align: "right",
      value: "Actions",
      defaultVisible: true,
      visibilityToggle: false,
      index: idx
    });

    this.visibleHeaders = this.headers.filter((h: DataObject) => {
      return h["defaultVisible"] === true;
    });
  }

  localizeEnum(e: Enumeration | undefined) {
    let ret: string = "";
    if (e) {
      ret = this.$root.$t(e.LocalizationKey).toString();
    }

    return ret;
  }

  wrapItems(subjects: Subject[]) {
    let items: DisplayItem[] = [];

    subjects.forEach((s: Subject) => {
      let di: DisplayItem = new DisplayItem(s);
      let profile: VolunteerProfile =
        s.VolunteerProfile || new VolunteerProfile();

      di.Over18Str = this.localizeEnum(profile.EighteenOrOver);
      di.MediaConsentStr = this.localizeEnum(profile.MediaConsent);

      di.PronounPreferenceStr = this.localizeEnum(profile.PronounPreference);

      di.DeliveryLanguageStrs = this.enumHeaderToArray(
        profile.DeliveryLanguages
      );
      di.AreasOfStudyStrs = this.enumHeaderToArray(
        profile.AreasOfStudyOrExpertise
      );

      if (di.LatestEnrolment.SiteSpecificResponse) {
        if (di.LatestEnrolment.SiteSpecificResponse.VehicleAccess) {
          if (di.SiteSpecificRegistration.VehicleAccess) {
            di.VehicleAccess = this.localizeEnum(
              di.SiteSpecificRegistration.VehicleAccess
            );
          }
        }

        if (di.LatestEnrolment.SiteSpecificResponse.CanDriveRental) {
          if (di.SiteSpecificRegistration.CanDriveRental) {
            di.CanDriveRental = this.localizeEnum(
              di.SiteSpecificRegistration.CanDriveRental
            );
          }
        }

        if (di.LatestEnrolment.SiteSpecificResponse.Location) {
          if (di.SiteSpecificRegistration.Location) {
            di.Location = this.localizeEnum(
              di.SiteSpecificRegistration.Location
            );
          }
        }

        if (
          di.LatestEnrolment.SiteSpecificResponse.IndigenousProgramsInterest
        ) {
          if (di.SiteSpecificRegistration.IndigenousProgramsInterest) {
            di.IndigenousProgramsInterest = this.localizeEnum(
              di.SiteSpecificRegistration.IndigenousProgramsInterest
            );
          }
        }

        if (di.LatestEnrolment.SiteSpecificResponse.CampusHeader) {
          if (di.SiteSpecificRegistration.CampusHeader) {
            di.CampusArray = this.enumHeaderToArray(
              di.SiteSpecificRegistration.CampusHeader
            );
          }
        }

        if (
          di.LatestEnrolment.SiteSpecificResponse.HighSchoolSubjectsEnumHeader
        ) {
          if (di.SiteSpecificRegistration.HighSchoolSubjectsEnumHeader) {
            di.HighSchoolSubjectsArray = this.enumHeaderToArray(
              di.SiteSpecificRegistration.HighSchoolSubjectsEnumHeader
            );
          }
        }

        if (di.LatestEnrolment.SiteSpecificResponse.AgeGroupsHeader) {
          if (di.SiteSpecificRegistration.AgeGroupsHeader) {
            di.AgeGroupsArray = this.enumHeaderToArray(
              di.SiteSpecificRegistration.AgeGroupsHeader
            );
          }
        }

        if (
          di.LatestEnrolment.SiteSpecificResponse.PreferredActivitiesEnumHeader
        ) {
          if (di.SiteSpecificRegistration.PreferredActivitiesEnumHeader) {
            di.PreferredActivitiesArray = this.enumHeaderToArray(
              di.SiteSpecificRegistration.PreferredActivitiesEnumHeader
            );
          }
        }
      }

      items.push(di);
    });

    this.selectedItems = [];
    this.wrappers = items;
  }

  enumHeaderToArray(header: EnumerationResponseHeader): string[] {
    let ret: string[] = [];
    header.Items.forEach((eri: EnumerationResponseItem) => {
      if (eri.Enumeration && eri.Enumeration.LocalizationKey) {
        let ls: string = this.$root
          .$t(eri.Enumeration.LocalizationKey)
          .toString();
        ret.push(ls);
      }
    });
    return ret;
  }

  onViewRegistrationClick(item: DisplayItem) {
    this.$router.push(
      `/volunteer/summary?subjectId=${item.Subject.Id}&registrationId=${item.LatestEnrolment.Id}`
    );
  }

  onViewNotesClick(item: any) {
    this.$router.push(`/subject/notes?subjectId=${item.Subject.Id}`);
  }

  onViewAwardsClick(item: any) {
    this.$router.push(`/subject/awards?subjectId=${item.Subject.Id}`);
  }

  onViewRecordClick(item: any) {
    this.$router.push(`/subject/record?subjectId=${item.Subject.Id}`);
  }

  showComfirmDialog(param: number) {
    this.approve = param === 1 ? true : false;
    this.approveClicked = true;
  }

  onToggleSelectedApprovalClick() {
    let updates: SiteRegistration[] = this.selectedItems.map(x => {
      return JSON.parse(JSON.stringify(x.LatestEnrolment));
    });

    updates.forEach((sr: SiteRegistration) => {
      sr.IsApproved = this.approve;
    });

    this.siteRegistrationApiService
      .PatchSiteRegistrations(updates)
      .then(resp => {
        if (resp.Success) this.getData();
      });

    this.approveClicked = false;
  }

  getEmails() {
    this.emails = this.selectedItems
      .map((di: DisplayItem) => {
        return di.Subject.Email;
      })
      .join(";");
    this.emailsDialog = true;
  }
}
