import UseCaseResponse from "@/types/UseCaseResponse";
import VolunteerHourType from "@/types/Volunteers/VolunteerHourType";
import BaseApiService from "./BaseApiService";
import axios from "axios";

export default class VolunteerHourTypeApiService extends BaseApiService {
  async GetVolunteerHourTypes(): Promise<UseCaseResponse<VolunteerHourType[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/VolunteerHourType`)
      .then(resp => resp.data as UseCaseResponse<VolunteerHourType[]>);
  }
}
