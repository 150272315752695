import BaseType from "../BaseType";
import Guid from "../Guid";
import EducatorAuxData from "./EducatorAuxData";

export default class EducatorLogItem extends BaseType {
  EducatorLogHeaderId: string = Guid.Empty;
  OrganizationId: string = Guid.Empty;
  EducatorAuxDataId: string = Guid.Empty;
  EducatorAuxData: EducatorAuxData = new EducatorAuxData();
  ActivityFeedback: boolean = false;
}
