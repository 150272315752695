
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import OrganizationApiService from "../../services/OrganizationApiService";
import ScreeningDocumentApiService from "../../services/ScreeningDocumentApiService";
import Guid from "../../types/Guid";
import ScreeningDocumentTrackingItem from "../../types/ScreeningDocument/ScreeningDocumentTrackingItem";
import DatePicker from "../DatePicker.vue";
import SchoolLookup from "../school/SchoolLookup.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";

@Component({
  components: {
    DatePicker,
    SchoolLookup,
    VMSTextField,
    VMSTextArea
  }
})
export default class ScreeningDocumentTrackItemEdit extends Vue {
  @Prop(Object) value: ScreeningDocumentTrackingItem | undefined;
  @Prop(String) batchId: string | undefined;

  orgApiService: OrganizationApiService = new OrganizationApiService();
  sdApiService: ScreeningDocumentApiService = new ScreeningDocumentApiService();

  trackingItem: ScreeningDocumentTrackingItem =
    JSON.parse(JSON.stringify(this.value)) ||
    new ScreeningDocumentTrackingItem();
  trackingItemBatchId: string = this.batchId || "";

  @Watch("value")
  onValueChanged() {
    this.trackingItem =
      JSON.parse(JSON.stringify(this.value)) ||
      new ScreeningDocumentTrackingItem();
  }

  @Watch("batchId")
  onBatchIdChanged() {
    this.trackingItemBatchId = this.batchId || "";
  }

  get TrackingItemDialogTitle(): string {
    if (this.trackingItem.Id && this.trackingItem.Id !== Guid.Empty) {
      return this.$t("EditTrackingItem") as string;
    } else {
      if (this.trackingItemBatchId) {
        return this.$t("AddTrackingItemBatch") as string;
      } else {
        return this.$t("AddTrackingItem") as string;
      }
    }
  }

  mounted() {}

  onCancelClick() {
    this.$emit("cancel");
  }

  onSaveTrackingItemClick() {
    if ((this.$refs.trackingItemForm as any).validate()) {
      if (this.trackingItem.Id && this.trackingItem.Id !== Guid.Empty) {
        this.sdApiService.UpdateTrackingItem(this.trackingItem).then(resp => {
          if (resp.Success) {
            this.emitSaved();
          }
        });
      } else {
        if (this.trackingItemBatchId) {
          this.sdApiService
            .CreateBatchTrackingItems(
              this.trackingItemBatchId,
              this.trackingItem
            )
            .then(resp => {
              this.emitSaved();
            });
        } else {
          this.sdApiService.CreateTrackingItem(this.trackingItem).then(resp => {
            this.emitSaved();
          });
        }
      }
    }
  }

  emitSaved() {
    this.$emit("saved", this.trackingItem);
  }
}
