import VolunteerProfile from "@/types/Profile/VolunteerProfile";
import BaseApiService from "./BaseApiService";
import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";

export default class VolunteerProfileApiService extends BaseApiService {
  async GetVolunteerProfileBySubjectId(
    subjectId: string
  ): Promise<UseCaseResponse<VolunteerProfile>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Subject/${subjectId}/VolunteerProfile`)
      .then(resp => resp.data as UseCaseResponse<VolunteerProfile>);
  }

  async CreateVolunteerProfile(
    profile: VolunteerProfile
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(
        `${this.url}/api/vms/Subject/${profile.SubjectId}/VolunteerProfile`,
        profile
      )
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateVolunteerProfile(
    profile: VolunteerProfile
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(
        `${this.url}/api/vms/Subject/${profile.SubjectId}/VolunteerProfile`,
        profile
      )
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteVolunteerProfile(
    profile: VolunteerProfile
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(
        `${this.url}/api/vms/Subject/${profile.SubjectId}/VolunteerProfile/${profile.Id}`
      )
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
