
import Vue from "vue";
import Component from "vue-class-component";
import OutreachRequestApiService from "@/services/OutreachRequestApiService";
import OutreachRequest, {
  OutreachType
} from "@/types/OutreachRequest/OutreachRequest";
import { RequestStatus } from "@/types/OutreachRequest/OutreachRequestStatus";

@Component
export default class OutreachRequestHistory extends Vue {
  apiService: OutreachRequestApiService = new OutreachRequestApiService();

  request: OutreachRequest = new OutreachRequest();

  get request_id(): string {
    return this.$route.params.requestId;
  }

  get status_headers(): object[] {
    return [
      {
        text: this.$root.$t("OutreachRequest.Assigned"),
        value: "CreatedOn"
      },
      {
        text: this.$root.$t("OutreachRequest.Status"),
        value: "Status"
      }
    ];
  }

  get site_headers(): object[] {
    return [
      {
        text: this.$root.$t("OutreachRequest.Assigned"),
        value: "CreatedOn"
      },
      {
        text: this.$root.$t("OutreachRequest.Site"),
        value: "Site"
      }
    ];
  }

  get status_records(): object[] {
    return this.request.StatusRecords.sort((a, b) => {
      let aco: Date = new Date(a.CreatedOn);
      let bco: Date = new Date(b.CreatedOn);
      if (aco < bco) return 1;
      else if (aco > bco) return -1;
      else return 0;
    }).map(s => {
      return {
        CreatedOn: s.CreatedOn.substring(0, 10),
        Status: this.$t(`OutreachRequest.Status.${RequestStatus[s.Status]}`)
      };
    });
  }

  get site_records(): object[] {
    return this.request.SiteRecords.sort((a, b) => {
      let aco: Date = new Date(a.CreatedOn);
      let bco: Date = new Date(b.CreatedOn);
      if (aco < bco) return 1;
      else if (aco > bco) return -1;
      else return 0;
    }).map(s => {
      return {
        CreatedOn: s.CreatedOn.substring(0, 10),
        Site: s.Site ? s.Site.Name : ""
      };
    });
  }

  get current_status(): string {
    if (this.request.LatestStatus) {
      return this.$t(
        `OutreachRequest.Status.${
          RequestStatus[this.request.LatestStatus.Status]
        }`
      ).toString();
    } else {
      return "";
    }
  }

  get current_site(): string {
    if (this.request.LatestSite && this.request.LatestSite.Site) {
      return this.request.LatestSite.Site.Name;
    } else {
      return "";
    }
  }

  get request_type(): string {
    return this.$t(
      `OutreachRequest.Type.${OutreachType[this.request.OutreachType]}`
    ).toString();
  }

  mounted() {
    if (this.request_id) {
      this.getData();
    }
  }

  getData() {
    this.apiService.GetById(this.request_id).then(resp => {
      this.request = resp.Value;
    });
  }
}
