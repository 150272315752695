import Guid from "@/types/Guid";
import BaseType from "../BaseType";
import Season from "../Season";
import Site from "../Site/Site";
import SiteSpecificResponse from "./SiteSpecificResponse";

export default class SiteRegistration extends BaseType {
  SubjectId: string = Guid.Empty;
  SiteId: string = Guid.Empty;
  Site: Site | undefined;
  SeasonId: string = Guid.Empty;
  Season: Season | undefined;
  CreatedOn: string = "";
  IsApproved: boolean = false;
  ApprovalDate: string = "";
  SiteSpecificResponse: SiteSpecificResponse | undefined;
}
