
import EducatorAuxDataApiService from "@/services/EducatorAuxDataApiService";
import OrganizationApiService from "@/services/OrganizationApiService";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EmailDialog from "@/components/EmailDialog.vue";
import EducatorSiteItem from "@/types/Educator/EducatorSiteItem";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import { DataOptions } from "vuetify";
import QueryEducatorsRequest from "@/types/Educator/QueryEducatorsRequest";

@Component({
  components: {
    EmailDialog,
    VMSTextField,
    VMSSelect
  }
})
export default class EducatorList extends Vue {
  @Prop(String) urlSite: string | undefined;
  @Prop(String) siteId: string | undefined;

  apiService: EducatorAuxDataApiService = new EducatorAuxDataApiService();
  emailDialog: boolean = false;

  query: QueryEducatorsRequest = new QueryEducatorsRequest();
  total_records: number = 0;
  educators: EducatorAuxData[] = [];
  selected: EducatorAuxData[] = [];

  get statusFilterOptions(): object[] {
    return [
      {
        text: this.$root.$t("Active"),
        value: true
      },
      {
        text: this.$root.$t("Inactive"),
        value: false
      }
    ];
  }

  get headers(): object[] {
    return [
      {
        text: this.$root.$t("FirstName"),
        align: "left",
        value: "FirstName"
      },
      {
        text: this.$root.$t("LastName"),
        align: "left",
        value: "LastName"
      },
      {
        text: this.$root.$t("School"),
        align: "left",
        sortable: false,
        value: "Organization.Name"
      },
      {
        text: this.$root.$t("Email"),
        align: "left",
        value: "Email"
      },
      {
        text: this.$root.$t("PhoneNumber"),
        align: "left",
        sortable: false,
        value: "PhoneNumber"
      },
      {
        text: this.$root.$t("Status"),
        align: "left",
        value: "ActiveStatus",
        sortable: false
      },
      {
        text: "",
        align: "right",
        value: "Actions"
      }
    ];
  }

  get Emails(): string {
    return this.selected
      .map((ew: EducatorAuxData) => {
        return ew.Email;
      })
      .join(`; `);
  }

  get SiteId(): string {
    return this.siteId || "";
  }

  mounted() {
    this.query.SiteId = this.siteId || "";
  }

  onOptionsUpdated(options: DataOptions) {
    this.query.updateFromTableOptions(options);
    this.getData();
  }

  getData() {
    this.apiService.QueryEducatorAuxData(this.query).then(resp => {
      if (resp.Success) {
        this.total_records = resp.TotalRecords;
        this.educators = resp.Value;
      }
    });
  }

  toggleActive(active: boolean) {
    let patches: EducatorAuxData[] = this.selected;

    patches.forEach((ead: EducatorAuxData) => {
      if (ead.EducatorSiteHeader) {
        let esi:
          | EducatorSiteItem
          | undefined = ead.EducatorSiteHeader.Items.find(
          x => x.SiteId === this.SiteId
        );
        if (esi) {
          esi.IsActive = active;
        }
      }
    });

    this.apiService.PatchEducatorAuxData(patches).then(resp => {
      if (resp.Success) {
        this.getData();
      }
    });
  }

  getEducatorStatus(item: EducatorAuxData): string {
    if (!item.EducatorSiteHeader || !item.EducatorSiteHeader.Items) {
      return "";
    } else {
      const site_item: EducatorSiteItem =
        item.EducatorSiteHeader.Items.find(si => si.SiteId == this.SiteId) ||
        new EducatorSiteItem();
      if (site_item.IsActive) {
        return this.$i18n.t("Active").toString();
      } else {
        return this.$i18n.t("Inactive").toString();
      }
    }
  }

  onAddClicked() {
    this.$router.push(`/${this.urlSite}/Educator/Create`);
  }

  onEditItem(item: EducatorAuxData) {
    this.$router.push(`/${this.urlSite}/Educator/Update?id=${item.Id}`);
  }
}
