export default class ConfigurationService {
  private static instance: ConfigurationService;

  // add additional configuration collections here
  private urls: any;
  private showVersion: boolean = true;
  private apiRequestLogSeconds: number = 5;

  private constructor() {}

  public static init(config: any): void {
    ConfigurationService.instance = new ConfigurationService();
    ConfigurationService.instance.urls = config.urls;
    ConfigurationService.instance.showVersion =
      config.showVersion == 1 ? true : false;
    ConfigurationService.instance.apiRequestLogSeconds =
      config.apiRequestLogSeconds;
  }

  public static getInstance(): ConfigurationService {
    if (!ConfigurationService.instance) throw "Instance not instantiated.";
    return ConfigurationService.instance;
  }

  public getUrl(key: string): any {
    return this.urls[key];
  }

  public getShowVersionFlag(): boolean {
    return this.showVersion;
  }

  public get ApiRequestLogSeconds(): number {
    return this.apiRequestLogSeconds;
  }
}
