import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import BaseApiService from "./BaseApiService";
import LocalizationKey from "@/types/Localizer/LocalizationKey";

export default class LocalizationApiService extends BaseApiService {
  async GetLocalizations(): Promise<UseCaseResponse<LocalizationKey[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/localizations/Localization/vms`)
      .then(resp => resp.data as UseCaseResponse<LocalizationKey[]>);
  }

  async GetLocalizationsFormatted(): Promise<UseCaseResponse<object>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/localizations/Localization/vms/Formatted`)
      .then(resp => resp.data as UseCaseResponse<object>);
  }

  async CreateKey(lk: LocalizationKey): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(
        `${this.url}/api/localizations/Localization/vms/${lk.Key}`,
        lk.Localizations
      )
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async PatchLocalizationFile(
    group_name: string,
    localeObject: object
  ): Promise<UseCaseResponse<number>> {
    await this.defineHeader();
    return axios
      .patch(
        `${this.url}/api/localizations/Localization/${group_name}`,
        localeObject
      )
      .then(resp => resp.data as UseCaseResponse<number>);
  }
}
