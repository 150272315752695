
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class TimePicker extends Vue {
  @Prop(String) value: String | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  timeVal: String = "";
  tPicker: Boolean = false;

  @Watch("value")
  onValueChanged() {
    this.setTimeVal();
  }

  mounted() {
    this.setTimeVal();
    this.emit();
  }

  setTimeVal() {
    if (this.value) {
      this.timeVal = this.value.substring(0, 5);
    } else {
      var now = new Date();
      var hours = (now.getHours() < 10 ? "0" : "") + String(now.getHours());
      var minutes =
        (now.getMinutes() < 10 ? "0" : "") + String(now.getMinutes());
      this.timeVal = `${hours}:${minutes}`;
      this.emit();
    }
  }

  get time() {
    return this.timeVal;
  }

  set time(val: String) {
    if (val.length === 5) {
      this.timeVal = val;
      this.emit();
    }
  }

  emit() {
    console.log(this.timeVal);
    this.$emit("input", `${this.timeVal}:00`);
  }
}
