import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import Award from "@/types/Award/Award";
import SubjectAward from "@/types/Award/SubjectAward";

export default class AwardApiService extends BaseApiService {
  //awards
  async GetAwards(): Promise<UseCaseResponse<Award[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Award`)
      .then(resp => resp.data as UseCaseResponse<Award[]>);
  }

  async GetAwardById(id: string): Promise<UseCaseResponse<Award>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Award/${id}`)
      .then(resp => resp.data as UseCaseResponse<Award>);
  }

  async CreateAward(award: Award): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Award`, award)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateAward(award: Award): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Award/${award.Id}`, award)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteAward(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Award/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async GetSubjectAwards(
    subjectId: string
  ): Promise<UseCaseResponse<SubjectAward[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/SubjectAward?subjectId=${subjectId}`)
      .then(resp => resp.data as UseCaseResponse<SubjectAward[]>);
  }

  async CreateSubjectAward(
    sAward: SubjectAward
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/SubjectAward`, sAward)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async DeleteSubjectAward(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/SubjectAward/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
