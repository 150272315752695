import BaseType from "../BaseType";
import Guid from "../Guid";

export default class ScreeningDocumentTrackingItem extends BaseType {
  ScreeningDocumentId: string = Guid.Empty;
  OrganizationId: string = "";
  SendTo: string = "";
  SentDate: string = "";
  RestrictedActivities: boolean = false;
  RestrictedActivityComments: string = "";
}
