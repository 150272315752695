import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";
import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import Guid from "../Guid";

export default class SiteSpecificResponse extends BaseType {
  SiteRegistrationId: string = Guid.Empty;
  VehicleAccessId: string | undefined;
  VehicleAccess: Enumeration | undefined;
  CanDriveRentalId: string | undefined;
  CanDriveRental: Enumeration | undefined;
  CampusHeaderId: string | undefined;
  CampusHeader: EnumerationResponseHeader | undefined;
  DietaryRestrictions: string | undefined;
  PreferredSchoolEducator: string | undefined;
  LocalSchools: string | undefined;
  PreferredVolunteers: string | undefined;
  AgeGroupsHeaderId: string | undefined;
  AgeGroupsHeader: EnumerationResponseHeader | undefined;
  HighSchoolSubjectsEnumHeaderId: string | undefined;
  HighSchoolSubjectsEnumHeader: EnumerationResponseHeader | undefined;
  LocationId: string | undefined;
  Location: Enumeration | undefined;
  IndigenousProgramsInterestId: string | undefined;
  IndigenousProgramsInterest: Enumeration | undefined;
  WhyVolunteer: string | undefined;
  AvailableTimes: string | undefined;
  PreferredActivitiesEnumHeader: EnumerationResponseHeader | undefined;
  SpecialInitiativesEnumHeaderId: string | undefined;
  SpecialInitiativesEnumHeader: EnumerationResponseHeader | undefined;
  StudentNumber: string | undefined;
  VolunteerTrainingCompletedId: string | undefined;
  VolunteerTrainingCompleted: Enumeration | undefined;
  GraduateWorkLocationId: string | undefined;
  GraduateWorkLocation: Enumeration | undefined;
  ShirtSize: string | undefined;
  VolunteerAgreementAcceptedId: string | undefined;
  VolunteerAgreementAccepted: Enumeration | undefined;
}
