
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmButton from "@/components/ConfirmButton.vue";
import OutreachRequestClass from "@/types/OutreachRequest/OutreachRequestClass";
import OrganizationApiService from "@/services/OrganizationApiService";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import OutreachRequestClassGrade from "@/types/OutreachRequest/OutreachRequestClassGrade";
import Guid from "@/types/Guid";
import KitApiService from "@/services/KitApiService";
import Kit from "@/types/Kit/Kit";

@Component({
  components: {
    ConfirmButton,
    VMSTextArea,
    VMSTextField,
    VMSSelect
  }
})
export default class WorkshopKitList extends Vue {
  @Prop(String) id: string | undefined;
  @Prop(String) siteId: string | undefined;
  @Prop(Array) value: OutreachRequestClass[] | undefined;

  //@ts-ignore
  classes = this.value || [];

  orgApiService: OrganizationApiService = new OrganizationApiService();
  en_grades: object[] = [];
  fr_grades: object[] = [];

  kitApiService: KitApiService = new KitApiService();
  kits: Kit[] = [];

  @Watch("value")
  onValueChanged() {
    this.classes = this.value || [];
  }

  get grade_options(): object[] {
    const grades = this.$i18n.locale === "fr" ? this.fr_grades : this.en_grades;
    return grades.map((g: any) => {
      return {
        text: g.Text,
        value: g.Id
      };
    });
  }

  get kit_options(): object[] {
    const fr = this.$i18n.locale === "fr";
    return this.kits.map(k => {
      return {
        text: fr ? k.FrenchName : k.EnglishName,
        value: k.Id
      };
    });
  }

  mounted() {
    this.orgApiService
      .All([
        this.orgApiService.GetEnumerationList("LTSAgeGroups", "en-CA"),
        this.orgApiService.GetEnumerationList("LTSAgeGroups", "fr-CA")
      ])
      .then(resps => {
        this.en_grades = resps[0].Value;
        this.fr_grades = resps[1].Value;
      });

    this.kitApiService.GetKitItemsBySiteId(this.siteId || "").then(resp => {
      if (resp.Success) {
        this.kits = resp.Value;
      }
    });
  }

  onAddClassClicked() {
    const c = new OutreachRequestClass();
    const g = new OutreachRequestClassGrade();
    c.Grades.push(g);
    this.classes.push(c);
  }

  onDeleteClassConfirmed(item: any) {
    const idx = this.classes.indexOf(item);
    if (idx) {
      if (item.Id !== Guid.Empty) {
        item.Deleted = true;
      } else {
        this.classes.splice(idx, 1);
      }
      this.$emit("input", this.classes);
    }
  }

  onAddGradeClicked(c: any) {
    if (c && c.Grades) {
      const g = new OutreachRequestClassGrade();
      g.OutreachRequestClassId = c.Id;
      c.Grades.push(g);
    }
  }

  onDeleteGradeConfirmed(c: any, g: any) {
    if (c && c.Grades) {
      const idx = c.Grades.indexOf(g);
      if (idx) {
        if (g.Id === Guid.Empty) {
          c.Grades.splice(idx, 1);
        } else {
          g.Deleted = true;
        }
      }
    }
  }
}
