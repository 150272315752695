
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DatePicker extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(String) default: string | undefined;
  localString: string = "en-CA"; //needs to be implemented
  dateVal: string = ""; //new Date().toISOString().substr(0, 10);
  dtPicker: Boolean = false;

  @Watch("value")
  onValueChanged(newVal: string) {
    if (!isNaN(Date.parse(newVal))) {
      this.setDefault();
    } else {
      this.dateVal = newVal;
    }
  }

  mounted() {
    this.setDefault();
    (this.$refs.dTextField as any).resetValidation();
  }

  setDefault() {
    let defaultValue: string = new Date().toISOString().substring(0, 10);

    if (this.default !== undefined) {
      defaultValue = this.default as string;
    }

    this.dateVal = this.value ? this.value : defaultValue;
    this.$emit("input", this.dateVal);
  }

  get date() {
    return this.dateVal;
  }

  set date(val: string) {
    if (!isNaN(Date.parse(val))) {
      this.dateVal = val;
      this.$emit("input", this.dateVal);
    } else {
      this.dateVal = "";
      this.$emit("input", this.dateVal);
    }
  }
}
