
import ReportApiService from "@/services/ReportApiService";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CoordinatorDashboard extends Vue {
  @Prop(Object) site_data: any | undefined;

  apiService: ReportApiService = new ReportApiService();
  data: any = {};

  get UrlPart(): string {
    return this.site_data.UrlPart ? this.site_data.UrlPart : "";
  }

  @Watch("site_data")
  onSiteChanged() {
    this.getData();
  }

  mounted() {
    this.getData();
  }

  getData() {
    const site_id = this.site_data.Id;
    if (site_id && site_id.length > 0) {
      this.apiService.GetDashboardData(site_id).then(resp => {
        if (resp.Success) {
          this.data = resp.Value;
        }
      });
    }
  }

  go(r: string) {
    this.$router.push(r);
  }
}
