
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import Kit from "@/types/Kit/Kit";
import KitApiService from "@/services/KitApiService";

@Component({
  components: {
    VMSSelect
  }
})
export default class KitItemSelect extends Vue {
  @Prop(Object) value: Kit | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(Boolean) disabled: Boolean | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(String) fieldoptions: string | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(String) siteId: string | undefined;

  kitItemApiService: KitApiService = new KitApiService();
  kits: Kit[] = [];

  //@ts-ignore
  selected: KitItem | null = this.value || null;

  get SiteId(): string {
    return this.siteId ? this.siteId : "";
  }

  @Watch("value")
  onPropValueChanged() {
    this.selected = this.value || null;
  }

  @Watch("siteId")
  onSiteChanged() {
    this.getData();
  }

  get KitItemWrappers(): object[] {
    return this.kits.map((ki: Kit) => {
      let name: string =
        this.$i18n.locale === "fr" ? ki.FrenchName : ki.EnglishName;
      return {
        text: name,
        value: ki
      };
    });
  }

  mounted() {
    this.getData();
  }

  getData() {
    this.kitItemApiService.GetKitItemsBySiteId(this.SiteId).then(resp => {
      this.kits = resp.Value;
    });
  }

  onInput() {
    this.$emit("input", this.selected);
  }
}
