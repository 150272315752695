import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import Post from "@/types/Post/Post";

export default class PostApiService extends BaseApiService {
  async GetPostsBySubjectId(
    subjectId: string
  ): Promise<UseCaseResponse<Post[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Post?subjectId=${subjectId}`)
      .then(resp => resp.data as UseCaseResponse<Post[]>);
  }

  async GetPostById(id: string): Promise<UseCaseResponse<Post>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Post/${id}`)
      .then(resp => resp.data as UseCaseResponse<Post>);
  }

  async CreatePost(post: Post): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Post`, post)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdatePost(post: Post): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Post/${post.Id}`, post)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeletePost(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Post/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
