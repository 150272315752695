
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import EnumerationResponse from "@/components/EnumerationResponse.vue";
import Kit from "@/types/Kit/Kit";
import KitApiService from "@/services/KitApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import { LanguageSpoken } from "@/types/Kit/Kit";
import CopyKitDialog from "@/components/kit/CopyKit.vue";
import EnumerationResponseHeader from "@/types/Enumeration/EnumerationResponseHeader";
import EnumerationResponseItem from "@/types/Enumeration/EnumerationResponseItem";
import Guid from "@/types/Guid";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationSelect from "@/components/EnumerationSelect.vue";

@Component({
  components: {
    VMSTextField,
    EnumerationResponse,
    CopyKitDialog,
    EnumerationSelect
  }
})
export default class KitForm extends Vue {
  @Prop(Boolean) value: boolean = false;
  @Prop(String) dialogTitle!: string;
  @Prop(Object) kitItem!: Kit;
  @Prop(Object) subjectsList!: EnumerationList;
  @Prop(Object) gradesList!: EnumerationList;
  @Prop(Array) languageOptions!: { text: string; value: LanguageSpoken }[];

  $refs!: Vue["$refs"] & {
    form: HTMLFormElement;
  };

  kitItemApiService: KitApiService = new KitApiService();
  copyDialogVisible: boolean = false;

  LanguageSpoken = LanguageSpoken;

  enumApiService: EnumerationApiService = new EnumerationApiService();
  durationList: EnumerationList = new EnumerationList();

  mounted() {
    this.enumApiService
      .GetEnumerationListsBySystemName(["KitDurations"])
      .then(resp => {
        if (resp.Success) {
          this.durationList = resp.Value[0];
        }
      });
  }

  async save() {
    if ((this.$refs.form as any).validate()) {
      if (this.kitItem) {
        if (!this.kitItem.IsNew) {
          this.UpdateFieldsBasedOnLanguage(this.kitItem);
          await this.kitItemApiService.UpdateKitItem(this.kitItem);
        } else {
          this.UpdateFieldsBasedOnLanguage(this.kitItem);
          await this.kitItemApiService.CreateKitItem(this.kitItem);
        }
      }
      this.closeDialog();
    }
  }

  UpdateFieldsBasedOnLanguage(kitItem: Kit) {
    if (kitItem.Language === LanguageSpoken.English) {
      kitItem.FrenchName = kitItem.EnglishName;
      kitItem.FrenchDescription = kitItem.EnglishDescription;
    } else if (kitItem.Language === LanguageSpoken.French) {
      kitItem.EnglishName = kitItem.FrenchName;
      kitItem.EnglishDescription = kitItem.FrenchDescription;
    }

    this.kitItem = kitItem;
  }

  closeDialog() {
    //(this.$refs.form as any).reset();
    this.$emit("update:value", this.kitItem.SiteId);
  }

  copyDialogShow() {
    this.copyDialogVisible = true;
  }

  get dialogVisible() {
    return this.value;
  }

  set dialogVisible(value: boolean) {
    this.$emit("update:value", null);
  }

  copyKitItems(_kitItem: Kit) {
    const _tempKitItem = this.kitItem;
    this.kitItem = JSON.parse(JSON.stringify(_kitItem));

    this.kitItem.SiteId = _tempKitItem.SiteId;
    this.kitItem.IsNew = true;
    this.kitItem.Site = _tempKitItem.Site;

    this.kitItem.Id = Guid.Empty;
    this.kitItem.GradesHeaderId = Guid.Empty;
    this.kitItem.SubjectsHeaderId = Guid.Empty;

    this.kitItem.GradesHeader = this.GetNewEnumerationResponseHeader(
      _kitItem.GradesHeader
    );
    this.kitItem.SubjectsHeader = this.GetNewEnumerationResponseHeader(
      _kitItem.SubjectsHeader
    );
  }

  GetNewEnumerationResponseHeader(
    erh: EnumerationResponseHeader | undefined
  ): EnumerationResponseHeader | undefined {
    let newHeader = erh;

    if (!newHeader) {
      return erh;
    }

    newHeader.Id = Guid.Empty;

    newHeader.Items =
      erh && erh.Items
        ? erh.Items.map((item: EnumerationResponseItem) => {
            let newItem = item;
            newItem.Id = Guid.Empty;
            newItem.EnumerationResponseHeaderId = Guid.Empty;
            return newItem;
          })
        : [];

    return newHeader;
  }
}
