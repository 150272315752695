var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("Reports")))]),_c('v-form',{ref:"reportForm"},[_c('VMSSelect',{attrs:{"items":_vm.reportDefinitions,"item-text":"text","label":_vm.$t('Report'),"rules":[() => _vm.selectedReport !== undefined || _vm.$t('FieldIsRequired')]},model:{value:(_vm.selectedReport),callback:function ($$v) {_vm.selectedReport=$$v},expression:"selectedReport"}}),(_vm.fieldVisible('siteId'))?_c('VMSSelect',{attrs:{"items":_vm.sites,"label":_vm.$t('Site'),"item-text":"Name","item-value":"Id","rules":[
        () =>
          (_vm.reportParams.SiteId && _vm.reportParams.SiteId.length > 0) ||
          _vm.authService.hasRole(['Staff', 'Administrator']) ||
          _vm.$t('FieldIsRequired')
      ]},model:{value:(_vm.reportParams.SiteId),callback:function ($$v) {_vm.$set(_vm.reportParams, "SiteId", $$v)},expression:"reportParams.SiteId"}}):_vm._e(),(_vm.fieldVisible('beginDate'))?_c('DatePicker',{attrs:{"label":_vm.$t('BeginDate'),"rules":[
        () =>
          (_vm.reportParams.BeginDate && _vm.reportParams.BeginDate.length > 0) ||
          _vm.$t('FieldIsRequired')
      ]},model:{value:(_vm.reportParams.BeginDate),callback:function ($$v) {_vm.$set(_vm.reportParams, "BeginDate", $$v)},expression:"reportParams.BeginDate"}}):_vm._e(),(_vm.fieldVisible('endDate'))?_c('DatePicker',{attrs:{"label":_vm.$t('EndDate'),"rules":[
        () =>
          (_vm.reportParams.EndDate && _vm.reportParams.EndDate.length > 0) ||
          _vm.$t('FieldIsRequired')
      ]},model:{value:(_vm.reportParams.EndDate),callback:function ($$v) {_vm.$set(_vm.reportParams, "EndDate", $$v)},expression:"reportParams.EndDate"}}):_vm._e(),(_vm.fieldVisible('locale'))?_c('VMSSelect',{attrs:{"items":_vm.locales,"label":_vm.$t('Language'),"rules":[() => _vm.reportParams.Locale.length > 0 || _vm.$t('FieldIsRequired')]},model:{value:(_vm.reportParams.Locale),callback:function ($$v) {_vm.$set(_vm.reportParams, "Locale", $$v)},expression:"reportParams.Locale"}}):_vm._e(),(_vm.fieldVisible('seasonId'))?_c('VMSSelect',{attrs:{"items":_vm.seasons,"label":_vm.$t('Season'),"rules":[
        () =>
          (_vm.reportParams.SeasonId && _vm.reportParams.SeasonId.length > 0) ||
          _vm.$t('FieldIsRequired')
      ]},model:{value:(_vm.reportParams.SeasonId),callback:function ($$v) {_vm.$set(_vm.reportParams, "SeasonId", $$v)},expression:"reportParams.SeasonId"}}):_vm._e(),(_vm.fieldVisible('initiatives'))?_c('VMSSelect',{attrs:{"items":_vm.InitiativeItems,"label":_vm.$t('SpecialInitiatives'),"multiple":""},model:{value:(_vm.reportParams.Initiatives),callback:function ($$v) {_vm.$set(_vm.reportParams, "Initiatives", $$v)},expression:"reportParams.Initiatives"}}):_vm._e()],1),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.selectedReport},on:{"click":_vm.runReport}},[_vm._v(_vm._s(_vm.$t("RunReport")))]),(_vm.err)?_c('div',{staticClass:"mt-2",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.err))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }