
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationListItem from "@/types/Enumeration/EnumerationListItem";
import EnumerationResponseHeader from "@/types/Enumeration/EnumerationResponseHeader";
import EnumerationResponseItem from "@/types/Enumeration/EnumerationResponseItem";
import Guid from "@/types/Guid";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSSelect
  }
})
export default class EnumerationResponse extends Vue {
  @Prop(Object) list: EnumerationList | undefined;
  @Prop(Object) value: EnumerationResponseHeader | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Boolean) multiple: boolean | undefined;

  selected: string[] | string = [];

  header: EnumerationResponseHeader =
    this.value || new EnumerationResponseHeader();

  @Watch("value")
  onValueChanged() {
    this.header = this.value || new EnumerationResponseHeader();
    this.setList();
    this.setSelected();
  }

  @Watch("list")
  onListChanged() {
    this.setList();
  }

  get Items(): object[] {
    let enumList: EnumerationList = this.list || new EnumerationList();
    return enumList.EnumerationItems.sort((a, b) => {
      if (a.SortOrder > b.SortOrder) return 1;
      else if (a.SortOrder < b.SortOrder) return -1;
      else return 0;
    }).map((eli: EnumerationListItem) => {
      return {
        text: this.$t(eli.Enumeration.LocalizationKey),
        value: eli.EnumerationId
      };
    });
  }

  mounted() {
    this.setList();
    if (this.header.Items.length > 0) {
      this.setSelected();
    }
  }

  setSelected() {
    let enumIds: string[] = this.header.Items.filter(
      (eri: EnumerationResponseItem) => !eri.Deleted
    ).map((eri: EnumerationResponseItem) => {
      return eri.EnumerationId;
    });

    if (this.multiple) {
      this.selected = enumIds;
    } else {
      if (enumIds.length > 0) {
        this.selected = enumIds[0];
      }
    }
  }

  setList() {
    if (this.list && this.list.Id !== this.header.EnumerationListId) {
      this.header.EnumerationListId = this.list.Id;
      this.header.EnumerationList = this.list;
    }
  }

  onInput(selected: string | string[]) {
    if (!Array.isArray(selected)) {
      selected = [selected];
    }

    this.setChanges(selected);

    this.$emit("input", this.header);
  }

  setChanges(selected: string[]) {
    let current: string[] = this.header.Items.filter(x => !x.Deleted).map(
      (eri: EnumerationResponseItem) => {
        return eri.EnumerationId;
      }
    );

    let added = selected.filter(x => current.includes(x) === false);
    let removed = current.filter(x => !selected.includes(x));

    this.add(added);
    this.remove(removed);
  }

  add(added: string[]) {
    added.forEach((eId: string) => {
      //restore items marked deleted rather than add again
      //items marked deleted in db wouldn't have been returned
      let markedDeleted:
        | EnumerationResponseItem
        | undefined = this.header.Items.find(
        (eri: EnumerationResponseItem) =>
          eri.EnumerationId == eId && eri.Deleted
      );
      if (markedDeleted) {
        markedDeleted.Deleted = false;
      } else {
        let toAdd: EnumerationResponseItem = new EnumerationResponseItem();
        toAdd.EnumerationResponseHeaderId = this.header.Id;
        toAdd.EnumerationId = eId;
        this.header.Items.push(toAdd);
      }
    });
  }

  remove(removed: string[]) {
    removed.forEach((eId: string) => {
      let toDel: EnumerationResponseItem =
        this.header.Items.find(
          (eri: EnumerationResponseItem) => eri.EnumerationId == eId
        ) || new EnumerationResponseItem();

      if (toDel.Id === Guid.Empty) {
        let idx: number = this.header.Items.indexOf(toDel);
        this.header.Items.splice(idx, 1);
      } else {
        toDel.Deleted = true;
      }
    });
  }
}
