
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmButton from "../../components/ConfirmButton.vue";
import Subject from "../../types/Subject/Subject";
import SubjectApiService from "../../services/SubjectApiService";
import NoteApiService from "../../services/NoteApiService";
import Note from "../../types/Note/Note";
import AuthorizationService from "../../services/AuthorizationService";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

class NoteWrapper {
  Note: Note = new Note();
  Edit: boolean = false;
  EditText: string = "";

  get Created(): Date {
    return new Date(`${this.Note.CreatedOn}`);
  }

  get Modified(): Date {
    return new Date(`${this.Note.ModifiedOn}`);
  }

  constructor(n: Note) {
    this.Note = n;
    this.EditText = this.Note.Text;
  }
}

@Component({
  components: {
    ConfirmButton,
    VMSTextArea,
    VMSSelect
  }
})
export default class SubjectNotes extends Vue {
  subject: Subject = new Subject();
  subjectApiService: SubjectApiService = new SubjectApiService();
  noteApiService: NoteApiService = new NoteApiService();
  noteWrappers: NoteWrapper[] = [];
  note: Note = new Note();
  subjectId: string = "";
  authService: AuthorizationService = AuthorizationService.getInstance();

  get Form(): any {
    return this.$refs.form as any;
  }

  permissionLevels: object[] = [
    {
      text: this.$root.$t("VolunteerReadCoordinatorWrite"),
      value: 1,
      adminOnly: false
    },
    {
      text: this.$root.$t("VolunteerHiddenCoordinatorWrite"),
      value: 2,
      adminOnly: false
    },
    {
      text: this.$root.$t("AllReadAdminWrite"),
      value: 3,
      adminOnly: true
    },
    {
      text: this.$root.$t("CoordinatorReadAdminWrite"),
      value: 4,
      adminOnly: true
    },
    {
      text: this.$root.$t("AdminOnly"),
      value: 5,
      adminOnly: true
    }
  ];

  get StaffAdmin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  get PermissionItems(): object[] {
    return this.permissionLevels.filter(
      (x: any) => this.StaffAdmin || !x.adminOnly
    );
  }

  noteEditable(n: Note): boolean {
    let coordinator: boolean = this.authService.hasRole(["Coordinator"]);
    if (this.StaffAdmin) return true;
    else {
      return coordinator && n.PermissionLevel < 3;
    }
  }

  mounted() {
    if (this.$route.query.subjectId) {
      this.subjectId = this.$route.query.subjectId as string;
      this.note.SubjectId = this.subjectId;

      this.subjectApiService.GetSubjectById(this.subjectId).then(resp => {
        this.subject = resp.Value;
      });

      this.getNotes();
    }
  }

  getNotes() {
    if (this.subjectId) {
      this.noteApiService.GetNotes(this.subjectId).then(resp => {
        this.noteWrappers = [];
        resp.Value.forEach((n: Note) => {
          this.noteWrappers.push(new NoteWrapper(n));
        });
      });
    }
  }

  onAddClick() {
    //add note
    if (this.Form.validate()) {
      this.noteApiService.CreateNote(this.note).then(resp => {
        if (resp.Success) {
          this.Form.resetValidation();
          this.note = new Note();
          this.note.SubjectId = this.subjectId;
          this.getNotes();
        }
      });
    }
  }

  onCancelEditClick(item: NoteWrapper) {
    item.EditText = item.Note.Text;
    item.Edit = false;
  }

  onSaveNoteEditClick(item: NoteWrapper) {
    let oldText: string = item.Note.Text;
    item.Note.Text = item.EditText;
    this.noteApiService
      .UpdateNote(item.Note)
      .then(resp => {
        if (!resp.Success) {
          console.log(resp);
        }
        item.Edit = false;
      })
      .catch(ex => {
        item.Note.Text = oldText;
        item.EditText = oldText;
        item.Edit = false;
      });
  }

  onDeleteNoteConfirmed(item: Note) {
    this.noteApiService.DeleteNote(item.Id).then(resp => {
      if (resp.Success) {
        this.getNotes();
      }
    });
  }
}
