import Training from "@/types/Training/Training";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import axios from "axios";
import TrainingType from "@/types/Training/TrainingType";

export default class TrainingApiService extends BaseApiService {
  async CreateTraining(training: Training): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Training`, training)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetTrainingBySiteId(
    siteId: string,
    activeSeasonOnly: boolean,
    siteHostedOnly: boolean
  ): Promise<UseCaseResponse<Training[]>> {
    await this.defineHeader();

    return axios
      .get(
        `${this.url}/api/vms/Training?siteId=${siteId}&activeSeasonOnly=${activeSeasonOnly}&siteHostedOnly=${siteHostedOnly}`
      )
      .then(resp => resp.data as UseCaseResponse<Training[]>);
  }

  async GetTrainingById(id: string): Promise<UseCaseResponse<Training>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Training/${id}`)
      .then(resp => resp.data as UseCaseResponse<Training>);
  }

  async GetTrainingTypes(): Promise<UseCaseResponse<TrainingType[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Training/Type`)
      .then(resp => resp.data as UseCaseResponse<TrainingType[]>);
  }

  async UpdateTraining(training: Training): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Training/${training.Id}`, training)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteTraining(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Training/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
