var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.EmailCreateEditHeader))]),_c('v-form',{ref:"emailForm"},[_c('VMSSelect',{attrs:{"label":_vm.$t('EmailType'),"items":_vm.emailTypes,"rules":[
        () => _vm.email_type !== null || `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
      ]},on:{"input":function($event){_vm.email.EmailType = _vm.email_type || 0}},model:{value:(_vm.email_type),callback:function ($$v) {_vm.email_type=$$v},expression:"email_type"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('ApplyHTMLBranding')},model:{value:(_vm.email.ApplyHTMLBranding),callback:function ($$v) {_vm.$set(_vm.email, "ApplyHTMLBranding", $$v)},expression:"email.ApplyHTMLBranding"}}),_c('v-input',{attrs:{"rules":[
        () => _vm.EmailLocales.length > 0 || `${_vm.$t('EmailLocalizationRequired')}`
      ]}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.EmailLocales},scopedSlots:_vm._u([{key:`item.Body`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.Body.substr(0, 50))+"... ")]}},{key:`item.Locale`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getLocaleName(item.Locale))+" ")]}},{key:`item.Actions`,fn:function({ item }){return [_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.onEditLocalization(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('ConfirmButton',_vm._g({attrs:{"icon":"mdi-delete"},on:{"confirmed":function($event){return _vm.onDeleteLocalization(item)}}},on),[_vm._v(" "+_vm._s(_vm.$t("Delete"))+" "+_vm._s(_vm.$t("Data"))+"? ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Delete")))])])],1)]}}],null,true)})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t("Save")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","disabled":_vm.email_type === null},on:{"click":_vm.onAddLocalizationClick}},[_vm._v(_vm._s(_vm.$t("AddLocalization")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.onCancelClick}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('v-dialog',{model:{value:(_vm.localizationDialog),callback:function ($$v) {_vm.localizationDialog=$$v},expression:"localizationDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("AddLocale")))]),_c('v-card-text',[_c('v-form',{ref:"localizationForm"},[_c('VMSSelect',{attrs:{"label":_vm.$t('Locale'),"items":_vm.locales,"rules":[
              () =>
                _vm.localizationLocale.length > 0 ||
                `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
            ]},model:{value:(_vm.localizationLocale),callback:function ($$v) {_vm.localizationLocale=$$v},expression:"localizationLocale"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('Subject'),"rules":[
              () =>
                _vm.localizationSubject.length > 0 ||
                `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
            ]},model:{value:(_vm.localizationSubject),callback:function ($$v) {_vm.localizationSubject=$$v},expression:"localizationSubject"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('Body'),"rules":[
              () =>
                _vm.localizationBody.length > 0 ||
                `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
            ]},model:{value:(_vm.localizationBody),callback:function ($$v) {_vm.localizationBody=$$v},expression:"localizationBody"}}),(_vm.TemplateParameters.length > 0)?[_c('p',[_vm._v(" "+_vm._s(_vm.$t("AvailableTemplateParameters"))+" ")]),_c('br'),_c('ul',_vm._l((_vm.TemplateParameters),function(key,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(_vm.$t(key))+" ")])}),0)]:_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveLocalizationClick}},[_vm._v(_vm._s(_vm.$t("Save")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onCancelLocalizationClick}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }