
import HistoricHours from "@/types/Historic/HistoricHours";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import HistoricHoursApiService from "@/services/HistoricHoursApiService";
import Subject from "@/types/Subject/Subject";
import SubjectApiService from "@/services/SubjectApiService";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";

@Component({
  components: {
    VMSTextField,
    VMSAutoComplete
  }
})
export default class HistoricHoursClaim extends Vue {
  email: string = "";
  hours: HistoricHours[] = [];
  selectedItems: HistoricHours[] = [];
  apiService: HistoricHoursApiService = new HistoricHoursApiService();
  loading: boolean = false;

  subjectApiService: SubjectApiService = new SubjectApiService();
  subjectSearchText: string = "";
  subjectloading: boolean = false;
  subjects: object[] = [];
  subjectId: string = "";
  subjectTypeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  subjectTimeout: any | null;

  headers: object[] = [
    {
      text: this.$root.$t("Email"),
      align: "left",
      value: "Email"
    },
    {
      text: this.$root.$t("Year"),
      align: "left",
      value: "Year"
    },
    {
      text: this.$root.$t("Preparation"),
      align: "left",
      value: "PrepHours"
    },
    {
      text: this.$root.$t("Delivery"),
      align: "left",
      value: "DeliveryHours"
    }
  ];

  onSearchClicked() {
    this.loading = true;
    this.$SetLoading(this.loading);

    this.apiService
      .SearchHistoricHours(this.email)
      .then(resp => {
        this.hours = resp.Value;
      })
      .finally(() => {
        this.loading = false;
        this.$SetLoading(this.loading);
      });
  }

  onSubjectSearchKeyUp() {
    if (this.subjectTimeout) clearTimeout(this.subjectTimeout);
    if (this.subjectSearchText.length > 0) {
      this.subjectTimeout = setTimeout(this.getSubjects, this.subjectTypeTimer);
    }
  }

  getSubjects() {
    this.subjectloading = true;
    this.subjectApiService
      .SearchSubjects(this.subjectSearchText)
      .then(resp => {
        if (resp && resp.Success) {
          this.subjects = resp.Value.map((s: Subject) => {
            return {
              text: `${s.GivenName} ${s.FamilyName}`,
              value: s.Id
            };
          });
        }
      })
      .finally(() => {
        this.subjectloading = false;
      });
  }

  onClaimClicked() {
    if (!this.loading) {
      this.loading = true;
      this.$SetLoading(this.loading);

      this.selectedItems.forEach((hh: HistoricHours) => {
        hh.SubjectId = this.subjectId;
      });

      this.apiService
        .PatchHistoricHours(this.selectedItems)
        .then(resp => {
          if (resp && resp.Success) {
            this.selectedItems = [];
            this.hours = [];
            this.$ShowSnackbar(this.$t("HourClaimSuccessMsg") as string);
          } else {
            this.$ShowSnackbar(this.$t("HourClaimErrorMsg") as string);
          }
        })
        .finally(() => {
          this.loading = false;
          this.$SetLoading(this.loading);
        });
    }
  }
}
