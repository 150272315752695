import BaseType from "../BaseType";
import Guid from "../Guid";

export default class Note extends BaseType {
  SubjectId: string = "";
  Text: string = "";
  PermissionLevel: number = 1;
  CreatedByName: string = "";
  ModifiedByName: string = "";
  CreatedOn: string = "";
  ModifiedOn: string = "";
}
