
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EmailApiService from "../../services/EmailApiService";
import Email, { EmailType } from "../../types/Email/Email";
import ConfirmButton from "../../components/ConfirmButton.vue";
import EmailLocalization from "../../types/Email/EmailLocalization";
import { TranslateResult } from "vue-i18n";
import EmailAddressList from "../../types/Email/EmailAddressList";
import Subject from "../../types/Subject/Subject";
import SubjectApiService from "../../services/SubjectApiService";
import Guid from "../../types/Guid";
import VMSTextField from "@/components/fields/VMSTextField.vue";

class EmailWrapper {
  Email: Email = new Email();
  Subject: string = "";
  Body: string = "";
  TypeString: string = "";

  constructor(e: Email, s: string, b: string, ts: string) {
    this.Email = e;
    this.Subject = s;
    this.Body = b;
    this.TypeString = ts;
  }
}

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class AddressListCreate extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  emailApiService: EmailApiService = new EmailApiService();
  subjectApiService: SubjectApiService = new SubjectApiService();
  emailWrappers: EmailWrapper[] = [];
  list: EmailAddressList = new EmailAddressList();
  address: string = "";
  searchDialog: boolean = false;
  searchString: string = "";
  subjects: Subject[] = [];
  selectedSubjects: Subject[] = [];
  subjectsLoading: boolean = false;
  selectedEmailWrapper: EmailWrapper[] = [];
  headers: object[] = [
    {
      text: this.$root.$t("EmailType"),
      value: "TypeString"
    },
    {
      text: this.$root.$t("Subject"),
      value: "Subject"
    },
    {
      text: this.$root.$t("Body"),
      value: "Body"
    }
  ];

  recipientHeaders: object[] = [
    {
      text: "",
      align: "left",
      value: "value"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  subjectHeaders: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "FullName"
    },
    {
      text: this.$root.$t("EmailAddress"),
      align: "left",
      value: "Email"
    }
  ];

  get AddressListCreateEditHeader(): TranslateResult {
    return this.$t("AddressListCreate");
  }

  get RecipientObjects(): object[] {
    return this.list.EmailAddresses.map(ea => {
      return {
        value: ea
      };
    });
  }

  mounted() {
    this.emailApiService.GetEmails().then(resp => {
      let emails: Email[] = resp.Value;
      let loc: string = this.$i18n.locale;
      let wrappers: EmailWrapper[] = [];
      emails.forEach((e: Email) => {
        let el: EmailLocalization =
          e.Localizations.find(l => l.Locale === loc) || e.Localizations[0];
        let ts: string = this.$t(
          `EmailType.${EmailType[e.EmailType]}`
        ) as string;
        wrappers.push(new EmailWrapper(e, el.Subject, el.Body, ts));
      });

      this.emailWrappers = wrappers;

      if (this.$route.query.id) {
        let editId: string = this.$route.query.id as string;
        this.getData(editId);
      }
    });
  }

  getData(editId: string) {
    this.emailApiService.GetAddressListsById(editId).then(resp => {
      this.list = resp.Value;
      let wrapper: EmailWrapper | undefined = this.emailWrappers.find(
        e => e.Email.Id === this.list.EmailId
      );
      if (wrapper) {
        this.selectedEmailWrapper = [];
        this.selectedEmailWrapper.push(wrapper);
      }
    });
  }

  onAddAddress() {
    if (this.address.length > 0) {
      this.list.EmailAddresses.push(this.address);
      this.address = "";
    }
  }

  onCancelClick() {
    this.$router.go(-1);
  }

  onSaveClick() {
    if ((this.$refs.form as any).validate()) {
      this.list.EmailId = this.selectedEmailWrapper[0].Email.Id;
      if (this.siteId) {
        this.list.SiteId = this.siteId;
      }

      if (this.list.Id === Guid.Empty) {
        //save
        this.emailApiService.CreateAddressList(this.list).then(resp => {
          if (resp.Success) {
            this.$router.go(-1);
          }
        });
      } else {
        this.emailApiService.UpdateAddressList(this.list).then(resp => {
          if (resp.Success) {
            this.$router.go(-1);
          }
        });
      }
    }
  }

  onDeleteAddressConfirmed(address: string) {
    let idx: number = this.list.EmailAddresses.indexOf(address);
    this.list.EmailAddresses.splice(idx, 1);
  }

  onSearchClick() {
    if (this.subjects.length === 0) {
      this.subjectsLoading = true;
      this.subjectApiService.GetSubjects().then(resp => {
        this.subjects = resp.Value;
        this.subjectsLoading = false;
      });
    }

    this.searchDialog = true;
  }

  onAddSubjectEmailsClick() {
    this.selectedSubjects.forEach((s: Subject) => {
      if (!this.list.EmailAddresses.includes(s.Email)) {
        this.list.EmailAddresses.push(s.Email);
      }
    });

    this.selectedSubjects = [];
    this.searchDialog = false;
    this.searchString = "";
  }

  onCancelSubjectSearchClick() {
    this.searchDialog = false;
    this.searchString = "";
  }
}
