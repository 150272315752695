import BaseType from "../BaseType";
import Guid from "../Guid";

export enum RequestStatus {
  Pending,
  Scheduled,
  Accepted,
  Waitlisted,
  Declined,
  Cancelled,
  Completed
}

export default class OutreachRequestStatus extends BaseType {
  OutreachRequestId: string = Guid.Empty;
  Status: RequestStatus = RequestStatus.Pending;
  CreatedOn: string = "";

  private static GetValidUpdatesForStatus(
    current_status: RequestStatus
  ): RequestStatus[] {
    switch (current_status) {
      case RequestStatus.Pending:
        return [
          RequestStatus.Accepted,
          RequestStatus.Declined,
          RequestStatus.Scheduled,
          RequestStatus.Completed,
          RequestStatus.Cancelled
        ];
      case RequestStatus.Accepted:
        return [
          RequestStatus.Scheduled,
          RequestStatus.Completed,
          RequestStatus.Cancelled
        ];
      case RequestStatus.Scheduled:
        return [
          RequestStatus.Accepted,
          RequestStatus.Completed,
          RequestStatus.Cancelled
        ];
      case RequestStatus.Completed:
        return [
          RequestStatus.Accepted,
          RequestStatus.Scheduled,
          RequestStatus.Cancelled
        ];
      case RequestStatus.Declined:
      case RequestStatus.Cancelled:
        return [
          RequestStatus.Accepted,
          RequestStatus.Scheduled,
          RequestStatus.Completed
        ];
      case RequestStatus.Waitlisted:
        return [
          RequestStatus.Accepted,
          RequestStatus.Declined,
          RequestStatus.Scheduled,
          RequestStatus.Completed
        ];
    }
  }

  public static GetValidStatusUpdates(
    current_status: RequestStatus[]
  ): RequestStatus[] {
    const all_status_updates = current_status.flatMap(s =>
      this.GetValidUpdatesForStatus(s)
    );

    const valid_options = [];

    //array intersection
    const status_map: any = {};
    all_status_updates.forEach((rs: RequestStatus) => {
      if (status_map[rs]) {
        status_map[rs] = status_map[rs] + 1;
      } else {
        status_map[rs] = 1;
      }
    });

    for (const k in status_map) {
      if (status_map[k] === current_status.length) {
        valid_options.push(Number.parseInt(k));
      }
    }
    //==================
    return valid_options;
  }
}
