
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OrganizationMerge from "@/components/organization/OrganizationMerge.vue";
import PatchOrganizationRequest from "@/types/Organization/PatchOrganizationRequest";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import OrganizationFilePatcher from "@/components/organization/OrganizationFilePatcher.vue";
import { PagedQueryRequestFiltered } from "@/types/Pocos/Pagination/PagedQueryRequest";
import { DataOptions } from "vuetify";
import OrganizationQuickMerge from "@/components/organization/OrganizationQuickMerge.vue";
import QueryOrganizationsRequest from "@/types/Organization/QueryOrganizationsRequest";

@Component({
  components: {
    OrganizationMerge,
    VMSTextField,
    VMSTextArea,
    VMSSelect,
    OrganizationFilePatcher,
    OrganizationQuickMerge
  }
})
export default class OrganizationList extends Vue {
  orgApiService: OrganizationApiService = new OrganizationApiService();
  orgs: GetOrganizationResponse[] = [];
  selectedOrgs: GetOrganizationResponse[] = [];

  loading: boolean = false;

  mergeDialog: boolean = false;
  mergeOrgs: GetOrganizationResponse[] = [];

  mergedRecordsDialog: boolean = false;
  mergedRecords: GetOrganizationResponse[] = [];
  mergedRecordsLoading: boolean = false;

  verifyDialog: boolean = false;
  verifyStatusComments: string = "";
  verifyOrgs: GetOrganizationResponse[] = [];

  fileUploadDialog: boolean = false;

  quickMergeDialog: boolean = false;

  query: PagedQueryRequestFiltered<
    QueryOrganizationsRequest
  > = new PagedQueryRequestFiltered(new QueryOrganizationsRequest());
  total_records: number = 0;

  status: object[] = [
    {
      text: this.$root.$t("New"),
      value: 0
    },
    {
      text: this.$root.$t("Verified"),
      value: 1
    },
    {
      text: this.$root.$t("Merged"),
      value: 2
    }
  ];

  headers: object[] = [
    {
      text: this.$root.$t("Id"),
      align: "left",
      sortable: false,
      value: "Id"
    },
    {
      text: this.$root.$t("Name"),
      align: "left",
      sortable: false,
      value: "Name"
    },
    {
      text: this.$root.$t("Address"),
      align: "left",
      sortable: false,
      value: "Address"
    },
    {
      text: this.$root.$t("City"),
      align: "left",
      sortable: false,
      value: "City"
    },
    {
      text: this.$root.$t("Province"),
      align: "left",
      sortable: false,
      value: "Province"
    },
    {
      text: this.$root.$t("PostalCode"),
      align: "left",
      sortable: false,
      value: "PostalCode"
    },
    {
      text: this.$root.$t("SchoolBoard"),
      align: "left",
      sortable: false,
      value: "SchoolBoard"
    },
    {
      text: this.$root.$t("Status"),
      align: "left",
      sortable: false,
      value: "StatusName"
    },
    {
      text: "",
      align: "left",
      sortable: false,
      value: "Actions"
    }
  ];

  mergedHeaders: object[] = [
    {
      text: this.$root.$t("Id"),
      align: "left",
      sortable: false,
      value: "Id"
    },
    {
      text: this.$root.$t("Name"),
      align: "left",
      sortable: false,
      value: "Name"
    },
    {
      text: this.$root.$t("Address"),
      align: "left",
      sortable: false,
      value: "Address"
    },
    {
      text: this.$root.$t("City"),
      align: "left",
      sortable: false,
      value: "City"
    },
    {
      text: this.$root.$t("Province"),
      align: "left",
      sortable: false,
      value: "Region"
    },
    {
      text: this.$root.$t("SchoolBoard"),
      align: "left",
      sortable: false,
      value: "SchoolBoard"
    }
  ];

  onOptionsUpdated(options: DataOptions) {
    this.query.updateFromTableOptions(options);
    this.getData();
  }

  getData() {
    this.loading = true;
    this.orgApiService
      .QueryOrganizations(this.query)
      .then(resp => {
        if (resp && resp.Success) {
          this.orgs = resp.Value;
          this.total_records = resp.TotalRecords;
        } else {
          this.orgs = [];
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onEditItem(item: GetOrganizationResponse) {
    this.$router.push(`/organization/update?id=${item.Id}`);
  }

  onMergeItem(item: GetOrganizationResponse) {
    this.mergeOrgs = [];
    this.mergeOrgs.push(item);
    this.mergeDialog = true;
  }

  onMergeSaved() {
    this.mergeDialog = false;
    this.orgs = [];
    this.selectedOrgs = [];
  }

  onVerifyItemClicked(item: GetOrganizationResponse) {
    this.verifyOrgs = [item];
    this.verifyDialog = true;
  }

  onVerifySelectedClicked() {
    this.verifyOrgs = this.selectedOrgs.filter(x => x.Status !== 1);
    this.verifyDialog = true;
  }

  onVerifyClicked() {
    let patches: PatchOrganizationRequest[] = [];
    this.verifyOrgs.forEach((gor: GetOrganizationResponse) => {
      let patch: PatchOrganizationRequest = new PatchOrganizationRequest();
      patch.Id = gor.Id;
      patch.Status = 1;
      patch.StatusComments = this.verifyStatusComments;
      patches.push(patch);
    });

    this.orgApiService
      .PatchOrganizations(patches)
      .then(resp => {
        if (resp.Success) {
          this.verifyStatusComments = "";
          this.selectedOrgs = [];
          this.orgs = [];
          this.verifyDialog = false;
          this.verifyOrgs = [];
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  onMergeSelectedClicked() {
    this.mergeOrgs = [];
    this.selectedOrgs.forEach((o: GetOrganizationResponse) => {
      this.mergeOrgs.push(o);
    });

    this.mergeDialog = true;
  }

  onViewMergedRecordsClicked(item: GetOrganizationResponse) {
    this.mergedRecords = [];
    this.mergedRecordsDialog = true;
    this.mergedRecordsLoading = true;
    this.orgApiService
      .GetOrganizationsByMergedId(item.Id)
      .then(resp => {
        if (resp && resp.Success) {
          this.mergedRecords = resp.Value;
        }
      })
      .finally(() => {
        this.mergedRecordsLoading = false;
      });
  }
}
