
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import EvaluationApiService from "../../services/EvaluationApiService";
import EvaluationReponse from "@/types/Evaluation/EvaluationResponse";

@Component({
  components: {}
})
export default class WorkshopEvaluation extends Vue {
  evaluationApiService: EvaluationApiService = new EvaluationApiService();
  selectedEvaluation: EvaluationReponse = new EvaluationReponse();

  evaluations: any = [];
  questions: any = [];
  answers: any = [];

  get WorkshopId(): string {
    return this.$route.params.workshopId;
  }

  get EvaluationLink(): string {
    return this.$t("ActivityEvaluationLink", {
      workshop_id: this.WorkshopId
    }) as string;
  }

  mounted() {
    this.evaluationApiService
      .GetEvaluationByWorkshopId(this.WorkshopId)
      .then(r => {
        this.evaluations = r.Value;
      });
  }

  @Watch("selectedEvaluation")
  onSelectedEvaluationChanged() {
    this.questions = JSON.parse(
      this.selectedEvaluation.EvaluationSurvey.QuestionProSurvey
    ).sort((a: any, b: any) => {
      return a.orderNumber > b.orderNumber;
    });
    this.answers = JSON.parse(
      this.selectedEvaluation.QuestionProResponse
    ).responseSet;
  }
}
