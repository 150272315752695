var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("EnumerationCreate")))]),_c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('v-form',{ref:"form"},[_c('VMSTextField',{attrs:{"label":_vm.$t('LocalizationKey'),"rules":[
              () =>
                _vm.enumeration.LocalizationKey.length > 0 ||
                `${_vm.$t('LocalizationKey')} ${_vm.$t('IsRequired')}`
            ]},model:{value:(_vm.enumeration.LocalizationKey),callback:function ($$v) {_vm.$set(_vm.enumeration, "LocalizationKey", $$v)},expression:"enumeration.LocalizationKey"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('Description'),"rules":[
              () =>
                _vm.enumeration.Description.length > 0 ||
                `${_vm.$t('Description')} ${_vm.$t('IsRequired')}`
            ]},model:{value:(_vm.enumeration.Description),callback:function ($$v) {_vm.$set(_vm.enumeration, "Description", $$v)},expression:"enumeration.Description"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('Value'),"rules":[
              () =>
                _vm.enumeration.Value.length > 0 ||
                `${_vm.$t('Value')} ${_vm.$t('IsRequired')}`
            ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSaveClicked.apply(null, arguments)}},model:{value:(_vm.enumeration.Value),callback:function ($$v) {_vm.$set(_vm.enumeration, "Value", $$v)},expression:"enumeration.Value"}})],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":_vm.onSaveClicked}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1),_c('h3',{staticClass:"mt-6"},[_vm._v("Localizations")]),_c('LocalizationsTable',{staticClass:"mt-4",on:{"item-selected":function($event){_vm.enumeration.LocalizationKey = $event.Key}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }