import BaseType from "../BaseType";
import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import Guid from "../Guid";
import Site from "../Site/Site";
import VolunteerLogHeader from "../Volunteers/VolunteerLogHeader";
import ProgramSupportKit from "./ProgramSupportKit";

export default class ProgramSupport extends BaseType {
  SiteId: string = Guid.Empty;
  Site: Site | undefined;
  Name: string = "";
  Description: string = "";
  DateCompleted: string = "";
  VolunteerLogHeader: VolunteerLogHeader | undefined;
  ProgramSupportKits: ProgramSupportKit[] = [];
  SpecialInitiativesHeaderId: string = "";
  SpecialInitiativesHeader: EnumerationResponseHeader | undefined;
}
