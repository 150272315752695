
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SiteApiService from "@/services/SiteApiService";
import KitApiService from "@/services/KitApiService";
import EnumerationApiService from "@/services/EnumerationApiService";
import Site, { SiteType } from "@/types/Site/Site";
import Kit from "@/types/Kit/Kit";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import { LanguageSpoken } from "@/types/Kit/Kit";
import KitForm from "@/components/kit/KitForm.vue";
import AuthorizationService from "@/services/AuthorizationService";
import ConfirmButton from "@/components/ConfirmButton.vue";

@Component({
  components: {
    KitForm,
    ConfirmButton
  }
})
export default class KitList extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  loading: boolean = false;
  siteApiService = new SiteApiService();
  enumApiService = new EnumerationApiService();
  kitItemApiService: KitApiService = new KitApiService();

  site: Site = new Site();
  sites: Site[] = [];
  KitItems: Kit[] = [];
  kitItem: Kit = new Kit();

  selectedSiteId: string = "";

  kitFormDialog: boolean = false;

  dialogTitle: string = "";
  gradesList: EnumerationList = new EnumerationList();
  subjectsList: EnumerationList = new EnumerationList();

  nationalSiteId: string = "";
  authService: AuthorizationService = AuthorizationService.getInstance();

  languageOptions = [
    { text: this.$root.$t("English"), value: LanguageSpoken.English },
    { text: this.$root.$t("French"), value: LanguageSpoken.French },
    { text: this.$root.$t("Bilingual"), value: LanguageSpoken.Bilingual }
  ];

  headers: any[] = [
    { text: this.$root.$t("KitName"), value: "Name" },
    {
      text: this.$root.$t("KitVisibility"),
      value: "Visibility",
      sortable: false
    },
    { text: "", value: "actions", sortable: false }
  ];

  get StaffOrAdmin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  mounted() {
    this.selectedSiteId = this.value || "";
    this.loading = true;
    this.siteApiService
      .GetSites()
      .then(resp => {
        if (resp.Success) {
          const sites: Site[] = resp.Value;
          const national = sites.find(s => s.Type === SiteType.National);
          if (national) {
            this.nationalSiteId = national.Id;
          }
        }
      })
      .finally(() => {
        this.selectedSiteId =
          this.StaffOrAdmin && !this.siteId
            ? this.nationalSiteId
            : this.siteId || "";

        this.fetchData();
      });
  }

  @Watch("value")
  onSiteIdChanged() {
    this.selectedSiteId = this.value || "";
    this.fetchData();
  }

  onSelectedSiteChanged() {
    this.$emit("input", this.selectedSiteId);
    this.loadKitsForSelectedSite();
  }

  async fetchData() {
    if (!this.selectedSiteId) return;

    this.loading = true;
    this.siteApiService
      .All([
        this.siteApiService.GetSiteById(this.selectedSiteId),
        this.siteApiService.GetSites(),
        this.kitItemApiService.GetKitItemsBySiteId(this.selectedSiteId),
        this.enumApiService.GetEnumerationListsBySystemName([
          "Grades",
          "AreasOfStudy"
        ])
      ])
      .then(resps => {
        this.site = resps[0].Value;
        this.sites = resps[1].Value;
        this.KitItems = resps[2].Value;

        const enums: EnumerationList[] = resps[3].Value;

        this.gradesList =
          enums.find(e => e.SystemName === "Grades") || new EnumerationList();

        this.subjectsList =
          enums.find(e => e.SystemName === "AreasOfStudy") ||
          new EnumerationList();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get KitItemWrappers(): object[] {
    return this.KitItems.map((ki: Kit) => {
      let name: string = "";
      if (this.$i18n.locale === "fr") {
        name = ki.FrenchName;
      } else {
        name = ki.EnglishName;
      }

      return {
        Name: name,
        Visibility: ki.Visibility,
        KitItem: ki
      };
    });
  }

  deleteKitItem(item: Kit) {
    this.kitItemApiService.DeleteKitItem(item.Id).then(resp => {
      if (resp.Success) {
        this.KitItems.splice(this.KitItems.indexOf(item), 1);
      }
    });
  }

  addNewKitItem() {
    this.dialogTitle = this.$t("AddNewKit").toString();
    this.kitItem = new Kit();
    this.kitItem.SiteId = this.selectedSiteId;
    this.kitItem.Site = this.site;
    this.kitItem.IsNew = true;
    this.kitFormDialog = true;
  }

  editKitItem(item: Kit) {
    this.dialogTitle = this.$t("KitEdit").toString();
    this.kitItem = { ...item };
    this.kitItem.SiteId = this.selectedSiteId;
    this.kitItem.Site = this.site;
    this.kitFormDialog = true;
  }

  toggleVisibility(item: Kit) {
    this.kitItem = { ...item };
    this.kitItemApiService.UpdateKitItem(this.kitItem).then(() => {
      const _kitItem = this.KitItems.find(k => k.Id === item.Id);
      if (_kitItem) _kitItem.Visibility = item.Visibility;
    });
  }

  closeDialogs() {
    this.kitFormDialog = false;
    this.fetchData();
  }

  get SortedSites(): Site[] {
    let sorted = this.sites.sort((a, b) => a.Name.localeCompare(b.Name));
    return sorted;
  }

  async loadKitsForSelectedSite() {
    this.kitItemApiService
      .GetKitItemsBySiteId(this.selectedSiteId)
      .then(resp => {
        if (resp.Success) {
          this.KitItems = resp.Value;
        }
      });

    this.siteApiService.GetSiteById(this.selectedSiteId).then(resp => {
      if (resp.Success) {
        this.site = resp.Value;
      }
    });
  }
}
