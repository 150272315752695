
import Guid from "@/types/Guid";
import WorkshopFileUploadResponse from "@/types/Workshop/WorkshopFileUpload/WorskhopFileUploadResponse";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class WorkshopFileStats extends Vue {
  @Prop(Object) value: WorkshopFileUploadResponse | undefined;
  @Prop(Boolean) loading: boolean | undefined;
  @Prop(String) loadingMsg: string | undefined;

  response: WorkshopFileUploadResponse =
    this.value || new WorkshopFileUploadResponse();

  educators: number = 0;
  orgs: number = 0;

  @Watch("value")
  onValueChanged() {
    this.response = this.value || new WorkshopFileUploadResponse();
    this.setStats();
  }

  mounted() {
    this.setStats();
  }

  setStats() {
    this.educators = 0;
    this.orgs = 0;
    this.response.Educators.forEach(e => {
      if (e.Id === Guid.Empty) {
        ++this.educators;
      }
    });

    this.response.SchoolDemographics.forEach(sdri => {
      if (sdri.OrganizationId === Guid.Empty) {
        ++this.orgs;
      }
    });
  }
}
