
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Award from "../../types/Award/Award";
import AwardApiService from "../../services/AwardApiService";
import AwardLocale from "../../types/Award/AwardLocale";
import ConfirmButton from "../../components/ConfirmButton.vue";
import Subject from "../../types/Subject/Subject";
import SubjectApiService from "../../services/SubjectApiService";
import SubjectAward from "../../types/Award/SubjectAward";
import Guid from "../../types/Guid";
import DatePicker from "../../components/DatePicker.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import AuthorizationService from "@/services/AuthorizationService";

class SubjectAwardWrapper {
  Name: string = "";
  Description: string = "";
  SubjectAward: SubjectAward = new SubjectAward();

  get IsNational(): boolean {
    return this.SubjectAward.Award ? this.SubjectAward.Award.IsNational : false;
  }

  get AwardedOn(): string {
    return new Date(`${this.SubjectAward.AwardedOn}`).toDateString();
  }

  constructor(sa: SubjectAward, l: string) {
    this.SubjectAward = sa;
    if (sa.Award) {
      let locale: AwardLocale =
        sa.Award.Locales.find(x => x.Locale === l) || new AwardLocale();
      this.Name = locale.Name;
      this.Description = locale.Description;
    } else {
      this.Name = sa.OtherTitle;
      this.Description = sa.OtherDescription;
    }
  }
}

class AwardWrapper {
  Award: Award = new Award();
  Text: string = "";

  constructor(a: Award, l: string) {
    this.Award = a;
    let locale: AwardLocale =
      a.Locales.find(x => x.Locale === l) || new AwardLocale();

    this.Text = locale.Name;
  }
}

@Component({
  components: {
    ConfirmButton,
    DatePicker,
    VMSTextField,
    VMSSelect
  }
})
export default class SubjectAwards extends Vue {
  subject: Subject = new Subject();
  subjectAwardWrappers: SubjectAwardWrapper[] = [];
  awardApiService: AwardApiService = new AwardApiService();
  subjectApiService: SubjectApiService = new SubjectApiService();
  awardWrappers: AwardWrapper[] = [];
  subjectId: string = "";
  subjectAward: SubjectAward = new SubjectAward();
  awardedOn: string = "";
  authService: AuthorizationService = AuthorizationService.getInstance();

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: this.$root.$t("AwardContext"),
      align: "left",
      value: "SubjectAward.AwardContext"
    },
    {
      text: this.$root.$t("Awarded"),
      align: "left",
      value: "AwardedOn"
    },
    {
      text: this.$root.$t("National"),
      align: "left",
      value: "IsNational"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  mounted() {
    let canGrantNational: boolean = this.authService.hasRole([
      "Administrator",
      "Staff"
    ]);
    this.awardApiService.GetAwards().then(resp => {
      let aws: AwardWrapper[] = [];
      let awardTypes: Award[] = canGrantNational
        ? resp.Value
        : resp.Value.filter(x => !x.IsNational);
      awardTypes.forEach((a: Award) => {
        aws.push(new AwardWrapper(a, this.$i18n.locale));
      });

      aws.sort((a, b) => a.Text.localeCompare(b.Text));

      let ow: AwardWrapper = new AwardWrapper(new Award(), this.$i18n.locale);
      ow.Text = this.$t("Other") as string;
      ow.Award.Id = "";
      aws.push(ow);
      this.awardWrappers = aws;
    });

    if (this.$route.query.subjectId) {
      this.subjectId = this.$route.query.subjectId as string;
      this.subjectApiService.GetSubjectById(this.subjectId).then(resp => {
        this.subject = resp.Value;
      });

      this.getSubjectAwards();
    }
  }

  onAwardInput() {
    this.subjectAward.OtherTitle = "";
    this.subjectAward.OtherDescription = "";
  }

  getSubjectAwards() {
    this.awardApiService.GetSubjectAwards(this.subjectId).then(resp => {
      console.log(resp);
      this.subjectAwardWrappers = [];
      resp.Value.forEach((sa: SubjectAward) => {
        this.subjectAwardWrappers.push(
          new SubjectAwardWrapper(sa, this.$i18n.locale)
        );
      });
    });
  }

  onDeleteItemConfirmed(item: SubjectAwardWrapper) {
    this.awardApiService.DeleteSubjectAward(item.SubjectAward.Id).then(resp => {
      if (resp.Success) this.getSubjectAwards();
    });
  }

  onAddClicked() {
    if ((this.$refs.AwardForm as any).validate()) {
      this.subjectAward.AwardId = this.subjectAward.Award.Id;
      this.subjectAward.Award = new Award();
      this.subjectAward.SubjectId = this.subjectId;
      this.subjectAward.AwardedOn = this.awardedOn;

      this.awardApiService.CreateSubjectAward(this.subjectAward).then(resp => {
        if (resp.Success) {
          this.subjectAward = new SubjectAward();
          (this.$refs.AwardForm as any).resetValidation();
          this.getSubjectAwards();
        }
      });
    }
  }
}
