
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VMSTextArea extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Boolean) outlined: boolean | undefined;
  @Prop(Boolean) noResize: boolean | undefined;

  val: string = this.value || "";
  error: boolean = false;

  type_timer: number = 1000 * 0.25;
  timeout: any | null = null;

  @Watch("value")
  onValueChanged() {
    this.val = this.value || "";
  }

  onInput() {
    this.error = false;
    this.$emit("input", this.val);

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.$emit("typing-complete", this.val);
    }, this.type_timer);
  }

  onKeyUp(event: any) {
    this.$emit("keyup", event);
  }

  onBlur(event: any) {
    this.error = !(this.$refs.in as any).validate();
    this.$emit("blur", event);
  }
}
