export default class TrainingFormConfig {
  HiddenFields: string[] = [];
  VolunteerHourTypes: string[] = ["PD Participation"];

  BuildConfig(typename: string) {
    switch (typename) {
      case "NationalWorkshopTraining":
        this.HiddenFields = ["Conference"];
        break;
      case "SiteSpecificTraining":
        this.HiddenFields = ["TrainingDelivered", "Conference", "Skills"];
        break;
      case "SiteBasedPDOpportunity":
        this.HiddenFields = ["TrainingDelivered", "Conference"];
        break;
      case "CoordinatorConferenceParticipation":
        this.HiddenFields = [
          "TrainingDelivered",
          "NameOfTraining",
          "Facilitators"
        ];

        this.VolunteerHourTypes = [];
        break;
    }
  }
}
