
import SiteApiService from "@/services/SiteApiService";
import Site from "@/types/Site/Site";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSSelect
  }
})
export default class SiteSelect extends Vue {
  @Prop(String) value!: string | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;

  selected: string = this.value || "";
  sites: Site[] = [];
  siteApiService: SiteApiService = new SiteApiService();

  @Watch("value")
  onValueChanged() {
    this.selected = this.value || "";
  }

  get Items(): object[] {
    return this.sites.map((site: Site) => {
      return {
        text: site.Name,
        value: site.Id
      };
    });
  }

  mounted() {
    this.siteApiService.GetSites().then(resp => {
      this.sites = resp.Value;
    });
  }

  onInput() {
    this.$emit("input", this.selected);
  }
}
