import BaseType from "../BaseType";
import Guid from "../Guid";
import Kit from "../Kit/Kit";
import OutreachRequestClassGrade from "./OutreachRequestClassGrade";

export default class OutreachRequestClass extends BaseType {
  OutreachRequestId: string = Guid.Empty;
  KitId: string | null = null;
  Kit: Kit | null = null;
  AdditionalInfo: string | null = null;
  Topic: string | null = null;
  Grades: OutreachRequestClassGrade[] = [];
}
