
import DataObject from "@/types/DataObject";
import WorkshopFileUploadResponse from "@/types/Workshop/WorkshopFileUpload/WorskhopFileUploadResponse";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import WorkshopFileError from "@/types/Workshop/WorkshopFileUpload/WorkshopFileError";

@Component
export default class WorkshopFileErrorsTable extends Vue {
  @Prop(Object) value: WorkshopFileUploadResponse | undefined;
  @Prop(Boolean) loading: boolean | undefined;
  @Prop(String) loadingMsg: string | undefined;

  response: WorkshopFileUploadResponse =
    this.value || new WorkshopFileUploadResponse();

  @Watch("value")
  onValueChanged() {
    this.response = this.value || new WorkshopFileUploadResponse();
  }

  headers: object[] = [
    {
      text: this.$root.$t("ErrorType"),
      align: "left",
      value: "TypeStr"
    },
    {
      text: this.$root.$t("Message"),
      align: "left",
      value: "Message"
    },
    {
      text: this.$root.$t("Info"),
      align: "left",
      value: "Info"
    }
  ];

  get Errors(): WorkshopFileError[] {
    return this.response.Errors;
  }
}
