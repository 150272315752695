
import Guid from "@/types/Guid";
import WorkshopKit from "@/types/Workshop/WorkshopKit";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmButton from "../ConfirmButton.vue";
import KitItemSelect from "../kit/KitItemSelect.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";

class DisplayItem {
  Name: string = "";
  Damaged: string = "";
  Item: WorkshopKit = new WorkshopKit();

  constructor(wk: WorkshopKit, n: string, d: string) {
    this.Item = wk;
    this.Name = n;
    this.Damaged = d;
  }
}

@Component({
  components: {
    ConfirmButton,
    KitItemSelect,
    VMSTextArea
  }
})
export default class WorkshopKitList extends Vue {
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(String) siteId: String | undefined;
  @Prop(Array) value: WorkshopKit[] | undefined;

  //@ts-ignore
  workshopKits: WorkshopKit[] = this.value || [];
  kit: WorkshopKit = new WorkshopKit();
  itemBackup: WorkshopKit = new WorkshopKit();
  addDialog: boolean = false;
  edit: boolean = false;

  @Watch("value")
  onValueChanged() {
    this.workshopKits = this.value || [];
  }

  get Items(): DisplayItem[] {
    console.log(this.workshopKits);
    return this.workshopKits
      .filter(x => !x.Deleted)
      .map((wk: WorkshopKit) => {
        let name: string = "";
        if (this.$root.$i18n.locale === "fr") {
          name = wk.Kit.FrenchName;
        } else {
          name = wk.Kit.EnglishName;
        }

        let damaged = wk.IsKitDamaged ? this.$t("Yes") : this.$t("No");

        return new DisplayItem(wk, name, damaged as string);
      });
  }

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      value: "Name",
      align: "left"
    },
    {
      text: this.$root.$t("IsDamaged"),
      value: "Damaged",
      align: "left"
    },
    {
      text: this.$root.$t("Condition"),
      value: "Item.KitCondition",
      align: "left"
    },
    {
      text: "",
      value: "Actions",
      align: "right"
    }
  ];

  get DialogHeader(): string {
    return "AddKit";
  }

  onAddClick() {
    this.kit = new WorkshopKit();
    this.edit = false;
    this.addDialog = true;
  }

  onEditClick(item: DisplayItem) {
    this.edit = true;
    this.itemBackup = item.Item;
    this.kit = JSON.parse(JSON.stringify(this.itemBackup));
    this.addDialog = true;
  }

  onDeleteConfirmed(item: DisplayItem) {
    if (item.Item.Id === Guid.Empty) {
      this.workshopKits.splice(this.workshopKits.indexOf(item.Item), 1);
    } else {
      item.Item.Deleted = true;
    }

    this.$emit("input", this.workshopKits);
  }

  onCancelClick() {
    this.addDialog = false;
  }

  onSaveItemClick() {
    if (!this.edit) {
      this.workshopKits.push(this.kit);
    } else {
      this.itemBackup.KitId = this.kit.KitId;
      this.itemBackup.Kit = this.kit.Kit;
      this.itemBackup.IsKitDamaged = this.kit.IsKitDamaged;
      this.itemBackup.KitCondition = this.kit.KitCondition;
    }

    this.addDialog = false;
    this.$emit("input", this.workshopKits);
  }
}
