
import Vue from "vue";
import Component from "vue-class-component";
import Site from "@/types/Site/Site";
import { Prop, Watch } from "vue-property-decorator";
import Workshop from "@/types/Workshop/Workshop";
import WorkshopEdit from "./WorkshopEdit.vue";
import ConfirmButton from "../ConfirmButton.vue";
import Guid from "@/types/Guid";
import WorkshopType from "@/types/Workshop/WorkshopType";

@Component({
  components: {
    WorkshopEdit,
    ConfirmButton
  }
})
export default class ChildWorkshops extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(Object) type: WorkshopType | undefined;
  @Prop(Array) value: Workshop[] | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) fields: string[] | undefined;
  @Prop(String) createdBySiteId: string | undefined;

  site: Site = new Site();

  //@ts-ignore
  workshops: Workshop[] = this.value || [];
  edit: boolean = false;
  itemBackup: Workshop = new Workshop();
  editWorkshop: Workshop = new Workshop();
  addDialog: boolean = false;

  headers: object[] = [
    {
      text: "Name",
      align: "Left",
      value: "Name"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  @Watch("value")
  onValueChanged() {
    this.workshops = this.value || [];
  }

  get WorkshopType(): WorkshopType {
    return this.type || new WorkshopType();
  }

  get Items(): Workshop[] {
    return this.workshops.filter(x => !x.Deleted);
  }

  NewWorkshop(): Workshop {
    let ret: Workshop = new Workshop();
    ret.WorkshopTypeId = this.WorkshopType.Id;
    ret.CreatedBySiteId = this.createdBySiteId || "";
    return ret;
  }

  updateWorkshop() {
    this.itemBackup.Name = this.editWorkshop.Name;
    this.itemBackup.Date = this.editWorkshop.Date;
    this.itemBackup.Time = this.editWorkshop.Time;
    this.itemBackup.LocationTypeId = this.editWorkshop.LocationTypeId;
    this.itemBackup.LocationType = this.editWorkshop.LocationType;
    this.itemBackup.Description = this.editWorkshop.Description;
    this.itemBackup.Partnership = this.editWorkshop.Partnership;
    this.itemBackup.NumberOfYouth = this.editWorkshop.NumberOfYouth;
    this.itemBackup.RepeatVisit = this.editWorkshop.RepeatVisit;
    this.itemBackup.NumberOfAdults = this.editWorkshop.NumberOfAdults;
    this.itemBackup.Keywords = this.editWorkshop.Keywords;
    this.itemBackup.VolunteerLogHeaderId = this.editWorkshop.VolunteerLogHeaderId;
    this.itemBackup.VolunteerLogHeader = this.editWorkshop.VolunteerLogHeader;
    this.itemBackup.EducatorLogHeaderId = this.editWorkshop.EducatorLogHeaderId;
    this.itemBackup.EducatorLogHeader = this.editWorkshop.EducatorLogHeader;
    this.itemBackup.WorkshopKits = this.editWorkshop.WorkshopKits;
    this.itemBackup.SubjectAreasHeaderId = this.editWorkshop.SubjectAreasHeaderId;
    this.itemBackup.SubjectAreasHeader = this.editWorkshop.SubjectAreasHeader;
    this.itemBackup.LanguagesHeaderId = this.editWorkshop.LanguagesHeaderId;
    this.itemBackup.LanguagesHeader = this.editWorkshop.LanguagesHeader;
    this.itemBackup.GradesHeaderId = this.editWorkshop.GradesHeaderId;
    this.itemBackup.GradesHeader = this.editWorkshop.GradesHeader;
    this.itemBackup.SiteResponseHeaderId = this.editWorkshop.SiteResponseHeaderId;
    this.itemBackup.SiteResponseHeader = this.editWorkshop.SiteResponseHeader;
    this.itemBackup.SchoolDemographicResponseHeaderId = this.editWorkshop.SchoolDemographicResponseHeaderId;
    this.itemBackup.SchoolDemographicResponseHeader = this.editWorkshop.SchoolDemographicResponseHeader;
    this.itemBackup.SpecialInitiativesHeaderId = this.editWorkshop.SpecialInitiativesHeaderId;
    this.itemBackup.SpecialInitiativesHeader = this.editWorkshop.SpecialInitiativesHeader;
  }

  onAddClick() {
    this.edit = false;
    this.editWorkshop = this.NewWorkshop();
    this.addDialog = true;
  }

  onEditClick(ws: Workshop) {
    this.edit = true;
    this.itemBackup = ws;
    this.editWorkshop = JSON.parse(JSON.stringify(this.itemBackup));
    this.addDialog = true;
  }

  onDeleteConfirmed(ws: Workshop) {
    if (ws.Id === Guid.Empty) {
      const idx: number = this.workshops.indexOf(ws);
      this.workshops.splice(idx, 1);
      this.emitInput();
    } else {
      ws.Deleted = true;
      this.emitInput();
    }
  }

  onSaveItemClick(addAnother: boolean) {
    if ((this.$refs.wsEdit as WorkshopEdit).validate()) {
      if (!this.edit) {
        this.workshops.push(this.editWorkshop);
      } else {
        //edit
        this.updateWorkshop();
      }

      this.editWorkshop = this.NewWorkshop();

      if (!addAnother) {
        this.addDialog = false;
      } else {
        (this.$refs.dialogTitle as any).scrollIntoView();
        this.$ShowSnackbar(this.$t("ItemAdded") as string);
      }
    }

    this.emitInput();
  }

  onCancelClick() {
    this.addDialog = false;
  }

  emitInput() {
    this.$emit("input", this.workshops);
  }
}
