
import EducatorAuxDataApiService from "@/services/EducatorAuxDataApiService";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import Guid from "@/types/Guid";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";
import QueryEducatorsRequest from "@/types/Educator/QueryEducatorsRequest";
import { SortDirection } from "@/types/Pocos/Pagination/PagedQueryRequest";

@Component({
  components: {
    VMSAutoComplete
  }
})
export default class EducatorLookup extends Vue {
  @Prop(Object) value: EducatorAuxData | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) rules: Function[] | undefined;

  apiService: EducatorAuxDataApiService = new EducatorAuxDataApiService();

  displayItems: object[] = [];
  searchText: string = "";
  selectedItem: EducatorAuxData = this.value || new EducatorAuxData();
  loading: boolean = false;
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;

  query: QueryEducatorsRequest = new QueryEducatorsRequest();

  @Watch("value")
  onValueChanged() {
    this.selectedItem = this.value || new EducatorAuxData();
    if (this.selectedItem.Id !== Guid.Empty) {
      this.getById();
    }
  }

  mounted() {
    this.query.Take = 50;
    this.query.SortColumn = "FirstName";
    this.query.Sort = SortDirection.Ascending;

    if (this.selectedItem.Id !== Guid.Empty) {
      this.getById();
    }
  }

  getById() {
    this.loading = true;
    this.apiService.GetEducatorAuxDataById(this.selectedItem.Id).then(resp => {
      this.displayItems = [];
      const educatorData: EducatorAuxData = resp.Value;
      this.displayItems.push({
        text: `${educatorData.FirstName} ${educatorData.LastName} - ${educatorData.Email}`,
        value: educatorData
      });

      this.selectedItem = educatorData;
      this.loading = false;
    });
  }

  onKeyUp() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getData, this.typeTimer);
  }

  onInput() {
    this.$emit("input", this.selectedItem);
  }

  getData() {
    if (this.searchText.length > 2) {
      this.loading = true;
      this.query.Search = this.searchText;

      this.apiService
        .QueryEducatorAuxData(this.query)
        .then(resp => {
          if (resp.Success) {
            this.displayItems = [];
            resp.Value.forEach(ead => {
              this.displayItems.push({
                text: `${ead.FirstName} ${ead.LastName} - ${ead.Email}`,
                value: ead
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
