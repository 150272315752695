import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";
import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import Guid from "../Guid";
import Season from "../Season";
import Site from "../Site/Site";
import VolunteerLogHeader from "../Volunteers/VolunteerLogHeader";
import TrainingType from "./TrainingType";

export default class Training extends BaseType {
  SiteId: string = "";
  Site: Site | undefined;
  SeasonId: string = Guid.Empty;
  Season: Season | undefined;
  TrainingDeliveredId: string = Guid.Empty;
  TrainingDelivered: Enumeration | undefined;
  OtherTraining: string | undefined;
  DeliveryFormatHeaderId: string | undefined;
  DeliveryFormatHeader: EnumerationResponseHeader | undefined;
  TrainingTypeId: string = Guid.Empty;
  TrainingType: TrainingType = new TrainingType();
  CompletedDate: string = "";
  ExternalFacilitators: string | undefined;
  Description: string = "";
  SkillsEnumResponseHeaderId: string | undefined;
  SkillsEnumResponseHeader: EnumerationResponseHeader | undefined;
  FacilitatorLogHeaderId: string | undefined;
  FacilitatorLogHeader: VolunteerLogHeader | undefined;
  VolunteerLogHeaderId: string | undefined;
  VolunteerLogHeader: VolunteerLogHeader | undefined;
}
