import BaseApiService from "./BaseApiService";
import axios from "axios";

export default class ApiVersionApiService extends BaseApiService {
  async GetApiVersion(): Promise<string> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/Version`)
      .then(resp => resp.data as string);
  }
}
