import Report from "@/types/Report/Report";
import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import Vue from "vue";
import BaseApiService from "./BaseApiService";

export default class ReportApiService extends BaseApiService {
  // eslint-disable-next-line
  async RunReport(reportName: string, params: any): Promise<any> {    
    var fileName = reportName;
    await this.defineHeader();
    return axios({
      url: `${this.url}/api/vms/Report/${reportName}`,
      method: "POST",
      responseType: "blob",
      data: params
    }).then(response => {
      if (response && response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data as any])
        );
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${fileName}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        return true;
      } else {
        return false;
      }
    });
  }

  async GetReports(): Promise<UseCaseResponse<Report[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Report`)
      .then(resp => resp.data as UseCaseResponse<Report[]>);
  }

  async GetDashboardData(site_id: string): Promise<UseCaseResponse<object>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Report/Dashboard?site_id=${site_id}`)
      .then(resp => resp.data as UseCaseResponse<object>);
  }
}
