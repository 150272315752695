import BaseType from "../BaseType";
import Guid from "../Guid";
import Site from "../Site/Site";

export default class EducatorSiteItem extends BaseType {
  EducatorSiteHeaderId: string = Guid.Empty;
  SiteId: string = "";
  Site: Site = new Site();
  IsActive: boolean = false;
}
