import BaseType from "../BaseType";
import Guid from "../Guid";
import Kit from "../Kit/Kit";

export default class WorkshopKit extends BaseType {
  WorkshopId: string = Guid.Empty;
  KitId: string = "";
  Kit: Kit = new Kit();
  IsKitDamaged: boolean = false;
  KitCondition: string = "";
}
