var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"70%"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-form',{ref:"form"},[_c('v-card-text',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.kitItem.IsNew),expression:"kitItem.IsNew"}],attrs:{"color":"primary","size":"small","variant":"tonal"},on:{"click":_vm.copyDialogShow}},[_vm._v(" "+_vm._s(_vm.$t("CopyExistingKit"))+" ")])],1),_c('v-card-text',[_c('v-select',{staticStyle:{"width":"40%"},attrs:{"items":_vm.languageOptions,"label":_vm.$t('Language'),"variant":"outlined"},model:{value:(_vm.kitItem.Language),callback:function ($$v) {_vm.$set(_vm.kitItem, "Language", $$v)},expression:"kitItem.Language"}})],1),_c('v-card-text',[_c('v-row',[(
              _vm.kitItem.Language === _vm.LanguageSpoken.English ||
                _vm.kitItem.Language === _vm.LanguageSpoken.Bilingual
            )?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","md":"6"}},[_c('VMSTextField',{attrs:{"label":_vm.$t('EnglishName'),"rules":[
                () =>
                  _vm.kitItem.Language === _vm.LanguageSpoken.English ||
                  _vm.kitItem.Language === _vm.LanguageSpoken.Bilingual
                    ? (_vm.kitItem.EnglishName &&
                        _vm.kitItem.EnglishName.length > 0) ||
                      `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
                    : true
              ]},model:{value:(_vm.kitItem.EnglishName),callback:function ($$v) {_vm.$set(_vm.kitItem, "EnglishName", $$v)},expression:"kitItem.EnglishName"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('EnglishDescription'),"rules":[
                () =>
                  _vm.kitItem.Language === _vm.LanguageSpoken.English ||
                  _vm.kitItem.Language === _vm.LanguageSpoken.Bilingual
                    ? (_vm.kitItem.EnglishDescription &&
                        _vm.kitItem.EnglishDescription.length > 0) ||
                      `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
                    : true
              ]},model:{value:(_vm.kitItem.EnglishDescription),callback:function ($$v) {_vm.$set(_vm.kitItem, "EnglishDescription", $$v)},expression:"kitItem.EnglishDescription"}})],1):_vm._e(),(
              _vm.kitItem.Language === _vm.LanguageSpoken.French ||
                _vm.kitItem.Language === _vm.LanguageSpoken.Bilingual
            )?_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","md":"6"}},[_c('VMSTextField',{attrs:{"label":_vm.$t('FrenchName'),"rules":[
                () =>
                  _vm.kitItem.Language === _vm.LanguageSpoken.French ||
                  _vm.kitItem.Language === _vm.LanguageSpoken.Bilingual
                    ? (_vm.kitItem.FrenchName && _vm.kitItem.FrenchName.length > 0) ||
                      `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
                    : true
              ]},model:{value:(_vm.kitItem.FrenchName),callback:function ($$v) {_vm.$set(_vm.kitItem, "FrenchName", $$v)},expression:"kitItem.FrenchName"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('FrenchDescription'),"rules":[
                () =>
                  _vm.kitItem.Language === _vm.LanguageSpoken.French ||
                  _vm.kitItem.Language === _vm.LanguageSpoken.Bilingual
                    ? (_vm.kitItem.FrenchDescription &&
                        _vm.kitItem.FrenchDescription.length > 0) ||
                      `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
                    : true
              ]},model:{value:(_vm.kitItem.FrenchDescription),callback:function ($$v) {_vm.$set(_vm.kitItem, "FrenchDescription", $$v)},expression:"kitItem.FrenchDescription"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('EnumerationSelect',{attrs:{"id":"duration-select","label":_vm.$t('KitDuration'),"list":_vm.durationList},model:{value:(_vm.kitItem.DurationId),callback:function ($$v) {_vm.$set(_vm.kitItem, "DurationId", $$v)},expression:"kitItem.DurationId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('EnumerationResponse',{attrs:{"label":_vm.$t('Grades'),"list":_vm.gradesList,"multiple":true},model:{value:(_vm.kitItem.GradesHeader),callback:function ($$v) {_vm.$set(_vm.kitItem, "GradesHeader", $$v)},expression:"kitItem.GradesHeader"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('EnumerationResponse',{attrs:{"label":_vm.$t('KitSubjects'),"list":_vm.subjectsList,"multiple":true},model:{value:(_vm.kitItem.SubjectsHeader),callback:function ($$v) {_vm.$set(_vm.kitItem, "SubjectsHeader", $$v)},expression:"kitItem.SubjectsHeader"}})],1)],1),_c('v-switch',{staticStyle:{"display":"inline-block"},attrs:{"label":_vm.$t('KitVisibility')},model:{value:(_vm.kitItem.Visibility),callback:function ($$v) {_vm.$set(_vm.kitItem, "Visibility", $$v)},expression:"kitItem.Visibility"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(_vm._s(_vm.$t("close")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1),_c('CopyKitDialog',{attrs:{"kitItem":_vm.kitItem},on:{"closeDialog":function($event){_vm.copyDialogVisible = !_vm.copyDialogVisible},"copy":_vm.copyKitItems},model:{value:(_vm.copyDialogVisible),callback:function ($$v) {_vm.copyDialogVisible=$$v},expression:"copyDialogVisible"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }