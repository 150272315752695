import UseCaseResponse from "@/types/UseCaseResponse";
import Workshop from "@/types/Workshop/Workshop";
import BaseApiService from "./BaseApiService";
import axios from "axios";
import WorkshopType from "@/types/Workshop/WorkshopType";
import WorkshopFileUploadResponse from "@/types/Workshop/WorkshopFileUpload/WorskhopFileUploadResponse";

export default class WorkshopApiService extends BaseApiService {
  async QueryWorkshops(query: object): Promise<UseCaseResponse<Workshop[]>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Workshop/Query`, query)
      .then(resp => resp.data as UseCaseResponse<Workshop[]>);
  }

  async GetWorkshopById(id: string): Promise<UseCaseResponse<Workshop>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Workshop/${id}`)
      .then(resp => resp.data as UseCaseResponse<Workshop>);
  }

  async GetWorkshopsBySubjectId(
    subjectId: string,
    activeSeasonOnly: boolean
  ): Promise<UseCaseResponse<Workshop[]>> {
    await this.defineHeader();
    return axios
      .get(
        `${this.url}/api/vms/Workshop?subjectId=${subjectId}&activeSeasonOnly=${activeSeasonOnly}`
      )
      .then(resp => resp.data as UseCaseResponse<Workshop[]>);
  }

  async GetWorkshopsBySiteId(
    siteId: string,
    activeSeasonOnly: boolean
  ): Promise<UseCaseResponse<Workshop[]>> {
    await this.defineHeader();
    return axios
      .get(
        `${this.url}/api/vms/Workshop?siteId=${siteId}&activeSeasonOnly=${activeSeasonOnly}`
      )
      .then(resp => resp.data as UseCaseResponse<Workshop[]>);
  }

  async GetWorkshopTypes(): Promise<UseCaseResponse<WorkshopType[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/WorkshopType`)
      .then(resp => resp.data as UseCaseResponse<WorkshopType[]>);
  }

  async CreateWorkshop(workshop: Workshop): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Workshop`, workshop)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateWorkshop(workshop: Workshop): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Workshop/${workshop.Id}`, workshop)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteWorkshop(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Workshop/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async UploadWorkshopFile(
    file: File
  ): Promise<UseCaseResponse<WorkshopFileUploadResponse>> {
    await this.defineHeader();
    let data: FormData = new FormData();
    data.append("wsfile", file);
    let config: object = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return axios
      .post(`${this.url}/api/vms/Workshop/File`, data, config)
      .then(resp => {
        return resp.data as UseCaseResponse<WorkshopFileUploadResponse>;
      });
  }

  async CheckIsComplete(workshop: Workshop): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Workshop/${workshop.Id}/IsComplete`, workshop)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
