
import Vue from "vue";
import Component from "vue-class-component";
import Subject from "../../types/Subject/Subject";
import SubjectApiService from "../../services/SubjectApiService";
import Role from "../../types/Subject/Role";
import Guid from "../../types/Guid";
import VMSTextField from "@/components/fields/VMSTextField.vue";

class SubjectWrapper {
  Name: string = "";
  RoleNames: string = "";
  Subject: Subject = new Subject();

  constructor(s: Subject) {
    this.Subject = s;
    this.Name = s.FullName;
    this.RoleNames = s.SubjectRoles.map((x: any) => {
      return x.Role.Name;
    }).join(", ");
  }
}

@Component({
  components: {
    VMSTextField
  }
})
export default class SubjectList extends Vue {
  apiService: SubjectApiService = new SubjectApiService();

  headers: Object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("Roles"),
      align: "left",
      value: "RoleNames"
    },
    {
      text: "",
      value: "Actions",
      sortable: false
    }
  ];

  subjectWrappers: SubjectWrapper[] = [];
  searchText: string = "";
  coordinatorRole: Role = new Role();

  isCoordinator(item: SubjectWrapper): boolean {
    let coordinatorRoleId: string = this.coordinatorRole.Id;
    let subjectRoles: string[] = item.Subject.SubjectRoles.map((x: any) => {
      return x.RoleId;
    });

    return subjectRoles.includes(coordinatorRoleId);
  }

  mounted() {
    this.apiService
      .All([this.apiService.GetSubjects(), this.apiService.GetRoles()])
      .then(resps => {
        const subjects: Subject[] = resps[0].Value;
        this.subjectWrappers = subjects.map(x => {
          return new SubjectWrapper(x);
        });

        this.coordinatorRole =
          resps[1].Value.find((x: Role) => x.Name === "Coordinator") ||
          new Role();
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  onRoleQuickPromote(item: SubjectWrapper) {
    if (this.coordinatorRole.Id !== Guid.Empty && !this.isCoordinator(item)) {
      this.apiService
        .ApplyRole(item.Subject.Id, this.coordinatorRole.Id)
        .then(resp => {
          this.apiService
            .GetSubjects()
            .then(sResp => {
              const subjects: Subject[] = sResp.Value;
              this.subjectWrappers = subjects.map(x => {
                return new SubjectWrapper(x);
              });
            })
            .catch(error => {
              console.log(error.response);
            });
        });
    }
  }

  onRoleEditClick(item: SubjectWrapper) {
    this.$router.push(`/subject/roles?id=${item.Subject.Id}`);
  }

  onSubjectClick(item: SubjectWrapper) {
    this.$router.push(`/volunteer/summary?subjectId=${item.Subject.Id}`);
  }

  onAwardsClick(item: SubjectWrapper) {
    this.$router.push(`/subject/awards?subjectId=${item.Subject.Id}`);
  }

  onNotesClick(item: SubjectWrapper) {
    this.$router.push(`/subject/notes?subjectId=${item.Subject.Id}`);
  }
}
