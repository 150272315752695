
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import WorkshopApiService from "@/services/WorkshopApiService";
import WorkshopFileUploadResponse from "@/types/Workshop/WorkshopFileUpload/WorskhopFileUploadResponse";
import WorkshopFileEducatorTable from "@/components/workshops/workshopfileupload/WorkshopFileEducatorTable.vue";
import WorkshopFileErrorsTable from "@/components/workshops/workshopfileupload/WorkshopFileErrorsTable.vue";
import WorkshopFileStats from "@/components/workshops/workshopfileupload/WorkshopFileStats.vue";

@Component({
  components: {
    WorkshopFileEducatorTable,
    WorkshopFileErrorsTable,
    WorkshopFileStats
  }
})
export default class WorkshopFileUpload extends Vue {
  //@Prop(String) value: String | undefined;
  step: number = 1;
  file: File | null = null;
  loading: boolean = false;
  loadingMsg: string = "";
  tab: number = 0;
  workshopApiService: WorkshopApiService = new WorkshopApiService();
  response: WorkshopFileUploadResponse = new WorkshopFileUploadResponse();

  onUploadClicked() {
    if (this.file !== null) {
      this.loading = true;
      this.workshopApiService
        .UploadWorkshopFile(this.file)
        .then(resp => {
          this.response = resp.Value;
          this.step = 2;
        })
        .catch(ex => {
          console.log("An exception occurred calling UploadWorkshopFile");
          console.log(ex);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  onAddToLogClicked() {
    this.$emit("confirmed", this.response);
  }

  onCloseClicked() {
    this.$emit("closed");
    this.response = new WorkshopFileUploadResponse();
    this.step = 1;
  }
}
