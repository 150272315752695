import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import BaseType from "../BaseType";
import Site from "../Site/Site";
import Enumeration from "../Enumeration/Enumeration";

export enum LanguageSpoken {
  English,
  French,
  Other,
  Bilingual
}

export default class Kit extends BaseType {
  EnglishName: string = "";
  FrenchName: string = "";
  EnglishDescription: string = "";
  FrenchDescription: string = "";
  DurationId: string = "";
  Duration: Enumeration | null = null;
  Visibility: boolean = true;
  Language: LanguageSpoken = LanguageSpoken.English;
  SiteId: string = "";
  Site: Site = new Site();
  GradesHeaderId: string = "";
  GradesHeader: EnumerationResponseHeader | undefined;
  SubjectsHeaderId: string = "";
  SubjectsHeader: EnumerationResponseHeader | undefined;
  IsNew: boolean = false;
}
