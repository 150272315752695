
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Enumeration from "../../types/Enumeration/Enumeration";
import EnumerationApiService from "../../services/EnumerationApiService";
import { TranslateResult } from "vue-i18n";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import AuthorizationService from "@/services/AuthorizationService";
import LocalizationApiService from "@/services/LocalizationApiService";

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class EnumerationsView extends Vue {
  apiService: EnumerationApiService = new EnumerationApiService();
  headers: Object[] = [
    {
      text: this.$root.$t("LocalizationKey"),
      align: "left",
      value: "Text"
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: this.$root.$t("Value"),
      align: "left",
      value: "Value"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  enumerations: Enumeration[] = [];
  lblSearch: TranslateResult = this.$root.$t("Search");
  searchText: string = "";

  authService: AuthorizationService = AuthorizationService.getInstance();
  patchingLocales: boolean = false;
  localizerApiService: LocalizationApiService = new LocalizationApiService();
  get ElevatedUser(): boolean {
    return this.authService.hasRole(["localizer", "administrator"]);
  }

  mounted() {
    this.apiService.GetEnumerations().then(resp => {
      this.enumerations = resp.Value;
    });
  }

  onDeleteConfirmed(item: Enumeration) {
    this.apiService.DeleteEnumeration(item).then(resp => {
      if (resp.Value) {
        //remove from ui
        this.enumerations.splice(this.enumerations.indexOf(item), 1);
      } else {
        console.log("Failed to delete...");
      }
    });
  }

  onCreateClick() {
    this.$router.push("/enumerations/create");
  }

  onPatchLocaleFiles() {
    if (this.ElevatedUser) {
      this.patchingLocales = true;

      //dont send the debug locale
      const locale_msgs = {
        en: this.$i18n.messages["en"],
        fr: this.$i18n.messages["fr"]
      };

      this.localizerApiService
        .PatchLocalizationFile("vms", locale_msgs)
        .then(resp => {
          if (resp.Success) {
            this.$ShowSnackbar(`Added ${resp.Value} new keys!`);
          }
        })
        .finally(() => {
          this.patchingLocales = false;
        });
    }
  }
}
