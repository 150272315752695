
import SubjectApiService from "@/services/SubjectApiService";
import Site from "@/types/Site/Site";
import Subject from "@/types/Subject/Subject";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VolunteerLookupItem from "./VolunteerLookupItem";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";

class DataWrapper {
  Subject: Subject = new Subject();
  Text: string = "";
  Value: VolunteerLookupItem = new VolunteerLookupItem();

  constructor(s: Subject, site: Site) {
    this.Value.SubjectId = s.Id;
    this.Value.SiteId = site.Id;
    this.Value.Subject = s;

    this.Text = `${s.FullName} - ${site.Name}`;
  }
}

@Component({
  components: {
    VMSAutoComplete
  }
})
export default class VolunteerLookup extends Vue {
  @Prop(Object) value: VolunteerLookupItem | undefined;
  @Prop(String) siteId: string | undefined;
  @Prop(String) subjectId: string | undefined;
  @Prop(Array) rules: Array<any> | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) placeholder: string | undefined;

  subjectApiService: SubjectApiService = new SubjectApiService();
  searchLoading: boolean = false;
  searchItems: DataWrapper[] = [];
  search: string = "";
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;
  lookupItem: VolunteerLookupItem = this.value || new VolunteerLookupItem();

  @Watch("value")
  onValueChanged() {
    if (
      this.value &&
      (this.lookupItem.SiteId !== this.value.SiteId ||
        this.lookupItem.SubjectId !== this.value.SubjectId)
    ) {
      this.lookupItem = this.value;
      this.getById();
    }
  }

  mounted() {
    this.getById();
  }

  onKeyUp() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.doSearch, this.typeTimer);
  }

  removeDuplicates(a: Array<any>): Array<any> {
    return a.filter((element, idx, array) => {
      //remove duplicates based on compound Id
      return (
        array.findIndex(x => {
          return x.Id === element.Id;
        }) === idx
      );
    });
  }

  doSearch() {
    if (this.search) {
      this.searchLoading = true;
      this.subjectApiService.SearchSubjects(this.search).then(resp => {
        this.searchItems = [];
        let subjectSites: any[] = [];
        let site: Site = new Site();

        resp.Value.forEach((s: Subject) => {
          subjectSites = subjectSites.concat(
            s.SiteRegistrations.map(sr => {
              site = sr.Site || new Site();
              return {
                Id: `${s.Id}${site.Id}`,
                Subject: s,
                Site: site
              };
            })
          );
        });

        subjectSites = this.removeDuplicates(subjectSites);

        subjectSites.forEach((ss: any) => {
          this.searchItems.push(new DataWrapper(ss.Subject, ss.Site));
        });

        this.searchLoading = false;
      });
    }
  }

  onInput() {
    this.$emit("input", this.lookupItem);
  }

  getById() {
    if (this.lookupItem.SubjectId) {
      this.subjectApiService
        .GetSubjectById(this.lookupItem.SubjectId)
        .then(resp => {
          const sub: Subject = resp.Value;
          let site: Site = new Site();

          let subjectSites = sub.SiteRegistrations.map(sr => {
            site = sr.Site || new Site();
            return {
              Id: `${sub.Id}${site.Id}`,
              Subject: sub,
              Site: site
            };
          });

          subjectSites = this.removeDuplicates(subjectSites);

          subjectSites.forEach((ss: any) => {
            if (ss.Site.Id === this.lookupItem.SiteId) {
              this.searchItems = [];
              this.searchItems.push(new DataWrapper(ss.Subject, ss.Site));
              this.lookupItem = this.searchItems[0].Value;
            }
          });
        });
    }
  }

  Clear() {
    (this.$refs.vmsAutoComplete as VMSAutoComplete).Clear();
  }
}
