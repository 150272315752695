import Season from "@/types/Season";
import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";
import BaseApiService from "./BaseApiService";

export default class SeasonApiService extends BaseApiService {
  async GetSeasons(): Promise<UseCaseResponse<Season[]>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Season`)
      .then(resp => resp.data as UseCaseResponse<Season[]>);
  }

  async CreateSeason(item: Season): Promise<UseCaseResponse<Season>> {
    this.defineHeader();
    return axios
      .post(this.url + "/api/vms/Season", item)
      .then(resp => resp.data as UseCaseResponse<Season>);
  }

  async Patch(seasons: Season[]): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/Season`, seasons)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
