
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    VMSTextField
  }
})
export default class PhoneNumberInput extends Vue {
  @Prop(String) value: string | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Boolean) persistenthint: boolean | undefined;

  val: string = this.value || "";

  @Watch("value")
  onValueChanged() {
    this.val = this.value || "";
  }

  get AllRules(): Array<Function> {
    let ret: Array<Function> = this.rules || [];
    ret.push(() => this.validPhoneNumber());
    return ret;
  }

  validPhoneNumber(): boolean | string {
    if (
      this.value &&
      this.value.length > 0 &&
      /^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/.test(this.value) === false
    ) {
      return `${this.$t("InvalidPhoneNumber")}`;
    } else return true;
  }

  onInput() {
    this.$emit("input", this.val);
  }
}
