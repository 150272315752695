
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import Guid from "@/types/Guid";
import SiteRegistrationConfig from "@/types/SiteRegistration/SiteRegistrationConfig";
import Vue from "vue";
import Component from "vue-class-component";
import SiteApiService from "../../services/SiteApiService";
import Site, { SiteType } from "@/types/Site/Site";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import SchoolLookup from "@/components/school/SchoolLookup.vue";
import SiteOutreachRequestStatus, {
  SiteRequestStatus
} from "@/types/Site/SiteOutreachRequestStatus";
import SiteStatus from "@/types/Site/SiteStatus";

@Component({
  components: {
    VMSTextField,
    VMSSelect,
    SchoolLookup
  }
})
export default class SiteCreate extends Vue {
  site: Site = new Site();
  siteApiService: SiteApiService = new SiteApiService();

  configRegistration: boolean = false;
  enumApiService: EnumerationApiService = new EnumerationApiService();
  enumLists: EnumerationList[] = [];
  config: SiteRegistrationConfig = new SiteRegistrationConfig();
  edit_status: SiteStatus = new SiteStatus();

  get siteTypeOptions(): object[] {
    return [
      {
        text: "Regular",
        value: SiteType.Regular
      },
      {
        text: "Professional",
        value: SiteType.Professional
      },
      {
        text: "National",
        value: SiteType.National
      }
    ];
  }

  get Headers(): object[] {
    return [
      {
        text: this.$root.$t("Site.Status"),
        value: "IsActive",
        sortable: false
      },
      {
        text: this.$root.$t("Site.Status.Date"),
        value: "CreatedOn",
        sortable: false
      }
    ];
  }

  get StatusItems(): object[] {
    return this.site.SiteStatus.sort((a, b) => {
      const a_d = new Date(a.CreatedOn);
      const b_d = new Date(b.CreatedOn);
      if (a_d < b_d) return 1;
      else if (a_d > b_d) return -1;
      else return 0;
    });
  }

  mounted() {
    this.enumApiService.GetEnumerationLists().then(resp => {
      this.enumLists = resp.Value;
    });

    if (this.$route.query.id) {
      let siteId: string = this.$route.query.id as string;
      this.siteApiService.GetSiteById(siteId).then(resp => {
        this.site = resp.Value;
        console.log(this.site);
        this.edit_status.IsActive = this.site.LatestSiteStatus
          ? this.site.LatestSiteStatus.IsActive
          : true;
        if (this.site.RegistrationConfig) {
          this.config = JSON.parse(
            this.site.RegistrationConfig
          ) as SiteRegistrationConfig;
          this.configRegistration = true;
        }
      });
    }
  }

  onSaveClicked() {
    if (this.configRegistration) {
      this.site.RegistrationConfig = JSON.stringify(this.config);
    } else {
      this.site.RegistrationConfig = null;
    }

    if (this.site.Id === Guid.Empty) {
      let or_status: SiteOutreachRequestStatus = new SiteOutreachRequestStatus();
      or_status.SiteId = Guid.Empty;
      or_status.Status = SiteRequestStatus.Open;
      this.site.OutreachRequestStatus.push(or_status);

      let site_status: SiteStatus = new SiteStatus();
      site_status.IsActive = true;
      this.site.SiteStatus.push(site_status);

      this.siteApiService.CreateSite(this.site).then(resp => {
        if (resp.Success) this.$router.push("/sites");
      });
    } else {
      if (!this.site.SiteStatus) {
        this.site.SiteStatus = [];
      }

      if (
        !this.site.LatestSiteStatus ||
        this.site.LatestSiteStatus.IsActive != this.edit_status.IsActive
      ) {
        console.log("updating status..");
        this.site.SiteStatus.push(this.edit_status);
      }

      this.siteApiService.UpdateSite(this.site).then(resp => {
        if (resp.Success) this.$router.push(`/sites`);
      });
    }
  }
}
