import BaseType from "../BaseType";
import EmailAddressList from "./EmailAddressList";
import EmailLocalization from "./EmailLocalization";

export enum EmailType {
  SiteRegistration,
  SiteRegistrationNotification,
  RegistrationApproval,
  VolunteerAddedToActivity,
  ActivityEvaluationRequest,
  KitDamaged,

  // outreach request emails
  NewOutreachRequestToOpenSiteRequestor,
  NewOutreachRequestToWaitlistedSiteRequestor,
  OutreachRequestDeclinedRequestor,
  OutreachRequestAcceptedRequestor,
  NewOutreachRequestToOpenSite,
  NewOutreachRequestToNational,
  OutreachRequestSiteUpdated,
  OutreachRequestIntakeClosed
  // ======================
}

export default class Email extends BaseType {
  ApplyHTMLBranding: boolean = false;
  EmailType: EmailType = EmailType.SiteRegistration;
  AddressLists: EmailAddressList[] = [];
  Localizations: EmailLocalization[] = [];
}
