import BaseType from "../BaseType";
import Award from "./Award";

export default class SubjectAward extends BaseType {
  SubjectId: string = "";
  AwardId: string = "";
  Award: Award = new Award();
  CreatedOn: string = "";
  ModifiedOn: string = "";
  AwardedOn: string = "";
  OtherTitle: string = "";
  OtherDescription: string = "";
  AwardContext: string = "";
}
