import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";
import Guid from "../Guid";
import SiteResponseHeader from "../Site/SiteResponseHeader";

export default class VolunteerProfileNonStudent extends BaseType {
  VolunteerProfileId: string = Guid.Empty;
  SiteResponseHeaderId: string = "";
  SiteResponseHeader: SiteResponseHeader | undefined;
  WorkSituationId: string = Guid.Empty;
  WorkSituation: Enumeration | undefined;
  Employer: string = "";
  ModifiedOn: string = "";
}
