
import Vue from "vue";
import Component from "vue-class-component";
import DatePicker from "@/components/DatePicker.vue";
import Season from "../../types/Season";
import SeasonApiService from "../../services/SeasonApiService";

@Component({
  components: {
    DatePicker
  }
})
export default class SeasonCreate extends Vue {
  // Data item with default value
  season: Season = new Season();
  seasonApiService: SeasonApiService = new SeasonApiService();
  existingSeasons: Season[] = [];

  mounted() {
    this.seasonApiService.GetSeasons().then(resp => {
      this.existingSeasons = resp.Value;
    });
  }

  validateDate(datestr: string): boolean {
    let checkdate: Date = this.parseDate(datestr);
    let noOverlap: boolean = true;
    this.existingSeasons.forEach((s: Season) => {
      let sd: Date = this.parseDate(s.StartDate);
      let ed: Date = this.parseDate(s.EndDate);

      if (
        checkdate.getTime() >= sd.getTime() &&
        checkdate.getTime() <= ed.getTime()
      ) {
        noOverlap = false;
      }
    });

    return noOverlap;
  }

  parseDate(datestr: string): Date {
    let parts: string[] = datestr.split("-");
    let year: number = parseInt(parts[0]);
    let monthIdx: number = parseInt(parts[1]) - 1;
    let day: number = parseInt(parts[2]);

    return new Date(year, monthIdx, day);
  }

  onSaveClicked() {
    if ((this.$refs.form as any).validate()) {
      this.seasonApiService.CreateSeason(this.season).then(resp => {
        if (resp.Success) this.$router.push(`/seasons`);
      });
    }
  }
}
