
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EmailApiService from "../../services/EmailApiService";
import ConfirmButton from "../../components/ConfirmButton.vue";
import EmailAddressList from "../../types/Email/EmailAddressList";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import { EmailType } from "@/types/Email/Email";

@Component({
  components: {
    ConfirmButton,
    VMSTextField,
    VMSTextArea
  }
})
export default class AddressListList extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  emailApiService: EmailApiService = new EmailApiService();
  emailDialog: boolean = false;
  emailAddressLists: EmailAddressList[] = [];
  viewList: EmailAddressList = new EmailAddressList();

  viewTab: any = null;

  headers: object[] = [
    {
      text: this.$root.$t("Type"),
      value: "Email.EmailType"
    },
    {
      text: this.$root.$t("Recipients"),
      value: "EmailAddresses"
    },
    {
      text: this.$root.$t("Site"),
      value: "Site.Name"
    },
    {
      text: "",
      value: "Actions"
    }
  ];

  get FilteredHeaders(): object[] {
    if (!this.siteId) return this.headers;
    else return this.headers.filter((h: any) => h.value !== "Site.Name");
  }

  get emailTypes(): any {
    var ret: any = {};
    for (const t in EmailType) {
      const num_val = Number.parseInt(t);
      if (!Number.isNaN(num_val)) {
        ret[num_val] = this.$root.$t(`EmailType.${EmailType[t]}`);
      }
    }

    return ret;
  }

  mounted() {
    this.getData();
  }

  getData() {
    if (this.siteId) {
      this.emailApiService.GetAddressListsBySiteId(this.siteId).then(resp => {
        this.emailAddressLists = resp.Value;
      });
    } else {
      this.emailApiService.GetAddressLists().then(resp => {
        this.emailAddressLists = resp.Value;
      });
    }
  }

  onEditItemClicked(item: EmailAddressList) {
    if (this.urlSite) {
      this.$router.push(`/${this.urlSite}/AddressList/Update?id=${item.Id}`);
    } else {
      this.$router.push(`/email/addresslist/update?id=${item.Id}`);
    }
  }

  onDeleteItemConfirmed(item: EmailAddressList) {
    this.emailApiService.DeleteAddressList(item.Id).then(resp => {
      if (resp.Success) {
        this.getData();
      }
    });
  }

  onCreateClicked() {
    if (this.urlSite) {
      this.$router.push(`/${this.urlSite}/AddressList/Create`);
    } else {
      this.$router.push(`/email/addresslist/create`);
    }
  }

  onViewEmailsClicked(eal: EmailAddressList) {
    this.viewList = eal;
    this.emailDialog = true;
  }
}
