import Guid from "@/types/Guid";
import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import Email from "@/types/Email/Email";
import EmailAddressList from "@/types/Email/EmailAddressList";
import DataObject from "@/types/DataObject";

export default class EmailApiService extends BaseApiService {
  //emails
  async CreateEmail(email: Email): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Email`, email)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetEmails(): Promise<UseCaseResponse<Email[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Email`)
      .then(resp => resp.data as UseCaseResponse<Email[]>);
  }

  async GetEmailById(id: string): Promise<UseCaseResponse<Email>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Email/${id}`)
      .then(resp => resp.data as UseCaseResponse<Email>);
  }

  async UpdateEmail(email: Email): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Email/${email.Id}`, email)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteEmail(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Email/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async SendTestEmail(
    id: string,
    recipient: string
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Email/${id}/SendTest?recipient=${recipient}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
  //

  //address lists
  async CreateAddressList(
    list: EmailAddressList
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/EmailAddressList`, list)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetAddressListsBySiteId(
    siteId: string
  ): Promise<UseCaseResponse<EmailAddressList[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EmailAddressList?siteId=${siteId}`)
      .then(resp => resp.data as UseCaseResponse<EmailAddressList[]>);
  }

  async GetAddressListsById(
    id: string
  ): Promise<UseCaseResponse<EmailAddressList>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EmailAddressList/${id}`)
      .then(resp => resp.data as UseCaseResponse<EmailAddressList>);
  }

  async GetAddressLists(): Promise<UseCaseResponse<EmailAddressList[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EmailAddressList`)
      .then(resp => resp.data as UseCaseResponse<EmailAddressList[]>);
  }

  async UpdateAddressList(
    list: EmailAddressList
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/EmailAddressList/${list.Id}`, list)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteAddressList(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/EmailAddressList/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
  //
}
