import BaseType from "../BaseType";
import Guid from "../Guid";
import Enumeration from "./Enumeration";

export default class EnumerationListItem extends BaseType {
  EnumerationListId: string = Guid.Empty;
  EnumerationId: string = Guid.Empty;
  Enumeration: Enumeration = new Enumeration();
  SortOrder: number = 0;
  ConditionalTrigger: boolean = false;
}
