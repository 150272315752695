
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SchoolLookup from "../school/SchoolLookup.vue";
import EnumerationResponse from "../EnumerationResponse.vue";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationApiService from "@/services/EnumerationApiService";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import SchoolEdit from "@/components/school/SchoolEdit.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import Guid from "@/types/Guid";

@Component({
  components: {
    SchoolLookup,
    EnumerationResponse,
    PhoneNumberInput,
    SchoolEdit,
    VMSTextField
  }
})
export default class EducatorEdit extends Vue {
  @Prop(Object) value: EducatorAuxData | undefined;
  @Prop(String) id: string | undefined;

  //@ts-ignore
  educator: EducatorAuxData = this.value || new EducatorAuxData();
  enumApiService: EnumerationApiService = new EnumerationApiService();
  gradesEnumList: EnumerationList = new EnumerationList();
  languagesEnumList: EnumerationList = new EnumerationList();
  newOrg: boolean = false;

  /*
    Now, I know what your thinking.  Shouldn't this computed property work without the newOrg backing property?
    Isn't that the entire point of computed properties?
    Yes! Yes, it's what it should do and what they are for, and yet here we are.
  */
  get cAddOrg(): boolean {
    if (this.educator && this.educator.Organization) {
      this.newOrg = true;
    } else {
      this.newOrg = false;
    }
    return this.newOrg;
  }

  set cAddOrg(val: boolean) {
    if (val) {
      let org: UpdateOrganizationRequest = new UpdateOrganizationRequest();
      org.Id = Guid.Empty;
      this.educator.Organization = org;
      this.educator.OrganizationId = org.Id;
    } else {
      this.educator.Organization = undefined;
    }
    this.newOrg = val;
  }

  mounted() {
    this.enumApiService
      .GetEnumerationListsBySystemName(["Languages", "Grades"])
      .then(resp => {
        let lists: EnumerationList[] = resp.Value;
        console.log(lists);
        this.languagesEnumList =
          lists.find(l => l.SystemName === "Languages") ||
          new EnumerationList();
        this.gradesEnumList =
          lists.find(l => l.SystemName === "Grades") || new EnumerationList();
      });
  }

  validateEmail(email: string): boolean | string {
    const regex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
    return regex.test(email) || this.$t("InvalidEmailValidationMsg").toString();
  }

  @Watch("value")
  onValueChanged() {
    this.educator = this.value || new EducatorAuxData();
  }
}
