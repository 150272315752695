var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("EnumerationListCreate")))]),_c('div',[_c('v-form',{ref:"form"},[_c('VMSTextField',{attrs:{"label":_vm.$t('Name'),"rules":[
          () =>
            _vm.enumList.Name.length > 0 || `${_vm.$t('Name')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.enumList.Name),callback:function ($$v) {_vm.$set(_vm.enumList, "Name", $$v)},expression:"enumList.Name"}}),_c('VMSSelect',{attrs:{"label":_vm.$t('SystemName'),"items":_vm.SystemNames,"disabled":_vm.SystemNames.length === 0},on:{"input":_vm.setName},model:{value:(_vm.enumList.SystemName),callback:function ($$v) {_vm.$set(_vm.enumList, "SystemName", $$v)},expression:"enumList.SystemName"}})],1),_c('h3',[_vm._v(_vm._s(_vm.$t("Enumerations")))]),_c('VMSTextField',{attrs:{"label":_vm.$t('Search')},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.enumerations,"headers":_vm.enumHeaders,"search":_vm.searchText,"footer-props":{ 'items-per-page-options': [10, 20, 50, -1] }},scopedSlots:_vm._u([{key:`item.Text`,fn:function({ item }){return [_c('td',[_vm._v(_vm._s(_vm.$t(item.LocalizationKeyId)))])]}},{key:`item.Actions`,fn:function({ item }){return [_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.onAddEnum(item)}}},on),[_vm._v("mdi-plus-thick")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Add")))])])],1)]}}],null,true)}),_c('h3',[_vm._v(_vm._s(_vm.$t("SelectedItems")))]),_c('v-form',{ref:"itemsForm"},[_c('v-input',{attrs:{"rules":[
          () =>
            _vm.enumListItems.length > 0 ||
            `${_vm.$t('EnumerationListItemsRequired')}`
        ]}},[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.enumListHeaders,"items":_vm.enumListItems,"no-data-text":"No Enumerations Added","footer-props":{ 'items-per-page-options': [10, 20, 50, -1] }},scopedSlots:_vm._u([{key:`item.Text`,fn:function({ item }){return [_c('td',[_vm._v(_vm._s(_vm.$t(item.Enumeration.LocalizationKeyId)))])]}},{key:`item.ConditionalTrigger`,fn:function({ item }){return [_c('td',[_c('v-simple-checkbox',{attrs:{"ripple":false,"dense":""},model:{value:(item.ConditionalTrigger),callback:function ($$v) {_vm.$set(item, "ConditionalTrigger", $$v)},expression:"item.ConditionalTrigger"}})],1)]}},{key:`item.MoveActions`,fn:function({ item }){return [_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"disabled":item.SortOrder === 0},on:{"click":function($event){return _vm.moveItem(item, -1)}}},on),[_vm._v("mdi-chevron-up")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("MoveUp")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"disabled":item.SortOrder === _vm.enumListItems.length - 1},on:{"click":function($event){return _vm.moveItem(item, 1)}}},on),[_vm._v("mdi-chevron-down")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("MoveDown")))])])],1)]}},{key:`item.Actions`,fn:function({ item }){return [_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.onDeleteEnumItem(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Delete")))])])],1)]}}],null,true)})],1)],1)],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }