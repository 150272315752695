
import OrganizationFileUpdateError from "@/types/Organization/OrganizationFileUpdateError";
import OrganizationFileUpdateHeader from "@/types/Organization/OrganizationFileUpdateHeader";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class OrganizationFileErrorsTable extends Vue {
  @Prop(Object) value: OrganizationFileUpdateHeader | undefined;

  header: OrganizationFileUpdateHeader =
    this.value || new OrganizationFileUpdateHeader();

  expandedErrors: OrganizationFileUpdateError[] = [];

  headers: object[] = [
    {
      text: this.$root.$t("ErrorMessage"),
      align: "left",
      sortable: false,
      value: "Message"
    },
    {
      text: this.$root.$t("Type"),
      align: "left",
      sortable: false,
      value: "Type"
    }
  ];

  @Watch("value")
  onValueChanged() {
    this.header = this.value || new OrganizationFileUpdateHeader();
  }

  getTypeText(type: number): string {
    switch (type) {
      case 1:
        return "ComparisonError";
      case 2:
        return "DataError";
      default:
        return "ParseError";
    }
  }

  get SubItems(): object[] {
    if (this.expandedErrors.length === 0) {
      return [];
    } else {
      return this.expandedErrors.map(x => JSON.parse(x.InfoJson));
    }
  }

  get SubHeaders(): object[] {
    if (this.expandedErrors.length > 0) {
      let item: OrganizationFileUpdateError = this.expandedErrors[0];
      if (item.Type === 0) {
        return [
          {
            text: "Line Number",
            align: "left",
            sortable: false,
            value: "LineNum"
          },
          {
            text: "Line",
            align: "left",
            sortable: false,
            value: "Line"
          }
        ];
      } else if (item.Type === 1) {
        return [
          {
            text: "File Item",
            align: "left",
            sortable: false,
            value: "FileObject"
          },
          {
            text: "Database Item",
            align: "left",
            sortable: false,
            value: "DbObject"
          }
        ];
      } else if (item.Type === 2) {
        return [
          {
            text: "InvalidProperties",
            align: "left",
            sortable: false,
            value: "InvalidProperties"
          },
          {
            text: "Object",
            align: "left",
            sortable: false,
            value: "FileObject"
          }
        ];
      }
    }

    return [];
  }
}
