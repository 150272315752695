import BaseApiService from "./BaseApiService";
import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import VMSEnumeration from "@/types/Enumeration/VMSEnumeration";

export default class VMSEnumerationApiService extends BaseApiService {
  async GetVMSEnumerations(): Promise<UseCaseResponse<VMSEnumeration[]>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/VMSEnumeration`)
      .then(resp => resp.data as UseCaseResponse<VMSEnumeration[]>);
  }
}
