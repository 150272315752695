import ScreeningDocument from "@/types/ScreeningDocument/ScreeningDocument";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import axios from "axios";
import DataObject from "@/types/DataObject";
import ScreeningDocumentBatchCreateRequest from "@/types/ScreeningDocument/ScreeningDocumentBatchCreateRequest";
import { create } from "domain";
import ScreeningDocumentTrackingItem from "@/types/ScreeningDocument/ScreeningDocumentTrackingItem";

export default class ScreeningDocumentApiService extends BaseApiService {
  async GetScreeningDocuments(): Promise<UseCaseResponse<ScreeningDocument[]>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument`)
      .then(resp => resp.data as UseCaseResponse<ScreeningDocument[]>);
  }

  async GetScreeningDocumentsBySubjectId(
    subjectId: string
  ): Promise<UseCaseResponse<ScreeningDocument[]>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument?subjectId=${subjectId}`)
      .then(resp => resp.data as UseCaseResponse<ScreeningDocument[]>);
  }

  async GetScreeningDocumentsBySiteId(
    siteId: string
  ): Promise<UseCaseResponse<ScreeningDocument[]>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument?siteId=${siteId}`)
      .then(resp => resp.data as UseCaseResponse<ScreeningDocument[]>);
  }

  async GetScreeningDocumentById(
    id: string
  ): Promise<UseCaseResponse<ScreeningDocument>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument/${id}`)
      .then(resp => resp.data as UseCaseResponse<ScreeningDocument>);
  }

  async CreateScreeningDocument(
    doc: ScreeningDocument
  ): Promise<UseCaseResponse<string>> {
    this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/ScreeningDocument`, doc)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateScreeningDocument(
    doc: ScreeningDocument
  ): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/ScreeningDocument/${doc.Id}`, doc)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteScreeningDocument(id: string): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/ScreeningDocument/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async GetScreeningDocumentTypes(): Promise<UseCaseResponse<DataObject>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument/Type`)
      .then(resp => resp.data as UseCaseResponse<DataObject>);
  }

  async GetScreeningDocumentStatus(): Promise<UseCaseResponse<DataObject>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument/Status`)
      .then(resp => resp.data as UseCaseResponse<DataObject>);
  }

  async CreateScreeningDocumentBatch(
    createRequest: ScreeningDocumentBatchCreateRequest
  ): Promise<UseCaseResponse<string>> {
    this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/ScreeningDocument/Batch`, createRequest)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async CreateBatchTrackingItems(
    batchId: string,
    trackingItem: ScreeningDocumentTrackingItem
  ): Promise<UseCaseResponse<string>> {
    this.defineHeader();
    return axios
      .post(
        `${this.url}/api/vms/ScreeningDocument/Batch/${batchId}/TrackingItem`,
        trackingItem
      )
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async CreateTrackingItem(
    trackingItem: ScreeningDocumentTrackingItem
  ): Promise<UseCaseResponse<string>> {
    this.defineHeader();
    return axios
      .post(
        `${this.url}/api/vms/ScreeningDocument/${trackingItem.ScreeningDocumentId}/TrackingItem`,
        trackingItem
      )
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetScreeningDocumentsByBatchId(
    batchId: string
  ): Promise<UseCaseResponse<ScreeningDocument[]>> {
    this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ScreeningDocument/Batch/${batchId}`)
      .then(resp => resp.data as UseCaseResponse<ScreeningDocument[]>);
  }

  async DeleteTrackingItem(id: string): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/ScreeningDocument/TrackingItem/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteScreeningDocumentBatch(
    batchId: string
  ): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/ScreeningDocument/Batch/${batchId}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async UpdateScreeningDocumentApprovals(
    docs: ScreeningDocument[]
  ): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/ScreeningDocument`, docs)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async UpdateTrackingItem(
    item: ScreeningDocumentTrackingItem
  ): Promise<UseCaseResponse<boolean>> {
    this.defineHeader();
    return axios
      .put(
        `${this.url}/api/vms/ScreeningDocument/TrackingItem/${item.Id}`,
        item
      )
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
