
import SiteApiService from "@/services/SiteApiService";
import Guid from "@/types/Guid";
import Site from "@/types/Site/Site";
import SiteResponseHeader from "@/types/Site/SiteResponseHeader";
import SiteResponseItem from "@/types/Site/SiteResponseItem";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSSelect
  }
})
export default class SiteResponse extends Vue {
  @Prop(Object) value: SiteResponseHeader | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Boolean) multiple: boolean | undefined;

  selected: string[] | string = [];
  sites: Site[] = [];
  siteApiService: SiteApiService = new SiteApiService();

  //@ts-ignore
  header: SiteResponseHeader = this.value || new SiteResponseHeader();

  @Watch("value")
  onValueChanged() {
    this.header = this.value || new SiteResponseHeader();
    this.setSelected();
  }

  get Items(): object[] {
    return this.sites.map((site: Site) => {
      return {
        text: site.Name,
        value: site.Id
      };
    });
  }

  mounted() {
    this.siteApiService.GetSites().then(resp => {
      this.sites = resp.Value;
    });

    this.setSelected();
  }

  setSelected() {
    let siteIds: string[] = this.header.Items.filter(
      (sri: SiteResponseItem) => !sri.Deleted
    ).map((sri: SiteResponseItem) => {
      return sri.SiteId;
    });

    if (this.multiple) {
      this.selected = siteIds;
    } else {
      if (siteIds.length > 0) {
        this.selected = siteIds[0];
      }
    }
  }

  onInput(selected: string | string[]) {
    if (!Array.isArray(selected)) {
      selected = [selected];
    }

    this.setChanges(selected);

    this.$emit("input", this.header);
  }

  setChanges(selected: string[]) {
    let current: string[] = this.header.Items.filter(x => !x.Deleted).map(
      sri => {
        return sri.SiteId;
      }
    );

    let added = selected.filter(x => current.includes(x) === false);
    let removed = current.filter(x => !selected.includes(x));

    this.add(added);
    this.remove(removed);
  }

  add(added: string[]) {
    added.forEach((sId: string) => {
      //restore items marked deleted rather than add again
      //items marked deleted in db wouldn't have been returned
      let markedDeleted: SiteResponseItem | undefined = this.header.Items.find(
        (sri: SiteResponseItem) => sri.SiteId == sId && sri.Deleted
      );

      if (markedDeleted) {
        markedDeleted.Deleted = false;
      } else {
        let toAdd: SiteResponseItem = new SiteResponseItem();
        toAdd.SiteResponseHeaderId = this.header.Id;
        toAdd.SiteId = sId;
        this.header.Items.push(toAdd);
      }
    });
  }

  remove(removed: string[]) {
    removed.forEach((sId: string) => {
      let toDel: SiteResponseItem =
        this.header.Items.find((sri: SiteResponseItem) => sri.SiteId == sId) ||
        new SiteResponseItem();

      if (toDel.Id === Guid.Empty) {
        let idx: number = this.header.Items.indexOf(toDel);
        this.header.Items.splice(idx, 1);
      } else {
        toDel.Deleted = true;
      }
    });
  }
}
