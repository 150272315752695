
import EducatorLogHeader from "@/types/Educator/EducatorLogHeader";
import EducatorLogItem from "@/types/Educator/EducatorLogItem";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SchoolLookup from "../school/SchoolLookup.vue";
import EducatorLookup from "./EducatorLookup.vue";
import ConfirmButton from "../ConfirmButton.vue";
import Guid from "@/types/Guid";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import EducatorEdit from "@/components/educator/EducatorEdit.vue";

class DisplayItem {
  Item: EducatorLogItem = new EducatorLogItem();

  get Name(): string {
    return `${this.Item.EducatorAuxData.FirstName} ${this.Item.EducatorAuxData.LastName}`;
  }

  get Email(): string {
    return this.Item.EducatorAuxData.Email;
  }

  constructor(eli: EducatorLogItem) {
    this.Item = eli;
  }
}

@Component({
  components: {
    SchoolLookup,
    EducatorLookup,
    ConfirmButton,
    EducatorEdit
  }
})
export default class EducatorLog extends Vue {
  @Prop(Object) value: EducatorLogHeader | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;

  recordDialog: boolean = false;
  header: EducatorLogHeader = this.value || new EducatorLogHeader();
  item: EducatorLogItem = new EducatorLogItem();
  itemBackup: EducatorLogItem = new EducatorLogItem();
  edit: boolean = false;
  addEducator: boolean = false;

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      value: "Name",
      align: "left"
    },
    {
      text: this.$root.$t("Email"),
      value: "Email",
      align: "left"
    },
    {
      text: this.$root.$t("Survey"),
      value: "Item.ActivityFeedback",
      align: "left"
    },
    {
      text: "",
      value: "Actions",
      align: "right"
    }
  ];

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  get Items(): DisplayItem[] {
    return this.header.Items.filter(x => !x.Deleted).map(
      (eli: EducatorLogItem) => {
        return new DisplayItem(eli);
      }
    );
  }

  @Watch("value")
  onValueChanged() {
    this.header = this.value || new EducatorLogHeader();
  }

  onAddEducatorChanged() {
    this.item.EducatorAuxDataId = Guid.Empty;
    this.item.EducatorAuxData = new EducatorAuxData();
  }

  onAddRecordClicked() {
    this.edit = false;
    this.item = new EducatorLogItem();
    this.recordDialog = true;
  }

  onSaveItemClicked(addAnother: boolean) {
    if ((this.$refs.educatorForm as any).validate()) {
      if (!this.edit) {
        this.header.Items.push(this.item);
      } else {
        this.itemBackup.OrganizationId = this.item.OrganizationId;
        this.itemBackup.EducatorAuxDataId = this.item.EducatorAuxDataId;
        this.itemBackup.EducatorAuxData = this.item.EducatorAuxData;
        this.itemBackup.ActivityFeedback = this.item.ActivityFeedback;
      }

      this.$emit("input", this.header);

      this.item = new EducatorLogItem();

      if (!addAnother) {
        this.recordDialog = false;
      } else {
        this.$ShowSnackbar(this.$t("ItemAdded") as string);
      }
    }
  }

  onEditClicked(item: DisplayItem) {
    this.edit = true;

    if (item.Item.Id !== Guid.Empty) {
      this.addEducator = false;
    } else {
      this.addEducator = true;
    }

    this.itemBackup = item.Item;
    this.item = JSON.parse(JSON.stringify(item.Item));
    this.recordDialog = true;
  }

  onDeleteConfirmed(item: DisplayItem) {
    if (item.Item.Id === Guid.Empty) {
      this.header.Items.splice(this.header.Items.indexOf(item.Item), 1);
    } else {
      item.Item.Deleted = true;
      item.Item.EducatorAuxData.Deleted = true;
    }

    this.$emit("input", this.header);
  }

  onCancelClicked() {
    this.recordDialog = false;
    this.item = new EducatorLogItem();
    this.edit = false;
  }
}
