
import EducatorAuxDataApiService from "@/services/EducatorAuxDataApiService";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import Guid from "@/types/Guid";
import Vue from "vue";
import SchoolLookup from "../../components/school/SchoolLookup.vue";
import EnumerationResponse from "@/components/EnumerationResponse.vue";
import EducatorLookup from "@/components/educator/EducatorLookup.vue";
import { Component, Prop } from "vue-property-decorator";
import SiteApiService from "@/services/SiteApiService";
import Site from "@/types/Site/Site";
import EducatorSiteHeader from "@/types/Educator/EducatorSiteHeader";
import EducatorSiteItem from "@/types/Educator/EducatorSiteItem";
import EducatorEdit from "@/components/educator/EducatorEdit.vue";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import OrganizationApiService from "@/services/OrganizationApiService";

@Component({
  components: {
    SchoolLookup,
    EnumerationResponse,
    EducatorLookup,
    EducatorEdit
  }
})
export default class EducatorCreate extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  siteApiService: SiteApiService = new SiteApiService();
  site: Site = new Site();

  apiService: EducatorAuxDataApiService = new EducatorAuxDataApiService();
  educator: EducatorAuxData = new EducatorAuxData();
  addEducator: boolean = false;

  edit: boolean = false;

  orgApiService: OrganizationApiService = new OrganizationApiService();

  get HeaderText(): string {
    return !this.edit ? "AddEducatorToSite" : "EditEducator";
  }

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  get SiteId(): string {
    return this.siteId || "";
  }

  mounted() {
    this.siteApiService.GetSiteById(this.SiteId).then(resp => {
      this.site = resp.Value;
    });

    if (this.$route.query.id) {
      this.edit = true;
      const id: string = this.$route.query.id as string;
      this.apiService.GetEducatorAuxDataById(id).then(resp => {
        this.educator = resp.Value;
      });
    }
  }

  onAddEducatorChanged(add: boolean) {
    this.addEducator = add;
    this.educator = new EducatorAuxData();
  }

  async onSaveClicked() {
    if ((this.$refs.form as any).validate()) {
      if (this.educator.Organization) {
        let orgId: string = await this.SaveOrg(this.educator.Organization);
        if (orgId) {
          this.educator.OrganizationId = orgId;
          this.educator.Organization = undefined;
        } else {
          this.$ShowSnackbar("unable to save organization");
          return;
        }
      }

      if (this.educator.Id === Guid.Empty) {
        //create
        this.educator.EducatorSiteHeader = new EducatorSiteHeader();
        let siteRelation: EducatorSiteItem = new EducatorSiteItem();
        siteRelation.SiteId = this.site.Id;
        siteRelation.Site = this.site;
        siteRelation.IsActive = true;
        this.educator.EducatorSiteHeader.Items.push(siteRelation);

        this.apiService.CreateEducatorAuxData(this.educator).then(resp => {
          if (resp.Success) {
            this.$router.push(`/${this.urlSite}/Educators`);
          }
        });
      } else {
        //update
        if (!this.educator.EducatorSiteHeader) {
          this.educator.EducatorSiteHeader = new EducatorSiteHeader();
        }

        let siteRelation:
          | EducatorSiteItem
          | undefined = this.educator.EducatorSiteHeader.Items.find(
          esi => esi.SiteId == this.SiteId
        );

        if (!siteRelation) {
          siteRelation = new EducatorSiteItem();
          siteRelation.SiteId = this.SiteId;
          siteRelation.Site = this.site;
          siteRelation.IsActive = true;
          this.educator.EducatorSiteHeader.Items.push(siteRelation);
        }

        this.apiService.UpdateEducatorAuxData(this.educator).then(resp => {
          if (resp.Success) {
            this.$router.push(`/${this.urlSite}/Educators`);
          }
        });
      }
    }
  }

  async SaveOrg(org: UpdateOrganizationRequest): Promise<string> {
    let ret: string = "";
    if (org) {
      let resp = await this.orgApiService.CreateOrganization(org);
      if (resp.Success) {
        ret = resp.Value;
      }
    }
    return ret;
  }
}
