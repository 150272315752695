import BaseType from "../BaseType";
import Guid from "../Guid";
import Site from "../Site/Site";
import Subject from "../Subject/Subject";
import VolunteerLogItemAuxData from "./VolunteerLogItemAuxData";
import VolunteerLogItemHours from "./VolunteerLogItemHours";

export default class VolunteerLogItem extends BaseType {
  LogHeaderId: string = Guid.Empty;
  SubjectId: string = "";
  Subject: Subject | undefined;
  SiteId: string = "";
  Site: Site | undefined;
  AuxData: VolunteerLogItemAuxData | null = null;
  LogHours: VolunteerLogItemHours[] = [];
}
