
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ReportApiService from "@/services/ReportApiService";
import SiteApiService from "../services/SiteApiService";
import Site from "../types/Site/Site";
import DatePicker from "@/components/DatePicker.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import AuthorizationService from "@/services/AuthorizationService";
import Report from "@/types/Report/Report";
import SeasonApiService from "@/services/SeasonApiService";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";

class ReportParameters {
  SiteId: string | null = null;
  BeginDate: string | null = null;
  EndDate: string | null = null;
  Locale: string = "";
  SeasonId: string | null = null;
  Initiatives: string[] = [];
}

@Component({
  components: {
    DatePicker,
    VMSSelect
  }
})
export default class Home extends Vue {
  @Prop(String) siteId: string | undefined;
  reportService: ReportApiService = new ReportApiService();
  seasonApiService: SeasonApiService = new SeasonApiService();
  authService: AuthorizationService = AuthorizationService.getInstance();
  enumApiService: EnumerationApiService = new EnumerationApiService();

  locales: object[] = [
    {
      text: this.$root.$t("English"),
      value: "en"
    },
    {
      text: this.$root.$t("French"),
      value: "fr"
    }
  ];

  siteApiService: SiteApiService = new SiteApiService();
  sites: Site[] = [];

  selectedReport: Report | null = null;

  err: string = "";
  reports: Report[] = [];
  seasons: object[] = [];

  reportParams: ReportParameters = new ReportParameters();

  specialInitiatives: EnumerationList = new EnumerationList();

  get InitiativeItems(): object[] {
    return this.specialInitiatives.EnumerationItems.map(ei => {
      let ls: string = this.$t(ei.Enumeration.LocalizationKey).toString();

      return {
        text: ls,
        value: ei.EnumerationId
      };
    });
  }

  get reportDefinitions(): object[] {
    return this.reports.map(r => {
      return {
        text: this.$t(`ReportNames.${r.Name}`),
        value: r
      };
    });
  }

  mounted() {
    this.siteApiService.GetSites().then(r => {
      this.reportParams.SiteId = this.siteId || null;
      this.sites = r.Value;
    });

    this.enumApiService
      .GetEnumerationListsBySystemName(["SpecialInitiativesTags"])
      .then(resp => {
        if (resp.Success) {
          this.specialInitiatives = resp.Value[0];
          console.log(this.specialInitiatives);
        }
      });

    this.seasonApiService.GetSeasons().then(resp => {
      this.seasons = resp.Value.map(s => {
        let active: string = s.Active ? `(${this.$t("Active")})` : "";
        return {
          text: `${s.StartDate.substr(0, 10)} : ${s.EndDate.substr(
            0,
            10
          )} ${active}`,
          value: s.Id
        };
      });
    });

    this.reportService.GetReports().then(resp => {
      if (resp.Success) {
        this.reports = resp.Value;
      }
    });
  }

  runReport() {
    if ((this.$refs.reportForm as any).validate() && this.selectedReport) {
      let report: string = this.selectedReport.Name;
      this.reportService.RunReport(report, this.reportParams).then(resp => {
        if (!resp) {
          this.err = this.$t("ReportErrorMsg") as string;
        } else {
          this.err = "";
        }
      });
    }
  }

  fieldVisible(field: string) {
    if (!this.selectedReport) {
      return false;
    } else {
      return this.selectedReport.SupportedParameters === null
        ? false
        : this.selectedReport.SupportedParameters.includes(field);
    }
  }
}
