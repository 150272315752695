
import Enumeration from "@/types/Enumeration/Enumeration";
import VolunteerProfile from "@/types/Profile/VolunteerProfile";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VolunteerProfileTable extends Vue {
  @Prop(Object) value: VolunteerProfile | undefined;

  profile: VolunteerProfile = this.value || new VolunteerProfile();

  @Watch("value")
  onValueChanged() {
    this.profile = this.value || new VolunteerProfile();
  }

  localizeEnum(e: Enumeration): string | undefined {
    if (!e) {
      return "";
    } else {
      let ls: string = this.$root.$t(e.LocalizationKey).toString();
      return ls;
    }
  }
}
