var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.HeaderText))]),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],attrs:{"xs12":""}},[_c('v-form',{ref:"form"},[(_vm.isFieldEnabled('TrainingDelivered'))?_c('EnumerationSelect',{attrs:{"label":_vm.$t('WhatTrainingWasDelivered'),"list":_vm.trainingDeliveryEnumList,"rules":[
          () =>
            (_vm.training.TrainingDeliveredId &&
              _vm.training.TrainingDeliveredId !== _vm.EmptyGuid) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.training.TrainingDeliveredId),callback:function ($$v) {_vm.$set(_vm.training, "TrainingDeliveredId", $$v)},expression:"training.TrainingDeliveredId"}}):_vm._e(),(
          _vm.isFieldEnabled('NameOfTraining') &&
            _vm.training.TrainingDeliveredId ===
              `958cf867-4ca6-4d85-975d-9a727b9c5262`
        )?_c('VMSTextField',{attrs:{"label":_vm.$t('NameOfWorkshopTraining'),"rules":[
          () =>
            (_vm.training.OtherTraining && _vm.training.OtherTraining.length > 0) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.training.OtherTraining),callback:function ($$v) {_vm.$set(_vm.training, "OtherTraining", $$v)},expression:"training.OtherTraining"}}):_vm._e(),(_vm.isFieldEnabled('Conference'))?_c('EnumerationSelect',{attrs:{"label":_vm.$t('Conference'),"list":_vm.conferenceEnumList,"rules":[
          () =>
            _vm.training.TrainingDeliveredId !== _vm.EmptyGuid ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.training.TrainingDeliveredId),callback:function ($$v) {_vm.$set(_vm.training, "TrainingDeliveredId", $$v)},expression:"training.TrainingDeliveredId"}}):_vm._e(),_c('EnumerationResponse',{attrs:{"label":_vm.$t('WhichDeliveryFormatsWereUsed'),"list":_vm.deliveryFormatEnumList,"multiple":true,"rules":[
          () =>
            _vm.isEnumResponseHeaderValid(_vm.training.DeliveryFormatHeader) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.training.DeliveryFormatHeader),callback:function ($$v) {_vm.$set(_vm.training, "DeliveryFormatHeader", $$v)},expression:"training.DeliveryFormatHeader"}}),_c('DatePicker',{attrs:{"label":_vm.$t('Date'),"rules":[
          () =>
            _vm.validateDate(_vm.training.CompletedDate) ||
            `${_vm.$t('DateMustBeInValidSeason')}`
        ]},model:{value:(_vm.training.CompletedDate),callback:function ($$v) {_vm.$set(_vm.training, "CompletedDate", $$v)},expression:"training.CompletedDate"}}),(_vm.isFieldEnabled('ExternalFacilitators'))?_c('v-switch',{attrs:{"label":_vm.$t('ThereWereExternalFacilitators')},model:{value:(_vm.externalFacilitators),callback:function ($$v) {_vm.externalFacilitators=$$v},expression:"externalFacilitators"}}):_vm._e(),(_vm.externalFacilitators && _vm.isFieldEnabled('ExternalFacilitators'))?_c('VMSTextField',{attrs:{"label":_vm.$t('DescribeWhoElseContributedToTraining')},model:{value:(_vm.training.ExternalFacilitators),callback:function ($$v) {_vm.$set(_vm.training, "ExternalFacilitators", $$v)},expression:"training.ExternalFacilitators"}}):_vm._e(),(_vm.isFieldEnabled('Description'))?_c('VMSTextField',{attrs:{"label":_vm.$t('Description')},model:{value:(_vm.training.Description),callback:function ($$v) {_vm.$set(_vm.training, "Description", $$v)},expression:"training.Description"}}):_vm._e(),(_vm.isFieldEnabled('Skills'))?_c('EnumerationResponse',{attrs:{"label":_vm.$t('WhichSkillsWereAddressed'),"list":_vm.skillsEnumList,"multiple":true,"rules":[
          () =>
            _vm.isEnumResponseHeaderValid(_vm.training.SkillsEnumResponseHeader) ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}`
        ]},model:{value:(_vm.training.SkillsEnumResponseHeader),callback:function ($$v) {_vm.$set(_vm.training, "SkillsEnumResponseHeader", $$v)},expression:"training.SkillsEnumResponseHeader"}}):_vm._e(),(_vm.isFieldEnabled('Facilitators'))?_c('VolunteerHours',{attrs:{"label":_vm.$t('Facilitators'),"hourtypes":['PD Preparation', 'PD Delivery']},model:{value:(_vm.training.FacilitatorLogHeader),callback:function ($$v) {_vm.$set(_vm.training, "FacilitatorLogHeader", $$v)},expression:"training.FacilitatorLogHeader"}}):_vm._e(),_c('VolunteerHours',{staticClass:"mt-4",attrs:{"label":_vm.$t('Volunteers'),"hourtypes":_vm.formConfig.VolunteerHourTypes},model:{value:(_vm.training.VolunteerLogHeader),callback:function ($$v) {_vm.$set(_vm.training, "VolunteerLogHeader", $$v)},expression:"training.VolunteerLogHeader"}})],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary"},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }