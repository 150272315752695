import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import configurationService from "@/services/ConfigurationService";
import VueGtagPlugin from "vue-gtag";
import VueGtm from "vue-gtm";
import { GetConfigForEnvironment } from "./env-config";

Vue.config.productionTip = false;
Vue.prototype.$ScrollToFirstError = () => {
  (process as any).nextTick(() => {
    const el: HTMLElement | null = document.querySelector(
      ".v-messages.error--text:first-of-type"
    );

    if (el) {
      el.scrollIntoView();
      window.scrollBy(0, -200);
    }
  });
};

Vue.use(
  VueGtagPlugin,
  {
    config: {
      id: "UA-10578100-21",
      includes: [
        {
          id: "G-79B0J3ZV9W",
          params: {
            send_page_view: true
          }
        }
      ],
      params: {
        sendPageView: true
      }
    }
  },
  router
);

Vue.use(VueGtm, {
  id: ["GTM-TK4BQC2", "GTM-WHMF9DX"], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

// Get the configuration for current environment
const envConfig = GetConfigForEnvironment();

const config: any = {
  urls: {
    ltsol_id: envConfig.idUrl,
    ltsol_apis: envConfig.apiUrl
  },
  showVersion: envConfig.showVersion,
  apiRequestLogSeconds: envConfig.apiLogRequestSeconds
};

configurationService.init(config);
new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
