
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Award from "../../types/Award/Award";
import AwardApiService from "../../services/AwardApiService";
import ConfirmButton from "../../components/ConfirmButton.vue";
import AwardLocale from "../../types/Award/AwardLocale";

class AwardWrapper {
  Name: string = "";
  Description: string = "";
  Award: Award = new Award();

  get IsNational(): boolean {
    return this.Award.IsNational;
  }

  constructor(a: Award, l: string) {
    this.Award = a;
    let locale: AwardLocale =
      a.Locales.find(x => x.Locale === l) || new AwardLocale();
    this.Name = locale.Name;
    this.Description = locale.Description;
  }
}

@Component({
  components: {
    ConfirmButton
  }
})
export default class AwardList extends Vue {
  awardWrappers: AwardWrapper[] = [];
  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: this.$root.$t("National"),
      align: "left",
      value: "IsNational"
    },
    {
      text: "",
      align: "right",
      value: "Actions"
    }
  ];

  awardApiService: AwardApiService = new AwardApiService();

  mounted() {
    this.getData();
  }

  getData() {
    this.awardApiService.GetAwards().then(resp => {
      this.awardWrappers = [];
      resp.Value.forEach((a: Award) => {
        this.awardWrappers.push(new AwardWrapper(a, this.$i18n.locale));
      });

      this.awardWrappers.sort((a, b) => a.Name.localeCompare(b.Name));
    });
  }

  onAddClicked() {
    this.$router.push(`/award/create`);
  }

  onDeleteItemConfirmed(item: AwardWrapper) {
    this.awardApiService.DeleteAward(item.Award.Id).then(resp => {
      this.getData();
    });
  }

  onEditItemClicked(item: AwardWrapper) {
    this.$router.push(`/award/update?id=${item.Award.Id}`);
  }
}
