
import YouthDemographics from "@/types/YouthDemographics/YouthDemographics";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import Guid from "@/types/Guid";

@Component({
  components: {
    EnumerationSelect
  }
})
export default class YouthDemographicsLog extends Vue {
  @Prop(Object) value: YouthDemographics | undefined;
  @Prop(String) id: string | undefined;
  @Prop(Boolean) hideToggle: boolean | undefined;

  addDemogs: boolean = false;
  //@ts-ignore
  demogs: YouthDemographics = this.value || new YouthDemographics();
  enumApiService: EnumerationApiService = new EnumerationApiService();
  populationDensities: EnumerationList = new EnumerationList();
  genderIdentities: EnumerationList = new EnumerationList();

  get cAddDemogs(): boolean {
    return this.addDemogs;
  }

  set cAddDemogs(val: boolean) {
    this.addDemogs = val;
    if (this.addDemogs) {
      this.$emit("input", this.demogs);
    } else {
      this.$emit("input", undefined);
    }
  }

  @Watch("value")
  onValueChanged() {
    this.demogs = this.value || new YouthDemographics();
    if (this.demogs.Id !== Guid.Empty) {
      this.addDemogs = true;
    }
  }

  mounted() {
    if (this.demogs.Id !== Guid.Empty) {
      this.addDemogs = true;
    }

    this.enumApiService
      .GetEnumerationListsBySystemName([
        "YouthGroupGenderIdentity",
        "PopulationDensities"
      ])
      .then(resp => {
        if (resp.Success) {
          let lists: EnumerationList[] = resp.Value;
          this.genderIdentities =
            lists.find(x => x.SystemName === "YouthGroupGenderIdentity") ||
            new EnumerationList();
          this.populationDensities =
            lists.find(x => x.SystemName === "PopulationDensities") ||
            new EnumerationList();
        }
      });
  }
}
