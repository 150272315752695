import Guid from "../Guid";

export default class UpdateOrganizationRequest {
  Id: string = "";
  Name: string = "";
  CountryId: string = Guid.Empty;
  RegionId: string = "";
  City: string = "";
  Address: string = "";
  PhysicalAddress: string = "";
  PostalCode: string = "";
  PhysicalPostalCode: string = "";
  TelephoneNumber: string = "";
  FaxNumber: string = "";
  IsLowIncome: boolean = false;
  IsPrimarilyFnmi: boolean = false;
  RepresentedFnmi: string = "";
  GenderDistributionId: string = "";
  LanguageId: string = "";
  TypeId: string = "";
  SubTypeId: string = "";
  SchoolBoardId: string = "";
  GradeLevelsId: string = "";
  Validated: boolean = false;
  Status: number = 0;
  StatusDate: string = new Date().toDateString();
  DateClosed: string = "";
  StatusComments: string = "";
  MergedId: string = "";
  CreationSource: string = "";
}
