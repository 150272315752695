import Vue from "vue";
import Vuetify from "vuetify/lib";
// Translation provided by Vuetify (typescript)
import en from "vuetify/src/locale/en";
import fr from "vuetify/src/locale/fr";

Vue.use(Vuetify);

export const lang = window.location.origin.includes("parlonssciences")
  ? "fr"
  : "en";

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: lang
  },
  theme: {
    themes: {
      light: {
        primary: "#0054a4", //blue
        secondary: "#737373", //grey
        accent: "#7dc242" //green
      }
    }
  }
});
