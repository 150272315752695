
import { Component } from "vue-property-decorator";
import LocalizationApiService from "@/services/LocalizationApiService";
import LocalizationKey from "@/types/Localizer/LocalizationKey";
import Vue from "vue";
import VMSTextField from "../fields/VMSTextField.vue";
import VMSTextArea from "../fields/VMSTextArea.vue";

@Component({
  components: {
    VMSTextField,
    VMSTextArea
  }
})
export default class LocalizationsTable extends Vue {
  apiService: LocalizationApiService = new LocalizationApiService();
  localizations: LocalizationKey[] = [];

  search: string = "";
  group_id: string = "";
  add_key: LocalizationKey = new LocalizationKey();
  adding: boolean = false;

  headers: object[] = [
    {
      text: this.$root.$t("Key"),
      value: "Key"
    },
    {
      text: this.$root.$t("English"),
      value: "Localizations.en"
    },
    {
      text: this.$root.$t("English"),
      value: "Localizations.en"
    },
    {
      text: "",
      value: "Actions"
    }
  ];

  mounted() {
    this.getData();
  }

  KeyUnique(key: string): boolean {
    const existing = this.localizations.find(l => l.Key === key);
    return existing === undefined;
  }

  getData() {
    this.apiService.GetLocalizations().then(resp => {
      if (resp.Success) {
        this.localizations = resp.Value;
      }
    });
  }

  onSelectItem(item: LocalizationKey) {
    this.$emit("item-selected", item);
  }

  onAddClicked() {
    this.add_key = new LocalizationKey();
    this.adding = true;
  }

  onSaveKeyClicked() {
    let ret: boolean = (this.$refs.key_form as any).validate();
    if (ret) {
      this.apiService.CreateKey(this.add_key).then(resp => {
        if (resp.Success) {
          this.getData();
          this.adding = false;
        }
      });
    }
  }
}
