import DataObject from "../DataObject";

export default class WorkshopConfig {
  ParentFields: string[] = [];
  ChildFields: string[] = [];

  ParentRequirements: string[] = [];
  ChildRequirements: string[] = [];

  SupportsBulkUpload: string[] = ["Workshop", "OnCampusEventWithSchools"];

  BuildMaps(type: string) {
    switch (type) {
      case "Workshop":
        this.ParentFields = [
          "ActivityName",
          "ActivityDate",
          "ActivityTime",
          "Sites",
          "TypeOfLocation",
          "School",
          "Description",
          "Partnership",
          "LanguageOfActivity",
          "GradeOfStudents",
          "RepeatVisit",
          "NumberOfAdults",
          "Keywords",
          "SubjectAreas",
          "VolunteerHours",
          "Kits",
          "Educators",
          "YouthDemographics",
          "SpecialInitiativesTags",
          "VolunteerLocation",
          "ZoomId"
        ];

        break;

      case "MultiWorkshop":
        this.ParentFields = [
          "ActivityName",
          "ActivityDate",
          "Sites",
          "TypeOfLocation",
          "School",
          "Description",
          "Partnership",
          "YouthDemographics",
          "VolunteerLocation",
          "ZoomId"
        ];

        this.ChildFields = [
          "ActivityName",
          "ActivityTime",
          "LanguageOfActivity",
          "GradeOfStudents",
          "NumberOfYouth",
          "RepeatVisit",
          "NumberOfAdults",
          "Keywords",
          "SubjectAreas",
          "VolunteerHours",
          "Kits",
          "Educators",
          "SpecialInitiativesTags"
        ];
        break;

      case "ActivityBooth":
        this.ParentFields = [
          "ActivityName",
          "ActivityDate",
          "ActivityTime",
          "Sites",
          "TypeOfLocation",
          "School",
          "Description",
          "Partnership",
          "LanguageOfActivity",
          "GradeOfStudents",
          "VolunteerHours",
          "YouthDemographics",
          "VolunteerLocation",
          "ZoomId"
        ];

        this.ChildFields = [
          "ActivityName",
          "NumberOfYouth",
          "NumberOfAdults",
          "Keywords",
          "SubjectAreas",
          "Kits",
          "SpecialInitiativesTags"
        ];
        break;

      case "PublicOnCampusEvent":
        this.ParentFields = [
          "ActivityName",
          "ActivityDate",
          "Sites",
          "TypeOfLocation",
          "Description",
          "LanguageOfActivity",
          "GradeOfStudents",
          "NumberOfYouthAtEvent",
          "FacultyOrCampusStaff",
          "MediaInAttendance",
          "OutsideFundingReceived",
          "VolunteerHours",
          "YouthDemographics",
          "Partnership",
          "VolunteerLocation",
          "ZoomId"
        ];

        this.ChildFields = [
          "ActivityName",
          "ActivityTime",
          "NumberOfYouth",
          "RepeatVisit",
          "NumberOfAdults",
          "Keywords",
          "SubjectAreas",
          "Kits",
          "SpecialInitiativesTags"
        ];
        break;

      case "MentoringScienceFairJudge":
        this.ParentFields = [
          "ActivityName",
          "ActivityDate",
          "ActivityTime",
          "Sites",
          "TypeOfLocation",
          "School",
          "Description",
          "Partnership",
          "LanguageOfActivity",
          "GradeOfStudents",
          "RepeatVisit",
          "NumberOfAdults",
          "Keywords",
          "SubjectAreas",
          "VolunteerHours",
          "Educators",
          "YouthDemographics",
          "SpecialInitiativesTags",
          "VolunteerLocation",
          "ZoomId"
        ];
        break;

      case "OnCampusEventWithSchools":
        this.ParentFields = [
          "ActivityName",
          "ActivityDate",
          "Sites",
          "TypeOfLocation",
          "Description",
          "LanguageOfActivity",
          "GradeOfStudents",
          "FacultyOrCampusStaff",
          "MediaInAttendance",
          "OutsideFundingReceived",
          "PhotoPermissionIdMethod",
          "SuccessesSuggestionsIssues",
          "Educators",
          "School",
          "NumberOfYouthAtEvent",
          "VolunteerHours",
          "YouthDemographics",
          "VolunteerLocation",
          "ZoomId"
        ];

        this.ChildFields = [
          "ActivityName",
          "ActivityTime",
          "NumberOfYouth",
          "RepeatVisit",
          "NumberOfAdults",
          "Keywords",
          "SubjectAreas",
          "Partnership",
          "Kits",
          "SpecialInitiativesTags"
        ];
        break;
    }
  }
}
