import BaseType from "../BaseType";
import Guid from "../Guid";
import EnumerationList from "./EnumerationList";
import EnumerationResponseItem from "./EnumerationResponseItem";

export default class EnumerationResponseHeader extends BaseType {
  EnumerationListId: string = Guid.Empty;
  EnumerationList: EnumerationList = new EnumerationList();
  Items: EnumerationResponseItem[] = [];
}
