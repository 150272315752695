import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";
import YouthDemographics from "../YouthDemographics/YouthDemographics";
import OutreachRequestClass from "./OutreachRequestClass";
import OutreachRequestNote from "./OutreachRequestNote";
import OutreachRequestSite from "./OutreachRequestSite";
import OutreachRequestStatus from "./OutreachRequestStatus";

export enum OutreachType {
  InPerson,
  Virtual,
  Either
}

export default class OutreachRequest extends BaseType {
  RegistrationSubmissionId: string | null = null;
  FirstTimeParticipating: boolean = false;
  OutreachType: OutreachType = OutreachType.Either;
  AvailableTimes: string = "";
  LogisticDetails: string = "";
  SiteSpecified: boolean = false;
  OrganizationId: string | null = null;
  NoOrgDetails: string = "";
  NoOrgPostalCode: string = "";
  ContactFirstName: string = "";
  ContactLastName: string = "";
  ContactEmail: string = "";
  ContactPhoneNumber: string = "";
  EventAddress: string = "";
  EventCity: string = "";
  EventProvince: string = "";
  EventPostalCode: string = "";
  LanguageId: string = "";
  Language: Enumeration | null = null;
  OtherLanguage: string = "";
  SpecificDemographics: boolean = false;
  YouthDemographicsId: string | null = null;
  YouthDemographics: YouthDemographics | null = null;
  Notes: OutreachRequestNote[] = [];
  Classes: OutreachRequestClass[] = [];
  StatusRecords: OutreachRequestStatus[] = [];
  SiteRecords: OutreachRequestSite[] = [];
  LatestStatus: OutreachRequestStatus | null = null;
  LatestSite: OutreachRequestSite | null = null;
}
