
import WorkshopFileUploadResponse from "@/types/Workshop/WorkshopFileUpload/WorskhopFileUploadResponse";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import Guid from "@/types/Guid";

@Component({
  components: {
    VMSTextField
  }
})
export default class WorkshopFileEducatorTable extends Vue {
  @Prop(Object) value: WorkshopFileUploadResponse | undefined;
  @Prop(Boolean) loading: boolean | undefined;
  @Prop(String) loadingMsg: string | undefined;

  response: WorkshopFileUploadResponse =
    this.value || new WorkshopFileUploadResponse();

  search: string = "";

  @Watch("value")
  onValueChanged() {
    this.response = this.value || new WorkshopFileUploadResponse();
  }

  headers: object[] = [
    {
      text: this.$root.$t("Educator"),
      align: "left",
      value: "Educator"
    },
    {
      text: this.$root.$t("Organization"),
      align: "left",
      value: "Organization"
    }
  ];

  get EducatorOrgs(): object[] {
    return this.response.Educators.map(e => {
      return {
        Educator: `${e.FirstName} ${e.LastName} - (${e.Email})`,
        Organization: e.Organization ? e.Organization.Name : "",
        EducatorAdd: e.Id === Guid.Empty,
        OrganizationAdd: e.OrganizationId === Guid.Empty
      };
    });
  }
}
