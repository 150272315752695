import BaseApiService from "./BaseApiService";
import axios from "axios";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import UseCaseResponse from "@/types/UseCaseResponse";
import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import PatchOrganizationRequest from "@/types/Organization/PatchOrganizationRequest";
import OrganizationFileUpdateHeader from "@/types/Organization/OrganizationFileUpdateHeader";
import { PagedQueryRequestFiltered } from "@/types/Pocos/Pagination/PagedQueryRequest";
import PagedUseCaseResponse from "@/types/PagedUseCaseResponse";
import QueryOrganizationsRequest from "@/types/Organization/QueryOrganizationsRequest";

export default class OrganizationApiService extends BaseApiService {
  cache: any = {};

  async GetOrganizations(
    query: string,
    type?: string,
    status?: number
  ): Promise<UseCaseResponse<GetOrganizationResponse[]>> {
    await this.defineHeader();

    let url: string = `${this.url}/api/Organization?query=${query}&includeUnverified=true`;
    if (type !== undefined) {
      url += `&type=${type}`;
    }

    if (status !== undefined) {
      url += `&status=${status}`;
    }

    return axios
      .get(url)
      .then(resp => resp.data as UseCaseResponse<GetOrganizationResponse[]>);
  }

  async QueryOrganizations(
    query: PagedQueryRequestFiltered<QueryOrganizationsRequest>
  ): Promise<PagedUseCaseResponse<GetOrganizationResponse>> {
    await this.defineHeader();

    let url: string = `${this.url}/api/Organization/Query`;

    return axios
      .post(url, query)
      .then(resp => resp.data as PagedUseCaseResponse<GetOrganizationResponse>);
  }

  async GetOrganizationById(
    id: string
  ): Promise<UseCaseResponse<GetOrganizationResponse>> {
    if (this.cache[id]) return this.cache[id];

    await this.defineHeader();
    return axios.get(`${this.url}/api/Organization/${id}`).then(resp => {
      this.cache[id] = resp.data;
      return resp.data as UseCaseResponse<GetOrganizationResponse>;
    });
  }

  async GetOrganizationsByMergedId(
    mergedId: string
  ): Promise<UseCaseResponse<GetOrganizationResponse[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/Organization?mergedId=${mergedId}`)
      .then(resp => {
        return resp.data as UseCaseResponse<GetOrganizationResponse[]>;
      });
  }

  async GetDuplicates(): Promise<UseCaseResponse<any[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/Organization/Duplicates`)
      .then(resp => resp.data);
  }

  async CreateOrganization(
    request: UpdateOrganizationRequest
  ): Promise<UseCaseResponse<string>> {
    request.CreationSource = "VMS";
    await this.defineHeader();
    return axios.post(`${this.url}/api/Organization`, request).then(resp => {
      return resp.data as UseCaseResponse<string>;
    });
  }

  async UpdateOrganization(
    request: UpdateOrganizationRequest
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Organization/${request.Id}`, request)
      .then(resp => {
        return resp.data as UseCaseResponse<boolean>;
      });
  }

  async GetEnumerationList(
    desc: string,
    locale: string = ""
  ): Promise<UseCaseResponse<GetOrgEnumResponse[]>> {
    await this.defineHeader();
    let url: string = `${this.url}/api/Organization/Enumeration/${desc}`;
    if (locale.length > 0) {
      url += `?locale=${locale}`;
    }

    return axios.get(url).then(resp => {
      return resp.data as UseCaseResponse<GetOrgEnumResponse[]>;
    });
  }

  async PatchOrganizations(
    patches: PatchOrganizationRequest[]
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/Organization`, patches)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async UploadOrgFile(file: File): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    let data: FormData = new FormData();
    data.append("orgfile", file);
    let config: object = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return axios
      .post(`${this.url}/api/vms/Organization/UpdateFile`, data, config)
      .then(resp => {
        return resp.data as UseCaseResponse<string>;
      });
  }

  async GetOrganizationFileUpdateHeader(
    headerId: string
  ): Promise<UseCaseResponse<OrganizationFileUpdateHeader>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Organization/UpdateFile/${headerId}`)
      .then(resp => {
        return resp.data as UseCaseResponse<OrganizationFileUpdateHeader>;
      });
  }

  async RunOrganizationFileUpdateHeader(
    headerId: string
  ): Promise<UseCaseResponse<OrganizationFileUpdateHeader>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Organization/UpdateFile/${headerId}`)
      .then(resp => {
        return resp.data as UseCaseResponse<OrganizationFileUpdateHeader>;
      });
  }

  async DeleteOrganizationFileUpdateHeader(
    headerId: string
  ): Promise<UseCaseResponse<OrganizationFileUpdateHeader>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Organization/UpdateFile/${headerId}`)
      .then(resp => {
        return resp.data as UseCaseResponse<OrganizationFileUpdateHeader>;
      });
  }
}
