
import Vue from "vue";
import Component from "vue-class-component";
import ScreeningDocumentApiService from "../../services/ScreeningDocumentApiService";
import ScreeningDocumentBatchCreate from "../../components/ScreeningDocuments/ScreeningDocumentBatchCreate.vue";
import DataObject from "../../types/DataObject";
import ScreeningDocument from "../../types/ScreeningDocument/ScreeningDocument";
import ScreeningDocumentTrackingItem from "../../types/ScreeningDocument/ScreeningDocumentTrackingItem";
import { Prop, Watch } from "vue-property-decorator";
import ScreeningDocumentEdit from "../../components/ScreeningDocuments/ScreeningDocumentEdit.vue";
import ScreeningDocumentTrackItemEdit from "../../components/ScreeningDocuments/ScreeningDocumentTrackItemEdit.vue";
import Subject from "@/types/Subject/Subject";
import OrganizationApiService from "@/services/OrganizationApiService";
import VMSTextField from "@/components/fields/VMSTextField.vue";

class TrackingItemWrapper {
  Item: ScreeningDocumentTrackingItem = new ScreeningDocumentTrackingItem();

  private nameLookup: Function | undefined;
  name: string = "";

  get OrgName(): string {
    if (!this.name && this.nameLookup) {
      this.nameLookup(this);
    }

    return this.name;
  }

  set OrgName(val: string) {
    this.name = val;
  }

  get SentDate(): string {
    return this.Item.SentDate ? this.Item.SentDate.substr(0, 10) : "";
  }

  constructor(
    trackItem: ScreeningDocumentTrackingItem,
    orgNameLookup: Function
  ) {
    this.Item = trackItem;
    this.nameLookup = orgNameLookup;
  }
}

class DocumentWrapper {
  Document: ScreeningDocument = new ScreeningDocument();
  TrackingItems: TrackingItemWrapper[] = [];
  SubjectName: string = "";
  Type: string = "";
  Status: string = "";

  approvedAtLoad: boolean = false;

  constructor(
    sd: ScreeningDocument,
    subjectName: string,
    typeName: string,
    status: string
  ) {
    this.Document = sd;
    this.SubjectName = subjectName;
    this.Type = typeName;
    this.Status = status;
    this.approvedAtLoad = this.Document.Approved;
  }

  update(sd: ScreeningDocument, t: string, s: string) {
    this.Document = sd;
    this.Type = t;
    this.Status = s;
    this.approvedAtLoad = this.Document.Approved;
  }

  get BatchId(): string {
    return this.Document.BatchId;
  }

  get Approved(): boolean {
    return this.Document.Approved;
  }

  get ApprovalDate(): string {
    return this.Document.ApprovalDate
      ? this.Document.ApprovalDate.substr(0, 10)
      : "";
  }

  get ExpiryDate(): string {
    return this.Document.ExpiryDate
      ? this.Document.ExpiryDate.substr(0, 10)
      : "";
  }

  get NationalOfficeFileDate(): string {
    return this.Document.NationalOfficeFileDate
      ? this.Document.NationalOfficeFileDate.substr(0, 10)
      : "";
  }

  get ApprovalChanged(): boolean {
    return this.Document.Approved !== this.approvedAtLoad;
  }
}

@Component({
  components: {
    ScreeningDocumentBatchCreate,
    ScreeningDocumentEdit,
    ScreeningDocumentTrackItemEdit,
    VMSTextField
  }
})
export default class ScreeningDocumentList extends Vue {
  @Prop(String) siteId: string | undefined;

  sdApiService: ScreeningDocumentApiService = new ScreeningDocumentApiService();
  sdTypes: DataObject = new DataObject();
  sdStatus: DataObject = new DataObject();
  docWrappers: DocumentWrapper[] = [];
  expandedDocs: DocumentWrapper[] = [];
  groupBy: string[] = ["BatchId"];
  searchText: string = "";
  documentsLoading: boolean = false;

  trackingItemDialog: boolean = false;
  trackingItem: ScreeningDocumentTrackingItem = new ScreeningDocumentTrackingItem();
  trackingItemBatchId: string = "";

  editDocument: ScreeningDocument = new ScreeningDocument();
  docDialog: boolean = false;
  batchAddScreeningDocumentDialog: boolean = false;

  orgApiService: OrganizationApiService = new OrganizationApiService();

  get viewBatches(): boolean {
    return this.groupBy.length > 0;
  }

  set viewBatches(vb: boolean) {
    this.groupBy = vb ? ["BatchId"] : [];
    this.headers.forEach((h: any) => {
      if (h.text) {
        h.sortable = this.groupBy.length === 0;
      }
    });
  }

  get documents(): ScreeningDocument[] {
    return this.docWrappers.map(x => {
      return x.Document;
    });
  }

  @Watch("expandedDocs")
  onDocsExpandedChanged() {
    let docId: string = "";
    if (this.expandedDocs.length > 0) {
      docId = this.expandedDocs[0].Document.Id;
    }

    if (
      docId &&
      this.expandedDocs[0].Document.ScreeningDocumentTrackingItems.length === 0
    ) {
      this.loadTrackingItemsForDoc(docId);
    }
  }

  headers: Object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "SubjectName",
      sortable: false
    },
    {
      text: this.$root.$t("Status"),
      align: "left",
      value: "Status",
      sortable: false
    },
    {
      text: this.$root.$t("Type"),
      align: "left",
      value: "Type",
      sortable: false
    },
    {
      text: this.$root.$t("Approved"),
      align: "left",
      value: "Approved",
      sortable: false
    },
    {
      text: this.$root.$t("ApprovalDate"),
      align: "left",
      value: "ApprovalDate",
      sortable: false
    },
    {
      text: this.$root.$t("FileDate"),
      align: "left",
      value: "NationalOfficeFileDate",
      sortable: false
    },
    {
      text: this.$root.$t("ExpiryDate"),
      align: "left",
      value: "ExpiryDate",
      sortable: false
    },
    { text: "", align: "right", value: "Actions", sortable: false }
  ];

  trackingItemHeaders: object[] = [
    {
      text: this.$root.$t("SchoolName"),
      align: "left",
      value: "OrgName"
    },
    { text: this.$root.$t("Sent"), align: "left", value: "SentDate" },
    {
      text: this.$root.$t("RestrictedActivities"),
      align: "left",
      value: "Item.RestrictedActivities"
    },
    {
      text: this.$root.$t("RestrictedComments"),
      align: "left",
      value: "Item.RestrictedActivityComments"
    },
    { text: "", align: "right", value: "Actions" }
  ];

  mounted() {
    this.documentsLoading = true;

    this.sdApiService
      .All([
        this.sdApiService.GetScreeningDocumentTypes(),
        this.sdApiService.GetScreeningDocumentStatus()
      ])
      .then(resps => {
        this.sdTypes = resps[0].Value;
        this.sdStatus = resps[1].Value;

        this.getData();
      });
  }

  getData() {
    if (this.siteId) {
      this.documentsLoading = true;
      this.sdApiService
        .GetScreeningDocumentsBySiteId(this.siteId)
        .then(resp => {
          this.docWrappers = [];
          let screeningDocs: ScreeningDocument[] = resp.Value;
          screeningDocs.forEach((sd: ScreeningDocument) => {
            let sub: Subject = sd.Subject || new Subject();
            let wrapper: DocumentWrapper = new DocumentWrapper(
              sd,
              sub.FullName,
              this.$t(sd.TypeString) as string,
              this.$t(sd.StatusString) as string
            );
            this.docWrappers.push(wrapper);
          });

          this.documentsLoading = false;
        });
    }
  }

  getBatchSummary(docs: DocumentWrapper[]): string {
    return `${docs.length} ${this.$t("Items")} - ${docs[0].Type} - ${
      docs[0].Status
    }`;
  }

  getBatchApprovalStatus(docs: DocumentWrapper[]) {
    if (docs.length === 0) return false;
    else return docs[0].Document.Approved;
  }

  toggleBatchApproval(docs: DocumentWrapper[]) {
    if (docs.length > 0) {
      const approveStatus: boolean = !docs[0].Document.Approved;
      docs.forEach(dw => {
        dw.Document.Approved = approveStatus;
      });
    }
  }

  loadTrackingItemsForDoc(docId: string) {
    this.sdApiService.GetScreeningDocumentById(docId).then(resp => {
      let doc: DocumentWrapper | undefined = this.docWrappers.find(
        x => x.Document.Id === docId
      );

      if (doc) {
        doc.Document.ScreeningDocumentTrackingItems =
          resp.Value.ScreeningDocumentTrackingItems;

        doc.TrackingItems = [];
        doc.Document.ScreeningDocumentTrackingItems.forEach(
          (sdti: ScreeningDocumentTrackingItem) => {
            if (doc) {
              doc.TrackingItems.push(
                new TrackingItemWrapper(sdti, this.setOrgName)
              );
            }
          }
        );
      }
    });
  }

  loadTrackingItemsForBatch(batchId: string) {
    this.sdApiService.GetScreeningDocumentsByBatchId(batchId).then(resp => {
      const docs: ScreeningDocument[] = resp.Value;

      docs.forEach((sd: ScreeningDocument) => {
        let docWrapper: DocumentWrapper | undefined = this.docWrappers.find(
          x => x.Document.Id === sd.Id
        );

        if (docWrapper) {
          docWrapper.Document.ScreeningDocumentTrackingItems =
            sd.ScreeningDocumentTrackingItems;
          docWrapper.TrackingItems = [];
          sd.ScreeningDocumentTrackingItems.forEach(
            (sdti: ScreeningDocumentTrackingItem) => {
              if (docWrapper) {
                docWrapper.TrackingItems.push(
                  new TrackingItemWrapper(sdti, this.setOrgName)
                );
              }
            }
          );
        }
      });
    });
  }

  setOrgName(trackItemWrapper: TrackingItemWrapper) {
    this.orgApiService
      .GetOrganizationById(trackItemWrapper.Item.OrganizationId)
      .then(resp => {
        if (resp && resp.Success) {
          trackItemWrapper.OrgName = resp.Value.Name;
        }
      });
  }

  onBatchAddTrackingItemClick(docs: DocumentWrapper[]) {
    if (docs.length > 0) {
      this.trackingItemBatchId = docs[0].Document.BatchId;
      this.trackingItem = new ScreeningDocumentTrackingItem();
      this.trackingItemDialog = true;
    }
  }

  onAddTrackingItemClick(doc: ScreeningDocument) {
    this.trackingItemBatchId = "";
    this.trackingItem = new ScreeningDocumentTrackingItem();
    this.trackingItem.ScreeningDocumentId = doc.Id;
    this.trackingItemDialog = true;
  }

  onBatchDeleteClick(docs: DocumentWrapper[]) {
    if (docs.length > 0) {
      const batchId: string = docs[0].Document.BatchId;
      this.sdApiService.DeleteScreeningDocumentBatch(batchId).then(resp => {
        if (resp.Success) {
          this.getData();
        }
      });
    }
  }

  onDeleteScreeningDocumentClick(doc: ScreeningDocument) {
    this.sdApiService.DeleteScreeningDocument(doc.Id).then(resp => {
      this.getData();
    });
  }

  onDeleteTrackingItemClick(item: ScreeningDocumentTrackingItem) {
    this.sdApiService.DeleteTrackingItem(item.Id).then(resp => {
      if (resp.Success) {
        this.loadTrackingItemsForDoc(item.ScreeningDocumentId);
      }
    });
  }

  onEditScreeningDocumentClick(doc: ScreeningDocument) {
    this.editDocument = JSON.parse(JSON.stringify(doc));
    this.docDialog = true;
  }

  onEditTrackingItemClick(item: ScreeningDocumentTrackingItem) {
    this.trackingItemBatchId = "";
    this.trackingItem = item;
    this.trackingItemDialog = true;
  }

  onScreeningDocumentSave() {
    const docId: string = this.editDocument.Id;
    this.sdApiService.UpdateScreeningDocument(this.editDocument).then(uResp => {
      if (uResp.Success) {
        this.sdApiService.GetScreeningDocumentById(docId).then(resp => {
          let dw: DocumentWrapper | undefined = this.docWrappers.find(
            x => x.Document.Id === docId
          );
          if (dw) {
            const doc: ScreeningDocument = resp.Value;
            dw.update(
              doc,
              this.$t(this.sdStatus[doc.Status]) as string,
              this.$t(this.sdTypes[doc.Type]) as string
            );
          }
        });
      }
    });

    this.docDialog = false;
  }

  onTrackItemCanceled() {
    this.trackingItemBatchId = "";
    this.trackingItem = new ScreeningDocumentTrackingItem();
    this.trackingItemDialog = false;
  }

  onTrackItemSaved(item: ScreeningDocumentTrackingItem) {
    if (this.trackingItemBatchId) {
      this.loadTrackingItemsForBatch(this.trackingItemBatchId);
    } else {
      this.loadTrackingItemsForDoc(item.ScreeningDocumentId);
    }
    this.trackingItemDialog = false;
  }

  onSaveApprovalChangesClick() {
    const changedWrappers: DocumentWrapper[] = this.docWrappers.filter(
      x => x.ApprovalChanged
    );

    const approvalChanges: ScreeningDocument[] = changedWrappers.map(x => {
      return x.Document;
    });

    if (approvalChanges.length > 0) {
      this.sdApiService
        .UpdateScreeningDocumentApprovals(approvalChanges)
        .then(resp => {
          if (resp.Success) {
            this.getData();
          }
        });
    }
  }

  onScreeningDocBatchSaved() {
    this.getData();
    this.batchAddScreeningDocumentDialog = false;
  }
}
