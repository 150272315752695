var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("SiteRegistration")))]),_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t("PleaseSelectSite"))+" ")]),_c('v-form',{ref:"registrationForm"},[_c('VMSSelect',{attrs:{"items":_vm.sites,"item-text":"Name","return-object":"","rules":[
          () => _vm.selectedSite.Id !== _vm.EmptyGuid || `${_vm.$t('FieldIsRequired')}`
        ]},on:{"input":_vm.onSelectedSiteInput},model:{value:(_vm.selectedSite),callback:function ($$v) {_vm.selectedSite=$$v},expression:"selectedSite"}}),(_vm.registration.SiteSpecificResponse)?[(_vm.RegistrationConfig.VehicleAccess)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('AccessToVehicleLongLabel'),"list":_vm.vehicleAccessEnumList,"rules":[
            () =>
              (_vm.fieldRequired('VehicleAccess')
                ? _vm.validString(_vm.Response.VehicleAccessId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.VehicleAccessId),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "VehicleAccessId", $$v)},expression:"registration.SiteSpecificResponse.VehicleAccessId"}}):_vm._e(),(_vm.RegistrationConfig.CanDriveRental)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('WillingToDriveRentalVehicleLongLabel'),"list":_vm.driveRentalEnumList,"rules":[
            () =>
              (_vm.fieldRequired('CanDriveRental')
                ? _vm.validString(_vm.Response.CanDriveRentalId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.CanDriveRentalId),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "CanDriveRentalId", $$v)},expression:"registration.SiteSpecificResponse.CanDriveRentalId"}}):_vm._e(),(_vm.RegistrationConfig.Campus)?_c('EnumerationResponse',{attrs:{"label":_vm.$t('OnWhichCampusLongLabel'),"list":_vm.campusEnumList,"multiple":true,"rules":[
            () =>
              (_vm.fieldRequired('Campus')
                ? _vm.validHeader(_vm.Response.CampusHeader)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.CampusHeader),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "CampusHeader", $$v)},expression:"registration.SiteSpecificResponse.CampusHeader"}}):_vm._e(),(_vm.RegistrationConfig.DietaryRestrictions)?_c('VMSTextField',{attrs:{"label":_vm.$t('DietaryRestrictionsLongLabel'),"rules":[
            () =>
              (_vm.fieldRequired('DietaryRestrictions')
                ? _vm.validString(_vm.Response.DietaryRestrictions)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.DietaryRestrictions),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "DietaryRestrictions", $$v)},expression:"registration.SiteSpecificResponse.DietaryRestrictions"}}):_vm._e(),(_vm.RegistrationConfig.PreferredSchoolEducator)?_c('VMSTextField',{attrs:{"label":_vm.$t('PreferredSchoolEducatorLongLabel'),"rules":[
            () =>
              (_vm.fieldRequired('PreferredSchoolEducator')
                ? _vm.validString(_vm.Response.PreferredSchoolEducator)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.PreferredSchoolEducator),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "PreferredSchoolEducator", $$v)},expression:"registration.SiteSpecificResponse.PreferredSchoolEducator"}}):_vm._e(),(_vm.RegistrationConfig.LocalSchools)?[_c('a',{staticStyle:{"margin-top":"10px"},attrs:{"target":"__blank","href":"https://docs.google.com/document/d/1J-ws-Mheyx8HWynEvb57EOw3zbKpMrAxj4erU0TVJ1Y/view"}},[_vm._v(" "+_vm._s(_vm.$t("ViewMaps"))+" ")]),_c('VMSTextField',{attrs:{"label":_vm.$t('LocalSchoolsLongLabel'),"rules":[
              () =>
                (_vm.fieldRequired('LocalSchools')
                  ? _vm.validString(_vm.Response.LocalSchools)
                  : true) || `${_vm.$t('FieldIsRequired')}`
            ]},model:{value:(_vm.registration.SiteSpecificResponse.LocalSchools),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "LocalSchools", $$v)},expression:"registration.SiteSpecificResponse.LocalSchools"}})]:_vm._e(),(_vm.RegistrationConfig.PreferredVolunteers)?_c('VMSTextField',{attrs:{"label":_vm.$t('PreferredVolunteersLongLabel'),"rules":[
            () =>
              (_vm.fieldRequired('PreferredVolunteers')
                ? _vm.validString(_vm.Response.PreferredVolunteers)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.PreferredVolunteers),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "PreferredVolunteers", $$v)},expression:"registration.SiteSpecificResponse.PreferredVolunteers"}}):_vm._e(),(_vm.RegistrationConfig.AgeGroups)?_c('EnumerationResponse',{attrs:{"label":_vm.$t('AgeGroupsLongLabel'),"list":_vm.ageGroupsEnumList,"multiple":true,"rules":[
            () =>
              (_vm.fieldRequired('AgeGroups')
                ? _vm.validHeader(_vm.Response.AgeGroupsHeader)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.AgeGroupsHeader),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "AgeGroupsHeader", $$v)},expression:"registration.SiteSpecificResponse.AgeGroupsHeader"}}):_vm._e(),(_vm.RegistrationConfig.HighSchoolSubjects)?_c('EnumerationResponse',{attrs:{"label":_vm.$t('HighSchoolSubjectsLongLabel'),"list":_vm.hsSubjectsEnumList,"multiple":true,"rules":[
            () =>
              (_vm.fieldRequired('HighSchoolSubjects')
                ? _vm.validHeader(_vm.Response.HighSchoolSubjectsEnumHeader)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(
            _vm.registration.SiteSpecificResponse.HighSchoolSubjectsEnumHeader
          ),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "HighSchoolSubjectsEnumHeader", $$v)},expression:"\n            registration.SiteSpecificResponse.HighSchoolSubjectsEnumHeader\n          "}}):_vm._e(),(_vm.RegistrationConfig.Location)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('LocationLongLabel'),"list":_vm.locationEnumList,"rules":[
            () =>
              (_vm.fieldRequired('Location')
                ? _vm.validString(_vm.Response.LocationId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.LocationId),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "LocationId", $$v)},expression:"registration.SiteSpecificResponse.LocationId"}}):_vm._e(),(_vm.RegistrationConfig.IndigenousProgramsInterest)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('IndigenousProgramsInterestLongLabel'),"list":_vm.indigenousInterestEnumList,"rules":[
            () =>
              (_vm.fieldRequired('IndigenousProgramsInterest')
                ? _vm.validString(_vm.Response.IndigenousProgramsInterestId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(
            _vm.registration.SiteSpecificResponse.IndigenousProgramsInterestId
          ),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "IndigenousProgramsInterestId", $$v)},expression:"\n            registration.SiteSpecificResponse.IndigenousProgramsInterestId\n          "}}):_vm._e(),(_vm.RegistrationConfig.WhyVolunteer)?_c('VMSTextField',{attrs:{"label":_vm.$t('WhyVolunteerLongLabel'),"rules":[
            () =>
              (_vm.fieldRequired('WhyVolunteer')
                ? _vm.validString(_vm.Response.WhyVolunteer)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.WhyVolunteer),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "WhyVolunteer", $$v)},expression:"registration.SiteSpecificResponse.WhyVolunteer"}}):_vm._e(),(_vm.RegistrationConfig.AvailableTimes)?_c('VMSTextField',{attrs:{"label":_vm.$t('AvailableTimesLongLabel'),"hint":_vm.$t('AvailableTimesHint'),"rules":[
            () =>
              (_vm.fieldRequired('AvailableTimes')
                ? _vm.validString(_vm.Response.AvailableTimes)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.AvailableTimes),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "AvailableTimes", $$v)},expression:"registration.SiteSpecificResponse.AvailableTimes"}}):_vm._e(),(_vm.RegistrationConfig.PreferredActivities)?_c('EnumerationResponse',{attrs:{"label":_vm.$t('PreferredActivitiesLongLabel'),"list":_vm.preferredActivitiesEnumList,"multiple":true,"rules":[
            () =>
              (_vm.fieldRequired('PreferredActivities')
                ? _vm.validHeader(_vm.Response.PreferredActivitiesEnumHeader)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(
            _vm.registration.SiteSpecificResponse.PreferredActivitiesEnumHeader
          ),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "PreferredActivitiesEnumHeader", $$v)},expression:"\n            registration.SiteSpecificResponse.PreferredActivitiesEnumHeader\n          "}}):_vm._e(),(_vm.RegistrationConfig.SpecialInitiatives)?_c('EnumerationResponse',{attrs:{"label":_vm.$t('SpecialInitiativesLongLabel'),"list":_vm.specialInitiativesEnumList,"multiple":true,"rules":[
            () =>
              (_vm.fieldRequired('SpecialInitiatives')
                ? _vm.validHeader(_vm.Response.SpecialInitiativesEnumHeader)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(
            _vm.registration.SiteSpecificResponse.SpecialInitiativesEnumHeader
          ),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "SpecialInitiativesEnumHeader", $$v)},expression:"\n            registration.SiteSpecificResponse.SpecialInitiativesEnumHeader\n          "}}):_vm._e(),(_vm.RegistrationConfig.StudentNumber)?_c('VMSTextField',{attrs:{"label":_vm.$t('StudentNumberLongLabel'),"rules":[
            () =>
              (_vm.fieldRequired('StudentNumber')
                ? _vm.validString(_vm.Response.StudentNumber)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.StudentNumber),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "StudentNumber", $$v)},expression:"registration.SiteSpecificResponse.StudentNumber"}}):_vm._e(),(_vm.RegistrationConfig.VolunteerTrainingCompleted)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('VolunteerTrainingCompletedLongLabel'),"list":_vm.volunteerTrainingCompletedEnumList,"rules":[
            () =>
              (_vm.fieldRequired('VolunteerTrainingCompleted')
                ? _vm.validString(_vm.Response.VolunteerTrainingCompletedId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(
            _vm.registration.SiteSpecificResponse.VolunteerTrainingCompletedId
          ),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "VolunteerTrainingCompletedId", $$v)},expression:"\n            registration.SiteSpecificResponse.VolunteerTrainingCompletedId\n          "}}):_vm._e(),(_vm.RegistrationConfig.GraduateWorkLocation)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('GraduateWorkLocationLongLabel'),"list":_vm.graduateLocationsEnumList,"rules":[
            () =>
              (_vm.fieldRequired('GraduateWorkLocation')
                ? _vm.validString(_vm.Response.GraduateWorkLocationId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.GraduateWorkLocationId),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "GraduateWorkLocationId", $$v)},expression:"registration.SiteSpecificResponse.GraduateWorkLocationId"}}):_vm._e(),(_vm.RegistrationConfig.ShirtSize)?_c('VMSTextField',{attrs:{"label":_vm.$t('ShirtSizeLongLabel'),"rules":[
            () =>
              (_vm.fieldRequired('ShirtSize')
                ? _vm.validString(_vm.Response.ShirtSize)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(_vm.registration.SiteSpecificResponse.ShirtSize),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "ShirtSize", $$v)},expression:"registration.SiteSpecificResponse.ShirtSize"}}):_vm._e(),(_vm.RegistrationConfig.VolunteerAgreementAccepted)?_c('EnumerationSelect',{attrs:{"label":_vm.$t('VolunteerAgreementAcceptedLongLabel'),"list":_vm.volunteerAgreementAcceptedEnumList,"rules":[
            () =>
              (_vm.fieldRequired('VolunteerAgreementAccepted')
                ? _vm.validString(_vm.Response.VolunteerAgreementAcceptedId)
                : true) || `${_vm.$t('FieldIsRequired')}`
          ]},model:{value:(
            _vm.registration.SiteSpecificResponse.VolunteerAgreementAcceptedId
          ),callback:function ($$v) {_vm.$set(_vm.registration.SiteSpecificResponse, "VolunteerAgreementAcceptedId", $$v)},expression:"\n            registration.SiteSpecificResponse.VolunteerAgreementAcceptedId\n          "}}):_vm._e()]:_vm._e()],2),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.onRegisterClick}},[_vm._v(_vm._s(_vm.$t("EnrolNow")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }