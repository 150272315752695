import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";
import Guid from "../Guid";

export default class VolunteerProfileStudent extends BaseType {
  VolunteerProfileId: string = Guid.Empty;
  PostSecondaryInstitutionId: string = "";
  LevelOfStudyId: string = Guid.Empty;
  LevelOfStudy: Enumeration | undefined;
  Faculty: string = "";
  Department: string = "";
  ModifiedOn: string = "";
}
