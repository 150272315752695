import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";
import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import Guid from "../Guid";
import Subject from "../Subject/Subject";
import VolunteerProfileNonStudent from "./VolunteerProfileNonStudent";
import VolunteerProfileStudent from "./VolunteerProfileStudent";

export default class VolunteerProfile extends BaseType {
  SubjectId: string = "";
  Subject: Subject | undefined;
  InterestedOpportunitiesId: string = Guid.Empty;
  InterestedOpportunities: EnumerationResponseHeader = new EnumerationResponseHeader();
  LanguagePreferenceId: string = Guid.Empty;
  LanguagePreference: Enumeration | undefined;
  DeliveryLanguagesId: string = Guid.Empty;
  DeliveryLanguages: EnumerationResponseHeader = new EnumerationResponseHeader();
  PronounPreferenceId: string = Guid.Empty;
  PronounPreference: Enumeration | undefined;
  CustomPronoun: string = "";
  GenderIdentityId: string = Guid.Empty;
  GenderIdentity: Enumeration | undefined;
  CustomGenderIdentity: string = "";
  ProvinceId: string = Guid.Empty;
  Province: Enumeration | undefined;
  City: string = "";
  AreasOfStudyOrExpertiseId: string = Guid.Empty;
  AreasOfStudyOrExpertise: EnumerationResponseHeader = new EnumerationResponseHeader();
  HeardAboutLTS: string = "";
  PRCConsentId: string = Guid.Empty;
  PRCConsent: Enumeration | undefined;
  WorkWithKidsExperience: string = "";
  EthnicDescriptorsId: string = Guid.Empty;
  EthnicDescriptors: EnumerationResponseHeader = new EnumerationResponseHeader();
  EighteenOrOverId: string = Guid.Empty;
  EighteenOrOver: Enumeration | undefined;
  MediaConsentId: string = Guid.Empty;
  MediaConsent: Enumeration | undefined;
  QuotesAndTestimonialConsentId: string = Guid.Empty;
  QuotesAndTestimonialConsent: Enumeration | undefined;
  StudentProfile: VolunteerProfileStudent | undefined;
  NonStudentProfile: VolunteerProfileNonStudent | undefined;
  AcceptVolunteerPolicies: boolean = false;
  AcceptAccessibilityPolicies: boolean = false;
  AcceptHarassmentPolicies: boolean = false;
  AcceptConfidentialityPolicies: boolean = false;
  AcceptCovidBestPracticesAgreement: boolean = false;
  CovidVaccinesReceived: boolean | undefined;
  PhoneNumber: string = "";
  ModifiedOn: string = "";
  Hobbies: string = "";
}
