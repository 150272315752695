
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AuthorizationService from "../../services/AuthorizationService";
import SubjectApiService from "../../services/SubjectApiService";
import SiteVolunteerManagement from "../../views/volunteers/SiteVolunteerManagement.vue";
import ScreeningDocuments from "../../views/screeningdocuments/ScreeningDocumentList.vue";
import GoogleDriveEmbeded from "../../views/GoogleDriveEmbed.vue";
import SiteApiService from "../../services/SiteApiService";
import Site from "../../types/Site/Site";
import AddressListLists from "../../views/emails/AddressListList.vue";
import AddressListCreate from "../../views/emails/AddressListCreate.vue";
import EducatorList from "../../views/educators/EducatorList.vue";
import EducatorCreate from "../../views/educators/EducatorCreate.vue";
import WorkshopList from "@/views/workshops/WorkshopList.vue";
import WorkshopCreate from "../../views/workshops/WorkshopCreate.vue";
import SiteRegistration from "../../types/SiteRegistration/SiteRegistration";
import WorkshopApiService from "@/services/WorkshopApiService";
import WorkshopType from "@/types/Workshop/WorkshopType";
import TrainingType from "@/types/Training/TrainingType";
import TrainingApiService from "@/services/TrainingApiService";
import TrainingList from "@/views/training/TrainingList.vue";
import TrainingCreate from "@/views/training/TrainingCreate.vue";
import Subject from "@/types/Subject/Subject";
import Reports from "@/views/Reports.vue";
import ProgramSupportList from "@/views/programsupport/ProgramSupportList.vue";
import ProgramSupportCreate from "@/views/programsupport/ProgramSupportCreate.vue";
import OutreachRequestList from "@/views/outreachrequests/OutreachRequestList.vue";
import OutreachRequestCreate from "@/views/outreachrequests/OutreachRequestCreate.vue";
import OutreachRequestHistory from "@/views/outreachrequests/OutreachRequestHistory.vue";
import KitList from "@/views/kits/KitList.vue";

class SiteData {
  Id: string = "";
  Name: string = "";
  UrlPart: string = "";
}

@Component
export default class VmsDrawer extends Vue {
  @Prop(Boolean) active!: boolean | undefined;

  drawer: boolean = this.active === undefined ? true : this.active;

  name: String = "VmsDrawer";
  siteApiService: SiteApiService = new SiteApiService();
  authorizationService: AuthorizationService = AuthorizationService.getInstance();
  subjectApiService: SubjectApiService = new SubjectApiService();
  workshopApiService: WorkshopApiService = new WorkshopApiService();
  workshopTypes: WorkshopType[] = [];

  trainingTypes: TrainingType[] = [];
  trainingApiService: TrainingApiService = new TrainingApiService();
  subject: Subject = new Subject();

  right: boolean = false;

  menuItems: any = {
    top: [],
    activities: [],
    training: [],
    coordinator: [],
    coordinatorQuickReferences: [],
    bottom: [],
    programSupport: []
  };

  selectedSite: SiteData = new SiteData();
  enroledSites: Site[] = [];
  allSites: Site[] = [];

  get ElevatedUser(): boolean {
    return this.authorizationService.hasRole([
      "Administrator",
      "Staff",
      "Coordinator"
    ]);
  }

  get OrgManager(): boolean {
    return this.authorizationService.hasRole([
      "Administrator",
      "Organization Manager"
    ]);
  }

  get SiteDataOptions(): SiteData[] {
    return this.enroledSites.map(s => {
      return {
        Id: s.Id,
        Name: s.Name,
        UrlPart: this.getSiteUrlPart(s)
      };
    });
  }

  get siteHasHandbookURL(): boolean {
    const site: Site =
      this.enroledSites.find(s => s.Id === this.selectedSite.Id) || new Site();

    if (this.$i18n.locale === "fr") {
      return (
        (site.FrenchHandbookURL ? true : false) &&
        site.FrenchHandbookURL.length > 0
      );
    } else {
      return (
        (site.FrenchHandbookURL ? true : false) &&
        site.EnglishHandbookURL.length > 0
      );
    }
  }

  @Watch("active")
  onActiveChanged() {
    this.drawer = this.active === undefined ? true : this.active;
  }

  @Watch("selectedSite")
  onChangedSite(newValue: SiteData, oldValue: SiteData) {
    this.buildMenu();

    const curSiteUrl: string = oldValue.UrlPart;
    let path: string = this.$router.currentRoute.path;

    if (
      path.includes(curSiteUrl) &&
      newValue.Id !== oldValue.Id &&
      oldValue.Id !== "" &&
      newValue.Id !== ""
    ) {
      path = path.replace(curSiteUrl, newValue.UrlPart);
      this.$router.push(path);
    }

    this.$emit("update:selectedSite", this.selectedSite);
  }

  created() {
    this.$router.afterEach(this.matchSelectedSiteToUrl);
    this.load();
  }

  matchSelectedSiteToUrl() {
    if (this.$router.currentRoute.matched.length < 1) return;

    const matched = this.$router.currentRoute.matched[0] as any;

    let site_id: string = "";
    if (
      matched.props &&
      matched.props.default &&
      matched.props.default.siteId
    ) {
      site_id = matched.props.default.siteId;
    }

    const sd = this.SiteDataOptions.find(s => s.Id === site_id);
    if (sd && sd.Id !== this.selectedSite.Id) {
      this.selectedSite = sd;
    }
  }

  onDrawerInput() {
    this.$emit("update:active", this.drawer);
  }

  load() {
    if (this.ElevatedUser) {
      this.trainingApiService
        .GetTrainingTypes()
        .then(typeResp => {
          let tTypes: TrainingType[] = typeResp.Value;
          this.trainingTypes = tTypes.sort((a, b) => {
            if (a.SortOrder > b.SortOrder) return 1;
            else if (a.SortOrder < b.SortOrder) return -1;
            else return 0;
          });
          this.getData();
        })
        .catch(ex => {
          this.getData();
        });
    } else {
      this.getData();
    }
  }

  getData() {
    this.subjectApiService
      .All([
        this.subjectApiService.GetSubjectById(this.$VmsLoggedInSubjectId),
        this.workshopApiService.GetWorkshopTypes(),
        this.siteApiService.GetSites()
      ])
      .then(responses => {
        this.subject = responses[0].Value;

        let wsTypes: WorkshopType[] = responses[1].Value;
        this.workshopTypes = wsTypes.sort((a, b) => {
          if (a.SortOrder > b.SortOrder) return 1;
          else if (a.SortOrder < b.SortOrder) return -1;
          else return 0;
        });

        this.allSites = responses[2].Value;

        let registeredSites: string[] = [];
        this.subject.SiteRegistrations.forEach((sr: SiteRegistration) => {
          if (sr.Season && sr.Season.Active && sr.IsApproved)
            registeredSites.push(sr.SiteId);
        });

        this.allSites.forEach((s: Site) => {
          if (this.authorizationService.hasRole(["Administrator", "Staff"])) {
            this.enroledSites.push(s);
          } else if (registeredSites.includes(s.Id)) {
            this.enroledSites.push(s);
          }
        });

        if (this.SiteDataOptions.length > 0) {
          this.selectedSite = this.SiteDataOptions[0];
        }

        this.buildMenu();
        this.buildSiteRoutes();
      });
  }

  getSiteUrlPart(s: Site): string {
    return encodeURIComponent(s.Name.replace(/\s/g, ""));
  }

  buildSiteRoutes() {
    this.enroledSites = this.enroledSites.sort((a: Site, b: Site) => {
      return a.Name.localeCompare(b.Name, "en", {
        sensitivity: "base",
        ignorePunctuation: true
      });
    });

    let routes: any[] = [];

    this.enroledSites.forEach((s: Site) => {
      let urlSite = this.getSiteUrlPart(s);

      routes.push({
        path: `/${urlSite}/Volunteers`,
        component: SiteVolunteerManagement,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/VolunteerScreening`,
        component: ScreeningDocuments,
        props: { siteId: s.Id }
      });

      routes.push({
        path: `/${urlSite}/Resources`,
        component: GoogleDriveEmbeded,
        props: { siteId: s.Id }
      });

      routes.push({
        path: `/${urlSite}/reports`,
        component: Reports,
        props: { siteId: s.Id }
      });

      routes.push({
        path: `/${urlSite}/Kits`,
        component: KitList,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/AddressLists`,
        component: AddressListLists,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/AddressList/Create`,
        component: AddressListCreate,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/AddressList/Update`,
        component: AddressListCreate,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/Workshops`,
        component: WorkshopList,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/Educators`,
        component: EducatorList,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/Educator/Create`,
        component: EducatorCreate,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/Educator/Update`,
        component: EducatorCreate,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      this.workshopTypes.forEach((wt: WorkshopType) => {
        routes.push({
          path: `/${urlSite}/${wt.Name}/Create`,
          component: WorkshopCreate,
          props: { siteId: s.Id, urlSite: urlSite, type: wt.Name }
        });

        routes.push({
          path: `/${urlSite}/${wt.Name}/Update`,
          component: WorkshopCreate,
          props: { siteId: s.Id, urlSite: urlSite, type: wt.Name }
        });
      });

      routes.push({
        path: `/${urlSite}/Training`,
        component: TrainingList,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      this.trainingTypes.forEach((tt: TrainingType) => {
        routes.push({
          path: `/${urlSite}/${tt.Name}/Create`,
          component: TrainingCreate,
          props: { siteId: s.Id, urlSite: urlSite, type: tt.Name }
        });

        routes.push({
          path: `/${urlSite}/${tt.Name}/Update`,
          component: TrainingCreate,
          props: { siteId: s.Id, urlSite: urlSite, type: tt.Name }
        });
      });

      routes.push({
        path: `/${urlSite}/ProgramSupport`,
        component: ProgramSupportList,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/ProgramSupport/Create`,
        component: ProgramSupportCreate,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/ProgramSupport/Update`,
        component: ProgramSupportCreate,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/OutreachRequests`,
        component: OutreachRequestList,
        props: { siteId: s.Id, urlSite: urlSite }
      });

      routes.push({
        path: `/${urlSite}/OutreachRequest/Create`,
        component: OutreachRequestCreate,
        props: { siteId: s.Id, urlSite: urlSite },
        meta: {
          back: {
            to_route: `/${urlSite}/OutreachRequests`,
            localization_key: "OutreachRequests"
          }
        }
      });

      routes.push({
        path: `/${urlSite}/OutreachRequest/Update`,
        component: OutreachRequestCreate,
        props: { siteId: s.Id, urlSite: urlSite },
        meta: {
          back: {
            to_route: `/${urlSite}/OutreachRequests`,
            localization_key: "OutreachRequests"
          }
        }
      });

      routes.push({
        path: `/${urlSite}/OutreachRequest/:requestId/History`,
        component: OutreachRequestHistory,
        props: { siteId: s.Id, urlSite: urlSite },
        meta: {
          back: {
            to_route: `/${urlSite}/OutreachRequests`,
            localization_key: "OutreachRequests"
          }
        }
      });
    });

    this.$router.addRoutes(routes);
  }

  buildMenu() {
    if (!this.selectedSite) return;
    let siteName = this.selectedSite.UrlPart;

    const site: Site =
      this.enroledSites.find(s => s.Id === this.selectedSite.Id) || new Site();
    //add site specific handbook links to i18n
    this.$i18n.mergeLocaleMessage("en", {
      SiteSpecificHandbookURL: site.EnglishHandbookURL
    });
    this.$i18n.mergeLocaleMessage("fr", {
      SiteSpecificHandbookURL: site.FrenchHandbookURL
    });

    this.menuItems.top = [];
    this.menuItems.main = [];
    this.menuItems.activities = [];
    this.menuItems.training = [];
    this.menuItems.coordinator = [];
    this.menuItems.coordinatorQuickReferences = [];
    this.menuItems.bottom = [];
    this.menuItems.programSupport = [];

    this.menuItems.top.push({
      title: "menu_home",
      link: `/`,
      icon: "mdi-home"
    });

    let registerTitle: string =
      this.enroledSites.length > 0 ? "menu_register_again" : "menu_register";

    this.menuItems.top.push({
      title: registerTitle,
      link: `/siteenrolment`,
      icon: "mdi-plus"
    });

    this.menuItems.main.push({
      title: "menu_resources",
      link: `/${siteName}/Resources`,
      icon: "mdi-book-open-variant"
    });
    this.menuItems.main.push({
      title: "menu_handsOnActivities",
      linkext: "NavHandsOnActivitiesUrl",
      icon: "mdi-open-in-new",
      target: "_blank"
    });
    this.menuItems.main.push({
      title: "menu_suggestHandsOnCctivity",
      linkext: "NavSubmitHandsOnActivitiesUrl",
      icon: "mdi-open-in-new",
      target: "_blank"
    });
    this.menuItems.main.push({
      title: "menu_pdAndTraining",
      linkext: "url_lms",
      icon: "mdi-open-in-new",
      target: "_blank"
    });

    this.menuItems.programSupport.push({
      title: "menu_programSupportList",
      link: `/${siteName}/ProgramSupport`,
      icon: ""
    });

    this.menuItems.programSupport.push({
      title: "menu_programSupportCreate",
      link: `/${siteName}/ProgramSupport/Create`,
      icon: ""
    });

    this.menuItems.activities.push({
      title: "menu_viewActivities",
      link: `/${siteName}/Workshops`,
      icon: ""
    });

    this.workshopTypes.forEach((wt: WorkshopType) => {
      this.menuItems.activities.push({
        title: `menu_${wt.Name}`,
        link: `/${siteName}/${wt.Name}/Create`
      });
    });

    if (this.ElevatedUser) {
      this.menuItems.training.push({
        title: "ViewTrainingLogs",
        link: `/${siteName}/Training`,
        icon: ""
      });

      this.trainingTypes.forEach((tt: TrainingType) => {
        if (
          !tt.StaffOnly ||
          this.authorizationService.isAdmin() ||
          this.authorizationService.hasRole(["Staff"])
        ) {
          this.menuItems.training.push({
            title: `log_${tt.Name}`,
            link: `/${siteName}/${tt.Name}/Create`
          });
        }
      });

      if (this.ElevatedUser) {
        this.menuItems.coordinator.push({
          title: "menu_outreach",
          link: `/${siteName}/OutreachRequests`,
          icon: ""
        });
      }

      this.menuItems.coordinator.push({
        title: "menu_volunteerManagement",
        link: `/${siteName}/Volunteers`,
        icon: ""
      });

      this.menuItems.coordinator.push({
        title: "menu_volunteerScreening",
        link: `/${siteName}/VolunteerScreening`,
        icon: ""
      });

      this.menuItems.coordinator.push({
        title: "menu_kitList",
        link: `/${siteName}/Kits`,
        icon: ""
      });

      this.menuItems.coordinator.push({
        title: "menu_educatorManagement",
        link: `/${siteName}/Educators`,
        icon: ""
      });

      this.menuItems.coordinator.push({
        title: "menu_reports",
        link: `/${siteName}/Reports`,
        icon: ""
      });

      this.menuItems.coordinator.push({
        title: "menu_emailSettings",
        link: `/${siteName}/AddressLists`,
        icon: ""
      });

      this.menuItems.coordinator.push({
        title: "menu_coordinatorPdAndTraining",
        linkext: "url_coordinatorLms",
        icon: "mdi-open-in-new",
        target: "_blank"
      });

      // Quick References
      this.menuItems.coordinatorQuickReferences.push({
        title: "menu_workplansAndBugdets",
        link: `/static/workplans`,
        icon: ""
      });
      this.menuItems.coordinatorQuickReferences.push({
        title: "menu_ExpenseManagement",
        link: `/static/expensemanagement`,
        icon: ""
      });
      this.menuItems.coordinatorQuickReferences.push({
        title: "menu_commAndPromo",
        link: `/static/comms`,
        icon: ""
      });
      this.menuItems.coordinatorQuickReferences.push({
        title: "menu_surveysAndEvaluation",
        link: `/static/surveys`,
        icon: ""
      });
      this.menuItems.coordinatorQuickReferences.push({
        title: "menu_policyAndInsurance",
        link: `/static/policies`,
        icon: ""
      });
    }

    this.menuItems.bottom.push({
      title: "menu_contactStaff",
      link: `/static/contact`,
      icon: "mdi-clipboard-account-outline",
      requiresApproval: false
    });

    this.menuItems.bottom.push({
      title: "menu_bugReport",
      linkext: "url_bugreport",
      icon: "mdi-open-in-new",
      target: "_blank",
      requiresApproval: true
    });
  }

  lang(locale: string) {
    this.$i18n.locale = locale;
  }
}
