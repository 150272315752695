
import OrganizationApiService from "../../services/OrganizationApiService";
import SchoolDemographicResponseHeader from "@/types/SchoolDemographic/SchoolDemographicResponseHeader";
import SchoolDemographicResponseItem from "@/types/SchoolDemographic/SchoolDemographicResponseItem";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SchoolLookup from "../school/SchoolLookup.vue";
import ConfirmButton from "../ConfirmButton.vue";
import Guid from "@/types/Guid";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationSelect from "../EnumerationSelect.vue";
import EnumerationResponse from "../EnumerationResponse.vue";
import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import SchoolEdit from "./SchoolEdit.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    SchoolLookup,
    ConfirmButton,
    EnumerationSelect,
    EnumerationResponse,
    PhoneNumberInput,
    SchoolEdit,
    VMSTextField
  }
})
export default class SchoolDemoLog extends Vue {
  @Prop(Object) value: SchoolDemographicResponseHeader | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) hiddenfields: string[] | undefined;
  @Prop(Number) maxschools: number | undefined;

  @Watch("hiddenfields")
  onHiddenFieldsChanged() {
    this.hideFields = this.hideFields || [];
  }

  @Watch("maxschools")
  onMaxSchoolsChanged() {
    this.maxSchoolsCount = this.maxschools || 0;
  }

  organizationApiService: OrganizationApiService = new OrganizationApiService();
  schoolDemoDialog: boolean = false;
  header: SchoolDemographicResponseHeader =
    this.value || new SchoolDemographicResponseHeader();
  item: SchoolDemographicResponseItem = new SchoolDemographicResponseItem();
  itemBackup: SchoolDemographicResponseItem = new SchoolDemographicResponseItem();
  edit: boolean = false;
  addSchoolDemo: boolean = false;
  enumerationApiService: EnumerationApiService = new EnumerationApiService();
  genders: EnumerationList = new EnumerationList();
  fnmiTypes: EnumerationList = new EnumerationList();

  hideFields: string[] = this.hiddenfields || [];
  maxSchoolsCount: number = this.maxschools || 0;

  addOrg: boolean = false;
  editOrg: UpdateOrganizationRequest = new UpdateOrganizationRequest();
  tableRenderKey: number = 0;
  orgGenderEnumerations: GetOrgEnumResponse[] = [];

  headers: object[] = [
    {
      text: this.$root.$t("SchoolName"),
      value: "Organization.Name",
      align: "left"
    },
    {
      text: "",
      value: "Actions",
      align: "right"
    }
  ];

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  get Items(): SchoolDemographicResponseItem[] {
    return this.header.Items.filter(x => !x.Deleted);
  }

  fieldHidden(field: string): boolean {
    return this.hideFields.includes(field);
  }

  newEditOrg(): UpdateOrganizationRequest {
    let ret: UpdateOrganizationRequest = new UpdateOrganizationRequest();
    ret.Id = Guid.Empty;
    return ret;
  }

  mounted() {
    this.enumerationApiService
      .GetEnumerationListsBySystemName(["Genders", "IndigenousTypes"])
      .then(resp => {
        let lists: EnumerationList[] = resp.Value;
        this.genders =
          lists.find(x => x.SystemName === "Genders") || new EnumerationList();
        this.fnmiTypes =
          lists.find(x => x.SystemName === "IndigenousTypes") ||
          new EnumerationList();
      });

    this.organizationApiService
      .GetEnumerationList("SchoolClassType", this.$i18n.locale)
      .then(r => {
        this.orgGenderEnumerations = r.Value;
      });
  }

  @Watch("value")
  onValueChanged() {
    this.header = this.value || new SchoolDemographicResponseHeader();
  }

  onSchoolItemSelected(org: GetOrganizationResponse) {
    this.item.Organization = org as UpdateOrganizationRequest;
  }

  setAddSchoolDemo(add: boolean) {
    this.addSchoolDemo = add;
  }

  onAddRecordClicked() {
    this.edit = false;
    this.addOrg = false;
    this.item = new SchoolDemographicResponseItem();
    this.editOrg = this.newEditOrg();
    this.editOrg.Id = Guid.Empty;
    this.schoolDemoDialog = true;
  }

  onSaveItemClicked(addAnother: boolean) {
    let demoValid: boolean = this.$refs.schoolDemoForm
      ? (this.$refs.schoolDemoForm as any).validate()
      : true;
    let addOrgValid: boolean = this.$refs.addOrgForm
      ? (this.$refs.addOrgForm as any).validate()
      : true;

    if (demoValid && addOrgValid) {
      if (this.addOrg && this.editOrg) {
        this.item.Organization = this.editOrg;
        this.editOrg = this.newEditOrg();
        this.addOrg = false;
      }

      if (!this.edit) {
        this.header.Items.push(this.item);
      } else {
        this.itemBackup.SchoolDemographicResponseHeaderId = this.item.SchoolDemographicResponseHeaderId;
        this.itemBackup.OrganizationId = this.item.OrganizationId;
        this.itemBackup.NumberOfYouth = this.item.NumberOfYouth;
        this.itemBackup.Organization = this.item.Organization;
      }

      this.$emit("input", this.header);

      this.item = new SchoolDemographicResponseItem();

      if (!addAnother) {
        this.schoolDemoDialog = false;
      } else {
        this.$ShowSnackbar(this.$t("ItemAdded") as string);
      }
    }
  }

  onEditClicked(item: SchoolDemographicResponseItem) {
    this.edit = true;
    this.itemBackup = item;
    this.item = JSON.parse(JSON.stringify(item));

    if (
      (this.item.OrganizationId === "" ||
        this.item.OrganizationId === Guid.Empty) &&
      this.item.Organization
    ) {
      this.addOrg = true;
      this.editOrg = this.item.Organization;
    } else {
      this.addOrg = false;
      this.editOrg = this.newEditOrg();
    }

    this.schoolDemoDialog = true;
  }

  onDeleteConfirmed(item: SchoolDemographicResponseItem) {
    if (item.Id === Guid.Empty) {
      this.header.Items.splice(this.header.Items.indexOf(item), 1);
    } else {
      item.Deleted = true;
    }

    this.$emit("input", this.header);
  }

  onAddOrgChange() {
    this.editOrg = this.newEditOrg();
  }

  onCancelClick() {
    this.schoolDemoDialog = false;
  }
}
