var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.AddressListCreateEditHeader))]),_c('div',[_c('v-form',{ref:"form"},[_c('v-input',{attrs:{"rules":[
          () =>
            _vm.selectedEmailWrapper.length > 0 ||
            `${_vm.$t('Email')} ${_vm.$t('IsRequired')}`
        ]}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"items":_vm.emailWrappers,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 50, -1] },"single-select":true,"show-select":"","item-key":"Email.Id"},scopedSlots:_vm._u([{key:`item.Body`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.Body.substr(0, 50))+"... ")]}}],null,true),model:{value:(_vm.selectedEmailWrapper),callback:function ($$v) {_vm.selectedEmailWrapper=$$v},expression:"selectedEmailWrapper"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('VMSTextField',{attrs:{"label":_vm.$t('Address')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onAddAddress.apply(null, arguments)}},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","dense":""},on:{"click":_vm.onAddAddress}},[_vm._v(_vm._s(_vm.$t("Add")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","dense":""},on:{"click":_vm.onSearchClick}},[_vm._v(_vm._s(_vm.$t("Search")))])],1),_c('v-input',{attrs:{"rules":[
          () => _vm.RecipientObjects.length > 0 || `${_vm.$t('RecipientsRequired')}!`
        ]}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"items":_vm.RecipientObjects,"headers":_vm.recipientHeaders},scopedSlots:_vm._u([{key:`item.Actions`,fn:function({ item }){return [_c('td',[_c('ConfirmButton',{attrs:{"icon":"mdi-delete","tooltip":_vm.$t('Delete')},on:{"confirmed":function($event){return _vm.onDeleteAddressConfirmed(item.value)}}},[_vm._v(_vm._s(_vm.$t("Delete"))+" "+_vm._s(item.value)+"?")])],1)]}}],null,true)})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t("Save")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.onCancelClick}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1)],1),_c('v-dialog',{model:{value:(_vm.searchDialog),callback:function ($$v) {_vm.searchDialog=$$v},expression:"searchDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("User Search")]),_c('v-card-text',[_c('VMSTextField',{attrs:{"label":_vm.$t('Search')},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.subjectsLoading,"headers":_vm.subjectHeaders,"items":_vm.subjects,"search":_vm.searchString,"item-key":"Id","show-select":"","multi-select":""},model:{value:(_vm.selectedSubjects),callback:function ($$v) {_vm.selectedSubjects=$$v},expression:"selectedSubjects"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onAddSubjectEmailsClick}},[_vm._v(_vm._s(_vm.$t("Add")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.onCancelSubjectSearchClick}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }