var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-simple-table',{staticClass:"mt-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("RegistrationInformation"))+" ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("Approved")))]),_c('td',[(_vm.registration.IsApproved)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("ApprovalDate")))]),_c('td',[(_vm.registration.ApprovalDate)?[_vm._v(" "+_vm._s(new Date(_vm.registration.ApprovalDate).toLocaleDateString())+" ")]:_vm._e()],2)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("Season")))]),(_vm.registration.Season)?_c('td',[_vm._v(" "+_vm._s(new Date(_vm.registration.Season.StartDate).toLocaleDateString())+" : "+_vm._s(new Date(_vm.registration.Season.EndDate).toLocaleDateString())+" ")]):_vm._e()]),(_vm.registration.SiteSpecificResponse)?[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("VehicleAccess"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.localizeEnum(_vm.registration.SiteSpecificResponse.VehicleAccess))+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("CanDriveRental"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.localizeEnum(_vm.registration.SiteSpecificResponse.CanDriveRental))+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("Campus"))+" ")]),_c('td',[(_vm.registration.SiteSpecificResponse.CampusHeader)?_vm._l((_vm.registration.SiteSpecificResponse
                  .CampusHeader.Items),function(e,idx){return _c('v-chip',{key:idx,staticClass:"ma-1"},[_vm._v(" "+_vm._s(_vm.localizeEnum(e.Enumeration))+" ")])}):_vm._e()],2)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("DietaryRestrictions"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.registration.SiteSpecificResponse.DietaryRestrictions)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("PreferredSchoolEducator"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.registration.SiteSpecificResponse.PreferredSchoolEducator)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("LocalSchools"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.registration.SiteSpecificResponse.LocalSchools)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("PreferredVolunteers"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.registration.SiteSpecificResponse.PreferredVolunteers)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("AgeGroups"))+" ")]),_c('td',[(_vm.registration.SiteSpecificResponse.AgeGroupsHeader)?_vm._l((_vm.registration.SiteSpecificResponse
                  .AgeGroupsHeader.Items),function(e,idx){return _c('v-chip',{key:idx,staticClass:"ma-1"},[_vm._v(" "+_vm._s(_vm.localizeEnum(e.Enumeration))+" ")])}):_vm._e()],2)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("HighSchoolSubjects"))+" ")]),_c('td',[(
                _vm.registration.SiteSpecificResponse.HighSchoolSubjectsEnumHeader
              )?_vm._l((_vm.registration.SiteSpecificResponse
                  .HighSchoolSubjectsEnumHeader.Items),function(e,idx){return _c('v-chip',{key:idx,staticClass:"ma-1"},[_vm._v(" "+_vm._s(_vm.localizeEnum(e.Enumeration))+" ")])}):_vm._e()],2)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("Location"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.localizeEnum(_vm.registration.SiteSpecificResponse.Location))+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("IndigenousProgramsInterest"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.localizeEnum( _vm.registration.SiteSpecificResponse.IndigenousProgramsInterest ))+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("WhyVolunteer"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.registration.SiteSpecificResponse.WhyVolunteer)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("AvailableTimes"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.registration.SiteSpecificResponse.AvailableTimes)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("PreferredActivities"))+" ")]),_c('td',[(
                _vm.registration.SiteSpecificResponse
                  .PreferredActivitiesEnumHeader
              )?_vm._l((_vm.registration.SiteSpecificResponse
                  .PreferredActivitiesEnumHeader.Items),function(e,idx){return _c('v-chip',{key:idx,staticClass:"ma-1"},[_vm._v(" "+_vm._s(_vm.localizeEnum(e.Enumeration))+" ")])}):_vm._e()],2)])]:_vm._e()],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }