import Enumeration from "@/types/Enumeration/Enumeration";
import BaseType from "../BaseType";
import Guid from "../Guid";

export enum SiteRequestStatus {
  Open,
  Waitlisted,
  Closed
}

export default class SiteOutreachRequestStatus extends BaseType {
  SiteId: string = Guid.Empty;
  ReasonId: string | null = null;
  Reason: Enumeration | null = null;
  Status: SiteRequestStatus = SiteRequestStatus.Open;
  Comment: string = "";
  CreatedOn: string = "";
}
