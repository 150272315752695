import EducatorLogHeader from "../Educator/EducatorLogHeader";
import Enumeration from "../Enumeration/Enumeration";
import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import Guid from "../Guid";
import SiteResponseHeader from "../Site/SiteResponseHeader";
import VolunteerLogHeader from "../Volunteers/VolunteerLogHeader";
import SchoolDemographicResponseHeader from "../SchoolDemographic/SchoolDemographicResponseHeader";
import WorkshopKit from "./WorkshopKit";
import WorkshopType from "./WorkshopType";
import Season from "../Season";
import BaseType from "../BaseType";
import YouthDemographics from "../YouthDemographics/YouthDemographics";

export default class Workshop extends BaseType {
  CreatedBySiteId: string = "";
  ParentId: string = "";
  Parent: Workshop | null = null;
  ChildWorkshops: Workshop[] = [];
  SeasonId: string = Guid.Empty;
  Season: Season | undefined;
  Name: string = "";
  Date: string = "";
  Time: string = "";
  LocationTypeId: string = "";
  LocationType: Enumeration | undefined;
  VolunteerLocationId: string = "";
  VolunteerLocation: Enumeration | undefined;
  Description: string = "";
  Partnership: string = "";
  NumberOfYouth: number | undefined;
  NumberOfYouthAtEvent: number | undefined;
  RepeatVisit: boolean | undefined;
  NumberOfAdults: number | undefined;
  Keywords: string = "";
  FacultyOrCampusStaff: string = "";
  MediaInAttendance: string = "";
  OutsideFundingReceived: string = "";
  SuccessesSuggestionsIssues: string = "";
  PhotoPermissionIdMethod: string = "";
  IsComplete: boolean | null = null;
  VolunteerLogHeaderId: string = "";
  VolunteerLogHeader: VolunteerLogHeader | undefined;
  EducatorLogHeaderId: string = "";
  EducatorLogHeader: EducatorLogHeader | undefined;
  WorkshopKits: WorkshopKit[] = [];
  SubjectAreasHeaderId: string = "";
  SubjectAreasHeader: EnumerationResponseHeader | undefined;
  LanguagesHeaderId: string = "";
  LanguagesHeader: EnumerationResponseHeader | undefined;
  GradesHeaderId: string = "";
  GradesHeader: EnumerationResponseHeader | undefined;
  WorkshopTypeId: string = "";
  WorkshopType: WorkshopType | undefined;
  SiteResponseHeaderId: string = "";
  SiteResponseHeader: SiteResponseHeader | undefined;
  SchoolDemographicResponseHeaderId: string = "";
  SchoolDemographicResponseHeader: SchoolDemographicResponseHeader | undefined;
  YouthDemographicsId: string = "";
  YouthDemographics: YouthDemographics | undefined;
  SpecialInitiativesHeaderId: string = "";
  SpecialInitiativesHeader: EnumerationResponseHeader | undefined;
  ZoomId: string = "";
}
