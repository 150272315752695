
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import Guid from "../../types/Guid";
import ProgramSupportApiService from "../../services/ProgramSupportApiService";
import ProgramSupportKit from "../../types/ProgramSupport/ProgramSupportKit";
import ConfirmButton from "../ConfirmButton.vue";
import KitItemSelect from "../kit/KitItemSelect.vue";

@Component({
  components: {
    ConfirmButton,
    KitItemSelect
  }
})
export default class ProgramSupportKitList extends Vue {
  @Prop(String) label: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(String) siteId: String | undefined;
  @Prop(Array) value: ProgramSupportKit[] | undefined;

  //@ts-ignore
  pSupportKits: ProgramSupportKit[] = this.value || [];
  kit: ProgramSupportKit = new ProgramSupportKit();
  addDialog: boolean = false;
  programSupportApiService: ProgramSupportApiService = new ProgramSupportApiService();

  @Watch("value")
  onValueChanged() {
    this.pSupportKits = this.value || [];
  }

  get Items(): ProgramSupportKit[] {
    return this.pSupportKits.filter(x => !x.Deleted);
  }

  getKitName(kit: ProgramSupportKit): string {
    let ret: string = "";
    if (kit.Kit) {
      ret =
        this.$i18n.locale === "fr" ? kit.Kit.FrenchName : kit.Kit.EnglishName;
    }

    return ret;
  }

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      value: "Name",
      align: "left"
    },
    {
      text: "",
      value: "Actions",
      align: "right"
    }
  ];

  onAddClick() {
    this.kit = new ProgramSupportKit();
    this.addDialog = true;
  }

  onDeleteConfirmed(item: ProgramSupportKit) {
    if (item.Id === Guid.Empty) {
      this.pSupportKits.splice(this.pSupportKits.indexOf(item), 1);
    } else {
      item.Deleted = true;
    }

    this.$emit("input", this.pSupportKits);
  }

  onCancelClick() {
    this.addDialog = false;
  }

  onSaveItemClick() {
    this.pSupportKits.push(this.kit);
    this.addDialog = false;
    this.$emit("input", this.pSupportKits);
  }
}
