
import OrganizationApiService from "@/services/OrganizationApiService";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import PatchOrganizationRequest from "@/types/Organization/PatchOrganizationRequest";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    VMSTextField
  }
})
export default class OrganizationMerge extends Vue {
  @Prop(Array) value!: GetOrganizationResponse[] | undefined;
  orgApiService: OrganizationApiService = new OrganizationApiService();
  mergeItems: GetOrganizationResponse[] = this.value || [];
  searchText: string = "";
  orgs: GetOrganizationResponse[] = [];
  loading: boolean = false;
  selectedOrgs: GetOrganizationResponse[] = [];
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;
  step: number = 1;

  get selectedOrg(): GetOrganizationResponse {
    return this.selectedOrgs.length > 0
      ? this.selectedOrgs[0]
      : new GetOrganizationResponse();
  }

  headers: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      sortable: false,
      value: "Name"
    },
    {
      text: this.$root.$t("Address"),
      align: "left",
      sortable: false,
      value: "Address"
    },
    {
      text: this.$root.$t("City"),
      align: "left",
      sortable: false,
      value: "City"
    },
    {
      text: this.$root.$t("Status"),
      align: "left",
      sortable: false,
      value: "Status"
    }
  ];

  @Watch("value")
  onValueChanged() {
    this.mergeItems = this.value || [];
  }

  searchOrgs() {
    this.loading = true;
    this.orgApiService
      .GetOrganizations(this.searchText)
      .then(resp => {
        if (resp && resp.Success) {
          this.orgs = resp.Value;
        } else {
          this.orgs = [];
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getStatus(status: number): string {
    let ret = "";
    switch (status) {
      case 0:
        ret = "New";
        break;
      case 1:
        ret = "Verified";
        break;
      case 2:
        ret = "Merged";
        break;
    }
    return ret;
  }

  onKeyUp() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.searchText.length > 0) {
      this.timeout = setTimeout(this.searchOrgs, this.typeTimer);
    } else {
      this.orgs = [];
    }
  }

  onCancelClicked() {
    this.$emit("canceled");
    this.Cleanup();
  }

  onMergeClicked() {
    let patches: PatchOrganizationRequest[] = [];

    this.mergeItems.forEach((mi: GetOrganizationResponse) => {
      let patch: PatchOrganizationRequest = new PatchOrganizationRequest();
      patch.Id = mi.Id;
      patch.MergeId = this.selectedOrg.Id;
      patches.push(patch);
    });

    this.orgApiService
      .PatchOrganizations(patches)
      .then(resp => {
        if (resp && resp.Success) {
          this.$emit("saved");
          this.Cleanup();
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  Cleanup() {
    this.searchText = "";
    this.orgs = [];
    this.selectedOrgs = [];
    this.mergeItems = [];
    this.step = 1;
  }
}
