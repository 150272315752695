
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SiteApiService from "../services/SiteApiService";
import Site from "../types/Site/Site";
import AuthorizationService from "../services/AuthorizationService";

@Component
export default class GoogleDriveEmbed extends Vue {
  @Prop(String) siteId: string | undefined;

  authorizationService: AuthorizationService = AuthorizationService.getInstance();
  siteApiService: SiteApiService = new SiteApiService();
  site: Site = new Site();

  tab: any = null;
  items: any[] = [];

  getSiteId(): string {
    return this.siteId || "";
  }

  get ElevatedUser(): boolean {
    return this.authorizationService.hasRole([
      "Administrator",
      "Staff",
      "Coordinator"
    ]);
  }

  mounted() {
    this.items.push({
      tab: this.$root.$t("NationalOffice"),
      driveUrl:
        "https://script.google.com/macros/s/AKfycbxxIt7EP4pNS5pKu5ZnTJ-IUSnvuayNh270CGb-LqHKtb8gk4E/exec?folderid=0AKazyyQ0Rs94Uk9PVA&teamdrive=teamdrive&hideowners=hide&orderby=title_natural&filesize=show&desc=show"
    });

    if (this.ElevatedUser) {
      this.items.push({
        tab: this.$root.$t("Coordinators"),
        driveUrl:
          "https://script.google.com/macros/s/AKfycbxxIt7EP4pNS5pKu5ZnTJ-IUSnvuayNh270CGb-LqHKtb8gk4E/exec?folderid=0AG_5w88g6ExdUk9PVA&teamdrive=teamdrive&hideowners=hide&orderby=title_natural&filesize=show&desc=show"
      });
    }

    this.siteApiService.GetSiteById(this.getSiteId()).then(resp => {
      this.site = resp.Value;
      if (this.site.DriveURL.length > 0) {
        this.items.push({ tab: this.site.Name, driveUrl: this.site.DriveURL });
      }
    });
  }
}
