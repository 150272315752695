import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";

export enum LogLevel {
  Critical,
  Debug,
  Error,
  Information,
  None,
  Trace,
  Warning
}

class CreateLogRequest {
  Source: string = "VMS_UI";
  LogLevel: string = "None";
  Event: object | null = null;
  Message: string = "";
}

export default class LoggingApiService extends BaseApiService {
  async CreateLog(
    level: LogLevel,
    msg: string,
    event: object | null = null
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();

    const log_req = new CreateLogRequest();
    log_req.LogLevel = LogLevel[level];
    log_req.Message = msg;
    log_req.Event = event;

    return axios
      .post(`${this.url}/api/Logging/Log`, log_req)
      .then(resp => resp.data as UseCaseResponse<string>);
  }
}
