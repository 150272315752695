import BaseType from "../BaseType";
import Site from "../Site/Site";
import Email from "./Email";

export default class EmailAddressList extends BaseType {
  EmailId: string = "";
  Email: Email | null = null;
  SiteId: string = "";
  Site: Site | null = null;
  EmailAddresses: string[] = [];
}
