import BaseApiService from "./BaseApiService";
import HistoricHours from "@/types/Historic/HistoricHours";
import UseCaseResponse from "@/types/UseCaseResponse";
import axios from "axios";

export default class HistoricHoursApiService extends BaseApiService {
  async SearchHistoricHours(
    email: string
  ): Promise<UseCaseResponse<HistoricHours[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/HistoricHours?email=${email}`)
      .then(resp => resp.data as UseCaseResponse<HistoricHours[]>);
  }

  async PatchHistoricHours(
    hours: HistoricHours[]
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/HistoricHours`, hours)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
