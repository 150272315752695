import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import axios from "axios";
import PagedUseCaseResponse from "@/types/PagedUseCaseResponse";
import QueryEducatorsRequest from "@/types/Educator/QueryEducatorsRequest";

export default class EducatorAuxDataApiService extends BaseApiService {
  async CreateEducatorAuxData(
    data: EducatorAuxData
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/EducatorAuxData`, data)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async QueryEducatorAuxData(
    query: QueryEducatorsRequest
  ): Promise<PagedUseCaseResponse<EducatorAuxData>> {
    await this.defineHeader();

    return axios
      .post(`${this.url}/api/vms/EducatorAuxData/Query`, query)
      .then(resp => resp.data as PagedUseCaseResponse<EducatorAuxData>);
  }

  async GetEducatorAuxDataById(
    id: string
  ): Promise<UseCaseResponse<EducatorAuxData>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/EducatorAuxData/${id}`)
      .then(resp => resp.data as UseCaseResponse<EducatorAuxData>);
  }

  async UpdateEducatorAuxData(
    data: EducatorAuxData
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/EducatorAuxData/${data.Id}`, data)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async PatchEducatorAuxData(
    patches: EducatorAuxData[]
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/EducatorAuxData`, patches)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
