
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Guid from "../types/Guid";
import SubjectApiService from "../services/SubjectApiService";
import SiteApiService from "../services/SiteApiService";
import Site from "../types/Site/Site";
import SeasonApiService from "../services/SeasonApiService";
import Season from "../types/Season";
import Subject from "../types/Subject/Subject";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import VolunteerProfile from "@/types/Profile/VolunteerProfile";
import SiteRegistrationConfig from "@/types/SiteRegistration/SiteRegistrationConfig";
import SiteRegistrationApiService from "@/services/SiteRegistrationApiService";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationResponse from "@/components/EnumerationResponse.vue";
import SiteSpecificResponse from "@/types/SiteRegistration/SiteSpecificResponse";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import EnumerationResponseHeader from "@/types/Enumeration/EnumerationResponseHeader";

@Component({
  components: {
    EnumerationSelect,
    EnumerationResponse,
    VMSTextField,
    VMSSelect
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    const subjectApiService: SubjectApiService = new SubjectApiService();
    const seasonApiService: SeasonApiService = new SeasonApiService();

    subjectApiService
      .All([
        subjectApiService.GetSubjectById(Guid.Empty),
        seasonApiService.GetSeasons()
      ])
      .then(resps => {
        const subject: Subject = resps[0].Value;
        const seasons: Season[] = resps[1].Value;
        const activeSeason: Season =
          seasons.find(x => x.Active === true) || new Season();
        const activeSeasonStart = activeSeason.Active
          ? new Date(activeSeason.StartDate)
          : new Date();

        const profile: VolunteerProfile =
          subject.VolunteerProfile || new VolunteerProfile();
        const profileModified: Date =
          profile.Id !== Guid.Empty ? new Date(profile.ModifiedOn) : new Date();
        if (
          subject.VolunteerProfile === null ||
          profileModified <= activeSeasonStart
        ) {
          next((vm: any) => {
            vm.$router.push(`/volunteer/profile?subjectId=${subject.Id}`);
          });
        } else next();
      })
      .catch(ex => {
        console.log(`beforeRoute error: ${ex}`);
      });
  }
})
export default class SiteEnrolment extends Vue {
  siteApiService: SiteApiService = new SiteApiService();
  subjectApiService: SubjectApiService = new SubjectApiService();
  seasonApiService: SeasonApiService = new SeasonApiService();
  sites: Site[] = [];
  selectedSite: Site = new Site();
  registration: SiteRegistration = new SiteRegistration();
  registrationApiService: SiteRegistrationApiService = new SiteRegistrationApiService();
  saving: boolean = false;

  campusEnumList: EnumerationList = new EnumerationList();
  hsSubjectsEnumList: EnumerationList = new EnumerationList();
  ageGroupsEnumList: EnumerationList = new EnumerationList();
  enumApiService: EnumerationApiService = new EnumerationApiService();
  vehicleAccessEnumList: EnumerationList = new EnumerationList();
  driveRentalEnumList: EnumerationList = new EnumerationList();
  locationEnumList: EnumerationList = new EnumerationList();
  indigenousInterestEnumList: EnumerationList = new EnumerationList();
  preferredActivitiesEnumList: EnumerationList = new EnumerationList();
  specialInitiativesEnumList: EnumerationList = new EnumerationList();
  graduateLocationsEnumList: EnumerationList = new EnumerationList();
  volunteerTrainingCompletedEnumList: EnumerationList = new EnumerationList();
  volunteerAgreementAcceptedEnumList: EnumerationList = new EnumerationList();

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  fieldRequired(field: string): boolean {
    return this.RegistrationConfig.RequiredFields.includes(field);
  }

  validString(str: string | undefined | null): boolean {
    return str !== undefined && str !== null && str.length > 0;
  }

  validHeader(header: EnumerationResponseHeader | undefined): boolean {
    return header !== undefined && header.Items.length > 0;
  }

  onSelectedSiteInput() {
    this.registration.Site = this.selectedSite;
    this.registration.SiteId = this.registration.Site.Id;

    if (this.selectedSite.RegistrationConfig) {
      this.registration.SiteSpecificResponse = new SiteSpecificResponse();
      this.setEnumLists();
    } else {
      this.registration.SiteSpecificResponse = undefined;
    }
  }

  get RegistrationConfig(): SiteRegistrationConfig {
    return this.selectedSite.RegistrationConfig
      ? (JSON.parse(
          this.selectedSite.RegistrationConfig
        ) as SiteRegistrationConfig)
      : new SiteRegistrationConfig();
  }

  get Response(): SiteSpecificResponse {
    return this.registration.SiteSpecificResponse || new SiteSpecificResponse();
  }

  mounted() {
    this.subjectApiService
      .All([
        this.subjectApiService.GetSubjectById(this.$VmsLoggedInSubjectId),
        this.seasonApiService.GetSeasons(),
        this.siteApiService.GetSites()
      ])
      .then(r => {
        let registered: string[] = [];
        const subject = r[0].Value;

        const seasons: Season[] = r[1].Value;
        const activeSeason: Season =
          seasons.find(s => s.Active === true) || new Season();

        this.registration.SubjectId = this.$VmsLoggedInSubjectId;

        subject.SiteRegistrations.filter(
          (sr: SiteRegistration) => sr.SeasonId === activeSeason.Id
        ).forEach((r: SiteRegistration) => {
          registered.push(r.SiteId);
        });

        let allSites: Site[] = r[2].Value;
        this.sites = allSites
          .filter(s => !registered.includes(s.Id))
          .filter(s => s.LatestSiteStatus && s.LatestSiteStatus.IsActive);
      });
  }

  setEnumLists() {
    if (this.selectedSite && this.selectedSite.RegistrationConfig) {
      let cfg: SiteRegistrationConfig = JSON.parse(
        this.selectedSite.RegistrationConfig
      ) as SiteRegistrationConfig;

      if (cfg) {
        if (cfg.Campus && cfg.CampusEnumListId) {
          this.enumApiService
            .GetEnumerationList(cfg.CampusEnumListId)
            .then(resp => {
              this.campusEnumList = resp.Value;
            });
        }

        if (cfg.HighSchoolSubjects && cfg.HighSchoolSubjectsEnumListId) {
          this.enumApiService
            .GetEnumerationList(cfg.HighSchoolSubjectsEnumListId)
            .then(resp => {
              this.hsSubjectsEnumList = resp.Value;
            });
        }

        if (cfg.AgeGroups && cfg.AgeGroupsEnumListId) {
          this.enumApiService
            .GetEnumerationList(cfg.AgeGroupsEnumListId)
            .then(resp => {
              this.ageGroupsEnumList = resp.Value;
            });
        }

        if (cfg.VehicleAccess && cfg.VehicleAccessEnumListId) {
          this.enumApiService
            .GetEnumerationList(cfg.VehicleAccessEnumListId)
            .then(resp => {
              this.vehicleAccessEnumList = resp.Value;
            });
        }

        if (cfg.CanDriveRental && cfg.CanDriveRentalEnumListId) {
          this.enumApiService
            .GetEnumerationList(cfg.CanDriveRentalEnumListId)
            .then(resp => {
              this.driveRentalEnumList = resp.Value;
            });
        }

        if (cfg.Location && cfg.LocationEnumListId) {
          this.enumApiService
            .GetEnumerationList(cfg.LocationEnumListId)
            .then(resp => {
              this.locationEnumList = resp.Value;
            });
        }

        if (
          cfg.IndigenousProgramsInterest &&
          cfg.IndigenousInterestEnumListId
        ) {
          this.enumApiService
            .GetEnumerationList(cfg.IndigenousInterestEnumListId)
            .then(resp => {
              this.indigenousInterestEnumList = resp.Value;
            });
        }

        if (cfg.PreferredActivities && cfg.PreferredActivitiesListId) {
          this.enumApiService
            .GetEnumerationList(cfg.PreferredActivitiesListId)
            .then(resp => {
              this.preferredActivitiesEnumList = resp.Value;
            });
        }

        if (cfg.SpecialInitiatives && cfg.SpecialInitiativesListId) {
          this.enumApiService
            .GetEnumerationList(cfg.SpecialInitiativesListId)
            .then(resp => {
              this.specialInitiativesEnumList = resp.Value;
            });
        }

        if (cfg.GraduateWorkLocation && cfg.GraduateWorkLocationListId) {
          this.enumApiService
            .GetEnumerationList(cfg.GraduateWorkLocationListId)
            .then(resp => {
              this.graduateLocationsEnumList = resp.Value;
            });
        }

        if (
          cfg.VolunteerTrainingCompleted &&
          cfg.VolunteerTrainingCompletedListId
        ) {
          this.enumApiService
            .GetEnumerationList(cfg.VolunteerTrainingCompletedListId)
            .then(resp => {
              this.volunteerTrainingCompletedEnumList = resp.Value;
            });
        }

        if (
          cfg.VolunteerAgreementAccepted &&
          cfg.VolunteerAgreementAcceptedListId
        ) {
          this.enumApiService
            .GetEnumerationList(cfg.VolunteerAgreementAcceptedListId)
            .then(resp => {
              this.volunteerAgreementAcceptedEnumList = resp.Value;
            });
        }
      }
    }
  }

  onRegisterClick() {
    if ((this.$refs.registrationForm as any).validate()) {
      this.saving = true;
      this.registrationApiService
        .CreateSiteRegistration(this.registration)
        .then(resp => {
          if (resp.Success) {
            let id: string = resp.Value;
            this.registrationApiService
              .GetSiteRegistrationById(id)
              .then(resp => {
                if (resp.Success && resp.Value.IsApproved) {
                  //this is a returning volunteer, who was auto approved
                  this.$router.push("/"); //home
                  this.$ReloadDrawer();
                } else {
                  this.$router.push(`/enrolmentsuccess`);
                }
              })
              .finally(() => {
                this.saving = false;
              });
          }
        })
        .catch(ex => {
          this.saving = false;
          console.log(`an error occurred saving registration:`);
          console.log(ex);
        });
    }
  }
}
