var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("Subject"))+" "+_vm._s(_vm.$t("Awards")))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("SubjectName"))+": "+_vm._s(_vm.subject.GivenName + " " + _vm.subject.FamilyName)+" ")]),_c('v-form',{ref:"AwardForm"},[_c('VMSSelect',{attrs:{"items":_vm.awardWrappers,"item-text":"Text","item-value":"Award","label":_vm.$t('Award'),"rules":[
        () =>
          _vm.subjectAward.Award.Id !== _vm.EmptyGuid ||
          `${_vm.$t('Award')} ${_vm.$t('IsRequired')}!`
      ]},on:{"input":_vm.onAwardInput},model:{value:(_vm.subjectAward.Award),callback:function ($$v) {_vm.$set(_vm.subjectAward, "Award", $$v)},expression:"subjectAward.Award"}}),_c('DatePicker',{attrs:{"label":_vm.$t('Awarded'),"rules":[() => _vm.awardedOn.length > 0 || `${_vm.$t('InvalidDate')}`]},model:{value:(_vm.awardedOn),callback:function ($$v) {_vm.awardedOn=$$v},expression:"awardedOn"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('AwardContext'),"hint":_vm.$t('AwardContextHint'),"rules":[
        () =>
          _vm.subjectAward.AwardContext.length <= 80 ||
          `${_vm.$t('Field')} ${_vm.$t('Max80Characters')}`
      ]},model:{value:(_vm.subjectAward.AwardContext),callback:function ($$v) {_vm.$set(_vm.subjectAward, "AwardContext", $$v)},expression:"subjectAward.AwardContext"}}),(!_vm.subjectAward.Award.Id)?[_c('v-row',[_c('v-col',[_c('VMSTextField',{attrs:{"label":_vm.$t('AwardTitle'),"rules":[() => _vm.subjectAward.OtherTitle.length > 0 || ``]},model:{value:(_vm.subjectAward.OtherTitle),callback:function ($$v) {_vm.$set(_vm.subjectAward, "OtherTitle", $$v)},expression:"subjectAward.OtherTitle"}})],1),_c('v-col',[_c('VMSTextField',{attrs:{"label":_vm.$t('Description'),"rules":[
              () =>
                _vm.subjectAward.OtherDescription.length > 0 ||
                `${_vm.$t('Description')} ${_vm.$t('IsRequired')}!`
            ]},model:{value:(_vm.subjectAward.OtherDescription),callback:function ($$v) {_vm.$set(_vm.subjectAward, "OtherDescription", $$v)},expression:"subjectAward.OtherDescription"}})],1)],1)]:_vm._e()],2),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onAddClicked}},[_vm._v(_vm._s(_vm.$t("Add")))]),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"items":_vm.subjectAwardWrappers,"headers":_vm.headers},scopedSlots:_vm._u([{key:`item.IsNational`,fn:function({ item }){return [_c('td',[_c('v-checkbox',{attrs:{"disabled":true},model:{value:(item.IsNational),callback:function ($$v) {_vm.$set(item, "IsNational", $$v)},expression:"item.IsNational"}})],1)]}},{key:`item.Actions`,fn:function({ item }){return [_c('td',[_c('ConfirmButton',{attrs:{"icon":"mdi-delete","tooltip":_vm.$t('Delete')},on:{"confirmed":function($event){return _vm.onDeleteItemConfirmed(item)}}},[_vm._v(_vm._s(_vm.$t("Delete"))+" "+_vm._s(_vm.$t("Award"))+"?")])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }