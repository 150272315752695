export default class SiteRegistrationConfig {
  VehicleAccess: boolean = false;
  VehicleAccessEnumListId: string = "";
  CanDriveRental: boolean = false;
  CanDriveRentalEnumListId: string = "";
  Campus: boolean = false;
  CampusEnumListId: string = "";
  DietaryRestrictions: boolean = false;
  PreferredSchoolEducator: boolean = false;
  LocalSchools: boolean = false;
  PreferredVolunteers: boolean = false;
  AgeGroups: boolean = false;
  AgeGroupsEnumListId: string = "";
  HighSchoolSubjects: boolean = false;
  HighSchoolSubjectsEnumListId: string = "";
  Location: boolean = false;
  LocationEnumListId: string = "";
  IndigenousProgramsInterest: boolean = false;
  IndigenousInterestEnumListId: string = "";
  WhyVolunteer: boolean = false;
  AvailableTimes: boolean = false;
  PreferredActivities: boolean = false;
  PreferredActivitiesListId: string = "";
  SpecialInitiatives: boolean = false;
  SpecialInitiativesListId: string = "";
  StudentNumber: boolean = false;
  VolunteerTrainingCompleted: boolean = false;
  VolunteerTrainingCompletedListId: string = "";
  GraduateWorkLocation: boolean = false;
  GraduateWorkLocationListId: string = "";
  ShirtSize: boolean = false;
  VolunteerAgreementAccepted: boolean = false;
  VolunteerAgreementAcceptedListId: string = "";
  RequiredFields: string[] = [];
}
