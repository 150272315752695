
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VMSSelect extends Vue {
  @Prop() value!: any;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Boolean) multiple: boolean | undefined;
  @Prop(Array) items: Array<any> | undefined;
  @Prop(String) itemText: string | undefined;
  @Prop(String) itemValue: string | undefined;
  @Prop(Boolean) returnObject: boolean | undefined;
  @Prop(Boolean) chips: boolean | undefined;

  selected: any = this.value === undefined ? null : this.value;
  error: boolean = false;

  @Watch("value")
  onValueChanged() {
    this.selected = this.value;
  }

  onInput() {
    this.error = false;
    this.$emit("input", this.selected);
  }

  onBlur(event: any) {
    this.error = !(this.$refs.in as any).validate();
    this.$emit("blur", event);
  }
}
