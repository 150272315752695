import BaseType from "../BaseType";
import Site from "../Site/Site";

export default class Post extends BaseType {
  SiteId: string = "";
  Site: Site | undefined;
  Text: string = "";
  Pinned: boolean = false;
  CreatedOn: string = "";
  ModifiedOn: string = "";
}
