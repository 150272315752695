var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.HeaderText))]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-form',{ref:"form"},[_c('VMSTextField',{attrs:{"label":_vm.$t('NameOfEventTask'),"rules":[
          () => _vm.programSupport.Name.length > 0 || `${_vm.$t('FieldIsRequired')}`
        ]},model:{value:(_vm.programSupport.Name),callback:function ($$v) {_vm.$set(_vm.programSupport, "Name", $$v)},expression:"programSupport.Name"}}),_c('DatePicker',{attrs:{"label":_vm.$t('DateCompleted'),"rules":[
          () =>
            _vm.programSupport.DateCompleted !== '' || `${_vm.$t('FieldIsRequired')}`,
          () =>
            _vm.validateDate(_vm.programSupport.DateCompleted) ||
            `${_vm.$t('DateMustBeInValidSeason')}`
        ]},model:{value:(_vm.programSupport.DateCompleted),callback:function ($$v) {_vm.$set(_vm.programSupport, "DateCompleted", $$v)},expression:"programSupport.DateCompleted"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('Description')},model:{value:(_vm.programSupport.Description),callback:function ($$v) {_vm.$set(_vm.programSupport, "Description", $$v)},expression:"programSupport.Description"}}),(_vm.IsStaffAdmin)?_c('EnumerationResponse',{attrs:{"label":_vm.$t('SpecialInitiativesTags'),"list":_vm.initiativesTags,"multiple":true},model:{value:(_vm.programSupport.SpecialInitiativesHeader),callback:function ($$v) {_vm.$set(_vm.programSupport, "SpecialInitiativesHeader", $$v)},expression:"programSupport.SpecialInitiativesHeader"}}):_vm._e(),_c('v-input',{staticClass:"mt-4",attrs:{"rules":[
          () =>
            (_vm.programSupport.VolunteerLogHeader &&
              _vm.programSupport.VolunteerLogHeader.LogItems.filter(
                x => !x.Deleted
              ).length > 0) ||
            _vm.$t('HoursRequiredMsg')
        ]}},[_c('VolunteerHours',{staticStyle:{"width":"100%"},attrs:{"label":_vm.$t('VolunteerHours'),"hourtypes":['Program Support']},model:{value:(_vm.programSupport.VolunteerLogHeader),callback:function ($$v) {_vm.$set(_vm.programSupport, "VolunteerLogHeader", $$v)},expression:"programSupport.VolunteerLogHeader"}})],1),_c('ProgramSupportKitList',{staticClass:"mt-4",attrs:{"label":_vm.$t('Kits'),"siteId":_vm.siteId},model:{value:(_vm.programSupport.ProgramSupportKits),callback:function ($$v) {_vm.$set(_vm.programSupport, "ProgramSupportKits", $$v)},expression:"programSupport.ProgramSupportKits"}})],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary"},on:{"click":_vm.onSaveClick}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }