
import Vue from "vue";
import Component from "vue-class-component";
import DatePicker from "../DatePicker.vue";
import TimePicker from "../TimePicker.vue";
import SchoolDemoLog from "../school/SchoolDemoLog.vue";
import VolunteerHours from "../hourlogging/VolunteerHours.vue";
import EducatorLog from "../educator/EducatorLog.vue";
import WorkshopKitList from "./WorkshopKitList.vue";
import Site from "@/types/Site/Site";
import { Prop, Watch } from "vue-property-decorator";
import Workshop from "@/types/Workshop/Workshop";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationSelect from "../EnumerationSelect.vue";
import EnumerationResponse from "../EnumerationResponse.vue";
import SiteResponse from "../SiteResponse.vue";
import SeasonApiService from "@/services/SeasonApiService";
import Season from "@/types/Season";
import AuthorizationService from "@/services/AuthorizationService";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import YouthDemographicsLog from "@/components/youthdemographics/YouthDemographicsLog.vue";

@Component({
  components: {
    DatePicker,
    TimePicker,
    SchoolDemoLog,
    VolunteerHours,
    EducatorLog,
    WorkshopKitList,
    EnumerationSelect,
    EnumerationResponse,
    SiteResponse,
    VMSTextField,
    VMSTextArea,
    YouthDemographicsLog
  }
})
export default class WorkshopEdit extends Vue {
  @Prop(String) id: string | undefined;
  @Prop(String) siteId: string | undefined;
  @Prop(String) type: string | undefined;
  @Prop(Object) value: Workshop | undefined;
  @Prop(Array) fields: string[] | undefined;
  @Prop(Array) requirements: string[] | undefined;

  site: Site = new Site();

  //@ts-ignore
  workshop: Workshop = this.value || new Workshop();

  enumerationApiService: EnumerationApiService = new EnumerationApiService();
  locationTypes: EnumerationList = new EnumerationList();
  subjectAreas: EnumerationList = new EnumerationList();
  languages: EnumerationList = new EnumerationList();
  grades: EnumerationList = new EnumerationList();
  initiativesTags: EnumerationList = new EnumerationList();
  volunteerLocations: EnumerationList = new EnumerationList();

  seasonApiService: SeasonApiService = new SeasonApiService();
  unlockedSeasons: Season[] = [];

  authService: AuthorizationService = AuthorizationService.getInstance();

  schoolDemogsHiddenFields: string[] = [];
  maxSchools: number = 0;

  @Watch("value")
  onValueChanged() {
    this.workshop = this.value || new Workshop();

    this.setSchoolDemogConfig();
  }

  get WorkshopTypeName(): string {
    return this.type || "Workshop";
  }

  get IsStaffAdmin(): boolean {
    return this.authService.hasRole(["Staff", "Administrator"]);
  }

  fieldVisible(name: string): boolean {
    let map: string[] = this.fields || [];
    return map.includes(name);
  }

  fieldRequired(name: string): boolean {
    /* let reqs: string[] = this.requirements || [];
    return reqs.includes(name); */
    return true;
  }

  setSchoolDemogConfig() {
    switch (this.WorkshopTypeName) {
      case "Workshop":
        this.maxSchools = 0;
        break;
      case "ActivityBooth":
        this.schoolDemogsHiddenFields = ["NumberOfYouth"];
        break;
      case "MultiWorkshop":
        this.maxSchools = 1;
        this.schoolDemogsHiddenFields = ["NumberOfYouth"];
        break;
      case "MentoringScienceFairJudge":
        this.maxSchools = 0;
        this.schoolDemogsHiddenFields = [];
        break;
      case "PublicOnCampusEvent":
        this.maxSchools = 0;
        this.schoolDemogsHiddenFields = [];
        break;
      case "OnCampusEventWithSchools":
        this.maxSchools = 0;
        break;
    }
  }

  mounted() {
    this.enumerationApiService
      .GetEnumerationListsBySystemName([
        "LocationTypes",
        "AreasOfStudy",
        "Languages",
        "Grades",
        "SpecialInitiativesTags",
        "VolunteerLocations"
      ])
      .then(resp => {
        let lists: EnumerationList[] = resp.Value;
        this.locationTypes =
          lists.find(x => x.SystemName === "LocationTypes") ||
          new EnumerationList();
        this.subjectAreas =
          lists.find(x => x.SystemName === "AreasOfStudy") ||
          new EnumerationList();
        this.languages =
          lists.find(x => x.SystemName === "Languages") ||
          new EnumerationList();
        this.grades =
          lists.find(x => x.SystemName === "Grades") || new EnumerationList();

        this.initiativesTags =
          lists.find(x => x.SystemName === "SpecialInitiativesTags") ||
          new EnumerationList();

        this.volunteerLocations =
          lists.find(x => x.SystemName === "VolunteerLocations") ||
          new EnumerationList();
      });

    this.seasonApiService.GetSeasons().then(resp => {
      this.unlockedSeasons = resp.Value.filter(x => !x.Locked);
    });

    this.setSchoolDemogConfig();
  }

  validateDate(datestr: string): boolean {
    let checkdate: Date = this.parseDate(datestr);
    let dateInSeason: boolean = false;

    if (this.authService.hasRole(["Administrator", "Staff"])) {
      dateInSeason = true;
    } else {
      this.unlockedSeasons.forEach((s: Season) => {
        let sd: Date = this.parseDate(s.StartDate);
        let ed: Date = this.parseDate(s.EndDate);

        if (
          checkdate.getTime() >= sd.getTime() &&
          checkdate.getTime() <= ed.getTime()
        ) {
          dateInSeason = true;
        }
      });
    }

    return dateInSeason;
  }

  parseDate(datestr: string): Date {
    let parts: string[] = datestr.split("-");
    let year: number = parseInt(parts[0]);
    let monthIdx: number = parseInt(parts[1]) - 1;
    let day: number = parseInt(parts[2]);

    return new Date(year, monthIdx, day);
  }

  validate(): boolean {
    let ret: boolean = (this.$refs.form as any).validate();

    if (!ret) {
      this.$ScrollToFirstError();
    }

    return ret;
  }

  onFieldInput() {
    this.$emit("field-input");
  }
}
