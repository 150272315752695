
import OrganizationApiService from "@/services/OrganizationApiService";
import OrganizationFileUpdateHeader from "@/types/Organization/OrganizationFileUpdateHeader";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import OrganizationChangesTable from "@/components/organization/OrganizationChangesTable.vue";
import OrganizationFileErrorsTable from "@/components/organization/OrganizationFileErrorsTable.vue";
import Guid from "@/types/Guid";

@Component({
  components: {
    OrganizationChangesTable,
    OrganizationFileErrorsTable
  }
})
export default class OrganizationFilePatcher extends Vue {
  //@Prop(String) value: String | undefined;
  step: number = 1;
  file: File | null = null;
  apiService: OrganizationApiService = new OrganizationApiService();
  header: OrganizationFileUpdateHeader = new OrganizationFileUpdateHeader();
  loading: boolean = false;
  loadingMsg: string = "";
  tab: number = 0;
  updateCompleteMsg: string = "";

  onUploadClicked() {
    if (this.file !== null) {
      this.loading = true;
      this.loadingMsg = "ComparingFileMsg";
      this.apiService
        .UploadOrgFile(this.file)
        .then(resp => {
          if (resp.Success) {
            let headerId: string = resp.Value;
            if (headerId === Guid.Empty) {
              this.updateCompleteMsg = "NoChangesMsg";
              this.step = 3;
              this.loading = false;
            } else {
              this.loadingMsg = "RetrievingChangesMsg";
              ++this.step;
              this.apiService
                .GetOrganizationFileUpdateHeader(headerId)
                .then(headResp => {
                  if (headResp.Success) {
                    this.header = headResp.Value;
                    console.log(this.header);
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          } else {
            this.updateCompleteMsg = "UnableToCreateHeaderMsg";
            this.loading = false;
            this.step = 3;
          }
        })
        .catch(() => {
          this.updateCompleteMsg = "UnableToCreateHeaderMsg";
          this.loading = false;
          this.step = 3;
        });
    }
  }

  onCommitClicked() {
    this.apiService
      .RunOrganizationFileUpdateHeader(this.header.Id)
      .then(resp => {
        ++this.step;
        if (resp.Success) {
          this.updateCompleteMsg = "UpdatesAppliedMsg";
        } else {
          this.updateCompleteMsg = "UpdatesFailedMsg";
        }
      })
      .catch(() => {
        this.updateCompleteMsg = "UpdatesFailedMsg";
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onCloseClicked() {
    this.Cleanup();
    this.$emit("closed");
  }

  Cleanup() {
    if (this.header.Id) {
      this.apiService.DeleteOrganizationFileUpdateHeader(this.header.Id);
    }

    this.header = new OrganizationFileUpdateHeader();
    this.file = null;
    this.loading = false;
    this.loadingMsg = "";
    this.step = 1;
  }
}
