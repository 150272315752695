import SubjectService from "@/services/SubjectApiService";
import Subject from "@/types/Subject/Subject";
import Role from "@/types/Subject/Role";
import UseCaseResponse from "@/types/UseCaseResponse";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";

export default class AuthorizationService {
  private static instance: AuthorizationService;
  private subject: Subject;
  private subjectRoles: string[];

  public static init(subject: Subject) {
    if (!AuthorizationService.instance) {
      AuthorizationService.instance = new AuthorizationService(subject);
    }
  }

  public static getInstance(): AuthorizationService {
    return AuthorizationService.instance;
  }

  private constructor(subject: Subject) {
    this.subject = subject;
    this.subjectRoles = subject.SubjectRoles.map((x: any) => {
      return x.Role.Name;
    });
  }

  public get UserId(): string {
    return this.subject.SSOId;
  }

  public isAdmin(): boolean {
    let roles = ["Administrator"]; //, "Staff"];
    return roles.some(r => this.subjectRoles.includes(r));
  }

  public hasRole(roles: string[]): boolean {
    return roles.some(r => this.subjectRoles.includes(r)) || this.isAdmin();
  }

  public approvedInActiveSeason(): boolean {
    let staffaddmin: boolean = this.hasRole(["Staff", "Administrator"]);
    let approvedActiveRegistration: boolean =
      this.subject.SiteRegistrations.find(
        x => x.Season && x.Season.Active && x.IsApproved
      ) !== undefined;

    return staffaddmin || approvedActiveRegistration;
  }
}
