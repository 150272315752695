
import NoteApiService from "@/services/NoteApiService";
import OrganizationApiService from "@/services/OrganizationApiService";
import SiteRegistrationApiService from "@/services/SiteRegistrationApiService";
import SubjectApiService from "@/services/SubjectApiService";
import VolunteerProfileApiService from "@/services/VolunteerProfileApiService";
import Note from "@/types/Note/Note";
import VolunteerProfile from "@/types/Profile/VolunteerProfile";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import Subject from "@/types/Subject/Subject";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VolunteerProfileTable from "@/components/volunteersummary/VolunteerProfileTable.vue";
import SiteRegistrationTable from "@/components/volunteersummary/SiteRegistrationTable.vue";
import SubjectAward from "@/types/Award/SubjectAward";
import AwardLocale from "@/types/Award/AwardLocale";
import ScreeningDocument from "@/types/ScreeningDocument/ScreeningDocument";
import ScreeningDocumentApiService from "@/services/ScreeningDocumentApiService";
import DataObject from "@/types/DataObject";
import ScreeningDocumentTrackingItem from "@/types/ScreeningDocument/ScreeningDocumentTrackingItem";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import AuthorizationService from "@/services/AuthorizationService";

class SubjectAwardWrapper {
  Name: string = "";
  Context: string = "";
  Awarded: string = "";

  constructor(n: string, c: string, a: string) {
    this.Name = n;
    this.Context = c;
    this.Awarded = a;
  }
}

@Component({
  components: {
    VolunteerProfileTable,
    SiteRegistrationTable
  }
})
export default class VolunteerSummary extends Vue {
  tab: number = 0;

  subjectId: string = this.$VmsLoggedInSubjectId;
  subject: Subject = new Subject();
  subjectApiService: SubjectApiService = new SubjectApiService();
  authService: AuthorizationService = AuthorizationService.getInstance();

  profile: VolunteerProfile = new VolunteerProfile();
  profileApiService: VolunteerProfileApiService = new VolunteerProfileApiService();

  orgApiService: OrganizationApiService = new OrganizationApiService();
  orgName: string = "";

  registrationApiService: SiteRegistrationApiService = new SiteRegistrationApiService();
  selectedRegistrations: SiteRegistration[] = [];
  registrations: SiteRegistration[] = [];
  deleteRegistrationDialog: boolean = false;
  registrationId: string = "";

  notes: Note[] = [];
  noteApiService: NoteApiService = new NoteApiService();

  orgs: GetOrganizationResponse[] = [];
  screeningDocApiService: ScreeningDocumentApiService = new ScreeningDocumentApiService();
  screeningDocs: ScreeningDocument[] = [];
  expandedDocs: ScreeningDocument[] = [];
  sdTypes: DataObject = new DataObject();
  sdStatus: DataObject = new DataObject();
  screeningDocHeaders: object[] = [
    {
      text: this.$root.$t("Status"),
      align: "left",
      value: "Status"
    },
    {
      text: this.$root.$t("Type"),
      align: "left",
      value: "Type"
    },
    {
      text: this.$root.$t("Approved"),
      align: "left",
      value: "Approved"
    },
    {
      text: this.$root.$t("ApprovalDate"),
      align: "left",
      value: "ApprovalDate"
    },
    {
      text: this.$root.$t("FileDate"),
      align: "left",
      value: "FileDate"
    },
    {
      text: this.$root.$t("ExpiryDate"),
      align: "left",
      value: "ExpiryDate"
    }
  ];

  trackingItemHeaders: object[] = [
    {
      text: this.$root.$t("SchoolOrganization"),
      align: "left",
      value: "OrganizationId"
    },
    {
      text: this.$root.$t("Sent"),
      align: "left",
      value: "SentDate"
    },
    {
      text: this.$root.$t("RestrictedActivities"),
      align: "left",
      value: "RestrictedActivities"
    },
    {
      text: this.$root.$t("RestrictedComments"),
      align: "left",
      value: "RestrictedActivityComments"
    }
  ];

  get StaffAdmin(): boolean {
    return this.authService.hasRole(["Staff", "Administrator"]);
  }

  get Coordinator(): boolean {
    return this.authService.hasRole(["Coordinator"]);
  }

  permissionText(pl: number): string {
    switch (pl) {
      case 1:
        return this.$t("VolunteerReadCoordinatorWrite") as string;
      case 2:
        return this.$t("VolunteerHiddenCoordinatorWrite") as string;
      case 3:
        return this.$t("CoordinatorReadAdminWrite") as string;
      case 4:
        return this.$t("AllReadAdminWrite") as string;
      case 5:
        return this.$t("AdminOnly") as string;
      default:
        return "";
    }
  }

  registrationHeaders: object[] = [
    {
      text: this.$root.$t("Site"),
      value: "Site.Name",
      sortable: false
    },
    {
      text: this.$root.$t("Season"),
      value: "Season",
      sortable: false
    },
    {
      text: this.$root.$t("Registered"),
      value: "CreatedOn",
      sortable: false
    },
    {
      text: this.$root.$t("Approved"),
      value: "IsApproved",
      sortable: false
    }
  ];

  awardHeaders: object[] = [
    {
      text: this.$root.$t("Award"),
      value: "Name"
    },
    {
      text: this.$root.$t("Context"),
      value: "Context"
    },
    {
      text: this.$root.$t("Awarded"),
      value: "Awarded"
    }
  ];

  get SelectedRegistration(): SiteRegistration {
    if (this.selectedRegistrations.length === 1) {
      return this.selectedRegistrations[0];
    } else {
      return new SiteRegistration();
    }
  }

  get SubjectAwards(): SubjectAwardWrapper[] {
    return this.subject.SubjectAwards.map((sa: SubjectAward) => {
      let name: string = "";

      if (sa.Award) {
        let locale: AwardLocale | undefined = sa.Award.Locales.find(
          x => x.Locale === this.$i18n.locale
        );
        name = locale ? locale.Name : "";
      } else {
        name = sa.OtherTitle;
      }

      let awarded: string = new Date(`${sa.AwardedOn}`).toLocaleDateString();

      return new SubjectAwardWrapper(name, sa.AwardContext, awarded);
    });
  }

  get ShowEditProfile(): boolean {
    return this.subjectId === this.$VmsLoggedInSubjectId;
  }

  @Watch("expandedDocs")
  onDocsExpandedChanged() {
    if (
      this.expandedDocs.length > 0 &&
      this.expandedDocs[0].ScreeningDocumentTrackingItems
    ) {
      this.expandedDocs[0].ScreeningDocumentTrackingItems.forEach(
        (sdti: ScreeningDocumentTrackingItem) => {
          let orgId: string = sdti.OrganizationId;
          if (orgId) {
            let org = this.orgs.find(x => x.Id === orgId);
            if (!org) {
              this.orgApiService.GetOrganizationById(orgId).then(resp => {
                org = resp.Value;
                if (org) {
                  this.orgs.push(org);
                }
              });
            }
          }
        }
      );
    }
  }

  mounted() {
    if (this.$route.query.subjectId) {
      this.subjectId = this.$route.query.subjectId as string;

      if (this.$route.query.registrationId) {
        this.registrationId = this.$route.query.registrationId as string;
        this.tab = 1;
      }
    }

    this.subjectApiService.GetSubjectById(this.subjectId).then(resp => {
      this.subject = resp.Value;
    });

    this.profileApiService
      .GetVolunteerProfileBySubjectId(this.subjectId)
      .then(resp => {
        this.profile = resp.Value || new VolunteerProfile();
        if (this.profile.StudentProfile) {
          this.orgApiService
            .GetOrganizationById(
              this.profile.StudentProfile.PostSecondaryInstitutionId
            )
            .then((data: any) => {
              this.orgName = data.Value[0].Name;
            });
        }
      });

    this.getRegistrations();

    this.noteApiService.GetNotes(this.subjectId).then(resp => {
      this.notes = resp.Value;
    });

    this.screeningDocApiService
      .All([
        this.screeningDocApiService.GetScreeningDocumentTypes(),
        this.screeningDocApiService.GetScreeningDocumentStatus(),
        this.screeningDocApiService.GetScreeningDocumentsBySubjectId(
          this.subjectId
        )
      ])
      .then(resps => {
        this.sdTypes = resps[0].Value;
        this.sdStatus = resps[1].Value;
        this.screeningDocs = resps[2].Value;
      });
  }

  getRegistrations() {
    this.registrations = [];
    this.selectedRegistrations = [];
    this.registrationApiService
      .GetSiteRegistrationsBySubjectId(this.subjectId)
      .then(resp => {
        this.registrations = resp.Value;

        if (this.registrationId) {
          let sr: SiteRegistration | undefined = this.registrations.find(
            x => x.Id === this.registrationId
          );

          if (sr) {
            this.selectedRegistrations.push(sr);
          }

          this.registrationId = "";
        }
      });
  }

  getOrgName(id: string) {
    let org = this.orgs.find(x => x.Id === id);
    return org ? org.Name : "";
  }

  onViewRecordClicked() {
    this.$router.push(`/subject/record?subjectId=${this.subjectId}`);
  }

  onEditProfileClicked() {
    this.$router.push(`/volunteer/profile?subjectId=${this.subjectId}`);
  }

  onApproveRegistrationClicked() {
    let reg: SiteRegistration = JSON.parse(
      JSON.stringify(this.SelectedRegistration)
    );
    reg.IsApproved = true;

    this.registrationApiService.PatchSiteRegistrations([reg]).then(resp => {
      if (resp.Success) {
        this.SelectedRegistration.IsApproved = true;
      }
    });
  }

  onDeleteConfirmed() {
    this.registrationApiService
      .DeleteSiteRegistration(this.SelectedRegistration.Id)
      .then(resp => {
        if (resp.Success) {
          this.getRegistrations();
        }
      })
      .finally(() => {
        this.deleteRegistrationDialog = false;
      });
  }
}
