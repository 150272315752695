
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker.vue";
import VolunteerHours from "@/components/hourlogging/VolunteerHours.vue";
import ProgramSupport from "@/types/ProgramSupport/ProgramSupport";
import ProgramSupportApiService from "@/services/ProgramSupportApiService";
import Guid from "@/types/Guid";
import ProgramSupportKitList from "@/components/programsupport/ProgramSupportKitList.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import AuthorizationService from "@/services/AuthorizationService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationResponse from "@/components/EnumerationResponse.vue";
import SeasonApiService from "@/services/SeasonApiService";
import Season from "@/types/Season";

@Component({
  components: {
    DatePicker,
    VolunteerHours,
    ProgramSupportKitList,
    VMSTextField,
    VMSTextArea,
    EnumerationResponse
  }
})
export default class ProgramSupportCreate extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  programSupport: ProgramSupport = new ProgramSupport();
  programSupportApiService: ProgramSupportApiService = new ProgramSupportApiService();
  authService: AuthorizationService = AuthorizationService.getInstance();

  initiativesTags: EnumerationList = new EnumerationList();
  enumApiService: EnumerationApiService = new EnumerationApiService();

  seasonApiService: SeasonApiService = new SeasonApiService();
  unlockedSeasons: Season[] = [];

  get HeaderText(): string {
    if (this.programSupport.Id !== Guid.Empty) {
      return `${this.$t("Edit")} ${this.$t("ProgramSupport")}`;
    } else {
      return `${this.$t("Log")} ${this.$t("ProgramSupport")}`;
    }
  }

  get IsStaffAdmin(): boolean {
    return this.authService.hasRole(["Staff", "Administrator"]);
  }

  mounted() {
    this.enumApiService
      .GetEnumerationListsBySystemName(["SpecialInitiativesTags"])
      .then(resp => {
        if (resp.Success) {
          this.initiativesTags = resp.Value[0];
        }
      });

    this.seasonApiService.GetSeasons().then(resp => {
      this.unlockedSeasons = resp.Value.filter(x => !x.Locked);
    });

    if (this.$route.query.id) {
      let pSupportId: string = this.$route.query.id as string;
      this.programSupportApiService
        .GetProgramSupportById(pSupportId)
        .then(resp => {
          this.programSupport = resp.Value;
        });
    }
  }

  parseDate(datestr: string): Date {
    let parts: string[] = datestr.split("-");
    let year: number = parseInt(parts[0]);
    let monthIdx: number = parseInt(parts[1]) - 1;
    let day: number = parseInt(parts[2]);

    return new Date(year, monthIdx, day);
  }

  validateDate(datestr: string): boolean {
    let checkdate: Date = this.parseDate(datestr);
    let dateInSeason: boolean = false;

    /* if (this.authService.hasRole(["Administrator", "Staff"])) {
      dateInSeason = true;
    } else {
      this.unlockedSeasons.forEach((s: Season) => {
        let sd: Date = this.parseDate(s.StartDate);
        let ed: Date = this.parseDate(s.EndDate);

        if (
          checkdate.getTime() >= sd.getTime() &&
          checkdate.getTime() <= ed.getTime()
        ) {
          dateInSeason = true;
        }
      });
    } */

    this.unlockedSeasons.forEach((s: Season) => {
      let sd: Date = this.parseDate(s.StartDate);
      let ed: Date = this.parseDate(s.EndDate);

      if (
        checkdate.getTime() >= sd.getTime() &&
        checkdate.getTime() <= ed.getTime()
      ) {
        dateInSeason = true;
      }
    });

    return dateInSeason;
  }

  onSaveClick() {
    if ((this.$refs.form as any).validate()) {
      if (this.programSupport.Id === Guid.Empty) {
        this.programSupport.SiteId = this.siteId || "";
        this.programSupportApiService
          .CreateProgramSupport(this.programSupport)
          .then(resp => {
            if (resp.Success) {
              this.$router.push(`/${this.urlSite}/ProgramSupport`);
            }
          });
      } else {
        this.programSupportApiService
          .UpdateProgramSupport(this.programSupport)
          .then(resp => {
            if (resp.Success) {
              this.$router.push(`/${this.urlSite}/ProgramSupport`);
            }
          });
      }
    } else {
      this.$ScrollToFirstError();
    }
  }
}
