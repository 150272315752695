import axios from "axios";
import KitItem from "@/types/Kit/Kit";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";

export default class KitApiService extends BaseApiService {
  async GetKitItems(): Promise<UseCaseResponse<KitItem[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Kit`)
      .then(resp => resp.data as UseCaseResponse<KitItem[]>);
  }

  async GetKitItemsBySiteId(
    siteId: string
  ): Promise<UseCaseResponse<KitItem[]>> {
    await this.defineHeader();
    return axios
      .get(this.url + `/api/vms/Kit?siteId=${siteId}`)
      .then(resp => resp.data as UseCaseResponse<KitItem[]>);
  }

  async UpdateKitItem(kitItem: KitItem): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Kit/${kitItem.Id}`, kitItem)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteKitItem(kitItemId: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Kit/${kitItemId}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async CreateKitItem(kitItem: KitItem): Promise<UseCaseResponse<KitItem>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Kit`, kitItem)
      .then(resp => resp.data as UseCaseResponse<KitItem>);
  }
}
