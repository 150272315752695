
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Enumeration from "../../types/Enumeration/Enumeration";
import EnumerationApiService from "../../services/EnumerationApiService";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import Guid from "@/types/Guid";
import LocalizationsTable from "@/components/localizer/LocalizationsTable.vue";

@Component({
  components: {
    VMSTextField,
    LocalizationsTable
  }
})
export default class EnumerationCreate extends Vue {
  apiService: EnumerationApiService = new EnumerationApiService();
  enumeration: Enumeration = new Enumeration();
  enumerationItems: Enumeration[] = [];

  mounted() {
    const e_id: string = this.$route.params.id;
    if (e_id) {
      this.apiService.GetEnumerationById(e_id).then(resp => {
        if (resp.Success) {
          console.log(resp);
          this.enumeration = resp.Value;
        }
      });
    }
  }

  onSaveClicked() {
    if ((this.$refs.form as any).validate()) {
      let p: Promise<any>;
      if (!this.enumeration.Id || this.enumeration.Id === Guid.Empty) {
        p = this.apiService.CreateEnumeration(this.enumeration);
      } else {
        p = this.apiService.UpdateEnumeration(this.enumeration);
      }

      p.then((resp: any) => {
        if (resp.Value) {
          this.$router.push(`/enumerations`);
        } else {
          //show error msg, also this check should actually check we got a valid guid
          console.log("error");
        }
      });
    }
  }
}
