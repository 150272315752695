import BaseType from "../BaseType";
import Guid from "../Guid";

export default class EvaluationReponse extends BaseType {
  WorkshopId: string = Guid.Empty;
  EvaluationSurveyId: number = 0;
  QuestionProSurveyId: number = 0;
  QuestionProResponseId: number = 0;
  QuestionProResponse: any;
  EvaluationSurvey: any;
}
