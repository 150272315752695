import { PagedQueryRequest } from "../Pocos/Pagination/PagedQueryRequest";
import { RequestStatus } from "./OutreachRequestStatus";

export default class QueryOutreachRequestRequest extends PagedQueryRequest {
  SiteId: string | null = null;
  StatusFilters: RequestStatus[] = [];

  loadFromJson(json: object) {
    super.loadFromJson(json);
    const q = json as any;
    this.SiteId = q.SiteId;
    this.StatusFilters = q.StatusFilters;
  }
}
