
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VMSAutoComplete extends Vue {
  @Prop() value: any | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(String) placeholder: String | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Boolean) multiple: boolean | undefined;
  @Prop(Array) items: Array<any> | undefined;
  @Prop(String) itemText: string | undefined;
  @Prop(String) itemValue: string | undefined;
  @Prop(Boolean) returnObject: boolean | undefined;
  @Prop(Boolean) chips: boolean | undefined;
  @Prop(String) searchInput: string | undefined;
  @Prop(Boolean) loading: boolean | undefined;

  selected: any | undefined = this.value;
  search: string = this.searchInput || "";
  error: boolean = false;

  @Watch("value")
  onValueChanged() {
    this.selected = this.value;
  }

  @Watch("search")
  onSearchChanged() {
    this.$emit("update:search-input", this.search);
  }

  onInput() {
    this.error = false;
    this.$emit("input", this.selected);
  }

  onKeyUp(event: any) {
    this.$emit("keyup", event);
  }

  onFocus(event: any) {
    this.$emit("focus", event);
  }

  onBlur(event: any) {
    this.error = !(this.$refs.in as any).validate();
    this.$emit("blur", event);
  }

  Clear() {
    (this.$refs.vAutoComplete as any).reset();
  }
}
