
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Subject from "../../types/Subject/Subject";
import SubjectApiService from "../../services/SubjectApiService";
import Role from "../../types/Subject/Role";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSSelect
  }
})
export default class SubjectRoles extends Vue {
  apiService: SubjectApiService = new SubjectApiService();

  headers: Object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "Name"
    },
    {
      text: this.$root.$t("Description"),
      align: "left",
      value: "Description"
    },
    {
      text: "",
      value: "Actions",
      sortable: false
    }
  ];

  subjectId: any;
  subject: Subject = new Subject();
  roles: Object = {};
  selectedRole: Role = new Role();

  get filteredRoles() {
    var that = this;
    return Object.values(this.roles).filter(f => {
      let contains = false;
      for (let r in that.subject.SubjectRoles) {
        if (that.subject.SubjectRoles[r].RoleId == f.Id) contains = true;
      }
      return !contains;
    });
  }

  loadSubject() {
    this.apiService.GetSubjectById(this.subjectId).then(data => {
      this.subject = data.Value;
    });
  }

  mounted() {
    this.subjectId = this.$route.query.id as string;
    this.loadSubject();

    this.apiService.GetRoles().then(data => {
      this.roles = data.Value;
    });
  }

  onApplyRoleClick() {
    this.apiService
      .ApplyRole(this.subject.Id, this.selectedRole.Id)
      .then(() => {
        this.loadSubject();
      });
  }

  onRevokeRoleClick(item: any) {
    this.apiService.RevokeRole(item["SubjectId"], item["RoleId"]).then(() => {
      this.loadSubject();
    });
  }
}
