
import AuthorizationService from "@/services/AuthorizationService";
import OutreachRequestApiService from "@/services/OutreachRequestApiService";
import OutreachRequestStatus, {
  RequestStatus
} from "@/types/OutreachRequest/OutreachRequestStatus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import CreateOutreachRequestStatusRequest from "@/types/OutreachRequest/CreateOutreachRequestStatusRequest";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import EmailApiService from "@/services/EmailApiService";
import Email, { EmailType } from "@/types/Email/Email";
import EmailLocalization from "@/types/Email/EmailLocalization";

@Component({
  components: {
    VMSSelect,
    VMSTextArea
  }
})
export default class OutreachRequestStatusUpdateDialog extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(Array) items!: any[];

  show: boolean = this.value || false;
  edit_requests: any[] = [];
  edit_status: RequestStatus | null = null;
  send_email: boolean = false;
  note: string = "";

  authService: AuthorizationService = AuthorizationService.getInstance();
  outreachApiService: OutreachRequestApiService = new OutreachRequestApiService();
  emailApiService: EmailApiService = new EmailApiService();
  accepted_email: string = "";
  declined_email: string = "";

  @Watch("value")
  onValueChanged() {
    this.show = this.value || false;
    this.edit_status = null;
    this.note = "";
  }

  @Watch("items")
  onRequestsChanged() {
    this.edit_requests = this.items;
  }

  get staff_admin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  get outreach_status_options(): any[] {
    return [
      {
        text: this.$root.$t("OutreachRequest.Status.Waitlisted"),
        value: RequestStatus.Waitlisted
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Pending"),
        value: RequestStatus.Pending
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Accepted"),
        value: RequestStatus.Accepted
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Declined"),
        value: RequestStatus.Declined
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Scheduled"),
        value: RequestStatus.Scheduled
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Completed"),
        value: RequestStatus.Completed
      },
      {
        text: this.$root.$t("OutreachRequest.Status.Cancelled"),
        value: RequestStatus.Cancelled
      }
    ];
  }

  get valid_status_options(): any[] {
    let ret = [];
    if (this.staff_admin) {
      ret = this.outreach_status_options;
    } else {
      const editing_status: RequestStatus[] = this.edit_requests.map(
        or => or.Status as RequestStatus
      );

      const valid_options = OutreachRequestStatus.GetValidStatusUpdates(
        editing_status
      );

      ret = valid_options.map((rs: RequestStatus) => {
        return {
          text: this.$t(`OutreachRequest.Status.${RequestStatus[rs]}`),
          value: rs,
          single: rs === RequestStatus.Declined
        };
      });
    }

    //remove the current status for single edits
    const single = this.edit_requests.length === 1;
    const current_status: RequestStatus | null = single
      ? this.edit_requests[0].Status
      : null;
    return ret.filter(op => (single ? op.value !== current_status : true));
  }

  get email_status_types(): RequestStatus[] {
    return [RequestStatus.Accepted, RequestStatus.Declined];
  }

  get note_preview(): string {
    let note_template = "";
    console.log(this.edit_status);
    switch (this.edit_status) {
      case RequestStatus.Accepted:
        note_template = this.accepted_email;
        break;
      case RequestStatus.Declined:
        note_template = this.declined_email;
        break;
    }

    note_template = note_template.replace("{note}", this.note);

    return note_template;
  }

  mounted() {
    this.emailApiService.GetEmails().then(resp => {
      const accepted = resp.Value.find(
        (e: Email) => e.EmailType === EmailType.OutreachRequestAcceptedRequestor
      );
      const declined = resp.Value.find(
        (e: Email) => e.EmailType === EmailType.OutreachRequestDeclinedRequestor
      );

      const accepted_localization = accepted
        ? accepted.Localizations.find(
            (l: EmailLocalization) => l.Locale === "enfr"
          )
        : new EmailLocalization();
      const declined_localization = declined
        ? declined.Localizations.find(
            (l: EmailLocalization) => l.Locale === "enfr"
          )
        : new EmailLocalization();

      this.accepted_email = accepted_localization
        ? accepted_localization.Body
        : "";
      this.declined_email = declined_localization
        ? declined_localization.Body
        : "";
    });
  }

  onUpdateStatusConfirmed() {
    if (!(this.$refs.status_form as any).validate()) return;
    else if (this.edit_status === null) return;
    else {
      const status: RequestStatus = this.edit_status;

      const patches = this.edit_requests.map((or: any) => {
        const new_status = new OutreachRequestStatus();
        new_status.OutreachRequestId = or.Id;
        new_status.Status = status;

        const req = new CreateOutreachRequestStatusRequest();
        req.Status = new_status;
        req.SendEmail = this.send_email;
        req.Note = req.SendEmail ? this.note : "";

        return req;
      });

      this.outreachApiService.CreateRequestStatus(patches).then(resp => {
        if (resp.Success) {
          this.$emit("updated");
        }
      });
    }
  }

  onCancelClicked() {
    this.send_email = false;
    this.note = "";
    this.$emit("canceled");
  }
}
