
import ProgramSupportApiService from "@/services/ProgramSupportApiService";
import ProgramSupport from "@/types/ProgramSupport/ProgramSupport";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ConfirmButton from "../../components/ConfirmButton.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    ConfirmButton,
    VMSTextField
  }
})
export default class ProgramSupportList extends Vue {
  /* props: { siteId: s.Id, urlSite: urlSite } */
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;

  isActiveSeasonFilterValue: boolean = true;
  search: string = "";

  program_support: ProgramSupport[] = [];
  programSupportApiService: ProgramSupportApiService = new ProgramSupportApiService();

  get Headers(): object[] {
    return [
      {
        text: this.$root.$t("Name"),
        align: "left",
        value: "Name"
      },
      {
        text: this.$root.$t("DateCompleted"),
        align: "left",
        value: "DateCompleted",
        sort: this.$DateSort
      },
      {
        text: "",
        align: "right",
        value: "Actions"
      }
    ];
  }

  mounted() {
    this.getData();
  }

  onIsActiveSeasonFilterChanged() {
    this.getData();
  }

  getData() {
    this.program_support = [];
    this.programSupportApiService
      .GetProgramSupportBySiteId(
        this.siteId || "",
        this.isActiveSeasonFilterValue
      )
      .then(resp => {
        this.program_support = resp.Value;
      });
  }

  onEditItem(item: ProgramSupport) {
    this.$router.push(`/${this.urlSite}/ProgramSupport/Update?id=${item.Id}`);
  }

  onDeleteConfirmed(item: ProgramSupport) {
    this.programSupportApiService.DeleteProgramSupport(item.Id).then(resp => {
      if (resp.Success) {
        this.getData();
      }
    });
  }
}
