import BaseType from "../BaseType";
import Guid from "../Guid";

export default class VolunteerLogItemAuxData extends BaseType {
  LogItemId: string = Guid.Empty;
  FirstName: string = "";
  LastName: string = "";
  Email: string = "";
  Affiliation: string = "";
}
