
import DataObject from "@/types/DataObject";
import OrganizationFileUpdate from "@/types/Organization/OrganizationFileUpdate";
import OrganizationFileUpdateHeader from "@/types/Organization/OrganizationFileUpdateHeader";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import VMSTextField from "../fields/VMSTextField.vue";

@Component({
  components: {
    VMSSelect,
    VMSTextField
  }
})
export default class OrganizationChangesTable extends Vue {
  @Prop(Object) value: OrganizationFileUpdateHeader | undefined;
  @Prop(Boolean) loading: boolean | undefined;
  @Prop(String) loadingMsg: string | undefined;

  updateHeader: OrganizationFileUpdateHeader =
    this.value || new OrganizationFileUpdateHeader();

  visibleHeaders: object[] = [];
  search: string = "";

  @Watch("value")
  onValueChanged() {
    this.updateHeader = this.value || new OrganizationFileUpdateHeader();
  }

  get SortedVisibleHeaders(): object[] {
    return this.visibleHeaders.sort(
      (a, b) => (a as any).index - (b as any).index
    );
  }

  get ToggleableHeaders(): object[] {
    return this.headers.filter((h: DataObject) => {
      return h["visibilityToggle"] === true;
    });
  }

  get Changes(): OrganizationFileUpdate[] {
    return this.updateHeader.Items;
  }

  mounted() {
    this.setVisibleHeaders();
  }

  setVisibleHeaders() {
    this.headers.forEach(h => {
      this.visibleHeaders.push(h);
    });
  }

  onColumnsInput() {
    if (!this.visibleHeaders || this.visibleHeaders.length === 0) {
      this.setVisibleHeaders();
    }
  }

  getStyle(changed: boolean): string {
    return changed ? "background-color:lightgreen;" : "";
  }

  getStatusText(status: number): string {
    switch (status) {
      case 1:
        return this.$t("Verified") as string;
      case 2:
        return this.$t("Merged") as string;
      default:
        return this.$t("New") as string;
    }
  }

  isColumnVisible(col: string): boolean {
    return (
      this.SortedVisibleHeaders.find((x: any) => x.value === col) !== undefined
    );
  }

  headers: object[] = [
    {
      align: "left",
      sortable: false,
      visibilityToggle: false,
      index: -1
    },
    {
      text: "Id",
      align: "left",
      sortable: false,
      value: "Id",
      visibilityToggle: false,
      index: 0
    },
    {
      text: "Name",
      align: "left",
      sortable: false,
      value: "Name",
      visibilityToggle: true,
      index: 1
    },
    {
      text: "LTS_IES_SchoolId",
      align: "left",
      sortable: false,
      value: "LTS_IES_SchoolId",
      visibilityToggle: true,
      index: 2
    },
    {
      text: "Country",
      align: "left",
      sortable: false,
      value: "Country",
      visibilityToggle: true,
      index: 3
    },
    {
      text: "Province",
      align: "left",
      sortable: false,
      value: "Region",
      visibilityToggle: true,
      index: 4
    },
    {
      text: "City",
      align: "left",
      sortable: false,
      value: "City",
      visibilityToggle: true,
      index: 5
    },
    {
      text: "Address",
      align: "left",
      sortable: false,
      value: "Address",
      visibilityToggle: true,
      index: 6
    },
    {
      text: "Physical Address",
      align: "left",
      sortable: false,
      value: "PhysicalAddress",
      visibilityToggle: true,
      index: 7
    },
    {
      text: "PostalCode",
      align: "left",
      sortable: false,
      value: "PostalCode",
      visibilityToggle: true,
      index: 8
    },
    {
      text: "Physical PostalCode",
      align: "left",
      sortable: false,
      value: "PhysicalPostalCode",
      visibilityToggle: true,
      index: 9
    },
    {
      text: "PhoneNumber",
      align: "left",
      sortable: false,
      value: "TelephoneNumber",
      visibilityToggle: true,
      index: 10
    },
    {
      text: "FaxNumber",
      align: "left",
      sortable: false,
      value: "FaxNumber",
      visibilityToggle: true,
      index: 11
    },
    {
      text: "IsLowIncome",
      align: "left",
      sortable: false,
      value: "IsLowIncome",
      visibilityToggle: true,
      index: 12
    },
    {
      text: "IsPrimarilyFnmi",
      align: "left",
      sortable: false,
      value: "IsPrimarilyFnmi",
      visibilityToggle: true,
      index: 12
    },
    {
      text: "RepresentedFnmi",
      align: "left",
      sortable: false,
      value: "RepresentedFnmi",
      visibilityToggle: true,
      index: 13
    },
    {
      text: "GenderDistribution",
      align: "left",
      sortable: false,
      value: "GenderDistribution",
      visibilityToggle: true,
      index: 14
    },
    {
      text: "Language",
      align: "left",
      sortable: false,
      value: "Language",
      visibilityToggle: true,
      index: 15
    },
    {
      text: "Type",
      align: "left",
      sortable: false,
      value: "Type",
      visibilityToggle: true,
      index: 16
    },
    {
      text: "SubType",
      align: "left",
      sortable: false,
      value: "SubType",
      visibilityToggle: true,
      index: 17
    },
    {
      text: "SchoolBoard",
      align: "left",
      sortable: false,
      value: "SchoolBoard",
      visibilityToggle: true,
      index: 18
    },
    {
      text: "Grade Levels",
      align: "left",
      sortable: false,
      value: "GradeLevels",
      visibilityToggle: true,
      index: 19
    },
    {
      text: "Validated",
      align: "left",
      sortable: false,
      value: "Validated",
      visibilityToggle: true,
      index: 20
    },
    {
      text: "DateClosed",
      align: "left",
      sortable: false,
      value: "DateClosed",
      visibilityToggle: true,
      index: 21
    },
    {
      text: "MergedId",
      align: "left",
      sortable: false,
      value: "MergedId",
      visibilityToggle: true,
      index: 22
    },
    {
      text: "Status",
      align: "left",
      sortable: false,
      value: "Status",
      visibilityToggle: true,
      index: 23
    },
    {
      text: "StatusComments",
      align: "left",
      sortable: false,
      value: "StatusComments",
      visibilityToggle: true,
      index: 24
    }
  ];
}
