import BaseType from "../BaseType";
import Guid from "../Guid";
import Site from "../Site/Site";

export default class OutreachRequestSite extends BaseType {
  OutreachRequestId: string = Guid.Empty;
  SiteId: string = "";
  Site: Site | null = null;
  CreatedOn: string = "";
}
