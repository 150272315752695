
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Award from "../../types/Award/Award";
import AwardApiService from "../../services/AwardApiService";
import AwardLocale from "../../types/Award/AwardLocale";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    VMSTextField
  }
})
export default class AwardList extends Vue {
  award: Award = new Award();
  enLocale: AwardLocale = new AwardLocale();
  frLocale: AwardLocale = new AwardLocale();
  awardApiService: AwardApiService = new AwardApiService();
  edit: boolean = false;

  mounted() {
    this.enLocale.Locale = "en";
    this.frLocale.Locale = "fr";
    this.award.Locales.push(this.enLocale);
    this.award.Locales.push(this.frLocale);

    if (this.$route.query.id) {
      this.edit = true;
      let id: string = this.$route.query.id as string;
      this.awardApiService.GetAwardById(id).then(resp => {
        this.award = resp.Value;
        this.enLocale =
          this.award.Locales.find(x => x.Locale === "en") || new AwardLocale();
        this.frLocale =
          this.award.Locales.find(x => x.Locale === "fr") || new AwardLocale();
      });
    }
  }

  onSaveClicked() {
    console.log(this.award);
    if ((this.$refs.form as any).validate()) {
      if (this.edit) {
        this.update();
      } else {
        this.save();
      }
    }
  }

  save() {
    this.awardApiService.CreateAward(this.award).then(resp => {
      if (resp.Success) {
        this.$router.push(`/awards`);
      }
    });
  }

  update() {
    this.awardApiService.UpdateAward(this.award).then(resp => {
      if (resp.Success) {
        this.$router.push(`/awards`);
      }
    });
  }
}
