import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import ProgramSupport from "@/types/ProgramSupport/ProgramSupport";

export default class ProgramSupportApiService extends BaseApiService {
  async GetProgramSupportBySiteId(
    siteId: string,
    activeSeasonOnly: boolean
  ): Promise<UseCaseResponse<ProgramSupport[]>> {
    await this.defineHeader();
    return axios
      .get(
        `${this.url}/api/vms/ProgramSupport?siteId=${siteId}&activeSeasonOnly=${activeSeasonOnly}`
      )
      .then(resp => resp.data as UseCaseResponse<ProgramSupport[]>);
  }

  async GetProgramSupportById(
    id: string
  ): Promise<UseCaseResponse<ProgramSupport>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/ProgramSupport/${id}`)
      .then(resp => resp.data as UseCaseResponse<ProgramSupport>);
  }

  async CreateProgramSupport(
    pSupport: ProgramSupport
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/ProgramSupport`, pSupport)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateProgramSupport(
    pSupport: ProgramSupport
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/ProgramSupport/${pSupport.Id}`, pSupport)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteProgramSupport(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/ProgramSupport/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
