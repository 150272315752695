import BaseApiService from "./BaseApiService";
import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import Site from "@/types/Site/Site";
import SiteOutreachRequestStatus from "@/types/Site/SiteOutreachRequestStatus";

export default class SiteApiService extends BaseApiService {
  async GetSites(): Promise<UseCaseResponse<Site[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Site`)
      .then(resp => resp.data as UseCaseResponse<Site[]>);
  }

  async GetSiteById(siteId: string): Promise<UseCaseResponse<Site>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Site/${siteId}`)
      .then(resp => resp.data as UseCaseResponse<Site>);
  }

  async CreateSite(s: Site): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Site`, s)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateSite(s: Site): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Site/${s.Id}`, s)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteSite(siteId: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Site/${siteId}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async CreateSiteOutreachRequestStatus(
    status: SiteOutreachRequestStatus
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Site/${status.SiteId}/OutreachStatus`, status)
      .then(resp => resp.data as UseCaseResponse<string>);
  }
}
