
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Workshop from "@/types/Workshop/Workshop";
import WorkshopApiService from "@/services/WorkshopApiService";
import Guid from "@/types/Guid";
import WorkshopType from "@/types/Workshop/WorkshopType";
import WorkshopEdit from "@/components/workshops/WorkshopEdit.vue";
import ChildWorkshops from "@/components/workshops/ChildWorkshops.vue";
import WorkshopConfig from "@/types/Workshop/WorkshopConfig";
import SchoolDemographicResponseItem from "@/types/SchoolDemographic/SchoolDemographicResponseItem";
import OrganizationApiService from "@/services/OrganizationApiService";
import EducatorLogItem from "@/types/Educator/EducatorLogItem";
import WorkshopFileUpload from "@/components/workshops/workshopfileupload/WorkshopFileUpload.vue";
import WorkshopFileUploadResponse from "@/types/Workshop/WorkshopFileUpload/WorskhopFileUploadResponse";
import EducatorAuxData from "@/types/Educator/EducatorAuxData";
import EducatorLogHeader from "@/types/Educator/EducatorLogHeader";
import SchoolDemographicResponseHeader from "@/types/SchoolDemographic/SchoolDemographicResponseHeader";
import SiteResponseItem from "@/types/Site/SiteResponseItem";
import SiteResponseHeader from "@/types/Site/SiteResponseHeader";

@Component({
  components: {
    WorkshopEdit,
    ChildWorkshops,
    WorkshopFileUpload
  }
})
export default class WorkshopCreate extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;
  @Prop(String) type: string | undefined;

  workshop: Workshop = new Workshop();
  workshopApiService: WorkshopApiService = new WorkshopApiService();

  workshopType: WorkshopType = new WorkshopType();

  config: WorkshopConfig = new WorkshopConfig();

  orgApiService: OrganizationApiService = new OrganizationApiService();

  uploadFileDialog: boolean = false;
  file: File | null = null;
  fileLoading: boolean = false;

  get WorkshopTypeName(): string {
    return this.type || ""; //"Workshop";
  }

  get HeaderText(): string {
    return `${this.$root.$t("Log")} ${this.$t(this.WorkshopTypeName)}`;
  }

  mounted() {
    this.workshop.CreatedBySiteId = this.siteId || "";
    this.workshopApiService.GetWorkshopTypes().then(resp => {
      let types: WorkshopType[] = resp.Value;

      this.workshopType =
        types.find(x => x.Name === this.WorkshopTypeName) || new WorkshopType();

      this.workshop.WorkshopTypeId = this.workshopType.Id;
      this.workshop.WorkshopType = this.workshopType;

      this.config.BuildMaps(this.workshop.WorkshopType.Name);
    });

    if (this.$route.query.id) {
      let id: string = this.$route.query.id as string;
      this.workshopApiService.GetWorkshopById(id).then(resp => {
        this.workshop = resp.Value;
      });
    } else {
      if (this.siteId) {
        const header: SiteResponseHeader =
          this.workshop.SiteResponseHeader || new SiteResponseHeader();
        const sri: SiteResponseItem = new SiteResponseItem();
        sri.SiteId = this.siteId;
        sri.SiteResponseHeaderId = header.Id;
        header.Items.push(sri);
        this.workshop.SiteResponseHeader = header;
      }
    }
  }

  onFileConfirmed(item: WorkshopFileUploadResponse) {
    let ws: Workshop = JSON.parse(JSON.stringify(this.workshop));

    if (!ws.EducatorLogHeader) ws.EducatorLogHeader = new EducatorLogHeader();
    item.Educators.forEach((e: EducatorAuxData) => {
      let educator: EducatorAuxData = e;
      let logItem: EducatorLogItem = new EducatorLogItem();
      logItem.OrganizationId = educator.OrganizationId;
      logItem.EducatorAuxDataId = educator.Id;
      logItem.EducatorAuxData = educator;

      if (logItem.EducatorAuxData.Organization) {
        logItem.EducatorAuxData.Organization.Address =
          logItem.EducatorAuxData.Organization.Address || "";
        logItem.EducatorAuxData.Organization.TelephoneNumber =
          logItem.EducatorAuxData.Organization.TelephoneNumber || "";
      }

      ws.EducatorLogHeader!.Items.push(logItem);
    });

    if (!ws.SchoolDemographicResponseHeader) {
      ws.SchoolDemographicResponseHeader = new SchoolDemographicResponseHeader();
    }

    item.SchoolDemographics.forEach((sdri: SchoolDemographicResponseItem) => {
      if (sdri.Organization) {
        sdri.Organization.Address = sdri.Organization.Address || "";
        sdri.Organization.TelephoneNumber =
          sdri.Organization.TelephoneNumber || "";
      }

      ws.SchoolDemographicResponseHeader!.Items.push(sdri);
    });

    this.workshop = ws;
    this.uploadFileDialog = false;
  }

  onSaveClick() {
    if ((this.$refs.wsEdit as WorkshopEdit).validate()) {
      if (this.workshop.Id === Guid.Empty) {
        this.workshopApiService.CreateWorkshop(this.workshop).then(resp => {
          if (resp.Success) {
            this.$router.back();
          }
        });
      } else {
        this.workshopApiService.UpdateWorkshop(this.workshop).then(resp => {
          if (resp.Success) {
            this.$router.back();
          }
        });
      }
    }
  }

  checkComplete() {
    if (this.workshop) {
      this.workshopApiService.CheckIsComplete(this.workshop).then(resp => {
        if (resp.Success) {
          this.workshop.IsComplete = resp.Value;
        }
      });
    }
  }
}
