import BaseType from "../BaseType";
import Subject from "../Subject/Subject";
import ScreeningDocumentTrackingItem from "./ScreeningDocumentTrackingItem";

export default class ScreeningDocument extends BaseType {
  BatchId: string = "";
  Status: number = 0;
  Type: number = 0;
  Approved: boolean = false;
  ApprovalDate: string = "";
  NationalOfficeFileDate: string = "";
  ExpiryDate: string = "";
  ScreeningDocumentTrackingItems: ScreeningDocumentTrackingItem[] = [];
  StatusString: string = "";
  TypeString: string = "";
  SubjectId: string = "";
  Subject: Subject | undefined;
}
