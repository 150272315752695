import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import Note from "@/types/Note/Note";

export default class NoteApiService extends BaseApiService {
  async GetNotes(
    targetSubjectId: string | undefined
  ): Promise<UseCaseResponse<Note[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Note?targetSubjectId=${targetSubjectId}`)
      .then(resp => resp.data as UseCaseResponse<Note[]>);
  }

  async GetNoteById(id: string): Promise<UseCaseResponse<Note>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/Note/${id}`)
      .then(resp => resp.data as UseCaseResponse<Note>);
  }

  async CreateNote(note: Note): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/Note`, note)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async UpdateNote(note: Note): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/Note/${note.Id}`, note)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async DeleteNote(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/Note/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
