import Guid from "@/types/Guid";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import SubjectAward from "../Award/SubjectAward";
import BaseType from "../BaseType";
import Note from "../Note/Note";
import VolunteerProfile from "../Profile/VolunteerProfile";
import ScreeningDocument from "../ScreeningDocument/ScreeningDocument";

export default class Subject extends BaseType {
  SSOId: string = Guid.Empty;
  GivenName: string = "";
  FamilyName: string = "";
  SubjectRoles: any;
  SiteRegistrations: SiteRegistration[] = [];
  ScreeningDocuments: ScreeningDocument[] = [];
  VolunteerProfile: VolunteerProfile | undefined;
  Notes: Note[] = [];
  SubjectAwards: SubjectAward[] = [];
  Locale: string = "";
  Email: string = "";

  public get FullName() {
    return `${this.GivenName} ${this.FamilyName}`;
  }
}
