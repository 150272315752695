
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SiteApiService from "@/services/SiteApiService";
import Site from "@/types/Site/Site";
import Kit from "@/types/Kit/Kit";
import KitApiService from "@/services/KitApiService";

@Component
export default class CopyKit extends Vue {
  @Prop(Boolean) value: boolean = false;

  siteApiService = new SiteApiService();
  kitItemApiService: KitApiService = new KitApiService();

  sites: Site[] = [];
  KitItems: Kit[] = [];
  selectedKitId: string | null = null;
  selectedSiteId: string | null = null;
  nationalSiteId: string = "b9cc5bd3-9dfa-45ce-b58a-38ff426da7a1";

  async created() {
    this.sites = (await this.siteApiService.GetSites()).Value;
    await this.loadKitsForSelectedSite();
  }

  mounted() {
    this.selectedSiteId = this.nationalSiteId;
  }

  @Watch("selectedSiteId")
  async onSelectedSiteChanged() {
    await this.loadKitsForSelectedSite();
  }

  async loadKitsForSelectedSite() {
    const kitResponse = await this.kitItemApiService.GetKitItemsBySiteId(
      this.selectedSiteId || ""
    );
    this.KitItems = kitResponse.Value;
  }

  copyKitProperties() {
    if (!this.selectedKitId) {
      return;
    }
    const kitToCopy = this.KitItems.find(k => k.Id === this.selectedKitId);
    if (!kitToCopy) {
      return;
    }

    this.$emit("copy", { ...kitToCopy });
    this.selectedKitId = null;
    this.selectedSiteId = this.nationalSiteId;
    this.closeDialog();
  }

  get copyDialog(): boolean {
    return this.value;
  }

  set copyDialog(value: boolean) {
    this.$emit("closeDialog");
  }

  closeDialog() {
    this.$emit("closeDialog");
  }

  get SortedSites(): Site[] {
    let sorted = this.sites.sort((a, b) => a.Name.localeCompare(b.Name));
    return sorted;
  }
}
