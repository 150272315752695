var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("SiteOutreachStatusHeader")))]),_c('v-layout',{attrs:{"text-xs-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"statusForm"},[_c('VMSSelect',{attrs:{"label":_vm.$t('SiteOutreach.Status'),"items":_vm.outreach_status_options,"rules":[() => _vm.edit_status !== null || _vm.$t('FieldIsRequired')]},model:{value:(_vm.edit_status),callback:function ($$v) {_vm.edit_status=$$v},expression:"edit_status"}}),(_vm.edit_status === _vm.closed_status)?[_c('EnumerationSelect',{attrs:{"label":_vm.$t('SiteOutreach.Reason'),"list":_vm.statusReasonsEnumList,"rules":[
                  () =>
                    (_vm.edit_reason_id && _vm.edit_reason_id.length > 0) ||
                    _vm.$t('FieldIsRequired')
                ]},model:{value:(_vm.edit_reason_id),callback:function ($$v) {_vm.edit_reason_id=$$v},expression:"edit_reason_id"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('Comment'),"rules":[
                  () => _vm.edit_comment.length > 0 || _vm.$t('FieldIsRequired')
                ]},model:{value:(_vm.edit_comment),callback:function ($$v) {_vm.edit_comment=$$v},expression:"edit_comment"}})]:_vm._e()],2)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":_vm.onStatusSaveClicked}},[_vm._v(_vm._s(_vm.$t("Sites.OutreachRequestIntake.UpdateStatus")))])],1)],1),_c('p',{staticClass:"mt-6"},[_vm._v(" "+_vm._s(_vm.$t("SiteOutreach.CurrentStatus"))+": "+_vm._s(_vm.current_status_text)+" ")]),_c('p',{staticClass:"mt-6"},[_vm._v(_vm._s(_vm.$t("SiteOutreach.StatusHistory")))]),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.historic_headers,"items":_vm.status_records},scopedSlots:_vm._u([{key:`item.Reason`,fn:function({ item }){return [(item.Reason)?[_vm._v(" "+_vm._s(_vm.getEnumerationText(item.Reason.Id))+" ")]:_vm._e()]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }