
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSEnumerationApiService from "../../services/VMSEnumerationApiService";
import ConfirmButton from "../ConfirmButton.vue";
import VolunteerLogHeader from "@/types/Volunteers/VolunteerLogHeader";
import VolunteerLogItem from "@/types/Volunteers/VolunteerLogItem";
import VolunteerLogItemHours from "@/types/Volunteers/VolunteerLogItemHours";
import VolunteerLogItemCreate from "./VolunteerLogItemCreate.vue";
import Guid from "@/types/Guid";
import VolunteerHourTypeApiService from "@/services/VolunteerHourTypeApiService";
import VolunteerHourType from "@/types/Volunteers/VolunteerHourType";
import DataObject from "@/types/DataObject";

class DisplayItem {
  LogItem: VolunteerLogItem = new VolunteerLogItem();
  Name: string = "";
  Hours: DataObject = new DataObject();

  constructor(vli: VolunteerLogItem) {
    this.LogItem = vli;
    if (this.LogItem.Subject) {
      this.Name = this.LogItem.Subject.FullName;
    } else if (this.LogItem.AuxData) {
      this.Name = `${this.LogItem.AuxData.FirstName} ${this.LogItem.AuxData.LastName}`;
    }
    this.LogItem.LogHours.forEach((lh: VolunteerLogItemHours) => {
      let htId: string = lh.HourTypeId || "";
      this.Hours[htId] = lh.Hours;
    });
  }
}

@Component({
  components: {
    VolunteerLogItemCreate,
    ConfirmButton
  }
})
export default class VolunteerHours extends Vue {
  @Prop(Object) value!: VolunteerLogHeader | undefined;
  @Prop(String) id: String | undefined;
  @Prop(String) label: String | undefined;
  @Prop(String) hint: String | undefined;
  @Prop(Boolean) disabled: Boolean | undefined;
  @Prop(Array) rules: Array<Function> | undefined;
  @Prop(Array) hourtypes: String[] | undefined;

  hourTypeApiService: VolunteerHourTypeApiService = new VolunteerHourTypeApiService();
  vmsEnumApiService: VMSEnumerationApiService = new VMSEnumerationApiService();
  addDialog: boolean = false;
  logItem: VolunteerLogItem = new VolunteerLogItem();
  itemBackup: VolunteerLogItem = new VolunteerLogItem();
  edit: boolean = false;
  header: VolunteerLogHeader = this.value || new VolunteerLogHeader();
  volHourTypes: VolunteerHourType[] = [];

  get headers(): object[] {
    let ret: object[] = [
      {
        text: this.$root.$t("Name"),
        value: "Name",
        align: "left"
      }
    ];

    this.HourTypes.forEach((ht: VolunteerHourType) => {
      ret.push({
        text: this.$root.$t(ht.Name),
        value: `Hours.${ht.Id}`,
        align: "left"
      });
    });

    ret.push({
      text: "",
      value: "Actions",
      align: "right"
    });

    return ret;
  }

  @Watch("value")
  onValueChanged() {
    this.header = this.value || new VolunteerLogHeader();
  }

  get HourTypeNames(): String[] {
    return this.hourtypes || [];
  }

  get HourTypes(): VolunteerHourType[] {
    return this.volHourTypes.filter(x => this.HourTypeNames.includes(x.Name));
  }

  get DisplayItems(): DisplayItem[] {
    return this.header.LogItems.filter(x => !x.Deleted).map(
      (vli: VolunteerLogItem) => {
        return new DisplayItem(vli);
      }
    );
  }

  mounted() {
    this.hourTypeApiService.GetVolunteerHourTypes().then(resp => {
      this.volHourTypes = resp.Value;
      this.logItem = this.newLogItem();
    });
  }

  newLogItem(): VolunteerLogItem {
    let ret: VolunteerLogItem = new VolunteerLogItem();
    this.HourTypes.forEach((ht: VolunteerHourType) => {
      let hours: VolunteerLogItemHours = new VolunteerLogItemHours();
      hours.HourTypeId = ht.Id;
      ret.LogHours.push(hours);
    });

    return ret;
  }

  saveItemToHeader() {
    if (!this.edit) {
      this.header.LogItems.push(this.logItem);
    } else {
      this.itemBackup.SubjectId = this.logItem.SubjectId;
      this.itemBackup.Subject = this.logItem.Subject;
      this.itemBackup.SiteId = this.logItem.SiteId;
      this.itemBackup.AuxData = this.logItem.AuxData;
      this.itemBackup.LogHours = this.logItem.LogHours;
    }

    this.logItem = this.newLogItem();

    this.$emit("input", this.header);
  }

  onAddClick() {
    this.edit = false;
    this.logItem = this.newLogItem();
    this.addDialog = true;
  }

  onEditClick(item: DisplayItem) {
    this.edit = true;
    this.itemBackup = item.LogItem;
    this.logItem = JSON.parse(JSON.stringify(item.LogItem));
    this.addDialog = true;
  }

  onDeleteConfirmed(item: DisplayItem) {
    if (item.LogItem.Id === Guid.Empty) {
      this.header.LogItems.splice(
        this.header.LogItems.indexOf(item.LogItem),
        1
      );
    } else {
      item.LogItem.Deleted = true;
      item.LogItem.LogHours.forEach(lh => {
        lh.Deleted = true;
      });

      if (item.LogItem.AuxData) {
        item.LogItem.AuxData.Deleted = true;
      }
    }

    this.$emit("input", this.header);
  }

  onCancelClick() {
    this.addDialog = false;
  }

  onSaveLogItem() {
    this.saveItemToHeader();
    this.addDialog = false;
  }

  onSaveAddLogItem() {
    this.saveItemToHeader();
    this.$ShowSnackbar(this.$t("ItemAdded") as string);
  }
}
