export enum WorkshopFilterTypes {
  ActiveSeasonOnly,
  IncompleteLogs,
  MyLogs,
  School,
  Volunteer,
  Educator,
  HasEvaluations
}

export class WorkshopFilter {
  Type: WorkshopFilterTypes = WorkshopFilterTypes.ActiveSeasonOnly;
  Value: any;
  Text: string = "";

  constructor(wft: WorkshopFilterTypes, v: any, t: string) {
    this.Type = wft;
    this.Value = v;
    this.Text = t;
  }

  get Localize(): boolean {
    const localize_types: WorkshopFilterTypes[] = [
      WorkshopFilterTypes.ActiveSeasonOnly,
      WorkshopFilterTypes.IncompleteLogs,
      WorkshopFilterTypes.MyLogs,
      WorkshopFilterTypes.HasEvaluations
    ];

    return localize_types.includes(this.Type);
  }
}
