
import Vue from "vue";
import Component from "vue-class-component";
import SiteApiService from "@/services/SiteApiService";
import Site from "@/types/Site/Site";
import { Prop } from "vue-property-decorator";
import Guid from "@/types/Guid";
import Training from "@/types/Training/Training";
import TrainingApiService from "@/services/TrainingApiService";
import TrainingType from "@/types/Training/TrainingType";
import DatePicker from "@/components/DatePicker.vue";
import VolunteerHours from "@/components/hourlogging/VolunteerHours.vue";
import EnumerationSelect from "@/components/EnumerationSelect.vue";
import EnumerationResponse from "@/components/EnumerationResponse.vue";
import EnumerationApiService from "@/services/EnumerationApiService";
import EnumerationList from "@/types/Enumeration/EnumerationList";
import SeasonApiService from "@/services/SeasonApiService";
import Season from "@/types/Season";
import EnumerationResponseHeader from "@/types/Enumeration/EnumerationResponseHeader";
import TrainingFormConfig from "@/types/Training/TrainingFormConfig";
import AuthorizationService from "@/services/AuthorizationService";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    DatePicker,
    VolunteerHours,
    EnumerationSelect,
    EnumerationResponse,
    VMSTextField
  }
})
export default class TrainingCreate extends Vue {
  @Prop(String) siteId: string | undefined;
  @Prop(String) urlSite: string | undefined;
  @Prop(String) type: string | undefined;

  siteApiService: SiteApiService = new SiteApiService();
  site: Site = new Site();

  training: Training = new Training();
  trainingApiService: TrainingApiService = new TrainingApiService();
  trainingType: TrainingType = new TrainingType();

  enumApiService: EnumerationApiService = new EnumerationApiService();
  trainingDeliveryEnumList: EnumerationList = new EnumerationList();
  conferenceEnumList: EnumerationList = new EnumerationList();
  deliveryFormatEnumList: EnumerationList = new EnumerationList();
  skillsEnumList: EnumerationList = new EnumerationList();

  externalFacilitators: boolean = false;

  seasonApiService: SeasonApiService = new SeasonApiService();
  unlockedSeasons: Season[] = [];
  formConfig: TrainingFormConfig = new TrainingFormConfig();

  pageLoading: boolean = true;

  authService: AuthorizationService = AuthorizationService.getInstance();

  get EmptyGuid(): string {
    return Guid.Empty;
  }

  get TrainingTypeName(): string {
    return this.type || "";
  }

  get HeaderText(): string {
    return this.$t(`log_${this.TrainingTypeName}`) as string;
  }

  mounted() {
    this.trainingApiService
      .All([
        this.siteApiService.GetSiteById(this.siteId || ""),
        this.trainingApiService.GetTrainingTypes(),
        this.enumApiService.GetEnumerationListsBySystemName([
          "TrainingDelivered",
          "TrainingDeliveryFormats",
          "SkillsAndCompetencies",
          "Conferences"
        ]),
        this.seasonApiService.GetSeasons()
      ])
      .then(resps => {
        this.site = resps[0].Value;

        let types: TrainingType[] = resps[1].Value;
        this.trainingType =
          types.find(x => x.Name === this.TrainingTypeName) ||
          new TrainingType();
        this.training.TrainingTypeId = this.trainingType.Id;
        this.training.TrainingType = this.trainingType;

        this.formConfig.BuildConfig(this.training.TrainingType.Name);

        if (
          ["SiteSpecificTraining", "SiteBasedPDOpportunity"].includes(
            this.trainingType.Name
          )
        ) {
          this.training.TrainingDeliveredId =
            "958cf867-4ca6-4d85-975d-9a727b9c5262"; //other
        }

        (this.$refs.form as any).resetValidation();

        let lists: EnumerationList[] = resps[2].Value;

        this.trainingDeliveryEnumList =
          lists.find(x => x.SystemName === "TrainingDelivered") ||
          new EnumerationList();
        this.deliveryFormatEnumList =
          lists.find(x => x.SystemName === "TrainingDeliveryFormats") ||
          new EnumerationList();
        this.skillsEnumList =
          lists.find(x => x.SystemName === "SkillsAndCompetencies") ||
          new EnumerationList();
        this.conferenceEnumList =
          lists.find(x => x.SystemName === "Conferences") ||
          new EnumerationList();

        let seasons: Season[] = resps[3].Value;
        this.unlockedSeasons = seasons.filter(x => !x.Locked);

        this.pageLoading = false;
      });

    if (this.$route.query.id) {
      let id: string = this.$route.query.id as string;
      this.trainingApiService.GetTrainingById(id).then(resp => {
        this.training = resp.Value;
        if (this.training.ExternalFacilitators) {
          this.externalFacilitators = true;
        }
      });
    }
  }

  validateDate(datestr: string): boolean {
    let checkdate: Date = this.parseDate(datestr);
    let dateInSeason: boolean = false;

    if (this.authService.hasRole(["Administrator", "Staff"])) {
      dateInSeason = true;
    } else {
      this.unlockedSeasons.forEach((s: Season) => {
        let sd: Date = this.parseDate(s.StartDate);
        let ed: Date = this.parseDate(s.EndDate);

        if (
          checkdate.getTime() >= sd.getTime() &&
          checkdate.getTime() <= ed.getTime()
        ) {
          dateInSeason = true;
        }
      });
    }

    return dateInSeason;
  }

  parseDate(datestr: string): Date {
    let parts: string[] = datestr.split("-");
    let year: number = parseInt(parts[0]);
    let monthIdx: number = parseInt(parts[1]) - 1;
    let day: number = parseInt(parts[2]);

    return new Date(year, monthIdx, day);
  }

  isFieldEnabled(field: string): boolean {
    return !this.formConfig.HiddenFields.includes(field);
  }

  isEnumResponseHeaderValid(header: EnumerationResponseHeader | undefined) {
    if (!header) {
      return false;
    } else {
      return header.Items.length > 0;
    }
  }

  onSaveClick() {
    if ((this.$refs.form as any).validate()) {
      if (this.training.Id === Guid.Empty) {
        this.training.SiteId = this.siteId || "";
        this.trainingApiService.CreateTraining(this.training).then(resp => {
          if (resp.Success) {
            this.$router.push(`/${this.urlSite}/Training`);
          }
        });
      } else {
        this.trainingApiService.UpdateTraining(this.training).then(resp => {
          if (resp.Success) {
            this.$router.push(`/${this.urlSite}/Training`);
          }
        });
      }
    }
  }
}
