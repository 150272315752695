
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AuthorizationService from "../services/AuthorizationService";
import PostApiService from "../services/PostApiService";
import Post from "../types/Post/Post";
import ConfirmButton from "../components/ConfirmButton.vue";
import SiteApiService from "../services/SiteApiService";
import SeasonApiService from "../services/SeasonApiService";
import Subject from "@/types/Subject/Subject";
import SubjectApiService from "@/services/SubjectApiService";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";
import CoordinatorDashboard from "@/components/CoordinatorDashboard.vue";

class site {
  Id: string = "";
  Visible: boolean = false;
  Name: string = "";

  constructor(id: string, n: string) {
    this.Id = id;
    this.Name = n;
    this.Visible = true;
  }
}

@Component({
  components: {
    ConfirmButton,
    VMSTextArea,
    VMSSelect,
    CoordinatorDashboard
  }
})
export default class Home extends Vue {
  @Prop(Object) site_data: object | undefined;

  postApiService: PostApiService = new PostApiService();
  siteApiService: SiteApiService = new SiteApiService();
  seasonApiService: SeasonApiService = new SeasonApiService();
  post_site_id: string = "";
  posts: Post[] = [];
  newPost: Post = new Post();
  editPost: Post = new Post();
  postDialog: boolean = false;
  renderKey: number = 0;
  subject: Subject = new Subject();

  authorizationService: AuthorizationService = AuthorizationService.getInstance();
  subjectApiService: SubjectApiService = new SubjectApiService();
  subjectLoaded: boolean = false;

  pendingSites: string[] = [];
  enroledSites: site[] = [];
  pastSites: string[] = [];

  get StaffAdmin() {
    return this.authorizationService.hasRole(["Administrator", "Staff"]);
  }

  get Coordinator() {
    return this.authorizationService.hasRole(["Coordinator"]);
  }

  get FilteredPosts(): Post[] {
    const enabledSiteIds: string[] = this.enroledSites
      .filter(x => x.Visible === true)
      .map(x => x.Id);
    return this.posts.filter(
      x => enabledSiteIds.includes(x.SiteId) || !x.SiteId
    );
  }

  canEdit(p: Post): boolean {
    if (!p.SiteId) return this.StaffAdmin;
    else return this.Coordinator;
  }

  getSiteName(siteId: string): string {
    const s: site | undefined = this.enroledSites.find(x => x.Id === siteId);
    if (s) {
      return s.Name || (this.$t("National") as string);
    } else {
      return "";
    }
  }

  postModified(p: Post): boolean {
    const cTime: number = new Date(p.CreatedOn).getTime() / 1000;
    const mTime: number = new Date(p.ModifiedOn).getTime() / 1000;
    return Math.floor(cTime) !== Math.floor(mTime);
  }

  mounted() {
    this.getPosts();
    this.getSubject();
  }

  getSubject() {
    this.subjectApiService
      .GetSubjectById(this.$VmsLoggedInSubjectId)
      .then(resp => {
        this.subject = resp.Value;
        this.subjectLoaded = true;

        this.enroledSites = this.subject.SiteRegistrations.filter(
          sr => sr.Season && sr.Season.Active && sr.IsApproved
        ).map((sr: SiteRegistration) => {
          let sitename: string = sr.Site ? sr.Site.Name : "";
          return new site(sr.SiteId, sitename);
        });

        this.pastSites = this.subject.SiteRegistrations.filter(
          sr => sr.Season && !sr.Season.Active && sr.IsApproved
        ).map((sr: SiteRegistration) => {
          return sr.Id;
        });

        this.pendingSites = this.subject.SiteRegistrations.filter(
          sr => !sr.IsApproved && !sr.ApprovalDate
        ).map(sr => {
          return sr.Site ? sr.Site.Name : "";
        });
      });
  }

  getPosts() {
    this.postApiService
      .GetPostsBySubjectId(this.$VmsLoggedInSubjectId)
      .then(resp => {
        this.posts = [];
        this.posts = resp.Value;
      });
  }

  onCancelPostClicked() {
    this.postDialog = false;
    this.newPost = new Post();
  }

  onAddPostClicked() {
    this.newPost.SiteId = this.post_site_id;
    console.log(this.newPost.SiteId);
    this.postApiService.CreatePost(this.newPost).then(resp => {
      if (resp.Success) {
        this.getPosts();
        this.newPost = new Post();
      }

      this.postDialog = false;
    });
  }

  onDeletePostConfirmed(postId: string) {
    this.postApiService.DeletePost(postId).then(resp => {
      if (resp.Success) this.getPosts();
    });
  }

  onEditPostClicked(p: Post) {
    this.editPost.Id = p.Id;
    this.editPost.SiteId = p.SiteId;
    this.editPost.Text = p.Text;
    this.editPost.Pinned = p.Pinned;
  }

  onEditPostCancelClicked() {
    this.editPost = new Post();
  }

  onEditPostSaveClicked() {
    this.postApiService.UpdatePost(this.editPost).then(resp => {
      if (resp.Success) {
        this.editPost = new Post();
        this.getPosts();
      }
    });
  }
}
