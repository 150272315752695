
import SubjectApiService from "@/services/SubjectApiService";
import Subject from "@/types/Subject/Subject";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ScreeningDocumentApiService from "../../services/ScreeningDocumentApiService";
import DataObject from "../../types/DataObject";
import ScreeningDocument from "../../types/ScreeningDocument/ScreeningDocument";
import ScreeningDocumentBatchCreateRequest from "../../types/ScreeningDocument/ScreeningDocumentBatchCreateRequest";
import ScreeningDocumentEdit from "./ScreeningDocumentEdit.vue";
import VMSTextField from "@/components/fields/VMSTextField.vue";

@Component({
  components: {
    ScreeningDocumentEdit,
    VMSTextField
  }
})
export default class ScreeningDocumentBatchCreate extends Vue {
  @Prop(String) siteId: string | undefined;

  docTypeItems: object[] = [];
  docStatusItems: object[] = [];

  screeningDocApiService: ScreeningDocumentApiService = new ScreeningDocumentApiService();
  createRequest: ScreeningDocumentBatchCreateRequest = new ScreeningDocumentBatchCreateRequest();
  screeningDoc: ScreeningDocument = new ScreeningDocument();

  subjectApiService: SubjectApiService = new SubjectApiService();
  subjects: Subject[] = [];
  selectedSubjects: Subject[] = [];
  subjectsLoading: boolean = false;
  subjectSearch: string = "";

  subjectHeaders: object[] = [
    {
      text: this.$root.$t("Name"),
      align: "left",
      value: "FullName"
    },
    {
      text: this.$root.$t("Email"),
      align: "left",
      value: "Email"
    }
  ];

  mounted() {
    this.screeningDocApiService
      .All([
        this.screeningDocApiService.GetScreeningDocumentTypes(),
        this.screeningDocApiService.GetScreeningDocumentStatus()
      ])
      .then(resps => {
        const docTypes: DataObject = resps[0].Value;
        const docStatus: DataObject = resps[1].Value;

        for (let idx in docTypes) {
          this.docTypeItems.push({
            text: this.$t(docTypes[idx]),
            value: Number.parseInt(idx)
          });
        }

        for (let idx in docStatus) {
          this.docStatusItems.push({
            text: this.$t(docStatus[idx]),
            value: Number.parseInt(idx)
          });
        }
      });

    if (this.siteId) {
      this.subjectsLoading = true;
      this.subjectApiService.GetSubjectsBySite(this.siteId).then(resp => {
        this.subjects = resp.Value;
        this.subjectsLoading = false;
      });
    }
  }

  onSaveClick() {
    this.createRequest.Status = this.screeningDoc.Status;
    this.createRequest.Type = this.screeningDoc.Type;
    this.createRequest.NationalOfficeFileDate = this.screeningDoc.NationalOfficeFileDate;
    this.createRequest.Approved = this.screeningDoc.Approved;
    this.createRequest.ExpiryDate = this.screeningDoc.ExpiryDate;

    this.createRequest.SubjectIds = this.selectedSubjects.map(s => {
      return s.Id;
    });

    this.screeningDocApiService
      .CreateScreeningDocumentBatch(this.createRequest)
      .then(resp => {
        if (resp.Success) {
          this.createRequest = new ScreeningDocumentBatchCreateRequest();
          this.screeningDoc = new ScreeningDocument();
          this.selectedSubjects = [];
          this.$emit("saved", resp.Value);
        } else {
          console.log(resp);
        }
      });
  }

  onCancelClick() {
    this.$emit("cancel");
  }
}
