
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import OrganizationApiService from "../../services/OrganizationApiService";
import VMSAutoComplete from "@/components/fields/VMSAutoComplete.vue";
import GetOrganizationResponse from "@/types/Organization/GetOrganizationResponse";
import Guid from "@/types/Guid";
import { PagedQueryRequestFiltered } from "@/types/Pocos/Pagination/PagedQueryRequest";
import QueryOrganizationsRequest from "@/types/Organization/QueryOrganizationsRequest";

@Component({
  components: {
    VMSAutoComplete
  }
})
export default class SchoolLookup extends Vue {
  @Prop(String) value!: string | undefined;
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop(String) placeholder: string | undefined;
  @Prop(String) hint: string | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(Array) rules: Function[] | undefined;
  @Prop(String) typeId: string | undefined;

  apiService: OrganizationApiService = new OrganizationApiService();
  displayItems: object[] = [];
  searchText: string | null = "";
  selectedId: string = this.value || "";
  loading: boolean = false;
  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;

  query: PagedQueryRequestFiltered<
    QueryOrganizationsRequest
  > = new PagedQueryRequestFiltered<QueryOrganizationsRequest>(
    new QueryOrganizationsRequest()
  );

  @Watch("value")
  onValueChanged() {
    this.selectedId = this.value || "";
    this.getById();
  }

  mounted() {
    this.getById();
  }

  getById() {
    if (
      !this.selectedId ||
      this.selectedId.length === 0 ||
      this.selectedId === Guid.Empty
    )
      return;

    this.loading = true;
    this.apiService
      .GetOrganizationById(this.selectedId)
      .then(resp => {
        this.displayItems = [];
        let org: GetOrganizationResponse = resp.Value;
        this.displayItems.push({
          text: `${org.Name} - ${org.City}`,
          value: org.Id,
          data: org
        });

        this.loading = false;
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  onKeyUp() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.searchText && this.searchText.length > 0)
      this.timeout = setTimeout(this.getData, this.typeTimer);
  }

  onInput() {
    this.$emit("input", this.selectedId);

    let di: any = this.displayItems.find(
      x => (x as any).value === this.selectedId
    );

    if (di && di.data) {
      this.$emit("itemselected", di.data);
    }
  }

  getData() {
    this.loading = true;
    if (this.searchText && this.searchText.length > 0) {
      this.query.Search = this.searchText || "";
      this.query.Filter.TypeId = this.typeId || null;
      this.query.Filter.Status = [0, 1];
      this.apiService.QueryOrganizations(this.query).then(resp => {
        this.displayItems = [];
        resp.Value.forEach(element => {
          this.displayItems.push({
            text: `${element.Name} - ${element.City}`,
            value: element.Id,
            data: element
          });
        });

        this.loading = false;
      });
    }
  }

  Clear() {
    (this.$refs.vmsAutoComplete as VMSAutoComplete).Clear();
  }
}
