
import AuthorizationService from "@/services/AuthorizationService";
import OutreachRequestApiService from "@/services/OutreachRequestApiService";
import SiteApiService from "@/services/SiteApiService";
import OutreachRequestSite from "@/types/OutreachRequest/OutreachRequestSite";
import { RequestStatus } from "@/types/OutreachRequest/OutreachRequestStatus";
import Site, { SiteType } from "@/types/Site/Site";
import { SiteRequestStatus } from "@/types/Site/SiteOutreachRequestStatus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    VMSSelect
  }
})
export default class OutreachRequestSiteUpdateDialog extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(Array) items!: any[];

  show: boolean = this.value || false;
  edit_requests: any[] = [];
  sites: Site[] = [];
  edit_site_id: string = "";

  authService: AuthorizationService = AuthorizationService.getInstance();
  outreachApiService: OutreachRequestApiService = new OutreachRequestApiService();
  siteApiService: SiteApiService = new SiteApiService();

  @Watch("value")
  onValueChanged() {
    this.show = this.value || false;
    this.edit_site_id = "";
  }

  @Watch("items")
  onRequestsChanged() {
    this.edit_requests = this.items;
  }

  get staff_admin(): boolean {
    return this.authService.hasRole(["Administrator", "Staff"]);
  }

  get site_options(): any[] {
    const not_pending_waitlisted: number = this.edit_requests.filter(
      r =>
        r.Status !== RequestStatus.Pending &&
        r.Status !== RequestStatus.Waitlisted
    ).length;

    return this.sites
      .filter(
        s =>
          not_pending_waitlisted === 0 ||
          this.staff_admin ||
          s.Type === SiteType.National
      )
      .map(s => {
        let site_name: string = s.Name;
        switch (s.LatestOutreachRequestStatus.Status) {
          case SiteRequestStatus.Closed:
            site_name += ` (${this.$t("SiteOutreachStatus.Closed")})`;
            break;
          case SiteRequestStatus.Waitlisted:
            site_name += ` (${this.$t("SiteOutreachStatus.Waitlisted")})`;
            break;
        }

        return {
          text: site_name,
          value: s.Id
        };
      });
  }

  mounted() {
    this.siteApiService.GetSites().then(resp => {
      if (resp.Success) {
        this.sites = resp.Value;
      }
    });
  }

  onUpdateSiteClicked() {
    if (!(this.$refs.site_form as any).validate()) return;
    else if (!this.edit_site_id) return;
    else {
      const sites = this.edit_requests.map((or: any) => {
        const ret = new OutreachRequestSite();
        ret.OutreachRequestId = or.Id;
        ret.SiteId = this.edit_site_id;
        return ret;
      });

      this.outreachApiService.CreateRequestSites(sites).then(resp => {
        if (resp.Success) {
          this.$emit("updated");
        }
      });
    }
  }

  onCancelClicked() {
    this.$emit("canceled");
  }
}
