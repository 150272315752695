import axios from "axios";
import SecurityService from "@/services/SecurityService";
import ConfigurationService from "@/services/ConfigurationService";

export default abstract class BaseApiService {
  securityService: SecurityService = SecurityService.getInstance();
  url: string = ConfigurationService.getInstance().getUrl("ltsol_apis");

  async defineHeader() {
    await this.securityService.getAccessToken().then(
      accessToken => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
      },
      err => {
        console.log(err);
      }
    );
  }

  async All(promises: any[]) {
    this.defineHeader();
    return axios.all(promises);
  }
}
