import BaseType from "../BaseType";
import EnumerationResponseHeader from "../Enumeration/EnumerationResponseHeader";
import UpdateOrganizationRequest from "../Organization/UpdateOrganizationRequest";
import EducatorSiteHeader from "./EducatorSiteHeader";

export default class EducatorAuxData extends BaseType {
  SubjectId: string = "";
  FirstName: string = "";
  LastName: string = "";
  Email: string = "";
  PhoneNumber: string = "";
  OrganizationId: string = "";
  LanguagesHeaderId: string = "";
  LanguagesHeader: EnumerationResponseHeader | undefined;
  GradesHeaderId: string = "";
  GradesHeader: EnumerationResponseHeader | undefined;
  EducatorSiteHeaderId: string | undefined;
  EducatorSiteHeader: EducatorSiteHeader | undefined;

  //not mapped
  Organization: UpdateOrganizationRequest | undefined;
}
