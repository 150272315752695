
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EmailApiService from "../../services/EmailApiService";
import Email, { EmailType } from "../../types/Email/Email";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import EmailLocalization from "../../types/Email/EmailLocalization";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

class EmailWrapper {
  Email: Email = new Email();
  Subject: string = "";
  TypeString: string = "";

  get ApplyHTMLBranding(): boolean {
    return this.Email.ApplyHTMLBranding;
  }

  constructor(e: Email, s: string, ts: string) {
    this.Email = e;
    this.Subject = s;
    this.TypeString = ts;
  }
}

@Component({
  components: {
    VMSTextField,
    ConfirmDialog
  }
})
export default class EmailList extends Vue {
  //@Prop(Boolean) value: Boolean | undefined;
  emailApiService: EmailApiService = new EmailApiService();
  emailWrappers: EmailWrapper[] = [];
  headers: object[] = [
    {
      text: this.$root.$t("EmailType"),
      value: "TypeString"
    },
    {
      text: this.$root.$t("Subject"),
      value: "Subject"
    },
    {
      text: this.$root.$t("ApplyHTMLBranding"),
      value: "ApplyHTMLBranding"
    },
    {
      text: "",
      value: "Actions"
    }
  ];

  test_dialog: boolean = false;
  test_recipient: string = "";
  test_item: EmailWrapper | null = null;

  delete_item: EmailWrapper | null = null;
  confirm_delete: boolean = false;

  mounted() {
    this.getData();
  }

  getData() {
    this.emailWrappers = [];
    this.emailApiService.GetEmails().then(resp => {
      let emails: Email[] = resp.Value;
      let loc: string = this.$i18n.locale;
      let wrappers: EmailWrapper[] = [];
      emails.forEach((e: Email) => {
        let el: EmailLocalization =
          e.Localizations.find(l => l.Locale === loc) || e.Localizations[0];
        let ts: string = this.$t(
          `EmailType.${EmailType[e.EmailType]}`
        ) as string;
        wrappers.push(new EmailWrapper(e, el.Subject, ts));
      });

      this.emailWrappers = wrappers;
    });
  }

  onEditItemClicked(item: EmailWrapper) {
    this.$router.push(`/email/update?id=${item.Email.Id}`);
  }

  onDeleteItemClicked(item: EmailWrapper) {
    this.delete_item = item;
    this.confirm_delete = true;
  }

  onDeleteConfirmed() {
    if (this.delete_item) {
      this.emailApiService.DeleteEmail(this.delete_item.Email.Id).then(resp => {
        if (resp.Success) {
          this.getData();
        }
      });
    }
  }

  onSendTestClicked(item: EmailWrapper) {
    this.test_item = item;
    this.test_dialog = true;
  }

  onCreateClicked() {
    this.$router.push(`/email/create`);
  }

  closeTestDialog() {
    this.test_recipient = "";
    this.test_dialog = false;
  }

  onSendTestConfirmed() {
    if (this.test_item && this.test_item.Email && this.test_item.Email.Id) {
      this.emailApiService.SendTestEmail(
        this.test_item.Email.Id,
        this.test_recipient
      );

      this.closeTestDialog();
    }
  }
}
