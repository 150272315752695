
import Enumeration from "@/types/Enumeration/Enumeration";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SiteRegistrationTable extends Vue {
  @Prop(Object) value: SiteRegistration | undefined;

  registration: SiteRegistration = this.value || new SiteRegistration();

  @Watch("value")
  onValueChanged() {
    this.registration = this.value || new SiteRegistration();
  }

  localizeEnum(e: Enumeration): string | undefined {
    if (!e) {
      return "";
    } else {
      let ls: string = this.$root.$t(e.LocalizationKey).toString();
      return ls;
    }
  }
}
