var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-first-name` : undefined,"label":_vm.$t('FirstName'),"rules":[
      () => _vm.educator.FirstName.length > 0 || `${_vm.$t('FieldIsRequired')}`
    ]},model:{value:(_vm.educator.FirstName),callback:function ($$v) {_vm.$set(_vm.educator, "FirstName", $$v)},expression:"educator.FirstName"}}),_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-last-name` : undefined,"label":_vm.$t('LastName'),"rules":[
      () => _vm.educator.LastName.length > 0 || `${_vm.$t('FieldIsRequired')}`
    ]},model:{value:(_vm.educator.LastName),callback:function ($$v) {_vm.$set(_vm.educator, "LastName", $$v)},expression:"educator.LastName"}}),_c('VMSTextField',{attrs:{"id":_vm.id ? `${_vm.id}-email` : undefined,"label":_vm.$t('Email'),"rules":[() => _vm.validateEmail(_vm.educator.Email)]},model:{value:(_vm.educator.Email),callback:function ($$v) {_vm.$set(_vm.educator, "Email", $$v)},expression:"educator.Email"}}),_c('PhoneNumberInput',{attrs:{"id":_vm.id ? `${_vm.id}-phone` : undefined,"label":_vm.$t('PhoneNumber')},model:{value:(_vm.educator.PhoneNumber),callback:function ($$v) {_vm.$set(_vm.educator, "PhoneNumber", $$v)},expression:"educator.PhoneNumber"}}),_c('EnumerationResponse',{attrs:{"id":_vm.id ? `${_vm.id}-language` : undefined,"label":_vm.$t('LanguageOfClassGroup'),"multiple":true,"list":_vm.languagesEnumList},model:{value:(_vm.educator.LanguagesHeader),callback:function ($$v) {_vm.$set(_vm.educator, "LanguagesHeader", $$v)},expression:"educator.LanguagesHeader"}}),_c('EnumerationResponse',{attrs:{"id":_vm.id ? `${_vm.id}-grades` : undefined,"label":_vm.$t('GradesOfStudents'),"list":_vm.gradesEnumList,"multiple":true},model:{value:(_vm.educator.GradesHeader),callback:function ($$v) {_vm.$set(_vm.educator, "GradesHeader", $$v)},expression:"educator.GradesHeader"}}),_c('v-switch',{attrs:{"id":_vm.id ? `${_vm.id}-create-school-switch` : undefined,"label":_vm.$t('CreateNewSchoolOrganization')},model:{value:(_vm.cAddOrg),callback:function ($$v) {_vm.cAddOrg=$$v},expression:"cAddOrg"}}),(!_vm.cAddOrg)?_c('SchoolLookup',{attrs:{"id":_vm.id ? `${_vm.id}-school-lookup` : undefined,"label":_vm.$t('SchoolOrganization'),"placeholder":_vm.$t('AutocompletePlaceholderText'),"rules":[
      () => _vm.educator.OrganizationId.length > 0 || `${_vm.$t('FieldIsRequired')}`
    ]},model:{value:(_vm.educator.OrganizationId),callback:function ($$v) {_vm.$set(_vm.educator, "OrganizationId", $$v)},expression:"educator.OrganizationId"}}):_c('SchoolEdit',{attrs:{"id":_vm.id ? `${_vm.id}-school-edit` : undefined},model:{value:(_vm.educator.Organization),callback:function ($$v) {_vm.$set(_vm.educator, "Organization", $$v)},expression:"educator.Organization"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }