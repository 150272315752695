var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.classes.filter(x => !x.Deleted)),function(c,c_idx){return _c('v-card',{key:c_idx,staticStyle:{"margin-bottom":"10px"}},[(_vm.classes.filter(x => !x.Deleted).length > 1)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("OutreachRequest.Class"))+" "+_vm._s(c_idx + 1)+" "),_c('ConfirmButton',{staticStyle:{"padding-left":"8px","padding-bottom":"2px"},attrs:{"tooltip":_vm.$t('Delete'),"icon":"mdi-delete"},on:{"confirmed":function($event){return _vm.onDeleteClassConfirmed(c)}}},[_vm._v(" "+_vm._s(_vm.$t("OutreachRequest.DeleteClass"))+" ")])],1):_vm._e(),_c('v-card-text',[_c('div',[_c('span',{staticStyle:{"font-size":"20px","color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(_vm._s(_vm.$t("OutreachRequest.Grades")))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"margin-bottom":"6px","margin-left":"5px"},on:{"click":function($event){return _vm.onAddGradeClicked(c)}}},on),[_vm._v("mdi-plus-box")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Add")))])])],1),_vm._l((c.Grades.filter(x => !x.Deleted)),function(g,idx){return _c('div',{key:idx,staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.$t("OutreachRequest.Grade"))+" "),_c('v-select',{attrs:{"dense":"","items":_vm.grade_options,"rules":[
              () =>
                (g.GradeId && g.GradeId.length > 0) || _vm.$t('FieldIsRequired')
            ]},model:{value:(g.GradeId),callback:function ($$v) {_vm.$set(g, "GradeId", $$v)},expression:"g.GradeId"}})],1),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.$t("OutreachRequest.NumStudents"))+" "),_c('v-text-field',{attrs:{"dense":"","rules":[
              () =>
                (g.NumStudents && g.NumStudents > 0) ||
                _vm.$t('OutreachRequest.StudentsGreaterThanZero')
            ]},model:{value:(g.NumStudents),callback:function ($$v) {_vm.$set(g, "NumStudents", _vm._n($$v))},expression:"g.NumStudents"}})],1),_c('div',{staticClass:"col",staticStyle:{"margin-top":"35px"}},[(c.Grades.length > 1)?_c('ConfirmButton',{attrs:{"tooltip":_vm.$t('Delete'),"icon":"mdi-delete"},on:{"confirmed":function($event){return _vm.onDeleteGradeConfirmed(c, g)}}},[_vm._v(" "+_vm._s(_vm.$t("OutreachRequest.DeleteGrade"))+" ")]):_vm._e()],1)])}),_c('VMSSelect',{attrs:{"label":_vm.$t('OutreachRequest.Kit'),"items":_vm.kit_options},model:{value:(c.KitId),callback:function ($$v) {_vm.$set(c, "KitId", $$v)},expression:"c.KitId"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('OutreachRequest.AdditionalInfo')},model:{value:(c.AdditionalInfo),callback:function ($$v) {_vm.$set(c, "AdditionalInfo", $$v)},expression:"c.AdditionalInfo"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('OutreachRequest.Topic')},model:{value:(c.Topic),callback:function ($$v) {_vm.$set(c, "Topic", $$v)},expression:"c.Topic"}})],2),_c('v-card-actions',[(c_idx === _vm.classes.length - 1)?_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":_vm.onAddClassClicked}},[_vm._v(_vm._s(_vm.$t("OutreachRequest.AddClass")))]):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }