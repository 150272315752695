
import Vue from "vue";
import Component from "vue-class-component";
import Season from "../../types/Season";
import SeasonApiService from "../../services/SeasonApiService";

class SeasonWrapper {
  Season: Season = new Season();
  Active: boolean = false;
  Locked: boolean = false;

  get StartDate(): Date {
    return new Date(this.Season.StartDate);
  }

  get EndDate(): Date {
    return new Date(this.Season.EndDate);
  }

  constructor(s: Season) {
    this.Season = s;
    this.Active = s.Active;
    this.Locked = s.Locked;
  }
}

@Component
export default class SeasonList extends Vue {
  // Data item with default value
  headers: Object[] = [
    {
      text: this.$root.$t("Active"),
      value: "Active"
    },
    {
      text: this.$root.$t("Locked"),
      value: "Locked"
    },
    {
      text: this.$root.$t("StartDate"),
      align: "left",
      value: "StartDate",
      sort: this.$DateSort
    },
    {
      text: this.$root.$t("EndDate"),
      align: "left",
      value: "EndDate",
      sort: this.$DateSort
    }
  ];

  seasonWrappers: SeasonWrapper[] = [];
  activeSeasonId: string = "";
  seasonApiService: SeasonApiService = new SeasonApiService();

  mounted() {
    this.getData();
  }

  get UnsavedChanges(): SeasonWrapper[] {
    let changes: SeasonWrapper[] = this.seasonWrappers.filter(
      sw => sw.Active !== sw.Season.Active || sw.Locked !== sw.Season.Locked
    );

    return changes;
  }

  activeSeasonChanged(seasonId: string) {
    this.seasonWrappers.forEach((sw: SeasonWrapper) => {
      if (sw.Season.Id !== seasonId) sw.Active = false;
    });
  }

  getData() {
    this.seasonWrappers = [];
    this.activeSeasonId = "";

    this.seasonApiService.GetSeasons().then(resp => {
      let seasons = resp.Value;
      const activeSeason: Season | undefined = seasons.find(
        x => x.Active === true
      );

      seasons.forEach((s: Season) => {
        this.seasonWrappers.push(new SeasonWrapper(s));
      });

      if (activeSeason) {
        this.activeSeasonId = activeSeason.Id;
      }
    });
  }

  onCreateClick() {
    this.$router.push("/seasons/create");
  }

  onSaveActiveSeasonClick() {
    let changes: Season[] = this.UnsavedChanges.map((sw: SeasonWrapper) => {
      let ret: Season = new Season();
      ret.Id = sw.Season.Id;
      ret.StartDate = sw.Season.StartDate;
      ret.EndDate = sw.Season.EndDate;
      ret.Locked = sw.Locked;
      ret.Active = sw.Active;
      return ret;
    });

    this.seasonApiService.Patch(changes).then(resp => {
      this.getData();
    });
  }
}
