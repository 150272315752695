
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VMSTextArea from "@/components/fields/VMSTextArea.vue";

@Component({
  components: {
    VMSTextArea
  }
})
export default class EmailDialog extends Vue {
  @Prop(Boolean) value: boolean | undefined;
  @Prop(String) emails: string | undefined;

  visible: boolean = this.value || false;

  @Watch("value")
  onValueChanged() {
    this.visible = this.value || false;
  }

  get Emails(): string {
    return this.emails || "";
  }

  onCopyToClipboardClick() {
    navigator.clipboard.writeText(this.Emails).then(p => {
      this.visible = false;
      this.$emit("input", this.visible);
    });
  }

  onCloseClick() {
    this.visible = false;
    this.$emit("input", this.visible);
  }
}
