import LoggingApiService, { LogLevel } from "@/services/LoggingApiService";

class ApiRequest {
  private static max_id: number = 0;

  Id: number;
  RequestUrl: string;
  RequestMethod: string;
  RequestData: string | null;
  ResponseStatus: number | null = null;
  ResponseData: any | null = null;
  Start: number;
  End: number | null = null;
  ElapsedMS: number = -1;
  FailureMsg: string = "";
  UserId: string = "";
  Route: string = "";

  get IsFailure(): boolean {
    return this.FailureMsg.length > 0;
  }

  get IsLogMsg(): boolean {
    return (
      this.RequestMethod === "post" &&
      this.RequestUrl.endsWith("/api/Logging/Log")
    );
  }

  constructor(req: any, user_id: string, route: string) {
    this.RequestUrl = req.url;
    this.RequestMethod = req.method;
    this.RequestData = req.data || null;
    this.UserId = user_id;
    this.Route = route;
    this.Id = ++ApiRequest.max_id;
    (req as any).ltsapi_track_id = this.Id;
    this.Start = window.performance.now();
  }

  IsForResponse(resp: any): boolean {
    if (resp.config && resp.config.ltsapi_track_id) {
      return this.Id === resp.config.ltsapi_track_id;
    } else {
      return false;
    }
  }

  Complete(resp: any) {
    this.End = window.performance.now();
    this.ElapsedMS = this.End - this.Start;

    resp = resp.response || resp;

    this.ResponseData = resp.data;
    this.ResponseStatus = resp.status;

    if (!this.ResponseStatus) {
      this.FailureMsg = `Request Failed: Network Error! No response status.`;
    } else if (
      this.ResponseStatus &&
      (this.ResponseStatus < 200 || this.ResponseStatus >= 300)
    ) {
      this.FailureMsg = `Request Failed: ${this.ResponseStatus}`;
    } else if (this.ResponseData && this.ResponseData.Success === false) {
      this.FailureMsg = "Api returned a failure";
    }
  }
}

export default class ApiRequestTracker {
  private static enabled: boolean = false;
  private static active_requests: ApiRequest[] = [];
  private static logSecondsThreshold: number = 5;

  //this gets built before the config service if constructed here
  private static apiService: LoggingApiService | null = null;

  public static Init(seconds: number) {
    this.apiService = new LoggingApiService();
    this.logSecondsThreshold = seconds;
    this.enabled = true;
  }

  public static Add(req: object, user_id: string, route: string) {
    if (this.enabled) {
      this.active_requests.push(new ApiRequest(req, user_id, route));
    }
  }

  public static Complete(resp: object) {
    const req: ApiRequest | undefined = this.active_requests.find(x =>
      x.IsForResponse(resp)
    );

    if (req) {
      req.Complete(resp);
      this.active_requests.splice(this.active_requests.indexOf(req), 1);

      if (
        !req.IsLogMsg &&
        (req.IsFailure || req.ElapsedMS > this.logSecondsThreshold * 1000)
      ) {
        this.SaveLog(req);
      }
    }
  }

  private static SaveLog(req: ApiRequest) {
    if (this.apiService && this.enabled) {
      console.log("saving log..");
      console.log(req);

      const err_msg = req.IsFailure
        ? req.FailureMsg
        : "Api request took longer than set threshold";

      const ll: LogLevel = req.IsFailure ? LogLevel.Error : LogLevel.Warning;

      this.apiService
        .CreateLog(ll, err_msg, req)
        .then(resp => {
          if (!resp || !resp.Success) {
            console.log("Failed to save request. Disabling tracker.");
            this.enabled = false;
          } else {
            console.log(`Saved log.  Id: ${resp.Value}`);
          }
        })
        .catch(ex => {
          console.log(
            "An exception occurred saving log to dbase. Disabling tracker."
          );
          console.log(ex);
          this.enabled = false;
        });
    }
  }
}
