import Patch from "@/types/Patch";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import axios from "axios";
import SiteRegistration from "@/types/SiteRegistration/SiteRegistration";

export default class SiteRegistrationApiService extends BaseApiService {
  async PatchSiteRegistrations(
    registrations: SiteRegistration[]
  ): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/SiteRegistration`, registrations)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async CreateSiteRegistration(
    registration: SiteRegistration
  ): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/SiteRegistration`, registration)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async GetSiteRegistrationById(
    id: string
  ): Promise<UseCaseResponse<SiteRegistration>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/SiteRegistration/${id}`)
      .then(resp => resp.data as UseCaseResponse<SiteRegistration>);
  }

  async GetSiteRegistrationsBySubjectId(
    subjectId: string
  ): Promise<UseCaseResponse<SiteRegistration[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/SiteRegistration?subjectId=${subjectId}`)
      .then(resp => resp.data as UseCaseResponse<SiteRegistration[]>);
  }

  async DeleteSiteRegistration(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/SiteRegistration/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }
}
