
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ScreeningDocumentApiService from "../../services/ScreeningDocumentApiService";
import DataObject from "../../types/DataObject";
import ScreeningDocument from "../../types/ScreeningDocument/ScreeningDocument";
import DatePicker from "@/components/DatePicker.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    DatePicker,
    VMSSelect
  }
})
export default class ScreeningDocumentEdit extends Vue {
  @Prop(Object) value: ScreeningDocument | undefined;

  doc: ScreeningDocument = this.value || new ScreeningDocument();
  screeningDocApiService: ScreeningDocumentApiService = new ScreeningDocumentApiService();
  docTypeItems: object[] = [];
  docStatusItems: object[] = [];

  @Watch("value")
  onValueChanged() {
    this.doc = this.value || new ScreeningDocument();
  }

  mounted() {
    this.screeningDocApiService
      .All([
        this.screeningDocApiService.GetScreeningDocumentTypes(),
        this.screeningDocApiService.GetScreeningDocumentStatus()
      ])
      .then(resps => {
        const docTypes: DataObject = resps[0].Value;
        const docStatus: DataObject = resps[1].Value;

        for (let idx in docTypes) {
          this.docTypeItems.push({
            text: this.$t(docTypes[idx]),
            value: Number.parseInt(idx)
          });
        }

        for (let idx in docStatus) {
          this.docStatusItems.push({
            text: this.$t(docStatus[idx]),
            value: Number.parseInt(idx)
          });
        }
      });
  }
}
