import BaseType from "../BaseType";
import Guid from "../Guid";
import VolunteerHourType from "./VolunteerHourType";

export default class VolunteerLogItemHours extends BaseType {
  LogItemId: string = Guid.Empty;
  Hours: number = 0;
  HourTypeId: string | undefined;
  HourType: VolunteerHourType | undefined;
}
