var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pa-4"},[_c('v-card-subtitle',[_c('h4',[_vm._v(_vm._s(_vm.TrackingItemDialogTitle))])]),_c('v-card-text',[_c('v-form',{ref:"trackingItemForm"},[_c('SchoolLookup',{attrs:{"label":_vm.$t('SchoolOrganization'),"placeholder":_vm.$t('AutocompletePlaceholderText'),"rules":[
          () =>
            _vm.trackingItem.OrganizationId.length > 0 || _vm.$t('FieldIsRequired')
        ]},model:{value:(_vm.trackingItem.OrganizationId),callback:function ($$v) {_vm.$set(_vm.trackingItem, "OrganizationId", $$v)},expression:"trackingItem.OrganizationId"}}),_c('VMSTextField',{attrs:{"label":_vm.$t('SchoolOrganizationContact')},model:{value:(_vm.trackingItem.SentTo),callback:function ($$v) {_vm.$set(_vm.trackingItem, "SentTo", $$v)},expression:"trackingItem.SentTo"}}),_c('DatePicker',{attrs:{"label":_vm.$t('Sent')},model:{value:(_vm.trackingItem.SentDate),callback:function ($$v) {_vm.$set(_vm.trackingItem, "SentDate", $$v)},expression:"trackingItem.SentDate"}}),_c('DatePicker',{attrs:{"label":_vm.$t('ExpiryDate')},model:{value:(_vm.trackingItem.ExpiryDate),callback:function ($$v) {_vm.$set(_vm.trackingItem, "ExpiryDate", $$v)},expression:"trackingItem.ExpiryDate"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('RestrictedActivities')},on:{"change":function($event){_vm.trackingItem.RestrictedActivityComments = ''}},model:{value:(_vm.trackingItem.RestrictedActivities),callback:function ($$v) {_vm.$set(_vm.trackingItem, "RestrictedActivities", $$v)},expression:"trackingItem.RestrictedActivities"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('RestrictedActivitiesComments'),"disabled":!_vm.trackingItem.RestrictedActivities,"rules":[
          () =>
            _vm.trackingItem.RestrictedActivities === false ||
            _vm.trackingItem.RestrictedActivityComments.length > 0 ||
            `${_vm.$t('Field')} ${_vm.$t('IsRequired')}!`
        ]},model:{value:(_vm.trackingItem.RestrictedActivityComments),callback:function ($$v) {_vm.$set(_vm.trackingItem, "RestrictedActivityComments", $$v)},expression:"trackingItem.RestrictedActivityComments"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSaveTrackingItemClick}},[_vm._v(_vm._s(_vm.$t("Save")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onCancelClick}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }