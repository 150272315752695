
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  @Prop(Boolean) value: boolean | undefined;
  @Prop(String) id: String | undefined;

  confirm: boolean = false;

  @Watch("value")
  onValueChanged() {
    console.log("cd value changed");
    console.log(this.value);
    this.confirm = this.value || false;
  }

  onCancel() {
    this.confirm = false;
    this.$emit("input", this.confirm);
  }

  onConfirmed() {
    this.onCancel();
    this.$emit("confirmed");
  }
}
