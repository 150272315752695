import BaseType from "../BaseType";
import Enumeration from "../Enumeration/Enumeration";

export default class YouthDemographics extends BaseType {
  LowSocioEconomicStatus: boolean = false;
  BlackYouth: boolean = false;
  VisibleMinority: boolean = false;
  IndigenousYouth: boolean = false;
  PopulationDensityId: string = "";
  PopulationDensity: Enumeration | undefined;
  GenderIdentityId: string = "";
  GenderIdentity: Enumeration | undefined;
}
