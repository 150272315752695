
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AuthorizationService from "../../services/AuthorizationService";
import SecurityService from "../../services/SecurityService";

@Component
export default class VmsAppBar extends Vue {
  @Prop(Boolean) active!: boolean | undefined;

  drawer: boolean = this.active === undefined ? true : this.active;

  @Watch("active")
  onActiveChanged() {
    this.drawer = this.active === undefined ? true : this.active;
  }

  name: String = "VmsAppBar";
  adminItems: any = this.initAdminItems();
  authorizationService: AuthorizationService = AuthorizationService.getInstance();
  securityService: SecurityService = SecurityService.getInstance();

  get Admin() {
    return this.authorizationService.isAdmin();
  }

  initAdminItems() {
    return {
      configuration: [
        {
          title: "Sites",
          icon: "mdi-cog",
          link: "/sites"
        },
        { title: "Seasons", icon: "mdi-cog", link: "/seasons" },
        {
          title: "SubjectList",
          icon: "mdi-account-cowboy-hat",
          link: "/subjects"
        },
        {
          title: "Awards",
          icon: "",
          link: "/awards"
        },
        {
          title: "Kits",
          icon: "",
          link: "/kits"
        },
        { title: "EnumerationsView", icon: "mdi-cog", link: "/enumerations" },
        {
          title: "EnumerationListsView",
          icon: "mdi-cog",
          link: "/enumerations/lists"
        },
        {
          title: "HourClaiming",
          link: "/HourClaiming"
        }
      ],
      email: [
        {
          title: "AddressListsView",
          icon: "mdi-email",
          link: "/email/addresslists"
        },
        { title: "EmailList", icon: "mdi-email", link: "/emails" }
      ],
      awards: [
        {
          title: "AwardTypeList",
          icon: "",
          link: "/awardtypes"
        }
      ]
    };
  }

  onDrawerClick() {
    this.drawer = !this.drawer;
    this.$emit("update:active", this.drawer);
    console.log("event trigger : " + this.drawer);
  }

  lang(locale: string) {
    this.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;
  }

  onLogout() {
    this.securityService.signOut();
  }
}
