import axios from "axios";
import BaseApiService from "./BaseApiService";
import UseCaseResponse from "@/types/UseCaseResponse";
import OutreachRequest from "@/types/OutreachRequest/OutreachRequest";
import OutreachRequestSite from "@/types/OutreachRequest/OutreachRequestSite";
import OutreachRequestStatus from "@/types/OutreachRequest/OutreachRequestStatus";
import QueryOutreachRequestRequest from "@/types/OutreachRequest/QueryOutreachRequestRequest";
import CreateOutreachRequestStatusRequest from "@/types/OutreachRequest/CreateOutreachRequestStatusRequest";
import PagedUseCaseResponse from "@/types/PagedUseCaseResponse";

export default class OutreachRequestApiService extends BaseApiService {
  async GetAll(): Promise<UseCaseResponse<any[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/OutreachRequest`)
      .then(resp => resp.data as UseCaseResponse<any[]>);
  }

  async Query(
    query: QueryOutreachRequestRequest
  ): Promise<PagedUseCaseResponse<any>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/OutreachRequest/Query`, query)
      .then(resp => resp.data);
  }

  async GetBySiteId(siteId: string): Promise<UseCaseResponse<any[]>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/OutreachRequest?siteId=${siteId}`)
      .then(resp => resp.data as UseCaseResponse<any[]>);
  }

  async GetById(id: string): Promise<UseCaseResponse<OutreachRequest>> {
    await this.defineHeader();
    return axios
      .get(`${this.url}/api/vms/OutreachRequest/${id}`)
      .then(resp => resp.data as UseCaseResponse<OutreachRequest>);
  }

  async Create(or: OutreachRequest): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/OutreachRequest`, or)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async Update(or: OutreachRequest): Promise<UseCaseResponse<string>> {
    await this.defineHeader();
    return axios
      .put(`${this.url}/api/vms/OutreachRequest/${or.Id}`, or)
      .then(resp => resp.data as UseCaseResponse<string>);
  }

  async Delete(id: string): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .delete(`${this.url}/api/vms/OutreachRequest/${id}`)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async Patch(patches: any[]): Promise<UseCaseResponse<boolean>> {
    await this.defineHeader();
    return axios
      .patch(`${this.url}/api/vms/OutreachRequest`, patches)
      .then(resp => resp.data as UseCaseResponse<boolean>);
  }

  async CreateRequestSites(
    sites: OutreachRequestSite[]
  ): Promise<UseCaseResponse<number>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/OutreachRequest/Site`, sites)
      .then(resp => resp.data as UseCaseResponse<number>);
  }

  async CreateRequestStatus(
    update_requests: CreateOutreachRequestStatusRequest[]
  ): Promise<UseCaseResponse<number>> {
    await this.defineHeader();
    return axios
      .post(`${this.url}/api/vms/OutreachRequest/Status`, update_requests)
      .then(resp => resp.data as UseCaseResponse<number>);
  }
}
