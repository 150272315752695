
import UpdateOrganizationRequest from "@/types/Organization/UpdateOrganizationRequest";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import GetOrgEnumResponse from "@/types/Organization/GetOrgEnumResponse";
import OrganizationApiService from "@/services/OrganizationApiService";
import VMSTextField from "@/components/fields/VMSTextField.vue";
import VMSSelect from "@/components/fields/VMSSelect.vue";

@Component({
  components: {
    PhoneNumberInput,
    VMSTextField,
    VMSSelect
  }
})
export default class SchoolLookup extends Vue {
  @Prop(Object) value: UpdateOrganizationRequest | undefined;
  @Prop(Boolean) disabled: boolean | undefined;
  @Prop(String) id: string | undefined;

  orgApiService: OrganizationApiService = new OrganizationApiService();
  editOrg: UpdateOrganizationRequest =
    this.value || new UpdateOrganizationRequest();

  @Watch("value")
  onValueChanged() {
    this.editOrg = this.value || new UpdateOrganizationRequest();
  }

  orgGenderEnumerations: GetOrgEnumResponse[] = [];
  orgProvinceEnumerations: GetOrgEnumResponse[] = [];
  orgLanguageEnumerations: GetOrgEnumResponse[] = [];

  provinces: string[] = [
    "Yukon Territory",
    "Quebec",
    "Alberta",
    "Nunavut",
    "Manitoba",
    "Saskatchewan",
    "British Columbia",
    "Nova Scotia",
    "Northwest Territories",
    "Ontario",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Prince Edward Island",
    "Colombie-Britannique",
    "Nouveau-Brunswick",
    "Terre-Neuve-et-Labrador",
    "Nouvelle-Écosse",
    "Territoires du Nord-Ouest",
    "Île-du-Prince-Édouard",
    "Québec",
    "Territoire du Yukon"
  ];

  mounted() {
    this.orgApiService
      .All([
        this.orgApiService.GetEnumerationList(
          "SchoolClassType",
          this.$i18n.locale
        ),
        this.orgApiService.GetEnumerationList(
          "SchoolLanguage",
          this.$i18n.locale
        ),
        this.orgApiService.GetEnumerationList("Region", this.$i18n.locale)
      ])
      .then(r => {
        this.orgGenderEnumerations = r[0].Value;
        this.orgLanguageEnumerations = r[1].Value;

        let regionEnums: GetOrgEnumResponse[] = r[2].Value;
        this.orgProvinceEnumerations = regionEnums.filter(e =>
          this.provinces.includes(e.Text)
        );
      });
  }

  onOrgGenderInput() {
    let genderId: string = this.editOrg.GenderDistributionId;
    let orgGender: GetOrgEnumResponse =
      this.orgGenderEnumerations.find(
        (oer: GetOrgEnumResponse) => oer.Id == genderId
      ) || new GetOrgEnumResponse();

    if (orgGender.Id) {
      this.$emit("GenderInput", orgGender.Text);
    }
  }
}
