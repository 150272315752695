var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('VMSTextField',{attrs:{"label":_vm.$t('Search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":_vm.onAddClicked}},[_vm._v(_vm._s(_vm.$t("Localizations.AddNewKey")))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localizations,"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("NoErrorsMsg"))+" ")]},proxy:true},{key:`item.Actions`,fn:function({ item }){return [_c('td',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onSelectItem(item)}}},[_vm._v("Select")])],1)]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.adding),callback:function ($$v) {_vm.adding=$$v},expression:"adding"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("Localizations.AddKey")))]),_c('v-card-text',[_c('v-form',{ref:"key_form"},[_c('VMSTextField',{attrs:{"label":_vm.$t('Localizations.Key'),"rules":[
              () =>
                _vm.KeyUnique(_vm.add_key.Key) ||
                _vm.$t('Localizations.Validation.KeyMustBeUnique'),
              () =>
                (_vm.add_key.Key && _vm.add_key.Key.length > 0) ||
                _vm.$t('Localizations.Validation.KeyRequired')
            ]},model:{value:(_vm.add_key.Key),callback:function ($$v) {_vm.$set(_vm.add_key, "Key", $$v)},expression:"add_key.Key"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('Localizations.EnglishText'),"rules":[
              () =>
                (_vm.add_key.Localizations.en &&
                  _vm.add_key.Localizations.en.length > 0) ||
                _vm.$t('Localizations.Validation.EnglishRequired')
            ]},model:{value:(_vm.add_key.Localizations.en),callback:function ($$v) {_vm.$set(_vm.add_key.Localizations, "en", $$v)},expression:"add_key.Localizations.en"}}),_c('VMSTextArea',{attrs:{"label":_vm.$t('Localizations.FrenchText'),"rules":[
              () =>
                (_vm.add_key.Localizations.fr &&
                  _vm.add_key.Localizations.fr.length > 0) ||
                _vm.$t('Localizations.Validation.FrenchRequired')
            ]},model:{value:(_vm.add_key.Localizations.fr),callback:function ($$v) {_vm.$set(_vm.add_key.Localizations, "fr", $$v)},expression:"add_key.Localizations.fr"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":function($event){_vm.adding = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue darken-4"},on:{"click":_vm.onSaveKeyClicked}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }