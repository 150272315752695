import UpdateOrganizationRequest from "../Organization/UpdateOrganizationRequest";
import BaseType from "../BaseType";
import Guid from "../Guid";

export default class SchoolDemographicResponseItem extends BaseType {
  // client only
  //SchoolName: string = "";

  // entity
  SchoolDemographicResponseHeaderId: string = "";
  OrganizationId: string = Guid.Empty;
  NumberOfYouth: number = 0;
  Organization: UpdateOrganizationRequest | undefined;
}
